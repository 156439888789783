import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';

import { useToast } from '../context/Toast/ToastProvider';
import { ActivateAccount } from '../services/authService';
import LoadingSpinner from '../components/LoadingSpinner';
import { showToast, ToastType } from '../utils/ToastUtil';

const useStyles = makeStyles((theme) => ({
  submit: {
    backgroundColor: '#DA2037',
    margin: theme.spacing(3, 0, 2),
    borderRadius: '20px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#e84353',
    }
  },
  btnSecondary: {
    backgroundColor: '#002C54',
    '&:hover': {
      backgroundColor: '#003f77',
    },
    '& .MuiButton-startIcon': {
      marginLeft: -36,
      marginRight: 36,
    },
    margin: theme.spacing(0, 0, 4),
    borderRadius: '20px',
    textTransform: 'capitalize',
  },
  paper: {
    justifyContent: 'center',
    height: 200,
  }
}));

const Activate = ({ location }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const toastDispatch = useToast();
  
  useEffect(() => {
    let isMounted = true;
    const error = 'Ocurrió un error activando la cuenta';
    if (location.search) {
      try {
        const params = queryString.parse(location.search);
        ActivateAccount(params).then(() => {
          if (isMounted) {
            setSuccess(true);
          }
        }).catch(err => {
          setErrorMsg(`${error} ${err?.message || err?.description || err?.status || '' }`);
          showToast(toastDispatch, `${error} ${err?.message || err?.description || err?.status || '' }`, ToastType.ERROR, ToastType.ERROR)
        }).finally(() => setLoading(false));
      } catch (err) {
        setErrorMsg(error);
        showToast(toastDispatch, error, ToastType.ERROR, ToastType.ERROR);
      }
    } else if(history?.location?.state?.activated){
      setLoading(false);
      setSuccess(true);
    }
    return () => (isMounted = false);
 }, [location]);

  return (
    <div>
      {loading && <div className={classes.paper}><LoadingSpinner /></div>}
      {success ? (
        <div style={{color: '#002C54'}}>
          <h1>Tu cuenta ha sido activada!</h1>
          <h3>Ya podés iniciar sesión.</h3>
          <h3>Recordá completar la información de tu perfil antes de ser atendido.</h3>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => history.push("/login")}
            endIcon={<Icon>chevron_right</Icon>}
          >
            Ingresar
          </Button>
        </div>
      ) : (
        <div style={{marginTop: 100}}>
          <Alert severity="error">
            <AlertTitle><strong>Error</strong></AlertTitle>
            {errorMsg}
          </Alert>
        </div>
      )}
    </div>
  );
}

export default Activate;
