import React from "react";
export const RemoteVideo = ({ isProfessional }) => {
  return (
    <div
      id="remote"
      className="d-flex justify-content-center remote"
      style={
        isProfessional
          ? {
              height: "75vh",
              width: "70vw",
            }
          : {
              height: "75vh",
              width: "80vw",
            }
      }
    ></div>
  );
};
