import {
   MINUTES_AFTER_VIDEOCALL,
   MINUTES_BEFORE_VIDEOCALL,
} from '../conf/appSetting';
import {
   addMinutes,
   isBetweenDates,
   subtractMinutes,
   momentDate,
} from '../utils/DateUtil';

const appointmentStatusTranslated = {
   PROGRAMMED: 'Programado',
   FINISHED: 'Finalizado',
   CANCELLED: 'Cancelado',
};

// Helps to translate Appointment Status
export const appointmentStatusTranslate = (appointmentStatus) =>
   appointmentStatusTranslated[appointmentStatus];

// Helps to translate Appointment Status to plural and lowerCase
export const appointmentStatusTranslatePlural = (appointmentStatus) =>
   `${appointmentStatusTranslate(appointmentStatus)?.toLowerCase()}s`;

// Helps to sort a list of appointments from closest to furthest
export const sortDescAppointmentList = (appointmentList) =>
   appointmentList?.sort((a, b) => {
      let dateA = new Date(a?.appointment_date);
      let dateB = new Date(b?.appointment_date);
      return dateA - dateB;
   });

// Helps to know if an videocall is ready to get in
export const isVideocallReady = (appointmentDate) => {
   const appointmentDateParsed = new Date(appointmentDate);
   const currentDateTime = momentDate();
   const currentDateSubstracted = subtractMinutes(
      currentDateTime,
      MINUTES_BEFORE_VIDEOCALL,
   );
   const currentDateAdded = addMinutes(
      currentDateTime,
      MINUTES_AFTER_VIDEOCALL,
   );

   return isBetweenDates(
      appointmentDateParsed,
      currentDateSubstracted,
      currentDateAdded,
   );
};
