export default {
   formId: 'consultationForm',
   formField: {
      detail: {
         name: 'detail',
         label: 'Registro de Consulta',
         requiredErrorMsg: 'El registro de consulta es requerido',
         placeholder:
            'En este campo podrá agregar sus anotaciones de la consulta',
      },
      diagnosis: {
         name: 'diagnosis',
         nameProvitional: 'Diagnosis', //add Diagnosis because are many field call diagnosis and delay input fields 
         label: 'Diagnóstico',
         requiredErrorMsg: 'El diagnóstico es requerido',
      },
      reason: {
         name: 'reason',
         label: 'Motivo',
         maxErrorMsg: 'Máximo de caracteres alcanzado',
         requiredErrorMsg: 'El motivo es requerido',
      },
      specialties: {
         specialtyNames: {
            name: 'specialtyNames',
            label: 'Se sugiere atención médica especializada en',
            placeholder: 'Ingrese una especialidad',
         },
         comment: {
            name: 'comment',
            label: 'Comentario',
            placeholder: 'En este campo podrás agregar comentarios de la Derivación',
            requiredErrorMsg: 'El Comentario es requerido',
         },
         requiredErrorMsg: 'Debe agregar al menos una especialidad',
         addTooltip: 'Agregar Especialidad',
         uniqueMsg: 'La especialidad ya fue agregada',
         searchTextLabel: 'Nombre de la especialidad',
         searchTextRequired: 'El nombre de la especialidad es requerido'
      },
      studies: {
         studyNames: {
            name: 'studyNames',
            placeholder: 'Ingrese un estudio',
            requiredErrorMsg: 'El nombre de estudio es requerido',
         },
         requiredErrorMsg: 'Debe agregar al menos un estudio',
      },
      certificates: {
         unit: {
            name: 'unit',
            label: 'Por un plazo de',
            requiredErrorMsg: 'La unidad del plazo es requerida',
         },
         duration: {
            name: 'duration',
            label: 'Por un plazo de',
            requiredErrorMsg: 'El tipo de duración es requerida',
         },
         indications: {
            name: 'indications',
            label: 'Por lo que sugiero ',
            requiredErrorMsg: 'Indicaciones es requerido',
         },
         institution: {
            name: 'institution',
            label: 'Para ser presentado ante ',
            requiredErrorMsg: 'Debe ingresar una institución',
         },
      },
      medications: {
         name: 'medications',
         medicationName: {
            requiredErrorMsg: 'El nombre es requerido',
         },
         posology: {
            requiredErrorMsg: 'La posología es requerida',
         },
         quantity: {
            requiredErrorMsg: 'La cantidad es requerida',
            numberError: 'Ingresa sólo números'
         },
         uniqueMsg: 'El medicamento ya fue agregado',
         requiredErrorMsg: 'Debe agregar al menos un medicamento o indicación',
      },
      other_indications: {
         name: 'other_indications',
         label: 'Indicaciones',
         placeholder: 'Ingrese indicación',
         rows: 4
      }
   },
};
