import React, { useState, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { formatDate } from '../../../../../utils/DateUtil';

const OrderListItem = ({
   purchaseOrderId,
   status,
   pharmacy,
   branchOffice,
   quotedDate,
   sentDate,
   total,
   expiration
}) => {

   const [style, setStyle] = useState(false)
   const [date, setDate] = useState()


   useEffect(() => {
      if (status === "QUOTED") {

         setStyle(true)
         setDate(quotedDate)

      } else {

         setStyle(false)
         setDate(sentDate)

      }
   }, [status])
   
   let dates = formatDate(date);
   let expirationDate = formatDate(expiration);
   let parseTotal = total?.toFixed(2)

   return (
      <ListItem className="list-item">
         <div className="item-top">
            <ListItemText
               className="item-text"
               primary={`Pedido N° ${purchaseOrderId} - ${dates}`}
               secondary={
                  <span className="pharmacy-venc">
                     <span className="pharmacy">{`${pharmacy} - ${branchOffice}`}</span>
                     {style ? <span className="venc">{`Vto: ${expirationDate}`}</span> : null}
                  </span>
               }
            />
            <Chip className={style ? "COTIZADO" : "ENVIADO"} label={style ? "COTIZADO" : "ENVIADO"} />
         </div>
         <div className="item-bottom">
            <span>{`Total: $${parseTotal}`}</span>
         </div>
      </ListItem>
   );
};

export default OrderListItem;
