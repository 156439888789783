import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { ApplicationStyle } from '../../../../theme';
import { makeStyles } from '@material-ui/core/styles';
import { useStateProfConsultFilterValue } from '../../../../context/profConsultFilterState';
import { useStateAuthValue } from '../../../../context/Auth/AuthState';
import { FilterType } from '../../../../utils/FilterUtil';
import PhoneIcon from '@material-ui/icons/Phone';
import CancelIcon from '@material-ui/icons/Clear';
import { EventLog } from '../../../../services/medilinePrepaidService';
import firebase from '../../../../services/firebase';
const MedilineStyle = makeStyles(ApplicationStyle);

const ConsultationReason = ({
  specialty,
  isComplete,
  setPatientData,
  patientData,
  stompClient,
  patientLoginData,
}) => {
  const [{ userData, userToken }] = useStateAuthValue();
  const id = userData?.patient_dto?.auth_id;
  const [reason, setReason] = useState('');
  const MedilineClasses = MedilineStyle();
  const [, dispatch] = useStateProfConsultFilterValue();

  useEffect(() => {
    if (reason) {
      setPatientData({
        ...patientData,
        reason: reason,
      });
    }
  }, [reason]);
  // get ws order
  const getOrder = async () => {
    var wsMessage = {
      familymember: patientLoginData,
      patientId: id,
      specialtyId: specialty,
      command: 'GetOrder',
      reason: reason,
    };
    firebase.post({...wsMessage});
    stompClient.send('/app/message', {}, JSON.stringify(wsMessage));
    await EventLog(userToken, "enter queue", "paciente", wsMessage)
  };

  return (
    <div className={MedilineClasses.btnBoxCenterM0}>
      <div
        className={MedilineClasses.titleBlue}
        style={{ margin: '2rem 0 1rem 0' }}
      >
        Motivo de Consulta
      </div>
      <div style={{ marginLeft: '10px' }}>
        <TextField
          id="standard-multiline-static"
          label="Ingresar aqui el motivo de su consulta"
          multiline
          minRows={6}
          style={{ width: "100%" }}
          fullWidth={false}
          inputProps={{ maxLength: 256 }}
          onChange={(evt) => setReason(evt.target.value)}
        />
      </div>
      <div
        className={MedilineClasses.titleBlue}
        style={{ margin: '1rem 0 2rem 0', fontSize: '20px' }}
      >
        Llamar al profesional
      </div>
      <div className={MedilineClasses.btnSpacing} style={{ marginTop: '1rem' }}>
        <Grid container spacing={3}>
          <Grid
            container
            xs={6}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <div
              onClick={() => {
                if (isComplete && reason.length !== 0) {
                  userData.patient_dto.username = userData.username;
                  userData.patient_dto.email = userData.email;
                  const reasonText = reason?.length ? reason.trim() : null;
                  setPatientData({
                    patientId: id,
                    professionalId: '',
                    profesionalName: '',
                    specialtyId: specialty,
                    consult: reasonText,
                    queueId: '',
                    connected: true,
                    orderNumber: '',
                  });
                  dispatch({
                    type: 'LOADING',
                    clickButton: FilterType.SEARCH_PROFESSIONAL,
                    loading: false,
                  });
                  dispatch({
                    type: 'VIRTUAL_GUARD',
                    stomp: stompClient,
                    data: patientData,
                  });
                  getOrder();
                }
              }}
              style={{
                backgroundColor:
                  isComplete && reason.length !== 0 ? '#489633' : '#bdbdbd',
                padding: '10px',
                width: 'fit-content',
                borderRadius: '40px',
                position: 'relative',
                cursor:
                  isComplete && reason.length !== 0 ? 'pointer' : 'default',
              }}
            >
              <PhoneIcon
                className="icon"
                style={{
                  fill: '#fff',
                  height: '40px',
                  width: '40px',
                }}
              />
            </div>
          </Grid>
          <Grid
            container
            xs={6}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <div
              onClick={() => {
                dispatch({
                  type: 'LOADING',
                  clickButton: FilterType.FINISH_CONSULTATION,
                  loading: false,
                });
                localStorage.setItem('consult', null);
              }}
              style={{
                backgroundColor: '#d22a2a',
                padding: '10px',
                width: 'fit-content',
                borderRadius: '40px',
                position: 'relative',
                cursor: 'pointer',
              }}
            >
              <CancelIcon
                className="icon"
                style={{
                  fill: '#fff',
                  height: '40px',
                  width: '40px',
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ConsultationReason;
