import React from 'react';

import MedilineLogo from '../../../assets/img/logo-mediline-white.svg';

const LoginSlider = () => {
   return (
      <div className="login-slider">
         <img
            className="login-slider__logo"
            src={MedilineLogo}
            alt="Mediline"
         />
         <div className="icon-text">
            <div className="slider-icon">
               <img alt="Item menú" />
            </div>
            <span className="slider-text" />
         </div>
      </div>
   );
};

export default LoginSlider;
