import React, { useEffect, useState } from 'react';

import { useStateAuthValue } from '../../../../context/Auth/AuthState';
import { prescriptionMedication } from '../../../../services/pharmacyService';
import { formatDate } from '../../../../utils/DateUtil';
import { orderFormatedNumber } from '../../../../utils/OrderFormatedNumber';

const PrescriptionOfOrder = ({
   idPrescription,
   createdDate,
   diagnosis,
   professionalName,
   numberPrescription,
   professionalLicense
}) => {
   const [{ userToken }] = useStateAuthValue();
   const [medication, setMedication] = useState([]);
   const [total, setTotal] = useState();

   useEffect(() => {
      if (userToken) {
         getData();
      }
   }, [userToken]);

   const getData = async () => {
      await prescriptionMedication(idPrescription, userToken)
         .then((response) => {
            if (response?.content?.length) {
               let sum = 0;
               response.content.forEach(medicationItem => {
                  medicationItem.subtotal = medicationItem.unitPrice * medicationItem.quantity;
                  medicationItem.calculatedDiscount = medicationItem.subtotal * medicationItem.discount / 100;
                  medicationItem.total = medicationItem.subtotal - medicationItem.calculatedDiscount;
                  sum += medicationItem.total;
               });
               setTotal(sum);
            }
            setMedication(response.content);
         })
         .catch();
   };

   return (
      <div className="list-item-prescription">
         <div className="presc-data">
            <div className="id-state">
               <span>Receta N°</span>
               <span className="id-state__id">{numberPrescription}</span>
            </div>
            <div className="detail">
               <span>Fecha de emisión: {formatDate(createdDate)}</span>
               <span>Diagnóstico: {diagnosis}</span>
               <div className="professional-data">
                  <span className="professional-data__professional">
                     Profesional:
                  </span>
                  <span className="name">{professionalName}</span>
               </div>
               <div className="enrollment">
                  <span className="enrollment__label">Matrícula:</span>
                  <span className="enrollment__value">
                     {professionalLicense}
                  </span>
               </div>
            </div>
         </div>

         <div className="description-prescription">
            <div className="collapse-presctiption">
               <div className="cant-descrip">
                  <span className="cant">Cant.</span>
                  <span className="description">Descripción</span>
                  <span className="price">Precio</span>
                  <span className="price">Subtotal</span>
                  <span className="min">Cob.</span>
                  <span className="min">Desc %</span>
                  <span className="price">Desc $</span>
                  <span className="price">Total</span>
               </div>
               <div className="medicine">
                  {medication?.map((data) => {
                     return (
                        <div key={data?.id} className="medication">
                           <span className="medicine-number">
                              {data?.quantity}
                           </span>
                           <span className="medicine-name">{data?.name}</span>
                           <div className="medicine-price">$ {orderFormatedNumber(data.unitPrice)}</div>
                           <div className="medicine-price"> $ {orderFormatedNumber(data.subtotal)}</div>
                           <div className="medicine-min"><input checked={data?.covered} type="checkbox" /></div>
                           <div className="medicine-min"> {data?.discount ? `${data.discount} %` : ''}</div>
                           <div className="medicine-price"> $ {orderFormatedNumber(data.calculatedDiscount)}</div>
                           <div className="medicine-price"> $ {orderFormatedNumber(data.total)}</div>
                        </div>
                     );
                  })}
                  <div className="medication">
                     <span className="medicine-number"/>
                     <span className="medicine-name"/>
                     <div className="medicine-price"/>
                     <div className="medicine-price"/>
                     <div className="medicine-min"/>
                     <div className="medicine-min"/>
                     <div className="medicine-price">Total receta</div>
                     <div className="medicine-price">{total ? `$ ${orderFormatedNumber(total)}` : '$ 0.00'}</div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default PrescriptionOfOrder;
