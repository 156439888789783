import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Link, List, Tooltip } from '@material-ui/core';
import RefreshRounded from '@material-ui/icons/RefreshRounded';

import TurnListItem from './Item';
import HeaderImage from '../../../../assets/img/appointment-icon.svg';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import Appointment from '../../../../models/Appointment';
import AppointmentStatus from '../../../../models/enums/AppointmentStatus';
import { useStateAuthValue } from '../../../../context/Auth/AuthState';
import { useToast } from '../../../../context/Toast/ToastProvider';
import {
   getAppointments,
   putAppointment,
} from '../../../../services/appointmentService';
import { sortDescAppointmentList } from '../../../../utils/AppointmentUtil';
import { formatDate, addMonths } from '../../../../utils/DateUtil';
import { removeIndexFromList } from '../../../../utils/FormUtil';
import { OK } from '../../../../utils/ServiceUtil';
import { showToast, ToastType } from '../../../../utils/ToastUtil';

const TurnList = () => {
   const [{ userToken }] = useStateAuthValue();
   const [appointments, setAppointments] = useState([]);
   const [loading, setLoading] = useState(false);
   const toastDispatch = useToast();
   const [selectedAppointment, setSelectedAppointment] = useState();
   const [openModal, setOpenModal] = useState(false);
   const history = useHistory();

   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
   };

   useEffect(() => {
      let isMounted = true;
      if (userToken) {
         fetchAppointments(isMounted);
      }
      return () => (isMounted = false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userToken]);

   const fetchAppointments = async (isMounted) => {
      //commented to use for future requirements
      /* if (isMounted) {
         setLoading(true);
      }
      const errorMessage =
         'Ocurrió un error en la búsqueda de turnos programados';
      const nextMonth = formatDate(addMonths(new Date(), 1));
      const today = formatDate(new Date());

      await getAppointments(
         {
            statuses: [AppointmentStatus.PROGRAMMED],
            dateFrom: [today],
            dateTo: [nextMonth],
         },
         userToken,
         showError,
      )
         .then((response) => {
            if (isMounted && response.status === OK) {
               const appointmentDtos = response.data.map((appointment) => {
                  var appointmentDto = Appointment.AppointmentDto(
                     appointment?.id,
                     appointment?.appointment_date,
                     appointment?.status,
                     appointment?.patient_dto,
                     appointment?.professional_dto,
                  );
                  appointmentDto.consultation_id = appointment?.consultation_id;
                  appointmentDto.specialty = appointment?.specialty?.name;
                  return appointmentDto;
               });
               setAppointments(sortDescAppointmentList(appointmentDtos));
            } else {
               showError(errorMessage);
            }
         })
         .catch(() => {
            showError(errorMessage);
         })
         .finally(() => setLoading(false)); */
   };

   const handleClose = (confirmResponse) => {
      setOpenModal(false);

      if (confirmResponse) {
         const errorMessage = 'Ocurrió un error al cancelar el turno';
         setLoading(true);
         putAppointment(
            selectedAppointment?.id,
            { status: AppointmentStatus.CANCELLED },
            userToken,
            showError,
         )
            .then((response) => {
               if (OK === response?.status.toUpperCase()) {
                  removeIndexFromList(
                     selectedAppointment.index,
                     appointments,
                     setAppointments,
                  );
               } else {
                  showError(errorMessage);
               }
               setSelectedAppointment(null);
            })
            .catch(() => showError(errorMessage))
            .finally(() => setLoading(false));
      }
   };

   const disableDivStyles = { 
      pointerEvents: "none",
      opacity: "35%"
   }

   return (
      <div className="turn">
         <div style={disableDivStyles} className="title-wrapper">
            <div className="title-see-all">
               <img className="image" src={HeaderImage} alt="Turnos" />
               <Link
                  className="title"
                  onClick={() => {
                     history.push('/paciente/mis-turnos');
                  }}
               >
                  Turnos
               </Link>
            </div>
            <Tooltip title="Actualizar Turnos" component={'span'}>
               <IconButton
                  disabled={loading}
                  onClick={() => fetchAppointments(true)}
               >
                  <RefreshRounded />
               </IconButton>
            </Tooltip>
         </div>
         {loading ? (
            <LoadingSpinner />
         ) : (
            <div className="list">
               <List>
                  {appointments.map((item, index) => (
                     <TurnListItem
                        item={item}
                        key={item.id}
                        handleDelete={() => {
                           item.index = index;
                           setSelectedAppointment(item);
                           setOpenModal(true);
                        }}
                     ></TurnListItem>
                  ))}
               </List>
            </div>
         )}
         <ConfirmationModal
            open={openModal}
            handleClose={handleClose}
            title="¿Deseas cancelar el turno?"
            confirmButton="Confirmar"
            cancelButton="Volver"
            maxWidth="sm"
         />
      </div>
   );
};

export default TurnList;
