import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useStateAuthValue } from "../../context/Auth/AuthState";
import { GetSessionToken, ProfessionalLog } from "../../services/authService";
import { ToastType, showToast } from "../../utils/ToastUtil";
import { useToast } from "../../context/Toast/ToastProvider";
import SockJS from "sockjs-client";
import { over } from "stompjs";
import { useProfessionalLogState } from "../../context/profesionalPage/professionalLog/professionalLogState";

export const ProfessionalLogHook = () => {
  const [{ userData, userToken }] = useStateAuthValue();
  const [{ stompClient }, dispatch] = useProfessionalLogState();
  const toastDispatch = useToast();
  const showError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
  const showSucces = (msg) =>
    showToast(toastDispatch, msg, ToastType.SUCCESS, ToastType.SUCCESS);

  const [isConnected, setIsConnected] = useState(true);

  window.ononline = () => {
    if (
      !isConnected &&
      userData?.active_role === "PROFESSIONAL" &&
      GetSessionToken()
    ) {
      if (_stompClient.current == null) {
        attemptReconnect();
      }
      setIsConnected(true);
      showSucces("Professional Conectado");
    }
  };
  window.onoffline = () => {
    setIsConnected(false);
    showError("Professional Desconectado");
    if (_stompClient.current) {
      _stompClient.current.disconnect(() => {
        console.error("Error en la conexión WS");
        isConnectedWS = false;
        attemptReconnect();
      });
    }
  };

  const _stompClient = useRef(null);
  const UrlWS = process.env.REACT_APP_WS_PARAMETRICS_URL;
  const reconnectDelay = 2000; // 5 segundos de espera antes de reconectar
  let isConnectedWS = false;
  let isReconnecting = false; // Bandera para controlar si ya estamos intentando reconectar
  let fakePositive = true;
  const connectToWS = () => {
    fakePositive = true;
    const socket = new SockJS(UrlWS);
    _stompClient.current = over(socket);
    _stompClient.current.connect(
      {},
      (frame) => {
        // Conexión exitosa
        isConnectedWS = true;
        isReconnecting = false; // Reseteamos la bandera porque ya estamos conectados
        console.log("Conexión WS exitosa:", frame);

        dispatch({
          type: "SET_STOMP_CLIENT",
          payload: _stompClient.current,
        });

        _stompClient.current.subscribe(
          "/user/" + userData.email + "/ping",
          onPrivateMessageProfessional
        );

        dispatch({
          type: "SEND_MESSAGE",
          payload: {
            message: {
              professionalId: userData.email,
              command: "InitialPong",
            },
          },
        });
      },
      (error) => {
        // Manejar error de conexión y reconectar
        console.error("Error en la conexión WS:", error);
        isConnectedWS = false;
        fakePositive = false;
        attemptReconnect();
      }
    );

    _stompClient.current.onclose = () => {
      // Manejar cierre de conexión y reconectar
      console.warn("Conexión WS cerrada. Intentando reconectar...");
      isConnectedWS = false;
      fakePositive = false;
      attemptReconnect();
    };
    setTimeout(() => {
      if (fakePositive) {
        if (!isConnectedWS && !isReconnecting) {
          attemptReconnect();
        }
      }
    }, reconnectDelay);
  };
  const attemptReconnect = () => {
    if (!isConnectedWS && !isReconnecting) {
      isReconnecting = true; // Marcamos que estamos intentando reconectar
      console.log("Intentando reconectar...");
      setTimeout(() => {
        isReconnecting = false;
        connectToWS();
      }, reconnectDelay);
    }
  };

  let lastPingTime = Date.now();
  let intervalId = null;
  const onPrivateMessageProfessional = (message) => {
    if (userData?.active_role === "PROFESSIONAL" && GetSessionToken()) {
      switch (message.body) {
        case "ping":
          lastPingTime = Date.now();
          dispatch({
            type: "SEND_MESSAGE",
            payload: {
              message: {
                professionalId: userData.email,
                command: "Pong",
              },
            },
          });
          // Reiniciar el intervalo
          resetInterval();
          break;
        case "conexion-lost":
          if (_stompClient.current && _stompClient.current.connected) {
            _stompClient.current.disconnect(() => {
              console.error("Desconexion por inactividad");
              isConnectedWS = false;
              attemptReconnect();
            });
          }
          if (intervalId) {
            clearInterval(intervalId); // Detenemos el intervalo actual si existe
          }
          break;
        case "conexion-ended":
          if (_stompClient.current && _stompClient.current.connected) {
            _stompClient.current.disconnect(() => {
              console.info("Finalizo Conexion");
              isConnectedWS = false;
              //
            });
            if (intervalId) {
              clearInterval(intervalId); // Detenemos el intervalo actual si existe
            }
          }
          break;

        default:
          break;
      }
    }
  };
  // Función para verificar si pasaron más de 5 segundos desde el último ping
  function checkLastPingTime() {
    const currentTime = Date.now(); // Obtenemos el tiempo actual
    //console.log(`PingDiff - ${currentTime - lastPingTime}`);

    if (currentTime - lastPingTime > 4000) {
      if (intervalId) {
        clearInterval(intervalId); // Detenemos el intervalo actual si existe
      }
    }
  }
  // Función para iniciar o reiniciar el intervalo
  function resetInterval() {
    if (intervalId) {
      clearInterval(intervalId); // Detenemos el intervalo actual si existe
    }
    intervalId = setInterval(checkLastPingTime, 1000); // Iniciamos un nuevo intervalo
  }
  useEffect(() => {
    if (userData?.active_role === "PROFESSIONAL" && GetSessionToken()) {
      //console.log("ADDED window.addEventListener(unload)");
      window.addEventListener("unload", () => {
        if (userData?.active_role === "PROFESSIONAL" && GetSessionToken()) {
          dispatch({
            type: "SEND_MESSAGE",
            payload: {
              message: {
                professionalId: userData.email,
                command: "EndedUnexpectedly",
              },
            },
          });
        }
      });
      setTimeout(() => {
        if (_stompClient.current == null) {
        if (_stompClient.current != stompClient) {
          _stompClient.current = stompClient;
          _stompClient.current.subscribe(
            "/user/" + email + "/ping",
            onPrivateMessageProfessional
          );
        }else connectToWS();
      }
      }, 2000);
      
    } else {
      dispatch({
        type: "SEND_MESSAGE",
        payload: {
          message: {
            professionalId: userData.email,
            command: "EndedUnexpectedly",
          },
        },
      });
    }
  }, [userToken, userData]);

  useEffect(() => {
    if (stompClient == null) {
      if (_stompClient.current?.connected)
        _stompClient.current?.disconnect(() => {});
      _stompClient.current = null;
    } else {
      if (_stompClient.current != stompClient) {
        _stompClient.current = stompClient;
        _stompClient.current.subscribe(
          "/user/" + userData.email + "/ping",
          onPrivateMessageProfessional
        );
      }
    }
  }, [stompClient]);

  return <div id="professional-log" style={{ position: "absolute" }}></div>;
};

export const connectToWSProfessionalLog = (email, userData, dispatch) => {
  let _stompClient = null;
  const UrlWS = process.env.REACT_APP_WS_PARAMETRICS_URL;
  const socket = new SockJS(UrlWS);
  _stompClient = over(socket);
  _stompClient.connect(
    {},
    (frame) => {
      // Conexión exitosa

      console.log("Conexión WS exitosa:", frame);

      dispatch({
        type: "SET_STOMP_CLIENT",
        payload: _stompClient,
      });

      dispatch({
        type: "SEND_MESSAGE",
        payload: {
          message: {
            professionalId: email,
            command: "LogInPong",
          },
        },
      });
    },
    (error) => {
      // Manejar error de conexión y reconectar
      dispatch({
        type: "SET_STOMP_CLIENT",
        payload: null,
      });
    }
  );

  _stompClient.onclose = () => {
    // Manejar cierre de conexión y reconectar
    dispatch({
      type: "SET_STOMP_CLIENT",
      payload: null,
    });
  };
  
};
