import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';

const Paid = () => (
   <div className="paid">
      <div className="header">
         <h3>Pagadas</h3>
      </div>
      <List className="list">
         <ListItem className="list-item">
            <ListItemText
               className="item-text item-text--query-type"
               primary="PARTICULAR"
            />
            <ListItemText
               className="item-text item-text--price"
               primary="$40.575"
            />
         </ListItem>
         <ListItem className="list-item">
            <ListItemText
               className="item-text item-text--query-type"
               primary="STAFF MEDILINE"
            />
            <ListItemText
               className="item-text item-text--price"
               primary="$40.575"
            />
         </ListItem>
         <ListItem className="list-item">
            <ListItemText
               className="item-text item-text--query-type"
               primary="COSEGUROS"
            />
            <ListItemText
               className="item-text item-text--price"
               primary="$40.575"
            />
         </ListItem>
         <ListItem className="list-item list-item--total">
            <ListItemText
               className="item-text item-text--query-type"
               primary="TOTAL"
            />
            <ListItemText
               className="item-text item-text--price"
               primary="$121.725"
            />
         </ListItem>
      </List>
   </div>
);

export default Paid;
