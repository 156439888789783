import React, { useEffect, useState } from 'react';
import { List } from '@material-ui/core';
import ScheduleItem from './ScheduleItem';
import ScheduleCreateBtn from './ScheduleCreateBtn';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import { showToast, ToastType } from '../../../utils/ToastUtil';
import { removeIndexFromList } from '../../../utils/FormUtil';
import { GetScheduleByProfessional, ScheduleDelete, ScheduleValidate, ScheduleParse } from '../../../services/scheduleService';
import { AttentionType } from "../../../tests/mocks/Patient/MockSelectorData";
import { useStateAuthValue } from '../../../context/Auth/AuthState';
import { useToast } from '../../../context/Toast/ToastProvider';

const ScheduleList = () => {
   const [{ userData, userToken }] = useStateAuthValue();
   const toastDispatch = useToast();
   const [scheduleByAttentionTypeList, setScheduleByAttentionTypeList] = useState([]);
   const [loading, setLoading] = useState(true);
   const [openModal, setOpenModal] = useState(false);
   const [submitting, setSubmitting] = useState(false);
   const [requestDelete, setRequestDelete] = useState();

   const getData = async (isMounted) => {
     if (isMounted) {
       setLoading(true);
     }
     await GetScheduleByProfessional(userData?.professional_dto?.id, userToken,
      (sessionError) => showToast(toastDispatch, sessionError, ToastType.ERROR, ToastType.ERROR))
       .then((response) => {
         if (!response.data.error) {
           const schedules = ScheduleParse(response.data, userData, AttentionType);
           if (isMounted) {
              setScheduleByAttentionTypeList(schedules);
              setLoading(false);
           }
         } else {
           showToast(
             toastDispatch,
             "Ocurrió un error en la búsqueda de disponibilidades",
             ToastType.ERROR
           );
         }
       })
       .catch((err) => {
         showToast(toastDispatch, err.message, ToastType.ERROR);
       });
   };

   const removeScheduleItem = (index) => {
      let errorMessage ='Ocurrió un error tratando de eliminar la agenda';
      if (index || index === 0) {
         setSubmitting(true);
         const request = {
            schedule: {
               schedule_ids: scheduleByAttentionTypeList[index].id
            },
            index: index
         };
         setRequestDelete(request);
         ScheduleValidate(request.schedule, userToken,
            (sessionError) => showToast(toastDispatch, sessionError, ToastType.ERROR, ToastType.ERROR)).then(response => {
            if (response.status === 'CONFLICT') {
               setOpenModal(true);
            } else if (response.status === 'error') {
               showToast(toastDispatch, errorMessage, ToastType.ERROR);
            } else {
               showToast(toastDispatch, 'La agenda fue eliminada correctamente', ToastType.SUCCESS);
               removeIndexFromList(index, scheduleByAttentionTypeList, setScheduleByAttentionTypeList)
            }
         }).catch(err =>
            showToast(toastDispatch, err?.message || errorMessage, ToastType.ERROR)
         ).finally(() => setSubmitting(false));
     }
   };

   const handleClose = (confirmResponse) => {
      if (confirmResponse && requestDelete) {
         let errorMessage ='Ocurrió un error tratando de eliminar la agenda con turnos';
         ScheduleDelete(requestDelete.schedule, userToken,
            (sessionError) => showToast(toastDispatch, sessionError, ToastType.ERROR, ToastType.ERROR)).then(response => {
            if (response.status === 'error') {
               showToast(toastDispatch, errorMessage, ToastType.ERROR);
               getData(true);
            } else {
               showToast(toastDispatch, 'La agenda fue eliminada correctamente', ToastType.SUCCESS);
               removeIndexFromList(requestDelete.index, scheduleByAttentionTypeList, setScheduleByAttentionTypeList);
               setRequestDelete(null);
            }
            setOpenModal(false);
         }).catch(err =>
            showToast(toastDispatch, err?.message || errorMessage, ToastType.ERROR)
         );
      } else {
         setOpenModal(false);
      }
   };

   useEffect(() => {
      let isMounted = true;
      if (userToken) {
         getData(isMounted);
      }
      return () => {
         isMounted = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userToken]);

   const renderList = () => {
      if (scheduleByAttentionTypeList.length) {
         return (
            <div className="list">
               <List>
                  { scheduleByAttentionTypeList.map((schedule, index) =>
                     {
                        return (<ScheduleItem
                           schedule={schedule}
                           index={index}
                           key={index}
                           remove={() => {
                              if (!submitting) {
                                 removeScheduleItem(index);
                              }
                           }}
                           removeTooltip="Eliminar Disponibilidad"
                        />);
                     }
                  )}
               </List>
            </div>
         );
      } else {
         return (
            <div className="empty">
               <p className="message">No hay disponibilidades creadas.</p>
            </div>
         );
      }
   };
   return (
     <div className="schedule-list schedule-list--time-availability">
       <div className="btn-wrapper">
         <ScheduleCreateBtn
           refreshSchedules={(mounted) => getData(mounted)}
           removeTooltip="Eliminar disponibilidad"
         />
       </div>
       {loading ? <LoadingSpinner /> : renderList()}

      <ConfirmationModal
         open={openModal}
         handleClose={handleClose}
         title="Existen turnos programados en esta fecha"
         subtitle="Los pacientes serán notificados de su cancelación ¿Desea continuar?"
         confirmButton="Eliminar agenda"
         maxWidth='sm'
         cancelButton="Cancelar"
      />
     </div>
   );
};

export default ScheduleList;
