import React from 'react';

const AddressDetail = ({ formValues }) => {
   const {
      address_dto: {
         street,
         number,
         number_department,
         floor,
         postal_code,
         between_streets,
         city,
         country,
         province,
      },
   } = formValues;

   return (
      <div className="detail">
         <div className="header header--detail">
            <h2>Dirección</h2>
         </div>
         <div className="data-wrapper">
            <span className="data">
               Calle:<span className="bold">{street}</span>
            </span>
            <span className="data">
               Número:<span className="bold">{number}</span>
            </span>
            <span className="data">
               Departamento:
               <span className="bold">{number_department || '-'}</span>
            </span>
            <span className="data">
               Piso:<span className="bold">{floor || '-'}</span>
            </span>
            <span className="data">
               Código Postal:<span className="bold">{postal_code}</span>
            </span>
            <span className="data">
               Entre calles:
               <span className="bold">{between_streets || '-'}</span>
            </span>
            <span className="data">
               Ciudad:<span className="bold">{city}</span>
            </span>
            <span className="data">
               Provincia:<span className="bold">{province}</span>
            </span>
            <span className="data">
               País:<span className="bold">{country}</span>
            </span>
         </div>
      </div>
   );
};

export default AddressDetail;
