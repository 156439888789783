import React, { useEffect, useState } from 'react';

import {
   AutocompleteField,
   CheckboxField,
} from '../../../../components/FormFields';
import { GetPrepaids } from '../../../../services/userService';
import { useStateAuthValue } from '../../../../context/Auth/AuthState';
import { showToast, ToastType } from '../../../../utils/ToastUtil';
import { useToast } from '../../../../context/Toast/ToastProvider';

const AttentionDataForm = ({ formModel }) => {
   const {
      professional_dto: {
         attention_particular,
         prepaid_healths,
      },
   } = formModel;

   const [{ userToken }] = useStateAuthValue();
   const toastDispatch = useToast();
   const [prepaidHealthsList, setPrepaidHealthsList] = useState([]);

   useEffect(() => {
      let isMounted = true;

      const getData = async () => {
         await GetPrepaids(userToken, (sessionError) => showToast(toastDispatch, sessionError, ToastType.ERROR, ToastType.ERROR))
            .then((response) => {
               if (!response.data.error && isMounted) {
                  setPrepaidHealthsList(response.data);
               } else {
                  showToast(
                     toastDispatch,
                     'Ocurrió un error en la búsqueda de obras sociales',
                     ToastType.ERROR,
                  );
               }
            })
            .catch((err) => {
               showToast(toastDispatch, err.message, ToastType.ERROR);
            });
      };

      if (userToken) {
         getData();
      }
      return () => {
         isMounted = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userToken]);

   return (
      <>
         <div className="header">
            <h2>Datos de Atención</h2>
         </div>
         <div className="autocomplete-wrapper">
            <CheckboxField
               name={attention_particular.name}
               label={attention_particular.label}
            />
            <AutocompleteField
               name={prepaid_healths.name}
               label={prepaid_healths.label}
               options={prepaidHealthsList}
               className="field"
               placeholder={prepaid_healths.placeholder}
            />
         </div>
      </>
   );
};

export default AttentionDataForm;
