import { Order } from '../models/enums';

// Helps to get a not found text according order status
export const textByOrderStatus = (orderStatusToChange) => {
   let orderStatus;
   switch (orderStatusToChange) {
      case Order.QUOTED:
         orderStatus = 'cotizados';
         break;
      case Order.PRE_PAID:
         orderStatus = 'procesándose';
         break;
      case Order.PAID:
         orderStatus = 'pagados';
         break;
      case Order.SENT:
         orderStatus = 'enviados';
         break;
      case Order.DELIVERED:
         orderStatus = 'entregados';
         break;
      case Order.REJECTED:
         orderStatus = 'rechazados';
         break;
      default:
         orderStatus = '';
         break;
   }
   return `No hay pedidos ${orderStatus} que mostrar`;
};
