import React, { useState, useEffect } from 'react';
//eslint-disable-next-line
import { Button, List } from '@material-ui/core';
import { remove } from 'lodash';
import PrescriptionItem from './PrescriptionItem';
import PharmacyListModal from '../PharmacyListModal';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Modal from '../../../components/Modal';
import { useStateAuthValue } from '../../../context/Auth/AuthState';
import { useToast } from '../../../context/Toast/ToastProvider';
import { PrescriptionType } from '../../../models/enums';
import { GetPrescriptions } from '../../../services/prescriptionService';
import { GetProfessional } from "../../../services/professionalService";
import { textByPrescriptionType } from '../../../utils/PrescriptionUtil';
import { showToast, ToastType } from '../../../utils/ToastUtil';
import PrescriptionListFam from './PrescriptionLisFam';

const PrescriptionList = ({ filterStatus }) => {
   const toastDispatch = useToast();
   const [{ userToken, userData }] = useStateAuthValue();
   const [prescriptionListTitular, setPrescriptionListTitular] = useState([]);
   const [prescriptionListFam, setPrescriptionListFam] = useState([]);
   const [loading, setLoading] = useState(true);
   const [modalPharmacy, setModalPharmacy] = useState(false);
   const [selectedPrescription, setSelectedPrescription] = useState([]);
   const professionalProfiles = {};

   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
   };

   useEffect(() => {
      let isMounted = true;
      if (userToken) {
         setLoading(true);
         fetchPrescriptions({
            user : userData?.email,
            isMounted
         });
         userData?.patient_dto?.family_group?.map(async (f) => {
            await fetchPrescriptions({
               user : userData?.email +"%23"+ f.id_number,
               isFam: true,
               famId: f.id_number,
               famName:f.first_name + " " + f.last_name,
               isMounted
            });
         }) 
         
      }
      return () => (isMounted = false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userToken,filterStatus, userData]);

   const getProfiles = (data) => {
      data.forEach((item) => {
         if (!professionalProfiles[item.consultationDTO.professionalLogin]) {

            professionalProfiles[item.consultationDTO.professionalLogin] = null;
         }
      });
      const promises = [];
      Object.keys(professionalProfiles).forEach((prof) =>
         promises.push(
            GetProfessional(userToken, { professionalLogin: prof })
               .then((profile) => professionalProfiles[prof] = profile)
               //eslint-disable-next-line
               .catch((e) => console.log(e)),
         ),
      );
      return Promise.all(promises);
   }
   const fetchPrescriptions = async ({user, isFam=false, famId, famName, isMounted = true}) => {
      const errorMessage = 'Ocurrió un error en la búsqueda de recetas';

      const params = {
         statuses: filterStatus,
         patient_login: user,
         page_size: 100,
         page_number: 0,
         sort: 'createdDate,ASC',
      }

      return await GetPrescriptions(params, userToken, showError)
         .then(async (response) => {
            await getProfiles(response.content)

            response.content.forEach((prescr) => {
               if (professionalProfiles[prescr.consultationDTO.professionalLogin].id) {
                  prescr.professional_name = `${professionalProfiles[prescr.consultationDTO.professionalLogin]?.userData?.firstName || ''
                     } ${professionalProfiles[prescr.consultationDTO.professionalLogin]?.userData?.lastName || ''}`;
                  prescr.professional_license = professionalProfiles[prescr.consultationDTO.professionalLogin]?.license;
               }
            })
            if (isMounted) {
               if(!isFam){
                  setPrescriptionListTitular(response.content);
               } else {
                  const famPrescripcion = {content : response.content, famId, famName}
                  let famArray = prescriptionListFam;
                  if (!prescriptionListFam.find(item => item.famId === famPrescripcion.famId)) {
                     famArray.push(famPrescripcion)
                  } 
                  setPrescriptionListFam([...famArray]);
               }
            }
         })
         .catch(() => {
            showError(errorMessage);
         })
         .finally(() => setLoading(false));
   };


   const handleSelectedPrescription = (e) => {
      const id = e.target.id;
      if (selectedPrescription.includes(id)) {
         setSelectedPrescription(
            remove(selectedPrescription, (element) => element !== id),
         );
      } else {
         setSelectedPrescription((prev) => [...prev, id]);
      }
   };

   const renderList = (data) => {
      if (data?.length) {
         if (
            !data.some((data) => data?.status === filterStatus)
         ) {
            return (
               <div className="empty">
                  <p className="message">
                     {textByPrescriptionType(filterStatus)}
                  </p>
               </div>
            );
         } else {
            return (
               <div className="list">
                  <List>
                     {data.map((data) => {
                        return (
                           <PrescriptionItem
                              key={data.id}
                              prescriptionId={data.prescriptionId}
                              id={data.id}
                              state={data.status}
                              dateCreated={data.createdDate}
                              diagnosis={data.diagnosis}
                              professional={data.professional_name}
                              enrollment={data.professional_license}
                              medications={data.medications}
                              onSelectedPrescription={
                                 handleSelectedPrescription
                              }
                              selectedPrescription={selectedPrescription}
                           />
                        );
                     })}
                  </List>
               </div>
            );
         }
      } else {
         return (
            <div className="empty">
               <p className="message">{textByPrescriptionType(filterStatus)}</p>
            </div>
         );
      }
   };

   /* const pharmacyModal = () => {
      setModalPharmacy(true);
   }; */
   const pharmacyModalClose = () => {
      setModalPharmacy(false);
   };

   const renderButton = () => {
      if (filterStatus === PrescriptionType.NEW) {
         return (
            <div className="button-sent">
               {/* <Button
                  variant="contained"
                  disabled={!selectedPrescription.length}
                  onClick={pharmacyModal}
               >
                  Enviar a farmacia
               </Button> */}
               <Modal
                  maxWidth="md"
                  isActive={modalPharmacy}
                  handleClose={pharmacyModalClose}
               >
                  <PharmacyListModal
                     selectedPrescription={selectedPrescription}
                  />
               </Modal>
            </div>
         );
      }
   };

   return (
      <>
         <div className="prescription-list">
            {loading ? <LoadingSpinner /> : (
               <>
               <h3>Titular: {userData?.first_name + " " + userData?.last_name}</h3>
               {renderList(prescriptionListTitular)}
               </>
               )}
            <PrescriptionListFam famPrescr={prescriptionListFam} filterStatus={filterStatus}/>
         </div>
         {renderButton()}
      </>
   );
};

export default PrescriptionList;
