import React, { useEffect, useState } from 'react';

import config from '../conf/appSetting';
import { useStateAuthValue } from '../context/Auth/AuthState';
import { useToast } from '../context/Toast/ToastProvider';
import { getDecodedToken } from '../services/authService';
import { loginUtilGoogle } from '../utils/LoginUtil';
import { showToast, ToastType } from '../utils/ToastUtil';
import LoadingSpinner from "../components/LoadingSpinner";
import { IconButton } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Logo from '../assets/img/Logo_Mediline_Color_Positivo_vertical.jpg';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';

const GmailCallback = (props) => {
   const { history, location } = props;
   const { search } = location;
   const [searching, setSearching] = useState(true);
   const [errorMsg, setErrorMsg] = useState();
   const [, dispatch] = useStateAuthValue();
   const toastDispatch = useToast();
   let json;

   const handleClick = () => history.push('/');


   const showError = (msgError) => {
      setErrorMsg(msgError);
      showToast(toastDispatch, msgError, ToastType.ERROR, 'Error');
      setSearching(false);
   };

   const defaultHeaders = {
      Connection: 'close',
      Accept: 'application/json',
      'Content-Type': 'application/json',
   };

   const getGatewayLogin = async () => {
      const gmailCallBack =
         config.GMAIL_GOOGLE_AUTHORIZE_REDIRECT_URL +
         search +
         '&access_type=offline';
      setSearching(true);
      fetch(gmailCallBack, {
         method: 'GET',
         headers: defaultHeaders,
      }).then(response => {
         json = response.json() || {};
         const googleData = json ? getDecodedToken(json?.id_token) : {};
         const email = googleData?.sub;
         
         if (googleData?.auth) {
            loginUtilGoogle(
                 email,
                 dispatch,
                 history,
                 setSearching,
                 showError,
              );
         } else {
            history.push('/signup/' + json?.id_token);
         }
      }).catch(err => {
         showError(`Error en callback de gmail: ${err?.message || '' }`);
      });
   };

   useEffect(() => {
      getGatewayLogin();
   }, []);

   return <div className="gmail-callback">
      <div className="container">
         <div></div>
         <div className="box">
            <img src={Logo} className="img" alt="MediLine" />

            { searching ? (
               <LoadingSpinner />
            ) : (
               <>
                  { errorMsg && (<Alert severity="error">
                  <AlertTitle><strong>Error</strong></AlertTitle>
                     {errorMsg}
                  </Alert>)
                  }
                  <IconButton
                  className="button-primary"
                  variant="contained"
                  onClick={handleClick}
                  >
                  Ir al Inicio
                  <ArrowForwardIosOutlinedIcon />
                  </IconButton>
               </>
            )}
         </div>
      </div>
   </div>;
};

export default GmailCallback;
