import React, { useEffect, useState } from 'react';

import { renderOptions, filename } from '../../../../utils/FormUtil';
import { useFormikContext } from 'formik';
import { calculateAge } from '../../../../utils/DateUtil';

const MedicalRecordDetail = ({ formValues }) => {
   const { values } = useFormikContext();
   const [ageCalculate, setAgeCalculate] = useState([]);

   const {
      patient_dto: { pre_existing_disease,
         frequent_medication,
         surgeries,
         size,
         weight,
         allergies,
         family_history,
         vaccines,
         gynecological_data,
         study
      },
   } = formValues;

   useEffect(() => {
      setAgeCalculate(calculateAge(values.birth_date));
   }, [values.birth_date]);

   return (
      <div className="detail">
         <div className="header header--detail">
            <h2>Antecedentes Médicos</h2>
         </div>
         <div className="data-wrapper">
            <span className="data">
               Edad:<span className="bold">{ageCalculate}</span>
            </span>
            <span className="data">
               Peso:<span className="bold">{weight}</span>
            </span>
            <span className="data">
               Altura:<span className="bold">{size}</span>
            </span>
            <span className="data">
               Alergias:<span className="bold">{allergies}</span>
            </span>
            <span className="data">
               Antecedentes familiares:<span className="bold">{family_history}</span>
            </span>
            <span className="data">
               Vacunas:<span className="bold">{vaccines}</span>
            </span>
            <span className="data">
               Ginecológicos y Obstétricos:<span className="bold">{gynecological_data}</span>
            </span>
            <span className="data">
               Enfermedades preexistentes:
               <div className="options">
                  {renderOptions(pre_existing_disease)}
               </div>
            </span>
            <span className="data">
               Medicaciones frecuentes:
               <div className="options">
                  {renderOptions(frequent_medication, values.anotherMedication)}
               </div>
            </span>
            <span className="data">
               Cirugías:
               <div className="options">{renderOptions(surgeries)}</div>
            </span>
            <span className="data">
               Esudios Médicos:
               <div className="options">{filename(study)}</div>
            </span>
         </div>
      </div>
   );
};

export default MedicalRecordDetail;
