import React from 'react';
import { Button, List, ListItem, ListItemText } from '@material-ui/core';

const Billing = () => (
   <div className="billing">
      <div className="header">
         <h3>Datos de Facturación</h3>
      </div>
      <div className="list">
         <List>
            <ListItem className="list-item">
               <ListItemText
                  className="item-text"
                  primary="CUIT"
                  secondary="27-35925239-6"
               />
               <ListItemText
                  className="item-text"
                  primary="TIPO"
                  secondary="Monotributista Categoría E"
               />
            </ListItem>
            <ListItem className="list-item">
               <ListItemText
                  className="item-text"
                  secondary="[04/2017] 869090 - SERVICIOS RELACIONADOS CON LA SALUD HUMANA N.C.P. (INCLUYE SERVICIOS DE PSICÓLOGOS, FONOAUDIÓLOGOS, SERVICIOS DE ENFERMERÍA, TERAPIA OCUPACIONAL, BANCOS DE SANGRE, DE SEMEN,ETC.)
      "
               />
            </ListItem>
         </List>
      </div>
      <Button className="button button--upload" variant="outlined">
         Subir Factura
      </Button>
   </div>
);

export default Billing;
