import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ApplicationStyle } from "../../../../theme";
import { Chip, FormControlLabel, Checkbox, FormHelperText } from "@material-ui/core";

const MedilineStyle = makeStyles(ApplicationStyle);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  errorText: {
    paddingLeft: "22px",
  },
  allWeekCheckbox: {
    margin: "10px 0",
    "& span": {
      fontSize: "14px",
      padding: "5px",
    },
    "& svg": {
      fontSize: "18px",
      marginLeft: "10px",
    },
  },
}));

export default function ChipList({ data, setFieldValue, error }) {
  const MedilineClasses = MedilineStyle();
  const classes = useStyles();

  const [items, setItems] = useState(data);
  const [all, setAll] = useState(false);

  const handleCheck = (value, key) => {
    const toogleDates = [...items];
    if (key) {
      const oldDateIndex = toogleDates.findIndex((day) => day.key === key);
      if (oldDateIndex > -1) {
        toogleDates[oldDateIndex].selected = !toogleDates[oldDateIndex]
          .selected;
        if (toogleDates[oldDateIndex].selected) {
          const allSelected = toogleDates.find((day) => !day.selected);
          if (!allSelected) {
            setAll(true);
          }
        } else {
          setAll(false);
        }
      }
    } else {
      toogleDates.forEach((day) => (day.selected = value));
      setAll(value);
    }
    const fieldValue = toogleDates.filter(day => day.selected);
    setFieldValue(fieldValue);
    setItems(toogleDates);
  };

  return (
    <>
      <ul component="ul" className={classes.root}>
        {items.map((item) => (
          <li key={item.key}>
            <Chip
              onClick={() => {
                handleCheck(item.selected, item.key);
              }}
              variant={item.selected ? "default" : "outlined"}
              clickable
              label={item.label}
              color="primary"
              classes={{
                root: MedilineClasses.chipDay,
                outlinedPrimary: MedilineClasses.colorOutLinePrimary,
                clickableColorPrimary: MedilineClasses.colorPrimary,
                colorPrimary: MedilineClasses.colorPrimary,
              }}
            />
          </li>
        ))}
      </ul>
      <FormHelperText error className={classes.errorText}>{error}</FormHelperText>
      <FormControlLabel
        label="Toda la semana"
        className={classes.allWeekCheckbox}
        control={
          <Checkbox
            checked={all}
            onChange={(ev) => handleCheck(ev.target.checked, null)}
            name="checkedB"
            color="primary"
          />
        }
      />
    </>
  );
}
