export const sendDesktopNotification = (message) => {
  let options = {
    body: message,
    icon: '/android-chrome-512x512.png',
    badge: '/android-chrome-512x512.png',
  };
  const isMobile =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent
    );
  if (!isMobile) {
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
      const notification = new Notification('Mediline', options);
      notification.onclick = function () {
        window.focus();
      };
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          const notification = new Notification('Mediline', options);
          notification.onclick = function () {
            window.focus();
          };
        }
      });
    }
  }
};
