import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Button, ButtonGroup } from '@material-ui/core';
import esLocale from "date-fns/locale/es";
import {
   KeyboardDatePicker,
   MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import Paid from './Paid';
import ToCharge from './ToCharge';

const Payment = () => {
   const [selectedDateFrom, setSelectedDateFrom] = useState(new Date());
   const [selectedDateTo, setSelectedDateTo] = useState();
   const [activeFilter, setActiveFilter] = useState('TODAS');

   const handleClickButton = (buttonId) => {
      setActiveFilter(buttonId);
   };

   return (
      <div className="payment">
         <div className="filter">
            <div className="input-wrapper">
               <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                     disableToolbar
                     className="input"
                     autoOk
                     okLabel='Aceptar'
                     cancelLabel='Cancelar'
                     format="dd/MM/yyyy"
                     margin="normal"
                     id=""
                     label="Desde"
                     value={selectedDateFrom}
                     onChange={(value) => setSelectedDateFrom(value)}
                     maxDate={selectedDateTo || undefined}
                     invalidDateMessage="Ingrese una fecha Desde válida"
						   maxDateMessage="la fecha Desde debe ser anterior a la fecha Hasta"
						   minDateMessage="Ingrese una fecha posterior a la fecha actual"
                  />
                  <KeyboardDatePicker
                     disableToolbar
                     className="input"
                     autoOk
                     okLabel='Aceptar'
                     cancelLabel='Cancelar'
                     format="dd/MM/yyyy"
                     margin="normal"
                     id=""
                     label="Hasta"
                     value={selectedDateTo}
                     onChange={(value) => setSelectedDateTo(value)}
                     minDate={selectedDateFrom || undefined}
                     invalidDateMessage="Ingrese una fecha Desde válida"
							minDateMessage="Ingrese una fecha posterior a la fecha Desde"
                  />
               </MuiPickersUtilsProvider>
            </div>
            <ButtonGroup className="button-wrapper">
               <Button
                  className={`button ${
                     activeFilter === 'TODAS' && 'button--active'
                  }`}
                  onClick={() => handleClickButton('TODAS')}
               >
                  Todas
               </Button>
               <Button
                  className={`button ${
                     activeFilter === 'PAGADAS' && 'button--active'
                  }`}
                  onClick={() => handleClickButton('PAGADAS')}
               >
                  Pagadas
               </Button>
               <Button
                  className={`button ${
                     activeFilter === 'POR_COBRAR' && 'button--active'
                  }`}
                  onClick={() => handleClickButton('POR_COBRAR')}
               >
                  Por Cobrar
               </Button>
            </ButtonGroup>
         </div>
         {(activeFilter === 'PAGADAS' || activeFilter === 'TODAS') && <Paid />}
         {(activeFilter === 'POR_COBRAR' || activeFilter === 'TODAS') && (
            <ToCharge />
         )}
      </div>
   );
};

export default Payment;
