import {daysToString, formatDate} from '../utils/DateUtil';
import Schedule from '../models/Schedule';
import ScheduleWrapper from "@mediline/mediline-api-middleware/src/wrappers/schedule.wrapper";

const shw = new ScheduleWrapper();

export const ScheduleCreate = async (params, token, displayError = null) =>
	shw.scheduleCreate(token, params, displayError);

export const GetScheduleByProfessional = async (professionalId, token, displayError = null) =>
	shw.getScheduleByProfessional(token, professionalId, displayError);

export const ScheduleValidate = async (params, token, displayError = null) =>
	shw.scheduleValidate(token, params, displayError);

export const ScheduleDelete = async (params, token, displayError = null) =>
	shw.scheduleDelete(token, params, displayError);

export const ScheduleParse = (data, userData, AttentionType) => {
	let savedSchedules = [];
	data.forEach((schedule) => {
		let attentionType = AttentionType.find((at) => at?.descr === schedule?.name);
		schedule.schedules.forEach((scheduleByTime) => {
			const label = daysToString(scheduleByTime?.days);
			const newScheduleItem = new Schedule(
				scheduleByTime?.check_in,
				scheduleByTime?.check_out,
				{id: userData?.professional_dto?.id},
				{
					id: scheduleByTime?.id,
					groupIds: scheduleByTime?.ids,
					label: label,
					days: scheduleByTime?.days,
					attentionType: scheduleByTime?.name,
					attention_types_dto: [{id: attentionType?.id}],
					last_time_avalability: scheduleByTime?.last_time_avalability,
					lastTimeAvailability: formatDate(
						scheduleByTime?.last_time_avalability
					),
				},
			);
			savedSchedules.push(newScheduleItem);
		});
	});
	return savedSchedules;
};

export default {
	ScheduleCreate,
	GetScheduleByProfessional,
	ScheduleValidate,
	ScheduleDelete,
	ScheduleParse
};
