import React, { useState } from 'react';
import {
   //Checkbox,
   Collapse,
   //FormControlLabel,
   ListItem,
   ListItemText,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { GetPharmacyByPrescription } from '../../../services/pharmacyService';
import { useStateAuthValue } from '../../../context/Auth/AuthState';

import { PrescriptionType } from '../../../models/enums';
import { formatDate } from '../../../utils/DateUtil';

const PrescriptionItem = (props) => {
   const {
      diagnosis,
      prescriptionId,
      id,
      dateCreated,
      professional,
      enrollment,
      medications,
      //onSelectedPrescription,
      //selectedPrescription,
      state,
   } = props;
   const [open, setOpen] = useState(false);
   const [{userToken}] = useStateAuthValue();
   const [pharmacy, setPharmacy] = useState();

   if (state === PrescriptionType.DELIVERED) {
      GetPharmacyByPrescription({externalId: id}, userToken).then( res => {
         if (res?.content?.length && res.content[0]) {
            setPharmacy(`${res.content[0]?.pharmacy?.name || ''} / ${res.content[0]?.name || ''}`);
         }
      });
  }

   const handleClick = () => {
      setOpen(!open);
   };

   const divState = () => {
      if (state === PrescriptionType.NEW) {
         return <div className="id-state__stateNew">Nueva</div>;
      } else if (state === PrescriptionType.DELIVERED) {
         return <div className="id-state__stateSent">Enviada a farmacia</div>;
      } else {
         return <div className="id-state__stateOverdue">Vencida</div>;
      }
   };

   return (
      <ListItem className="list-item-prescription">
         <div className="presc-data">
            <div className="id-state">
               <span>Receta N°</span>
               <span className="id-state__id">{prescriptionId}</span>
               {divState()}
            </div>
            <div className="detail">
               <span>Fecha de Emisión: {formatDate(dateCreated)}</span>
               <span>Diagnóstico: {diagnosis}</span>
               <div className="professional-data">
                  <span className="professional-data__professional">
                     Profesional:
                  </span>
                  <span className="name">{professional}</span>
               </div>
               <div className="enrollment">
                  <span className="enrollment__label">Matrícula:</span>
                  <span className="enrollment__value">{enrollment}</span>
               </div>
               { pharmacy && pharmacy?.length > 0 && (
                  <div className="professional-data">
                     <span className="name"> {pharmacy}</span>
                  </div>
               )}
            </div>
         </div>

         {/* {state === PrescriptionType.NEW && (
            <div className="checkbox">
               <FormControlLabel
                  control={
                     <Checkbox
                        key={id}
                        id={String(id)}
                        checked={selectedPrescription.includes(String(id))}
                        onClick={(e) => onSelectedPrescription(e)}
                     />
                  }
                  label="Seleccionar para envío"
                  labelPlacement="start"
               />
            </div>
         )} */}

         <div className="description">
            <ListItem button onClick={handleClick}>
               <ListItemText />
               {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
               <div className="collapse">
                  <div className="cant-descrip">
                     <span className="cant">Cant.</span>
                     <span className="description">Descripción</span>
                     <span className="description" style={{paddingLeft: "10px"}}>Posología</span>
                  </div>
                  <div className="medicine">
                     {medications.map((data) => {
                        return (
                           <div key={data.quantity} className="medication">
                              <span className="medicine-number">
                                 {data.quantity}
                              </span>
                              <span className="medicine-name">{data.name}</span>
                              <span className="medicine-name" style={{paddingLeft: "10px"}}>{data.posology}</span>
                              <br />
                           </div>
                        );
                     })}
                  </div>
               </div>
            </Collapse>
         </div>
      </ListItem>
   );
};

export default PrescriptionItem;
