import React from 'react';
import { IconButton } from '@material-ui/core/';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const FilterButton = ({
  title,
  subtitle,
  idButton,
  active = false,
  disabled = false,
  hasIcon,
  handleClick,
  phoneIcon,
}) => {
  return (
    <div
      type="button"
      disabled={disabled}
      onClick={() => handleClick(idButton)}
      className={`filter-button ${active ? 'active' : ''} ${
        disabled ? 'disabled' : ''
      }`}
    >
      <div className="title-wrapper">
        <div className="title-icon">
          {title} {hasIcon && <FiberManualRecordIcon />}
        </div>
        <div className="subtitle">{subtitle}</div>
      </div>
      <IconButton edge="end">
        {phoneIcon ? (
          <PhoneIcon
            className="icon"
            style={{
              fill: 'white',
              backgroundColor: disabled ? '#adadad' : '#489633',
              borderRadius: '50%',
              padding: '5px',
              fontSize: '3.5rem',
            }}
          />
        ) : (
          <ArrowForwardIcon
            className="icon"
            style={{
              fill: '#fff',
            }}
          />
        )}
      </IconButton>
    </div>
  );
};

export default FilterButton;
