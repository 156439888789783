import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, Tooltip } from '@material-ui/core';
import plus from '../../../../../assets/img/plus.svg';
import ListItem from '../../../../../components/ListItem';
import { removeIndexFromList } from '../../../../../utils/FormUtil';
import InputField from '../../../../../components/FormFields/InputField';
import { UseStateVirtualGuard } from '../../../../../context/VirtualGuard/VirtualGuardProvider';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  studyName: Yup.string().min(2, 'Ingresa el nombre del estudio').required('El nombre del estudio es requerido')
});

function Studies({ formField, parentValues, hasErrors, errors, setFieldValue, handleDisabled }) {
  const baseForm = "studies.";
  const [{ consultation }] = UseStateVirtualGuard();
  const { diagnosis, studies: {
    studyNames
  }} = formField;
  const [list, setList] = useState(parentValues?.studyNames || []);
  const [title, setTitle] = useState('Dado que la Sr/a: Paciente, con DNI Nro presenta al momento de la consulta:');
  const { values } = useFormikContext();
  
  const addStudyName = (studyName) => {
    if (studyName && studyName.trim()?.length) {
      const newList = [
        {
          id: Math.random().toString(), // NOSONAR
          name: studyName,
          primary: studyName,
        },
        ...list,
      ];
      setList(newList);
      setFieldValue(baseForm + studyNames.name, newList);
      handleDisabled(false);
    }
  };
  
  const removeItem = (index) => {
    if (index || index === 0) {
      const newList = removeIndexFromList(index, list, setList);
      setFieldValue(baseForm + studyNames.name, newList);
      if (!newList.length) {
        handleDisabled(true);
      }
    }
  };

  useEffect(() => {
    if(values?.studies && !values.studies.diagnosis){
      values.studies.diagnosis = values.Diagnosis;
    }
  }, [values?.studies]); 

  useEffect(() => {
    if (consultation) {
      const patient = consultation?.patient_dto?.user;
      const fullName = patient?.first_name
        ? `${patient?.first_name} ${patient?.last_name || ''}`
        : patient?.alias || patient?.username || 'Paciente';
      setTitle(`Dado que el Sr/a: ${fullName} , DNI: ${
        patient?.id_number || 'Nro'
      }, presenta al momento de la consulta:`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultation]);
  
  useEffect(() => {
    handleDisabled(hasErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasErrors]);
  
  useEffect(() => {
    if (parentValues?.studyNames) {
      setList(parentValues.studyNames);
    } else if (parentValues?.diagnosis) {
      setFieldValue(baseForm + studyNames.name, []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValues]);

  return (
    <>
      <Typography component={"span"}>
        {title}
        </Typography>
      <InputField
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        name={baseForm + diagnosis.name}
        label={diagnosis.label + '*'}
        value={values?.studies?.diagnosis ? values.studies.diagnosis : values?.Diagnosis}
        className="field"
      />

      <Formik
        initialValues={{ studyName: ''}}
        validationSchema={schema}
        validateOnMount
      >
        {({studyNameErrors, values}) => (
          <Grid container spacing={1} className="pt-10">
          <Grid item xs={10}>
              <InputField
                onKeyPress={e => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                name='studyName'
                label="Nombre del estudio"
                className="field"
                maxLength = {400}
              />
          </Grid>
          <Grid item xs={2}>
            <Button
                width={40}
                type="button"
                fullWidth={false}
                disabled={!!studyNameErrors?.studyName || !values.studyName}
                className="plus-button"
                onClick={() => addStudyName(values.studyName)}
              >
              <Tooltip title="Agregar estudio">
                <img src={plus} alt={"plus"} />
              </Tooltip>
            </Button>
          </Grid>
          <ListItem data={list}
            removeItem={removeItem}
            noDataMessage=''
            required={true}
            errors={errors?.studyNames}
            label={baseForm + studyNames.name}
            className="field"
          />
        </Grid>
      )}
      </Formik>
    </>
  );
}
export default Studies;
