import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useStateAuthValue } from "../../Auth/AuthState";
import SockJS from "sockjs-client";
import { over } from "stompjs";
import { GetSessionToken } from "../../../services/authService";
import { useToast } from "../../Toast/ToastProvider";
import { showToast, ToastType } from "../../../utils/ToastUtil";


export const ProfessionalLogContext = createContext();

export const ProfessionalLogState = ({ reducer, initialState, children }) => {
  return (
    <ProfessionalLogContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </ProfessionalLogContext.Provider>
  );
};

export const useProfessionalLogState = () => useContext(ProfessionalLogContext);
