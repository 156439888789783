import React, { useEffect, useState } from "react";
import { MINUTES_INTERVAL } from "../../../../conf/appSetting";
import { useStateAuthValue } from "../../../../context/Auth/AuthState";
import { useToast } from "../../../../context/Toast/ToastProvider";
import { useFormik } from "formik";
import * as yup from "yup";
// Styles
import { makeStyles } from "@material-ui/core/styles";
import { ApplicationStyle } from "../../../../theme";
import {
  Backdrop,
  Fade,
  Modal,
  Typography,
  Grid,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  FormControl,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";

// Utils
import {
  weekDays,
  currentDateWithInterval,
  hourToString,
  validateHour,
  isTimeBefore
} from "../../../../utils/DateUtil";
import { showToast, ToastType } from "../../../../utils/ToastUtil";
// Components
import ChipList from "./ChipList";
import TimeRange from "./TimeRange";
import LoadingSpinner from '../../../../components/LoadingSpinner';
// Models
import Schedule from "../../../../models/Schedule";
// Services
import { ScheduleCreate } from "../../../../services/scheduleService";
import { AttentionType } from "../../../../tests/mocks/Patient/MockSelectorData";
import moment from 'moment-timezone';

const MedilineStyle = makeStyles(ApplicationStyle);
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperAddSchedule: {
    outline: 0,
    backgroundColor: "white",
    border: "hidden 0px #000000",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "25px",
    borderBottomLeftRadius: "25px",
    borderBottomRightRadius: "25px",
    padding: theme.spacing(2, 4, 3),
    width: "760px",
    "& h2": {
      fontSize: "24px",
    },
  },
  dateInputsButton: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    marginBottom: "15px",
    width: "100%",
  },
  buttonWrapper: {
    width: "100%",
    textAlign: "center",
    marginTop: "15px",
  },
  select: {
    borderRadius: "0 20px 20px 20px",
    boxShadow:
      "0 24px 32px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04)",
    fontSize: "14px",
    margin: "10px",
    padding: "0 10px",
    width: "300px",
  },
  errorText: {
    paddingLeft: "22px",
  },
}));

export default function CreateSchedule({
  open,
  handleCloseWithoutSave,
  refreshSchedules
}) {
  const MedilineClasses = MedilineStyle();
  const classes = useStyles();
  const currentDate = moment();
  const toastDispatch = useToast();
  const defaultHour = hourToString(currentDateWithInterval);
  const [{ userData, userToken }] = useStateAuthValue();
  const [loading, setLoading] = useState(false);
  const [prepaid, setPrepaid] = useState(false);

  const {
    values,
    setFieldValue,
    handleSubmit,
    validateForm,
    validateField,
    isValid,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      selectedDays: [],
      checkIn: hourToString(currentDateWithInterval),
      checkOut: '',
      startDate: '',
      dateTo: '',
      attentionTypeSelect: '',
      prepaidHealthSelect: ''
    },
    validationSchema: yup.object().shape({
      attentionTypeSelect: yup.string().required('Seleccione un tipo de consulta'),
      prepaidHealthSelect: yup.string().when('attentionTypeSelect', {
        is: (val) => val === '2', then: yup.string().required('Seleccione una Obra Social')
      }
      ),
      startDate:yup.date().nullable().typeError('').test(
        "startDate",
        `Ambas fechas son requeridas para guardar el rango`,
        function (value) {
          return (value && this.parent.dateTo) || (!value && !this.parent.dateTo);
        }
      ),

      dateTo: yup.date().nullable().typeError('').test(
        "dateTo",
        `La fecha Hasta debe ser mayor que la fecha Desde`,
        function (value) {
          const from = moment(this.parent.startDate).startOf('day');
          const to = moment(value).startOf('day');

          return (!value && !this.parent.startDate) || to.isSameOrAfter(from);

        }
      ),
      selectedDays: yup.array().min(1, "Selecciona al menos un día"),
      checkIn: yup
        .string()
        .required("Valor Desde requerido")
        .test(
          "checkInInterval",
          `El tiempo desde debe ser múltiplo de ${MINUTES_INTERVAL} minutos`,
          function (value) {
            return validateHour(value);
          }
        ),
      checkOut: yup
        .string()
        .required("Valor Hasta requerido")
        .test(
          "checkOutTime",
          `El tiempo Hasta debe ser mayor a ${MINUTES_INTERVAL} minutos del tiempo Inicial`,
          function (value) {
            if (this.parent.checkIn && value) {
              return isTimeBefore(this.parent.checkIn, value);
            }
            return false;
          }
        )
        .test(
          "checkOutInterval",
          `El tiempo hasta debe ser múltiplo de ${MINUTES_INTERVAL} minutos`,
          function (value) {
            return validateHour(value);
          }
        ),
      }),
      onSubmit: () => {
        let label = "Domingo - Sábado";
        const selectedDaysOfWeek = values.selectedDays.filter(
          (day) => day.selected
        );
        if (selectedDaysOfWeek.length < 7) {
          label = selectedDaysOfWeek.map((day) => day.label).join(", ");
        }
        const params = {
          label: label,
          days: values.selectedDays.map((day) => day.key),
          attention_types_dto: [{ id: values.attentionTypeSelect }],
          id_prepaid_healths: [values.prepaidHealthSelect],
          attentionType: values.attentionTypeSelect,
        };

        if (values?.startDate && values?.dateTo) {
          params.startDate = moment(values.startDate).startOf('day');
          params.duration = moment(values.dateTo).startOf('day').diff(params.startDate, 'days')
        } else {
          params.startDate = moment().startOf('day');
        }

        const newScheduleItem = new Schedule(
          values.checkIn,
          values.checkOut,
          { id: userData.professional_dto.id },
          params
        );
        const arrayAppointments = [newScheduleItem];
        saveSchedule(arrayAppointments);
      setLoading(true);
    },
  });

  useEffect(() => {
    validateForm(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [userData]);

  const closeAndClearForm = () => {
    resetForm({});
    weekDays.forEach((day) => (day.selected = false));
    handleCloseWithoutSave();
  };

  const saveSchedule = (scheduleToCreate) => {
    ScheduleCreate(scheduleToCreate, userToken, (sessionError) => showToast(toastDispatch, sessionError, ToastType.ERROR, ToastType.ERROR))
      .then((resp) => {
        setLoading(false);
        if (resp.data && resp.data.error) {
          showToast(toastDispatch, resp.data.message, ToastType.ERROR);
        } else {
          showToast(
            toastDispatch,
            "La Agenda fue guardada exitosamente!",
            ToastType.SUCCESS
          );
          refreshSchedules(true);
          closeAndClearForm();
        }
      })
      .catch((error) => {
        const msg = error.response?.status === 404 ? "Ya existe una agenda para ese día/horario":"Ocurrió un error guardando la agenda";
	      showToast(
          toastDispatch,
		      msg,
		      ToastType.ERROR
          )
          setLoading(false);
        });
  };

  const prepaidHealthsProfessionalList = userData?.professional_dto?.prepaid_healths;

  const onSelectChange = (e) => {
    setPrepaid(e.target.value === 2 ? true : false);
    setFieldValue('attentionTypeSelect',e.target.value)

  }
  const onPrepaidSelectChange = (e) => {
    setFieldValue('prepaidHealthSelect',e.target.value)
  }

  const AddScheduleModelContent = (
    <Fade in={open}>
      <div className={classes.paperAddSchedule}>
        <Typography
          component="div"
          className={classes.ModalContent}
          style={{ backgroundColor: "white" }}
        >
          <Grid container>
            <Grid item sm={12}>
              <h2
                className={MedilineClasses.titleRed}
                id="transition-modal-title"
              >
                ¿Qué tipo de consulta?
              </h2>
              <FormControl>
                <Select
                  name="attentionTypeSelect"
                  className={classes.select}
                  value={values?.attentionTypeSelect}
                  onChange={onSelectChange}
                  disableUnderline
                >
                  {AttentionType.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.descr}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error className={classes.errorText}>{errors.attentionTypeSelect}</FormHelperText>
              </FormControl>
              {prepaid &&
                <FormControl>
                <Select
                  name="prepaidHealthSelect"
                  className={classes.select}
                  value={values.prepaidHealthSelect}
                  onChange={(e) => onPrepaidSelectChange(e)}
                  disableUnderline
                  >
                    {prepaidHealthsProfessionalList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}

                      </MenuItem>
                    ))}

                  </Select>
                  <FormHelperText error className={classes.errorText}>{errors.prepaidHealthSelect}</FormHelperText>
                </FormControl>
              }

            </Grid>

            {/* week day buttons*/}
            <Grid item sm={12}>
              <h2
                className={MedilineClasses.titleRed}
                id="transition-modal-title"
              >
                ¿Qué días?
              </h2>
              <ChipList
                data={weekDays.slice(0, 7)}
                setFieldValue={(value) => setFieldValue("selectedDays", value)}
                error={errors.selectedDays || null}
              />
            </Grid>

            {/* time range inputs*/}
            <Grid item sm={12}>
              <h2 className={MedilineClasses.titleRed}>¿En qué horario?</h2>
              <div className={classes.dateInputsButton}>
                <TimeRange
                  values={values}
                  defaultDate={defaultHour}
                  setFieldValueStart={(value) => {
                    setFieldValue("checkIn", value);
                    validateField("checkIn");
                  }}
                  setFieldValueEnd={(value) => {
                    setFieldValue("checkOut", value);
                    validateField("checkOut");
                  }}
                />
              </div>
              <FormHelperText error className={classes.errorText}>
                {errors.checkIn || errors.checkOut || errors.checkOutTime}
              </FormHelperText>
              <h2 className={MedilineClasses.titleRed}>¿Entre qué fechas?</h2>
            </Grid>

            <Grid item sm={4} style={{ marginLeft: 20 }}>
              <FormControl>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    autoOk
                    okLabel='Aceptar'
                    cancelLabel='Cancelar'
                    label="Desde"
                    format="dd/MM/yyyy"
                    minDate={currentDate}
                    maxDate={values?.dateTo || undefined}
                    views={['year', 'month', 'date']}
                    onChange={(value) => setFieldValue("startDate", value)}
                    value={values?.startDate || null}
                    className={classes.textField}
                    invalidDateMessage="Ingrese una fecha Desde válida"
                    maxDateMessage=""
                    minDateMessage="Ingrese una fecha posterior a la fecha actual"
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item sm={2}></Grid>
            <Grid item sm={4} style={{ marginLeft: 20 }}>
              <FormControl>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    autoOk
                    okLabel='Aceptar'
                    cancelLabel='Cancelar'
                    label="Hasta"
                    format="dd/MM/yyyy"
                    minDate={values?.startDate || currentDate}
                    views={['year', 'month', 'date']}
                    onChange={(value) => setFieldValue("dateTo", value)}
                    value={values?.dateTo || null}
                    className={classes.textField}
                    invalidDateMessage="Ingrese una fecha Hasta válida"
                    minDateMessage="Ingrese una fecha posterior"
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
          </Grid>

          <FormHelperText error className={classes.errorText}>
            {errors.startDate || errors.dateTo}
          </FormHelperText>
          <div className={classes.buttonWrapper}>
            {loading ? <LoadingSpinner /> :
              <>
                <Button
                  onClick={closeAndClearForm}
                  className={MedilineClasses.btnRedNextOutline}
                >
                  {" "}
                  Cancelar
                </Button>
                <Button
                  disabled={!isValid}
                  onClick={handleSubmit}
                  className={MedilineClasses.btnBlueNext}
                >
                  {" "}
                  Guardar
                </Button>
              </>
            }
          </div>
        </Typography>
      </div>
    </Fade>
  );

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {AddScheduleModelContent}
      </Modal>
    </>
  );
}
