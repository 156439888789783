import React from 'react';


const CreatedAccount = () => {
  return <div>
      <div style={{ color: "#002C54" }}>
        <h1>¡Tu cuenta ha sido creada exitosamente!</h1>
        <h3>Se enviará un mail de confirmación</h3>
        <h3>Por favor revisa tu correo para activar la cuenta. </h3>
      </div>
    </div>;
}

export default CreatedAccount;
