import React, {useEffect, useState} from 'react';
import {List} from '@material-ui/core';

import ScheduleItem from './ScheduleItem';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Appointment from '../../../models/Appointment';
import AppointmentStatus from '../../../models/enums/AppointmentStatus';
import {showToast, ToastType} from '../../../utils/ToastUtil';
import {removeIndexFromList} from '../../../utils/FormUtil';
import {OK} from '../../../utils/ServiceUtil';
import {
	getAppointments,
	putAppointment,
} from '../../../services/appointmentService';
import {useStateAuthValue} from '../../../context/Auth/AuthState';
import {useToast} from '../../../context/Toast/ToastProvider';

const ScheduleList = () => {
	const [{userToken}] = useStateAuthValue();
	const [loading, setLoading] = useState(true);
	const toastDispatch = useToast();
	const [appointmentList, setAppointmentList] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [selectedAppointment, setSelectedAppointment] = useState();

	const showError = (msgError) => {
		showToast(toastDispatch, msgError, ToastType.ERROR, 'Error');
	};

	const getData = async (isMounted) => {
		if (isMounted) {
			setLoading(true);
		}
		const errorMessage =
			'Ocurrió un error en la búsqueda de turnos programados';
		getAppointments({statuses: [AppointmentStatus.PROGRAMMED]}, userToken)
			.then((response) => {
				if (isMounted && OK === response?.status) {
					const appointmentDtos = response.data.map((appointment) =>
						Appointment.AppointmentDto(
							appointment.id,
							appointment.appointment_date,
							appointment.status,
							appointment.patient_dto,
							appointment.professional_dto
						),
					);
					setAppointmentList(appointmentDtos);
				}
			})
			.catch((error) => {
				if (error.response.status !== 404) showError(errorMessage);
			}
	)
	.
		finally(() => setLoading(false));
	};

	const handleClose = (confirmResponse) => {
		setOpenModal(false);
		if (confirmResponse) {
			const errorMessage =
				'Ocurrió un error al cancelar el turno programado';
			setLoading(true);
			putAppointment(
				selectedAppointment?.id,
				{status: AppointmentStatus.CANCELLED},
				userToken,
				(sessionError) => showError(sessionError),
			)
				.then((response) => {
					if (OK === response?.status.toUpperCase()) {
						removeIndexFromList(
							selectedAppointment.index,
							appointmentList,
							setAppointmentList,
						);
					} else {
						showError(errorMessage);
					}
					setSelectedAppointment(null);
				})
				.catch(() => showError(errorMessage))
				.finally(() => setLoading(false));
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (userToken) {
			getData(isMounted);
		}
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userToken]);

	const renderList = () => {
		if (appointmentList.length) {
			return (
				<div className="list list--appointment">
					<List>
						{appointmentList.map((appointment, index) => {
							return (
								<ScheduleItem
									schedule={appointment}
									index={index}
									key={index}
									remove={() => {
										appointment.index = index;
										setSelectedAppointment(appointment);
										setOpenModal(true);
									}}
								/>
							);
						})}
					</List>
				</div>
			);
		} else {
			return (
				<div className="empty">
					<p className="message">No hay turnos programados.</p>
				</div>
			);
		}
	};
	return (
		<div className="schedule-list">
			{loading ? <LoadingSpinner/> : renderList()}
			<ConfirmationModal
				open={openModal}
				handleClose={handleClose}
				title="¿Deseas cancelar el turno?"
				confirmButton="Confirmar"
				cancelButton="Volver"
				maxWidth="sm"
			/>
		</div>
	);
};

export default ScheduleList;
