import moment from "moment-timezone";

import {
  LogIn,
  sessionSet,
  GetUserBySession,
  AUTH_SUBFIX_GATEWAY,
  AUTH_CONNECTION_GOOGLE_OAUTH2,
  AUTH_CONNECTION_USERNAME_PASSWORD,
  LogInSocial,
  getDecodedToken,
  ProfessionalLog,
} from "../services/authService";
import { GetUserProfile, GetAccount } from "../services/apiService";
import { Role } from "../models/enums";
import { EventLog } from "../services/medilinePrepaidService";
import { connectToWSProfessionalLog } from "../pages/professional/professionalLogHook";

const saveDataAndRedirect = (dispatch, userAuth = null, response, history) => {
  dispatch({
    type: "SAVE_USER_INFO",
    userAuth,
    userData: response?.data,
  });
  dispatch({ type: "SAVE_SESSION" });

  if (response?.data?.active_role === Role.PROFESSIONAL) {
    
    if (response?.data?.professional_dto?.full_profile) {
      history.push("/profesional");
    } else {
      history.push("/registro-completo");
    }
  } else if (response?.data?.active_role === Role.PATIENT) {
    if (response?.data?.patient_dto?.full_profile) {
      history.push("/paciente");
    } else {
      history.push("/registro-completo");
    }
  }
};

export const successLogin = async (
  response,
  values,
  dispatch,
  token,
  history
) => {
  dispatch({
    type: "LOG_IN",
    userToken: token,
  });

  const userAuth = GetUserBySession();

  const tokenDecoded = getDecodedToken(token);
  const timezone = tokenDecoded?.timezone;
  moment.tz.setDefault(timezone);
  await EventLog(token, "login", tokenDecoded.auth, response);

  response.data.email = values?.email || userAuth?.email || null;
  response.data.username =
    response?.data?.username || userAuth?.nickname || null;
  saveDataAndRedirect(dispatch, userAuth, response, history);
};

export const getProfile = async (
  data,
  values,
  dispatch,
  dispatchProfessionalLog,
  history,
  setSubmitting,
  displayError
) => {
  await GetUserProfile(data?.id_token, displayError)
    .then(async (response) => {
      !!setSubmitting && setSubmitting(false);
      if (response.status === "ok") {
        successLogin(response, values, dispatch, data?.id_token, history);
        if(response.data.active_role === "PROFESSIONAL") connectToWSProfessionalLog(values.email,response.data,dispatchProfessionalLog);
        //
        console.log("response: ",response);
        
      } else if (response.status === "error") {
        if (response.data.error === "not_found") {
          sessionSet(
            data,
            data?.social ? AUTH_CONNECTION_GOOGLE_OAUTH2 : AUTH_SUBFIX_GATEWAY,
            data?.expires_in
          );
          GetAccount(data?.id_token).then((accountResp) =>
            history.push(
              `/signup?mode=complete_registration&auth_number=${accountResp.id}`
            )
          );
        } else {
          if (response.data && response.data.message) {
            displayError(response.data.message);
          }
          history.push("/login");
        }
      }
    })
    .catch((e) => {
      //eslint-disable-next-line
      console.log(e);
      !!setSubmitting && setSubmitting(false);
      displayError("Ocurrió un error iniciando sesión");
    });
};

export const loginUtil = async (
  values,
  dispatch,
  dispatchProfessionalLog,
  history,
  setSubmitting,
  displayError
) => {
  await LogIn(values.email, values.password)
    .then(async (resp) => {
      const { data } = resp;
      sessionSet(
        resp.data,
        AUTH_CONNECTION_USERNAME_PASSWORD,
        resp.data.expires_in
      );
      getProfile(data, values, dispatch,dispatchProfessionalLog, history, setSubmitting, displayError);
      
    })
    .catch((err) => {
      setSubmitting(false);
      var detail = "Credenciales incorrectas";
      if (err?.response && err?.response?.status < 500) {
        if (err?.response?.data?.detail?.includes("not activated"))
          detail = "Usuario Desactivado";
        displayError(detail);
      } else {
        displayError(
          `Ocurrió un error tratando de autenticarse: ${
            err?.message || err?.status || err?.response?.status || ""
          }`
        );
      }
    });
};

export const loginUtilGoogle = async (
  email,
  dispatch,
  history,
  setSubmitting,
  displayError
) => {
  await LogInSocial(email)
    .then((response) => {
      sessionSet(
        response?.data,
        AUTH_CONNECTION_GOOGLE_OAUTH2,
        response?.data?.expires_in
      );
      getProfile(
        response?.data,
        { email: email },
        dispatch,
        history,
        setSubmitting,
        displayError
      );
    })
    .catch(() => {
      !!setSubmitting && setSubmitting(false);
      displayError("Ocurrió un error");
    });
};
