import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputField from './InputField';

const AutocompleteFreeText = ({
    options,
    allowFreeText = false,
    inputKey,
    inputValue,
    setField,
    searchTextLabel
}) => {
return (
    <Autocomplete
    freeSolo={allowFreeText}
    noOptionsText={"No se encontraron coincidencias"}
    inputValue={inputValue}
    options={options}
    getOptionLabel={(option) =>
      option?.name || option?.description || "- Opción sin Nombre-"
    }
    blurOnSelect
    renderInput={(params) => (
      <InputField
      onKeyPress={e => {
        if (e.key === 'Enter') e.preventDefault();
      }}
        name={inputKey}
        label={searchTextLabel}
        className="field"
        {...params}
      />
    )}
    id="searchLabel"
    onChange={(_, value) => {
      const newValue = value?.name || value?.description || '';
      setField(newValue);
    }}
  />
  );
}

export default AutocompleteFreeText;
