import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
   Accordion,
   AccordionSummary,
   AccordionDetails,
   Table,
   TableCell,
   TableContainer,
   TableRow,
   TableHead,
   TableBody,
} from '@material-ui/core';

import { formatDate } from '../../../utils/DateUtil';
import {getValuePlain, renderOptions, valueToName} from '../../../utils/FormUtil';
import consultationType from '../../../utils/consultationTypeUtil';
import LabTabs from './tabPanel';
import SpanPanel from './spanPanel';

const ConsultationData = ({ data }) => {
   return (
      <>
         <TableContainer>
            <Table>
               <TableHead></TableHead>
               <TableBody>
                  <TableRow className="table">
                     <TableCell
                        align="center"
                        variant="head"
                        className="table-cell"
                     >
                        <div className="detail">
                           <div className="data-wrapper">
                              <SpanPanel
                                 title="Fecha"
                                 data={formatDate(data?.creation_date)}
                              ></SpanPanel>
                              <SpanPanel
                                 title="Diagnóstico"
                                 data={getValuePlain(data?.diagnosis)}
                              ></SpanPanel>
                              <SpanPanel
                                 title="Motivo de consulta"
                                 data={getValuePlain(data?.reason)}
                              ></SpanPanel>
                           </div>
                        </div>
                     </TableCell>
                     <TableCell
                        align="center"
                        variant="head"
                        className="table-cell"
                     >
                        <div className="detail">
                           <div className="data-wrapper">
                              <SpanPanel
                                 title="Profesional"
                                 data={getValuePlain(data?.professional_name)}
                              ></SpanPanel>
                              <SpanPanel
                                 title="Especialidad"
                                 data={renderOptions(
                                    data?.professional_specialties,
                                 )}
                              ></SpanPanel>
                              <SpanPanel
                                 title="Matricula"
                                 data={getValuePlain(
                                    data?.professional_license,
                                 )}
                              ></SpanPanel>
                           </div>
                        </div>
                     </TableCell>
                     <TableCell
                        align="center"
                        variant="head"
                        className="table-cell"
                     >
                        <div className="detail">
                           <div className="data-wrapper">
                              <SpanPanel
                                 title="Tipo de Consulta"
                                 data={
                                    data?.consultation_type
                                       ? valueToName(
                                            consultationType,
                                            data?.consultation_type,
                                         )
                                       : '-'
                                 }
                              ></SpanPanel>
                              {data?.attention_type?.name === 'Particular' ? (
                                 <SpanPanel
                                    title="Tipo Atención"
                                    data={getValuePlain(
                                       data?.attention_type?.name,
                                    )}
                                 ></SpanPanel>
                              ) : (
                                 <>
                                    <SpanPanel
                                       title="Obra Social"
                                       data={getValuePlain(
                                          data?.prepaid_health_category
                                             ?.prepaid_health?.name,
                                       )}
                                    ></SpanPanel>
                                    <SpanPanel
                                       title="Plan"
                                       data={getValuePlain(
                                          data?.prepaid_health_category?.name,
                                       )}
                                    ></SpanPanel>
                                    <SpanPanel
                                       title="Afiliado"
                                       data={getValuePlain(
                                          data?.affiliate_number,
                                       )}
                                    ></SpanPanel>
                                 </>
                              )}
                           </div>
                        </div>
                     </TableCell>
                  </TableRow>
               </TableBody>
            </Table>
            <Table>
               <TableHead></TableHead>
               <TableBody>
                  <TableRow>
                     <TableCell className="table">
                        <div className="rc no-margin-left">
                           Registro de consulta:
                           <div className="normal detail">
                              {getValuePlain(data?.detail)}
                           </div>
                        </div>
                     </TableCell>
                  </TableRow>
               </TableBody>
            </Table>
            <Accordion className="accordion-history-clinic header-accodion-consultation">
               <AccordionSummary
                  className="data"
                  expandIcon={<ExpandMoreIcon />}
               >
                  Prescripciones
               </AccordionSummary>
               <AccordionDetails>
                  <TableContainer>
                     <Table>
                        <TableHead></TableHead>
                        <TableBody>
                           <TableRow className="table">
                              <LabTabs data={data} />
                           </TableRow>
                        </TableBody>
                     </Table>
                  </TableContainer>
               </AccordionDetails>
            </Accordion>
         </TableContainer>
      </>
   );
};

export default ConsultationData;
