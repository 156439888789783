import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { Button, FormControl, FormHelperText } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { srcFromFile } from '../../utils/FormUtil';

const FileUploadField = (props) => {
   const { label, requiredField, savedFile, isImage, validation } = props;
   const [field, meta, helpers] = useField(props);
   const { value } = field;
   const { touched, error } = meta;
   const { setValue } = helpers;
   const isError = !!(touched && error);
   const [typeError, setTypeError] = useState(false);
   const labelField = requiredField ? `${label}*` : label;
   const btnLabel =
      value !== '' ? `Cambiar ${labelField}` : `Subir ${labelField}`;

   useEffect(() => {
      if (value === '' && savedFile) {
         setValue(savedFile);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [value, savedFile]);

   const renderImage = () => {
      if (value !== '') {
         return <img className="image" src={srcFromFile(value)} alt={label} />;
      } else {
         return <p className="empty">No hay imagen subida</p>;
      }
   };

   const renderHelperText = () => {
      if(!value || value === ""){
         return (
            <FormHelperText
               className={`field-helper-text ${isError && 'error'}`}
            >
               {validation.requiredErrorMsg}
            </FormHelperText>
         );
      } if(typeError){
         return (
            <FormHelperText
               className={`field-helper-text ${isError && 'error'}`}
            >
               {validation.typeErrorMsg}
            </FormHelperText>
         );
      } else if (isError) {
         return (
            <FormHelperText
               className={`field-helper-text ${isError && 'error'}`}
            >
               {meta.error}
            </FormHelperText>
         );
      } else if (value?.name) {
         return (
            <FormHelperText className="field-helper-text">
               Archivo subido: {value.name}
            </FormHelperText>
         );
      }
   };

   const onChange = (e) => {
      if (e.target?.files) {
         let allowedExtensions =
         /(\.jpg|\.jpeg|\.png)$/i;
  
         if (!allowedExtensions.exec(e.target.files[0].name)) {
            setTypeError(true);
             return false;
         } else {
            setValue(e.target.files[0]);
            setTypeError(false);
         }
      }
   };

   return (
      <div className="file-upload-field">
         {isImage && <div className="show-image">{renderImage()}</div>}
         <FormControl className="file-upload-field__form">
            <Button
               className="btn"
               component="label"
               variant="contained"
               endIcon={<AttachFileIcon size={24} />}
            >
               {btnLabel}
               <input onChange={onChange} type="file" hidden />
            </Button>
            {renderHelperText()}
         </FormControl>
      </div>
   );
};

export default FileUploadField;
