import UserProfileWrapper from "@mediline/mediline-api-middleware/src/wrappers/user.profile.wrapper";

const upw = new UserProfileWrapper();

export const GetUserProfile = async (token, displayError = null) => await upw.getUserProfile(token, displayError);

export const UserFirstRegister = async (userRegister) => upw.userFirstRegister(
	{
		method_auth: userRegister.method_auth,
		number_auth: userRegister.number_auth,
		active_role: userRegister.active_role,
		id_number: userRegister.id_number,
		email: userRegister.email,
		username: userRegister.username,
		alias: userRegister.username,
		password: userRegister.password
	}
);

export const PostSpeedTest = async (userToken) => await upw.postSpeedTest(userToken);

export const GetSpeedTestTime = async (userToken) => await upw.getSpeedTestTime(userToken);

export const GetAccount = async (token, displayError = null) => upw.getAccount(token, displayError);
export default {
	GetUserProfile,
};
