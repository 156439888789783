import React from 'react';

import OrderList from './OrderList';
import TurnList from './TurnList';

const PatientData = () => {
   return (
      <>
         <TurnList />
         <OrderList />
      </>
   );
};

export default PatientData;
