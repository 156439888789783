import React, { createContext, useContext, useReducer } from 'react';
import { v4 } from 'uuid';

import Toast from '../../components/Toast';

const ToastContext = createContext();

// Toast Type ActionS
const ADD_TOAST = 'ADD_TOAST';
const REMOVE_TOAST = 'REMOVE_TOAST';

const ToastProvider = (props) => {
   const [state, dispatch] = useReducer((stateReducer, action) => {
      switch (action.type) {
         case ADD_TOAST:
            return [...stateReducer, { ...action.payload }];
         case REMOVE_TOAST:
            return stateReducer.filter((toast) => toast.id !== action.id);
         default:
            return stateReducer;
      }
   }, []);

   return (
      <ToastContext.Provider value={dispatch}>
         <div className="toast-wrapper">
            {state.map((toast) => {
               return (
                  <Toast dispatch={dispatch} key={toast.id} {...toast} />
               );
            })}
         </div>
         {props.children}
      </ToastContext.Provider>
   );
};

export const useToast = () => {
   const dispatch = useContext(ToastContext);

   return (props) => {
      dispatch({
         type: ADD_TOAST,
         payload: {
            id: v4(),
            ...props,
         },
      });
   };
};

export default ToastProvider;
