import { Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useToast } from "../../context/Toast/ToastProvider";
import { showToast, ToastType } from "../../utils/ToastUtil";
import HeaderImage from "../../assets/img/emitir_certificados.svg";
import LoadingSpinner from "../../components/LoadingSpinner";
const termsAndConditions = () => {
  const toastDispatch = useToast();

  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    async function loadPosts() {
      try {
        const response = await axios.get(
          "https://mediline.com.ar/wp-json/wp/v2/pages/?slug=terminos-y-condiciones"
        );

        const posts = await response.data[0].content.rendered;

        setContent(posts);
        setIsLoading(false);
      } catch (error) {
        showToast(
          toastDispatch,
          "No se pudieron cargar los términos y condiciones. " + error.message,
          ToastType.ERROR,
          ToastType.ERROR
        );
      }
    }

    loadPosts();
  }, []);

  return (
    <div className="terms-and-conditions">
      <div className="container">
        <div className="header">
          <img
            className="image"
            src={HeaderImage}
            alt="Términos y Condiciones"
          />
          <h2>Términos y Condiciones</h2>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="text-wrapper">
            <div className="text">
              <Typography
                color="textSecondary"
                gutterBottom
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default termsAndConditions;
