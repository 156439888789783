import React from 'react';
import {
   Chip,
   IconButton,
   ListItem,
   ListItemSecondaryAction,
   ListItemText,
   Tooltip,
   Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const ScheduleItem = ({ schedule, index, remove }) => {
   const { patient_dto, hour, date } = schedule;

   return (
      <ListItem className="list-item list-item--appointment" key={index}>
         <ListItemText
            className="item-text"
            primary={hour || ''}
            secondary={
               <Typography className="item-text" component="span">
                  {date}
               </Typography>
            }
         />
         <ListItemText
            primary={patient_dto?.aliasOrFullName}
            secondary={
               <Chip
                  className="item-chip"
                  label={patient_dto.prepaid_health?.name || 'Particular'}
                  component="span"
               />
            }
         />
         <ListItemSecondaryAction>
            <Tooltip title="Cancelar turno">
               <IconButton onClick={() => remove(index)}>
                  <DeleteIcon />
               </IconButton>
            </Tooltip>
         </ListItemSecondaryAction>
      </ListItem>
   );
};

export default ScheduleItem;
