import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import { InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import { getNotificationTemplates } from '../../services/notificationService';
import { useStateAuthValue } from '../../context/Auth/AuthState';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "95%",
    },
    wrapText: {
      width: "100%"
    },
    button: {
      backgroundColor: '#002c54',
      color: 'white',
      '&:hover': {
        backgroundColor: '#004080',
      },
    },
  })
);

export const MultiSelect = ({ handleSendMessage, setTemplate, template, messages, setTemplatesOptions, templatesOptions }) => {
  const classes = useStyles();
  const [{ userToken }] = useStateAuthValue();

  useEffect(() => {
    if (userToken) {
      getNotificationTemplates(userToken).then((res) => {
        if (res.status == 'OK') setTemplatesOptions(res.data);
      });
    }
  }, [userToken]);

  const handleChange = (event) => {
    setTemplate(event.target.value);
  };

  return (
    <>
      <form className={classes.wrapForm} noValidate autoComplete="off">
        {template === '' && (
          <Box sx={{ minWidth: 400, minHeight: 50 }}>
            <FormControl fullWidth>
              <InputLabel id="multiselect-label">Selecciona mensaje</InputLabel>
              <Select
                labelId="multiselect-label"
                id="multiselect-label"
                value={template}
                label="Selecciona mesaje"
                onChange={handleChange}
                //disabled={messages.length > 0 ? true : false}
              >
                {templatesOptions.length !== 0 && (
                  templatesOptions.map((element) => {
                    return <MenuItem key={element?.id} value={element?.template}>{element?.description}</MenuItem>
                  })
                )
                }
              </Select>
            </FormControl>
          </Box>
        )}
        {template !== '' && (
          <Box sx={{ maxWidth: 400, minHeight: 50 }}>
            <FormControl fullWidth>
              <Tooltip title={template}>
                <Chip
                  label={template}
                  variant="template"
                  onDelete={() => { setTemplate('') }}
                />
              </Tooltip>
            </FormControl>
          </Box>
        )}
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleSendMessage}
          disabled={template === ''}
        >
          <SendIcon />
        </Button>
      </form>
    </>
  );
};