import React from 'react';
import {
   AppBar,
   Toolbar,
   IconButton,
   Drawer,
   Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../../assets/img/logo.png';
import { useHistory } from 'react-router-dom';


const drawerWidth = 240;

const HeaderLight = () => {

const history = useHistory();

   const useStyles = makeStyles((theme) => ({
      appBar: {
         display: 'block',
         backgroundColor: '#002C54',
         transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
         }),
         zIndex: theme.zIndex.drawer + 1,
      },
      appBarShift: {
         marginLeft: drawerWidth,
         width: `calc(100% - ${drawerWidth}px)`,
         transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
         }),
      },
      toolbarNav: {
         backgroundColor: '#002C54',
         height: '80px',
         width: '100%',
         maxWidth: '1440px',
         margin: '0 auto',
      },
      titleHeader: {
         fontSize: '32px',
         fontWeight: '800',
         lineHeight: '40px',
         flexGrow: 1,
      },
      menuButton: {
         marginRight: theme.spacing(2),
      },
      imageLogo: {
         width: '70px',
         marginLeft: '16px',
      },
   }));
   const classes = useStyles();
   
   
      return (
      <div className="header">
         <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbarNav}>
               <Typography variant="h6" className={classes.titleHeader} noWrap>
               </Typography>
               <IconButton color="inherit" onClick={()=>{history.push('/signup')}}>
               <img src={Logo} alt="Logo" className={classes.imageLogo} />
               </IconButton>
            </Toolbar>
         </AppBar>
         <Drawer anchor="left" >
            
         </Drawer>
      </div>
   );
};

export default HeaderLight;