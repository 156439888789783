import { Buffer } from 'buffer';
const ENCODING = 'base64';
export const FORM_VALUES_KEY = Buffer.from('FORM_VALUES').toString(ENCODING);
export const POST_VIDEOCALL_KEY = Buffer.from('POST_VIDEOCALL_VALUES').toString(ENCODING);

export const set = (key, data) => {
   localStorage.setItem(key, Buffer.from(JSON.stringify(data)).toString(ENCODING));
};

export const removeLocalStorageItem = (key) => {
   localStorage.removeItem(key);
};

export const get = (key) => {
   const item = localStorage.getItem(key);
   if (item !== null) {
      return JSON.parse(Buffer.from(item, ENCODING).toString());
   }
   return null;
};

export const deleteFormValues = () => {
   localStorage.clear();
};
