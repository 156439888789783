import React, { useEffect, useState } from 'react';

import {
   AutocompleteFieldFreeSolo,
   InputField,
   RadioGroupField,
} from '../../../../components/FormFields';
import { useStateAuthValue } from '../../../../context/Auth/AuthState';
// Comentado hasta que se habiliten todas las especialidades
/* import { GetSpecialties } from '../../../../services/userService';
import { showToast, ToastType } from '../../../../utils/ToastUtil';
import { useToast } from '../../../../context/Toast/ToastProvider'; */
import licenceTypes from '../../../../tests/mocks/licenceTypes';



const ProfessionalDataForm = ({ formModel }) => {
   const {
      professional_dto: {
         licence_str,
         type_licence_str,
         specialties,
         academic_training,
      },
   } = formModel;
   
   const [{ userToken }] = useStateAuthValue();
   // Comentado hasta que se habiliten todas las especialidades
   // const toastDispatch = useToast();
   const [specialtiesList, setSpecialtiesList] = useState([]);
   
   useEffect(() => {
      // Comentado hasta que se habiliten todas las especialidades
      // let isMounted = true;
      // const getData = async () => {
      const getData = () => {
         setSpecialtiesList(
            [{
               id: 'b3792b24-85a8-4373-8b06-c0d48ec744a4',
               name: 'Clínica médica',
               specialityId:"06821b76-ea1a-4006-b311-5b93c47d8180"
            },   
            {
               id: 'e09fa8d7-77f3-4c63-93e1-19e6caf1f6fc',
               name: 'Pediatría'
            }]
         ); 
         // Comentado hasta que se habiliten todas las especialidades
         /* await GetSpecialties(userToken, (sessionError) => showToast(toastDispatch, sessionError, ToastType.ERROR, ToastType.ERROR))
            .then((response) => {
               if (!response.data.error && isMounted) {
                  setSpecialtiesList(response.data);
               } else {
                  showToast(
                     toastDispatch,
                     'Ocurrió un error en la búsqueda de especialidades',
                     ToastType.ERROR,
                  );
               }
            })
            .catch((err) => {
               showToast(toastDispatch, err.message, ToastType.ERROR);
            }); */
      }; 

      if (userToken) {
         getData();
      }
      
      // Comentado hasta que se habiliten todas las especialidades
      return () => {
         // isMounted = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userToken]); 

return (
      <>
         <div className="header">
            <h2>Datos Profesionales</h2>
         </div>
         <div className="form">
            <InputField
               name={licence_str.name}
               label={licence_str.label}
               className="field"
               requiredField
               maxLength = {10}
            />
            <RadioGroupField
               name={type_licence_str.name}
               label={type_licence_str.label}
               data={licenceTypes}
               className="field"
               requiredField
            />
            <AutocompleteFieldFreeSolo
               name={specialties.name}
               label={specialties.label}
               options={specialtiesList}
               className="field"
               requiredField
               placeholder={specialties.placeholder}
            /> 
            <InputField
               name={academic_training.name}
               label={academic_training.label}
               className="field"
               rows={3}
               requiredField
               maxLength = {255}
            />
         </div>
      </>
   );
};

export default ProfessionalDataForm;
