const countries = [
   { name: 'Argentina' },
   { name: 'Andorra' },
   { name: 'Afghanistan' },
   { name: 'Antigua and Barbuda' },
   { name: 'Anguilla' },
   { name: 'Albania' },
   { name: 'Armenia' },
   { name: 'Angola' },
   { name: 'Antarctica' },
   { name: 'American Samoa' },
   { name: 'Austria' },
   { name: 'Australia' },
   { name: 'Aruba' },
   { name: 'Alland Islands' },
   { name: 'Azerbaijan' },
   { name: 'Bosnia and Herzegovina' },
   { name: 'Barbados' },
   { name: 'Bangladesh' },
   { name: 'Belgium' },
   { name: 'Burkina Faso' },
   { name: 'Bulgaria' },
   { name: 'Bahrain' },
   { name: 'Burundi' },
   { name: 'Benin' },
   { name: 'Saint Barthelemy' },
   { name: 'Bermuda' },
   { name: 'Brunei Darussalam' },
   { name: 'Bolivia' },
   { name: 'Brazil' },
   { name: 'Bahamas' },
   { name: 'Bhutan' },
   { name: 'Bouvet Island' },
   { name: 'Botswana' },
   { name: 'Belarus' },
   { name: 'Belize' },
   { name: 'Canada' },
   { name: 'Cocos (Keeling) Islands' },
   { name: 'Congo, Democratic Republic of the' },
   { name: 'Central African Republic' },
   { name: 'Congo, Republic of the' },
   { name: 'Switzerland' },
   { name: "Cote d'Ivoire" },
   { name: 'Cook Islands' },
   { name: 'Chile' },
   { name: 'Cameroon' },
   { name: 'China' },
   { name: 'Colombia' },
   { name: 'Costa Rica' },
   { name: 'Cuba' },
   { name: 'Cape Verde' },
   { name: 'Curacao' },
   { name: 'Christmas Island' },
   { name: 'Cyprus' },
   { name: 'Czech Republic' },
   { name: 'Germany' },
   { name: 'Djibouti' },
   { name: 'Denmark' },
   { name: 'Dominica' },
   { name: 'Dominican Republic' },
   { name: 'Algeria' },
   { name: 'Ecuador' },
   { name: 'Estonia' },
   { name: 'Egypt' },
   { name: 'Western Sahara' },
   { name: 'Eritrea' },
   { name: 'Spain' },
   { name: 'Ethiopia' },
   { name: 'Finland' },
   { name: 'Fiji' },
   { name: 'Falkland Islands (Malvinas)' },
   { name: 'Micronesia, Federated States of' },
   { name: 'Faroe Islands' },
   { name: 'France' },
   { name: 'Gabon' },
   { name: 'United Kingdom' },
   { name: 'Grenada' },
   { name: 'Georgia' },
   { name: 'French Guiana' },
   { name: 'Guernsey' },
   { name: 'Ghana' },
   { name: 'Gibraltar' },
   { name: 'Greenland' },
   { name: 'Gambia' },
   { name: 'Guinea' },
   { name: 'Guadeloupe' },
   { name: 'Equatorial Guinea' },
   { name: 'Greece' },
   {
      name: 'South Georgia and the South Sandwich Islands',
   },
   { name: 'Guatemala' },
   { name: 'Guam' },
   { name: 'Guinea-Bissau' },
   { name: 'Guyana' },
   { name: 'Hong Kong' },
   { name: 'Heard Island and McDonald Islands' },
   { name: 'Honduras' },
   { name: 'Croatia' },
   { name: 'Haiti' },
   { name: 'Hungary' },
   { name: 'Indonesia' },
   { name: 'Ireland' },
   { name: 'Israel' },
   { name: 'Isle of Man' },
   { name: 'India' },
   { name: 'British Indian Ocean Territory' },
   { name: 'Iraq' },
   { name: 'Iran, Islamic Republic of' },
   { name: 'Iceland' },
   { name: 'Italy' },
   { name: 'Jersey' },
   { name: 'Jamaica' },
   { name: 'Jordan' },
   { name: 'Japan' },
   { name: 'Kenya' },
   { name: 'Kyrgyzstan' },
   { name: 'Cambodia' },
   { name: 'Kiribati' },
   { name: 'Comoros' },
   { name: 'Saint Kitts and Nevis' },
   {
      name: "Korea, Democratic People's Republic of",
   },
   { name: 'Korea, Republic of' },
   { name: 'Kuwait' },
   { name: 'Cayman Islands' },
   { name: 'Kazakhstan' },
   { name: "Lao People's Democratic Republic" },
   { name: 'Lebanon' },
   { name: 'Saint Lucia' },
   { name: 'Liechtenstein' },
   { name: 'Sri Lanka' },
   { name: 'Liberia' },
   { name: 'Lesotho' },
   { name: 'Lithuania' },
   { name: 'Luxembourg' },
   { name: 'Latvia' },
   { name: 'Libya' },
   { name: 'Morocco' },
   { name: 'Monaco' },
   { name: 'Moldova, Republic of' },
   { name: 'Montenegro' },
   { name: 'Saint Martin (French part)' },
   { name: 'Madagascar' },
   { name: 'Marshall Islands' },
   {
      name: 'Macedonia, the Former Yugoslav Republic of',
   },
   { name: 'Mali' },
   { name: 'Myanmar' },
   { name: 'Mongolia' },
   { name: 'Macao' },
   { name: 'Northern Mariana Islands' },
   { name: 'Martinique' },
   { name: 'Mauritania' },
   { name: 'Montserrat' },
   { name: 'Malta' },
   { name: 'Mauritius' },
   { name: 'Maldives' },
   { name: 'Malawi' },
   { name: 'Mexico' },
   { name: 'Malaysia' },
   { name: 'Mozambique' },
   { name: 'Namibia' },
   { name: 'New Caledonia' },
   { name: 'Niger' },
   { name: 'Norfolk Island' },
   { name: 'Nigeria' },
   { name: 'Nicaragua' },
   { name: 'Netherlands' },
   { name: 'Norway' },
   { name: 'Nepal' },
   { name: 'Nauru' },
   { name: 'Niue' },
   { name: 'New Zealand' },
   { name: 'Oman' },
   { name: 'Panama' },
   { name: 'Peru' },
   { name: 'French Polynesia' },
   { name: 'Papua New Guinea' },
   { name: 'Philippines' },
   { name: 'Pakistan' },
   { name: 'Poland' },
   { name: 'Saint Pierre and Miquelon' },
   { name: 'Pitcairn' },
   { name: 'Puerto Rico' },
   { name: 'Palestine, State of' },
   { name: 'Portugal' },
   { name: 'Palau' },
   { name: 'Paraguay' },
   { name: 'Qatar' },
   { name: 'Reunion' },
   { name: 'Romania' },
   { name: 'Serbia' },
   { name: 'Russian Federation' },
   { name: 'Rwanda' },
   { name: 'Saudi Arabia' },
   { name: 'Solomon Islands' },
   { name: 'Seychelles' },
   { name: 'Sudan' },
   { name: 'Sweden' },
   { name: 'Singapore' },
   { name: 'Saint Helena' },
   { name: 'Slovenia' },
   { name: 'Svalbard and Jan Mayen' },
   { name: 'Slovakia' },
   { name: 'Sierra Leone' },
   { name: 'San Marino' },
   { name: 'Senegal' },
   { name: 'Somalia' },
   { name: 'Suriname' },
   { name: 'South Sudan' },
   { name: 'Sao Tome and Principe' },
   { name: 'El Salvador' },
   { name: 'Sint Maarten (Dutch part)' },
   { name: 'Syrian Arab Republic' },
   { name: 'Swaziland' },
   { name: 'Turks and Caicos Islands' },
   { name: 'Chad' },
   { name: 'French Southern Territories' },
   { name: 'Togo' },
   { name: 'Thailand' },
   { name: 'Tajikistan' },
   { name: 'Tokelau' },
   { name: 'Timor-Leste' },
   { name: 'Turkmenistan' },
   { name: 'Tunisia' },
   { name: 'Tonga' },
   { name: 'Turkey' },
   { name: 'Trinidad and Tobago' },
   { name: 'Tuvalu' },
   { name: 'Taiwan, Province of China' },
   { name: 'United Republic of Tanzania' },
   { name: 'Ukraine' },
   { name: 'Uganda' },
   { name: 'United States' },
   { name: 'Uruguay' },
   { name: 'Uzbekistan' },
   { name: 'Holy See (Vatican City State)' },
   { name: 'Saint Vincent and the Grenadines' },
   { name: 'Venezuela' },
   { name: 'British Virgin Islands' },
   { name: 'US Virgin Islands' },
   { name: 'Vietnam' },
   { name: 'Vanuatu' },
   { name: 'Wallis and Futuna' },
   { name: 'Samoa' },
   { name: 'Kosovo' },
   { name: 'Yemen' },
   { name: 'Mayotte' },
   { name: 'South Africa' },
   { name: 'United Arab Emirates' },
   { name: 'Zambia' },
   { name: 'Zimbabwe' },
];

export default countries;
