import HttpClient from './fetch_api';
import {
   PURCHASE_ORDER,
   PHARMACY_SERVICE,
   STATE_PRESCRIPTION,
   PRESCIPTION_ORDER,
   PRESCRIPTION_MEDICATION,
   GET_EXTRA,
   CHANGE_STATE,
} from '../conf/appSetting';

export const sendOrder = async (params, token) =>
   HttpClient.post(PURCHASE_ORDER.base, params, HttpClient.Authorization(token));

export const getPharmacySearch = async (params, token) =>
   HttpClient.get(
      HttpClient.concatNotNullParam(
         PHARMACY_SERVICE.base + PHARMACY_SERVICE.search,
         params,
      ),
      HttpClient.Authorization(token),
   );

export const GetPharmacyByPrescription = async (params, token) =>
   HttpClient.get(
      HttpClient.concatNotNullParam(
         PRESCIPTION_ORDER.prescriptionBase,
         params,
      ),
      HttpClient.Authorization(token),
   );

export const getOrderStatus = async (params, token) =>
   HttpClient.get(
      HttpClient.concatNotNullParam(STATE_PRESCRIPTION.base, params),
      HttpClient.Authorization(token),
   );

export const getPrescriptionOfOrder = async (params, token) =>
   HttpClient.get(`${PRESCIPTION_ORDER.base}/${params}`, HttpClient.Authorization(token));

export const prescriptionMedication = async (params, token) =>
   HttpClient.get(`${PRESCRIPTION_MEDICATION.base}/${params}`, HttpClient.Authorization(token));

export const getExtra = async (params, token) =>
   HttpClient.get(`${GET_EXTRA.base}/${params}`, HttpClient.Authorization(token));

export const changeState = async (params, token) =>
   HttpClient.patch(CHANGE_STATE.base, params, HttpClient.Authorization(token));
