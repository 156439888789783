import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { MessageRightWhatsapp, MessageRightEmail } from "./Message.js";
import { MultiSelect } from "./MultiSelect.js";
import { sendNotifications, getNotificationDeliveries} from '../../services/notificationService.js';
import { useStateAuthValue } from "../../context/Auth/AuthState.js";
import { processEmail, validatePhone } from "../../utils/ToastUtil.js";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: "80vw",
      height: "45vh",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
    },
    paper2: {
      width: "80vw",
      maxWidth: "500px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    messagesBody: {
      width: "calc( 100% - 20px )",
      margin: 10,
      overflowY: "scroll",
      height: "calc( 100% - 80px )"
    }
  })
);

export default function ChatSend({setTemplate, template, patientSend, setLoading}) {
    const classes = useStyles();
    const [messages, setMessages] = useState([]);
    const [templatesOptions, setTemplatesOptions] = useState([]);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [{ userToken }] = useStateAuthValue();

    useEffect(() => {
      let email = (patientSend?.familymember ? processEmail(patientSend?.familymember) : null)
      let newMessages = [];
      if (userToken && email) {
        setLoading(true);
        getNotificationDeliveries(userToken, email).then((res) => {
          if (res.status === 'OK') {
            if (res.data.length !== 0) {
              res.data.map((element) => {
                newMessages.push({text: element?.body, timestamp: getCurrentTimestamp(element?.creationDate), type: element?.type.toLowerCase(), status: element?.status});
              })
            }
          }
          setMessages(newMessages)
        }).finally(() => setLoading(false));
      }
    }, [userToken, patientSend, refreshTrigger]);

    const getCurrentTimestamp = (date=null) => {
      let currentDate = null;
      if (date) {
        currentDate = new Date(date);    
      } else {
        currentDate = new Date();
      }
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    };

    const sendNotification = () => {
      let recipient = validatePhone(patientSend?.phone_number) ? (patientSend?.phone_number ? patientSend?.phone_number : '') : (patientSend?.familymember ? processEmail(patientSend?.familymember) : '')
      let event = null;
      let templateField = null;
      let data = {};
      if (template) {
        templateField = templatesOptions.find((element) => element.template === template);
        if (templateField !== null || templateField !== undefined) {
          event = templateField.event;
        }
        if (event !== undefined || event !== null) {
          data = {
            "entityId" : patientSend?.id,
            "event" : event,
            "entity" : {
              "id" : patientSend?.id,
              "consultationType" : "VIRTUAL_GUARD",
              "userLogin": patientSend?.familymember,
            },
            "recipients" : [ recipient ]
          }
          if (userToken) {
            handleSendNotifications(userToken, data);
          };
        }
      }
    }

    const handleSendNotifications = (userToken, data) => {
      sendNotifications(userToken, data).finally(() => {
        setTimeout(() => {
          setRefreshTrigger((prev) => prev + 1);
        }, 10000);
      });
    };
  
    const handleSendMessage = () => {
      if (template.trim() !== "") {
        const timestamp = getCurrentTimestamp();
        const newSentMessage = { text: template, timestamp, type: validatePhone(patientSend?.phone_number) ? (patientSend?.phone_number ? 'whatsapp' : '') : (patientSend?.familymember ? 'email' : '') };
        setMessages([...messages, newSentMessage]);
        setTemplate('');
        sendNotification();
      }
    };
  
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Paper id="style-1" className={classes.messagesBody}>
            {messages.map((msg, index) => (
              msg.type === 'whatsapp' ? (
               (msg?.status != "NEW") && (
                <MessageRightWhatsapp
                  key={index}
                  message={msg.text}
                  timestamp={msg.timestamp}
                  status={msg.status}
                  avatarDisp={true}
                />
               )
              ) : (
                (msg?.status != "NEW") && (
                  <MessageRightEmail
                    key={index}
                    message={msg.text}
                    timestamp={msg.timestamp}
                    status={msg.status}
                    avatarDisp={true}
                  />
                )
              )
            ))}
          </Paper>
          <MultiSelect
            setTemplate={setTemplate}
            template={template}
            messages={messages}
            handleSendMessage={handleSendMessage}
            templatesOptions={templatesOptions}
            setTemplatesOptions={setTemplatesOptions}
          />
        </Paper>
      </div>
    );
  }
