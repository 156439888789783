import React, {useEffect, useState} from "react";
import Modal from "../../../../components/Modal";
import { Rating } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import { ApplicationStyle } from "../../../../theme";
import { makeStyles } from "@material-ui/core/styles";
import { PostRanking } from "../../../../services/consultationService";
import { useStateAuthValue } from "../../../../context/Auth/AuthState";

const MedilineStyle = makeStyles(ApplicationStyle);
const ConsultationSuccessModal = ({
  open,
  title,
  subtitle,
  handleClose,
  outlinedConfirm = false,
  queue
}) => {
  const MedilineClasses = MedilineStyle();
  const [value, setValue] = useState(0);
  const [{ userToken }] = useStateAuthValue();

  const handleRanking  = async () => {
    await PostRanking(userToken, queue.queueId, value || 0)
    .finally(()=>{
      handleClose();
    });
  }

  return (
    <Modal isActive={open} handleClose={handleRanking}>
      <div className={MedilineClasses.btnBoxCenterM0}>
        <div className={MedilineClasses.titleBlue}>{title}</div>
        { subtitle && (
          <div className={MedilineClasses.subtitleBlue}>{subtitle}</div>
        )}
        <div className={MedilineClasses.btnSpacing}>
        <p style={{
            fontSize: "17px",
            fontWeight: "700",
            margin: "0"
        }}>Por favor califica tu consulta</p>
        <Rating 
        name="no-value" 
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        />
          <div style={{margin: "15px"}}>
              <Button
                className={outlinedConfirm? MedilineClasses.btnBlueOutline : MedilineClasses.btnBlueNext}
                onClick={() => handleRanking()}
              >
                Finalizar
              </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConsultationSuccessModal;
