import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';

import PatientIcon from '../../assets/img/signup-icon-patient.svg';
import PatientInactiveIcon from '../../assets/img/signup-icon-patient-inactive.svg';
import ProfessionalIcon from '../../assets/img/signup-icon-professional.svg';
import ProfessionalInactiveIcon from '../../assets/img/signup-icon-professional-inactive.svg';
import { Role } from '../../models/enums';

const RoleIcon = ({ setRoleSelected, role, active }) => {
   const roleIcon = role === Role.PATIENT ? PatientIcon : ProfessionalIcon;
   const roleInactiveIcon =
      role === Role.PATIENT ? PatientInactiveIcon : ProfessionalInactiveIcon;
   const roleTitle = role === Role.PATIENT ? 'PACIENTE' : 'PROFESIONAL';
   const roleIconClass = active ? 'active' : '';

   const handleRoleSelected = () => {
      setRoleSelected(role);
   };

   return (
      <div className={`role-icon ${roleIconClass}`}>
         <IconButton onClick={handleRoleSelected}>
            <img src={active ? roleIcon : roleInactiveIcon} alt={role} />
         </IconButton>
         <span className="text">{roleTitle}</span>
      </div>
   );
};

export default RoleIcon;

RoleIcon.propTypes = {
   setRoleSelected: PropTypes.func.isRequired,
   role: PropTypes.oneOf([Role.PATIENT, Role.PROFESSIONAL]),
   active: PropTypes.bool.isRequired,
};

RoleIcon.defaultProps = {
   setRoleSelected: null,
   role: null,
   active: null,
};
