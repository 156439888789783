import { useEffect, useRef } from 'react';

export const RefreshPage = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    const number = parseInt(process.env.REACT_APP_PAGE_REFRESH_DURATION);
    if(process.env.REACT_APP_PAGE_SHOULD_REFRESH === "true"){ 
    const interval = setInterval(() => {
      const consult = localStorage.getItem('consult');
      if (isMounted.current && consult !== 'true') {
        window.location.reload();
      }
    }, number);
    return () => {
      clearInterval(interval);
      isMounted.current = false;
    };
    }
  }, []);
  return null;
};
