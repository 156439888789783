import React, { useState } from "react";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  Collapse,
  ListItem,
  ListItemText,
} from '@material-ui/core';

const ConsultationData = ({ prescription }) => {

  const [open, setOpen] = useState(false);

  return (
    <>
       <div className="container">
            <div className="list">
              <ListItem className="list-item-prescription">
                <div className="presc-data">
                  <div className="id-state">
                    <span>Receta N°</span>
                    <span className="id-state__id">{prescription?.prescriptionId}</span>
                  </div>
                  <div className="detail">
                    <span>Diagnóstico: {prescription?.diagnosis}</span>                    
                  </div>
                </div>

                <div className="description">
                  <ListItem button onClick={()=>setOpen(!open)}>
                    Medicamentos: 
                    <ListItemText />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <div className="collapse">
                      <div className="cant-descrip">
                        <span className="cant">Cant.</span>
                        <span className="description">Descripción</span>
                      </div>
                      <div className="medicine">
                        {prescription?.medications?.map((data) => {
                          return (
                            <div key={data?.quantity} className="medication">
                              <span className="medicine-number">
                                {data?.quantity}
                              </span>
                              <span className="medicine-name">{data?.name}</span>
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Collapse>
                </div>
              </ListItem>
            </div>
          </div>
    </>
  );
};

export default ConsultationData;
