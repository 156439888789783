import React from 'react';

import ResultImage from '../../../../assets/img/estamos_buscando.svg';

const ResultDefault = () => (
   <div className="default">
      <img alt="¡Buscá tu especialista!" className="image" src={ResultImage} />
      <p className="text">¡Buscá tu especialista!</p>
   </div>
);

export default ResultDefault;
