const patientInitialValues = {
   alias: '',
   first_name: '',
   last_name: '',
   sex_str: '',
   birth_date: '',
   id_number: '',
   phone_number: '',
   address_dto: {
      id: '',
      street: '',
      number: '',
      number_department: '',
      floor: '',
      postal_code: '',
      between_streets: '',
      city: '',
      country: 'Argentina',
      province: '',
   },
   patient_dto: {
      pre_existing_disease: [],
      frequent_medication: [],
      another_frequent_medication:"",
      surgeries: [],
      size: '',
      weight: '',
      allergies: '',
      family_history: '',
      vaccines: '',
      gynecological_data: '',
      attention_type: '',
      affiliate_number: '',
      study: [],
   },
};

export default patientInitialValues;
