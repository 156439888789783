
export const professionalLogInitialState = {
  stompClient: null,
};

const ProfessionalLogReducer = (state, action) => {
  switch (action.type) {
    case "LOG_IN":
      //console.log("LOG_IN ProfessionalLogReducer");

      return {
        ...state,
      };
    case "SET_STOMP_CLIENT":
      return {
        ...state,
        stompClient: action.payload,
      };
    case "SEND_MESSAGE":
      const { message, destination } = action.payload;
      state.stompClient?.send("/app/pong", {}, JSON.stringify(message));
      return state;
    default:
      return state;
  }
};

export default ProfessionalLogReducer;