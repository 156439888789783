import React from 'react';
import { Button, List, ListItem, ListItemText } from '@material-ui/core';

const Data = () => (
   <div className="data">
      <div className="header">
         <h3>Datos de Cuenta</h3>
         <Button className="button" variant="outlined">
            Editar
         </Button>
      </div>
      <div className="list">
         <List>
            <ListItem className="list-item">
               <ListItemText
                  className="item-text"
                  primary="Titular"
                  secondary="Sabrina Jacqueline Godoy"
               />
            </ListItem>
            <ListItem className="list-item">
               <ListItemText
                  className="item-text"
                  primary="Número de cuenta"
                  secondary="4085709-2 081-9"
               />
            </ListItem>
            <ListItem className="list-item">
               <ListItemText
                  className="item-text"
                  primary="ALIAS"
                  secondary="ALIASPROFESIONAL"
               />
               <ListItemText
                  className="item-text"
                  primary="CUIL"
                  secondary="23-26587158-6"
               />
            </ListItem>
            <ListItem className="list-item">
               <ListItemText
                  className="item-text"
                  primary="Banco"
                  secondary="BANCO DE GALICIA Y BUENOS AIRES S.A.U."
               />
            </ListItem>
         </List>
      </div>
   </div>
);

export default Data;
