import React, { useState } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';

import HeaderImage from '../../../assets/img/MisPedidos.svg';
import { Order } from '../../../models/enums';
import MyOrdersData from '../../../pages/patient/myOrders/myOrdersData';

const MyOrders = () => {
   const [orderType, setOrderType] = useState({order : Order.CREATED,sort : 'createdDate,desc'});

   return (
      <div className="my-orders">
         <div className="header">
            <div className="image-title">
               <img className="image" src={HeaderImage} alt="Mis Pedidos" />
               <div className="title">
                  <h2>Mis Pedidos</h2>
               </div>
            </div>
            <ButtonGroup className="btn-group">
            <Button
                  onClick={() => setOrderType({order:Order.CREATED,sort : 'createdDate,desc'})}
                  className={`btn ${
                     orderType.order === Order.CREATED ? 'active' : ''
                  }`}
               >
                  Creados
               </Button>
               <Button
                  onClick={() => setOrderType({order:Order.QUOTED,sort : 'quotedDate,desc'})}
                  className={`btn ${
                     orderType.order === Order.QUOTED ? 'active' : ''
                  }`}
               >
                  Cotizados
               </Button>
               <Button
                  onClick={() => setOrderType({ order:Order.PRE_PAID,sort : 'prepaidDate,desc'})}
                  className={`btn ${
                     orderType.order === Order.PRE_PAID ? 'active' : ''
                  }`}
               >
                  Procesando
               </Button>
               <Button
                  onClick={() => setOrderType({ order : Order.PAID, sort : 'paidDate,desc'})}
                  className={`btn ${orderType .order=== Order.PAID ? 'active' : ''}`}
               >
                  Pagados
               </Button>
               <Button
                  onClick={() => setOrderType({ order :Order.SENT, sort : 'sentDate,desc'})}
                  className={`btn ${orderType.order === Order.SENT ? 'active' : ''}`}
               >
                  Enviados
               </Button>
               <Button
               //
                  onClick={() => setOrderType({ order :Order.DELIVERED, sort : 'deliveredDate,desc'})}
                  className={`btn ${
                     orderType.order === Order.DELIVERED ? 'active' : ''
                  }`}
               >
                  Entregados
               </Button>
               <Button
                  onClick={() => setOrderType({ order :Order.REJECTED, sort : 'rejectedDate,desc'})}
                  className={`btn ${
                     orderType.order === Order.REJECTED ? 'active' : ''
                  }`}
               >
                  Rechazados
               </Button>
            </ButtonGroup>
         </div>
         <div className="container">
            <MyOrdersData orderTypeSelected={orderType} />
         </div>
      </div>
   );
};

export default MyOrders;
