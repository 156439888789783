import React, { useState } from 'react';
import HistoryClinicIcon from '../../assets/img/historial-prestaciones.svg'
import { useStateAuthValue } from '../../context/Auth/AuthState';
import { PatientSearch } from '../../services/patientService';
import SearchBox from './SearchBox';
import SearchResult from './SerchedResult';

const HistoryClinic = () => {
    const [results, setResults] = useState([]);
    const [ userData ] = useStateAuthValue();

    const getData = async (params) => {
        await PatientSearch(userData.userToken, params)
        .then((response) => {
            setResults(response.data);
         });
     };

    const handelSearchClick = (params) => {
        getData(params);
    };
    return (
        <div className="history-clinic">
            <div className="header">
                <img className="image" src={HistoryClinicIcon} alt="Historia Clinica" />
                <h2>Historia Clinica</h2>
            </div>
            <div className="column">
            <SearchBox onSearch={handelSearchClick}/>
            <SearchResult results={results}/>
            </div>
        </div>
    );
};

export default HistoryClinic;
