import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStateAuthValue } from '../../context/Auth/AuthState';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoadingSpinner from "../LoadingSpinner"
import { ParentalRelationshipType } from '../../utils/ParentalRelationshipsUtil';
import {GetUserProfile} from "../../services/apiService";
import {GetMedilineFinancerByPatient} from "../../services/medilinePrepaidService";
import TrashIcon from "../../assets/img/trash-bin.png";
import PencilIcon from "../../assets/img/edit.png";
import { showToast, ToastType } from '../../utils/ToastUtil';
import { useToast } from '../../context/Toast/ToastProvider';

const FamilyGroup = () => {
    const [{ userData, userToken }] = useStateAuthValue();
    const history = useHistory();
    const [titular, setTitular] = useState("");
    const [plan, setPlan] = useState({});
    const prestador= "Mediline";
    const [loading, setLoading] = useState(true);
    const [familyGroupData, setFamilyGroupData] = useState([]);
    const [canAddMembers, setCanAddMembers] = useState(true);
    const toastDispatch = useToast();

    useEffect(() => {
      if(userData){
        setTitular(userData?.first_name + " " + userData?.last_name);   
      }
        const getFamily = async () => {
          setLoading(true);
          try {
            const res = await GetUserProfile(userToken);
            if (res.data.patient_dto.family_group.length > 0) {
                const uniqueIds = new Set();
                const filteredFamilyGroup = res.data.patient_dto.family_group.filter(member => {
                    if (uniqueIds.has(member.id)) {
                        return false;
                    } else {
                        uniqueIds.add(member.id);
                        return true;
                    }
                });
                setFamilyGroupData(filteredFamilyGroup);
            } else {
                setFamilyGroupData(res.data.patient_dto.family_group);
            }
          } catch (error) {
              if (process.env.REACT_APP_DEBUG === "true") {
                  const errorMessage = "Error:  ~ file: FamilyGroup index.js:34 ~ getFamily ~ error:" + error;
                  showToast(toastDispatch, errorMessage, ToastType.ERROR, ToastType.ERROR);
              }
          } finally {
            setLoading(false);
          }
        };

      if(userToken){
        getFamily();
      }

    },[userData]);

    useEffect(() => {
      const getPlan = async () => {
        await GetMedilineFinancerByPatient(userToken, userData.patient_dto.id)
        .then((res) => {
          setPlan(res);
        })
        .catch((e) => {
          if(process.env.REACT_APP_DEBUG === "true"){
            const errorMessage = "Error:  ~ file: FamilyGroup index.js:55 ~ getPlan ~ error:" + e;
            showToast(toastDispatch, errorMessage, ToastType.ERROR, ToastType.ERROR);
           }
        })
      }
      if(userData?.patient_dto?.id){
        getPlan();
      }
    }, [userData]);

    useEffect(() => {
      if(familyGroupData?.length < 3 && plan?.familyMembers !== 0){
        setCanAddMembers(true);
      } else {
        setCanAddMembers(false);
      }
    }, [familyGroupData, plan]);

    const addMember = () => {
      if(!loading){
        history.push({
          pathname: "/nuevo-miembro-familiar"
        })
      }
    }

    const editFamilyMember = (event) => {
      familyGroupData.map((per) => {
        if(per.id_number === event.currentTarget.id){
          history.push({
            pathname: "/nuevo-miembro-familiar",
            state: { memberData: per, relationship : per.relationship_to_titular}
          }) 
        }})
      } 

    const myPlanHandler = () => {
      history.push("/mi-plan")
    }

    const onSaveHandler = () => {
      history.push("/paciente")
    }

    const deleteFamilyGroupMember = () => {
      history.push("/mi-plan")
    }
    return (
      <div className='container'>
          <div className='title_container'>
              <h2>Mi Grupo Familiar</h2>
          </div>
          <div>
              <h5 style={{ padding: "10px 0" }}>Titular: {titular}</h5>
              <h5 style={{ paddingBottom: "10px" }}>Plan: {prestador} - {plan?.planName}</h5>
          </div>
          {plan?.planName === "Clasic" ? (
              <div className="table-responsive">
                  <table className="table table-striped text-center">
                      <thead>
                          <tr className="table-primary">
                              <th scope="col">#</th>
                              <th scope="col">Apellido</th>
                              <th scope="col">Nombre</th>
                              <th scope="col">DNI</th>
                              <th scope="col">Parentesco</th>
                              <th scope="col">Acciones</th>
                          </tr>
                      </thead>
                  </table>
              </div>
          ) : (
              <>
                  {!loading && familyGroupData.length !== 0 ? (
                      <div className="table-responsive">
                          <table className="table table-striped text-center">
                              <thead>
                                  <tr className="table-primary">
                                      <th scope="col">#</th>
                                      <th scope="col">Apellido</th>
                                      <th scope="col">Nombre</th>
                                      <th scope="col">DNI</th>
                                      <th scope="col">Parentesco</th>
                                      <th scope="col">Acciones</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {familyGroupData.map((member, index) => (
                                      <tr key={index}>
                                          <td scope="row">{index + 1}</td>
                                          <td>{member?.last_name ? member?.last_name : ""}</td>
                                          <td>{member?.first_name ? member?.first_name : ""}</td>
                                          <td>{member?.id_number ? member?.id_number : ""}</td>
                                          <td>{member?.relationship_to_titular ? ParentalRelationshipType.map((p) => (p.value === member?.relationship_to_titular ? p.name : "")) : ""}</td>
                                          <td>
                                              {member && (
                                                  <div>
                                                      <img id={member?.id_number ? member?.id_number : ""} src={TrashIcon} style={{ cursor: "pointer" }} className="icon" alt="Item menú" onClick={deleteFamilyGroupMember} />
                                                      <img id={member?.id_number ? member?.id_number : ""} src={PencilIcon} style={{ cursor: "pointer" }} className="icon" alt="Item menú" onClick={editFamilyMember} />
                                                  </div>
                                              )}
                                          </td>
                                      </tr>
                                  ))}
                              </tbody>
                          </table>
                      </div>
                  ) : loading ? <LoadingSpinner /> : null}
              </>
          )}
          <div className='button-container add-button'>
              <button style={!canAddMembers ? { backgroundColor: "#bdbdbd" } : {}} disabled={!canAddMembers} className='button add-button' onClick={addMember}>Agregar Miembro</button>
          </div>
          <p>Si quieres cambiar de plan o eliminar un miembro de tu grupo familiar, solicítalo haciendo clic en el botón <b> Mi Plan.</b></p>
          <div className='button-container'>
              <button className='button see-plan-button' onClick={myPlanHandler}>Mi Plan</button>
              <button className='button save-button' onClick={onSaveHandler}>Guardar</button>
          </div>
      </div>
  )
  
}
export default FamilyGroup;