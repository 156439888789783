import React from 'react';
import { useField } from 'formik';
import {
   MuiPickersUtilsProvider,
   KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";

const DatePickerField = (props) => {
   const { label, requiredField, ...rest } = props;
   const [field, meta, helpers] = useField(props);
   const { setValue } = helpers;
   const labelField = requiredField ? `${label}*` : label;
   const isError = !!(meta.touched && meta.error);

   return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
         <KeyboardDatePicker
            {...field}
            {...rest}
            autoOk
            okLabel='Aceptar'
            cancelLabel='Cancelar'
            label={labelField}
            onChange={setValue}
            error={isError}
            invalidDateMessage={isError}
            helperText={isError && meta.error}
         />
      </MuiPickersUtilsProvider>
   );
};

export default DatePickerField;
