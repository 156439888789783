// Filter Types
export const FilterType = {
    TEST_CONNECTION: "TEST_CONNECTION",
    VIRTUAL_GUARD_CALLED: "VIRTUAL_GUARD_CALLED",
    VIRTUAL_GUARD: 'VIRTUAL_GUARD',
    START_VIRTUAL_GUARD_CONNECTION: "START_VIRTUAL_GUARD_CONNECTION",
    FINISH_CONSULTATION : "FINISH_CONSULTATION",
    SELECTED_PROFESSIONAL: 'SELECTED_PROFESSIONAL',
    SEARCH_PROFESSIONAL : "SEARCH_PROFESSIONAL",
    PROFESIONAL_FOUND : "FOUND_PROFESIONAL"
 };