import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CreateSchedule from './createSchedule';

const ScheduleCreateBtn = ({ refreshSchedules }) => {
   const [openModal, setOpenModal] = useState(false);
   return (
     <>
       <Button
         color="primary"
         variant="contained"
         startIcon={<AddIcon size={24} />}
         className="schedule-create-btn"
         onClick={() => setOpenModal(!openModal)}
       >
         Crear disponibilidad
       </Button>
       {openModal && ( 
         <CreateSchedule open={openModal} 
         handleCloseWithoutSave={() => setOpenModal(false)}
         refreshSchedules={refreshSchedules} />
      )}
     </>
   );
};

export default ScheduleCreateBtn;
