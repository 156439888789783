import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button } from "@material-ui/core";
import LockOutlined from '@material-ui/icons/LockOutlined';
import { PassUpdate } from '../services/authService';
import { useToast } from '../context/Toast/ToastProvider';
import { useStateAuthValue } from '../context/Auth/AuthState';
import PasswordFields from '../components/PasswordFields';
import { showToast, ToastType } from '../utils/ToastUtil';
import PasswordScheme from './PasswordScheme';

const PasswordUpdate = () => {
  const [{ userToken }] = useStateAuthValue();
  const toastDispatch = useToast();
  const save = (values, helpers) => {
    PassUpdate({currentPassword: values.currentPassword, newPassword: values.password}, 
      userToken).then(() => {
        showToast(toastDispatch, '¡La contraseña se actualizó exitosamente!', ToastType.SUCCESS);
        helpers.resetForm();
      }).catch(() => showToast(toastDispatch, 'Ocurrió un error actualizando la contraseña', ToastType.ERROR, ToastType.ERROR));
  };

  const scheme = Yup.object().shape({
    currentPassword: Yup.string()
      .min(6, "Debes ingresar al menos 6 caracteres")
      .required("La contraseña es requerida"),
      ...PasswordScheme
    });

  return (
    <div className="password-update">
      <div className="header">
        <div className="image-title">
          <LockOutlined/>
          <h2>Actualizar Contraseña</h2>
        </div>
      </div>
      <div className="container">
        <Formik
          initialValues={{ currentPassword: '', password: '', passwordConfirmation: '' }}
          onSubmit={(values, helpers) => save(values, helpers)}
          validationSchema={scheme}
        >
          {({dirty, isValid}) => {
            return (
              <Form>
                <PasswordFields passwordUpdate={true} />
                <div className="btn-container">
                  <Button
                    disabled={
                      !(isValid && dirty)
                    }
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="submit"
                  >
                    Actualizar Contraseña
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default PasswordUpdate;
