import React from "react"
import { Button} from '@material-ui/core';
import ConfirmImage from "../../../../../assets/img/solicitud-aceptada.svg"

const ConfirmationModal = () => {
    return (
        <div className="confirmation-modal">
            <img
                  className="image"
                  src={ConfirmImage}
                  alt="Modal de Confirmacion"
               />
            <span className="sent">Receta enviada</span>
            <span className="text">La farmacia te contactará a la brevedad</span>
            <span className="text2">para gestionar tu pedido</span>
           
            <div className="button">
                <Button className="inicio" onClick={()=>window.location.reload()}>Ir al inicio</Button>
            </div>

        </div>
    )
}

export default ConfirmationModal