import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Role } from '../../models/enums';
import { sessionProfileGet } from '../../services/authService';

const LayoutRoute = ({
   component: Component,
   layout: Layout,
   publicRoute,
   authNotRequired,
   loggedIn,
   ...rest
}) => {
   const profileInfo = sessionProfileGet();
   const activeRol =
      profileInfo && profileInfo.userData && profileInfo.userData.active_role
         ? profileInfo.userData.active_role
         : null;
   return (
      <Route
         {...rest}
         render={(props) => {
            // Public routes
            if (publicRoute) {
               if (!authNotRequired && loggedIn && activeRol) {
                  if (activeRol === Role.PROFESSIONAL) {
                     return <Redirect from="*" to="/profesional" />;
                  } else if (activeRol === Role.PATIENT) {
                     return <Redirect from="*" to="/paciente" />;
                  }
               }
               return (
                  <Layout>
                     <Component {...props} />
                  </Layout>
               );
            } else if (activeRol) {
               // Private routes
               return (
                  <Layout>
                     <Component {...props} />
                  </Layout>
               );
            } else {
               return <Redirect from="*" to="/" />;
            }
         }}
      />
   );
};
export default LayoutRoute;
