import React, {useState} from 'react';
import {Button, FormControl, TextField} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
  } from '@material-ui/pickers';
  import DateFnsUtils from '@date-io/date-fns';
  import esLocale from "date-fns/locale/es";
import {formatDate} from '../../../utils/DateUtil';

const SearchBox = ({onSearch}) => {
	const [searchHistoryClinicNumber, setSearchHistoryClinicNumber] =
		useState('');
	const [searchIdNumber, setSearchIdNumber] = useState('');
	const [searchNameText, setSearchNameText] = useState('');
	const [searchDateFrom, setSearchDateFrom] = useState();
	const [searchDateTo, setSearchDateTo] = useState();

	return (
		<div className="search-box">
			<div className="detail-wrapper">
				<FormControl>
					<TextField
						label="N° de Historia Clínica"
						value={searchHistoryClinicNumber}
						onChange={({target: {value}}) =>
							setSearchHistoryClinicNumber(value)
						}
					/>
				</FormControl>
				<FormControl>
					<TextField
						label="DNI"
						value={searchIdNumber}
						onChange={({target: {value}}) => setSearchIdNumber(value)}
					/>
				</FormControl>
				<FormControl>
					<TextField
						label="Nombre y Apellido"
						value={searchNameText}
						onChange={({target: {value}}) => setSearchNameText(value)}
					/>
				</FormControl>
				<FormControl>
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
					<KeyboardDatePicker
						autoOk
						okLabel='Aceptar'
						cancelLabel='Cancelar'
						label="Desde"
						format="dd/MM/yyyy"
						maxDate={searchDateTo || undefined}
						views={['year', 'month', 'date']}
						onChange={(value) => setSearchDateFrom(value)}
						value={searchDateFrom || null}
						invalidDateMessage="Ingrese una fecha Desde válida"
						maxDateMessage="la fecha Desde debe ser anterior a la fecha Hasta"
						minDateMessage="Ingrese una fecha posterior a la fecha actual"
					/>
					</MuiPickersUtilsProvider>
				</FormControl>
				<FormControl>
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
						<KeyboardDatePicker
							autoOk
							okLabel='Aceptar'
							cancelLabel='Cancelar'
							label="Hasta"
							format="dd/MM/yyyy"
							views={['year', 'month', 'date']}
							onChange={(value) => setSearchDateTo(value)}
							value={searchDateTo || null}
							minDate={searchDateFrom || undefined}
							invalidDateMessage="Ingrese una fecha Desde válida"
							minDateMessage="Ingrese una fecha posterior a la fecha Desde"
						/>
					</MuiPickersUtilsProvider>
				</FormControl>

				<Button
					type="submit"
					variant="contained"
					className="btn-search"
					endIcon={<SearchIcon/>}
					onClick={() => {
						const params = {
							fullName: searchNameText,
							fromDate: searchDateFrom && searchDateFrom.getDate()? formatDate(searchDateFrom) : '',
							toDate: searchDateTo && searchDateTo.getDate() ? formatDate(searchDateTo) : '',
							clinicHistoryId: searchHistoryClinicNumber,
							idNumber: searchIdNumber,
						};
						onSearch(params);
					}}
				>
					Buscar
				</Button>
			</div>
		</div>
	);
};

export default SearchBox;
