import React, { useState, useEffect } from 'react';
import { List } from '@material-ui/core';

import MyOrderItem from '../myOrderItem';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { useStateAuthValue } from '../../../../context/Auth/AuthState';
import { useToast } from '../../../../context/Toast/ToastProvider';
import { Order } from '../../../../models/enums';
import { getOrderStatus } from '../../../../services/pharmacyService';
import { textByOrderStatus } from '../../../../utils/OrderUtil';
import { showToast, ToastType } from '../../../../utils/ToastUtil';

const MyOrders = ({ orderTypeSelected }) => {
   
   const [{ userToken, userData }] = useStateAuthValue();
   const [loading, setLoading] = useState(true);
   const toastDispatch = useToast();
   const [orderList, setOrderList] = useState([]);
   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, 'Error');
   };

   useEffect(() => {
      let isMounted = true;

      if (userToken && userData) {
         getData(isMounted);
      }

      return () => {
         isMounted = false;
      };
   }, [userToken, userData, orderTypeSelected.order]);

   const getData = async (isMounted) => {
      /* const errorMessage = 'Ocurrió un error en el servicio de pedidos';

      if (isMounted) {
         setLoading(true);
      }

      await getOrderStatus(
         {
            patient_login: userData?.email,
            statuses: orderTypeSelected.order,
            size: 100,
            sort: orderTypeSelected.sort,
         },
         userToken,
      )
         .then((response) => {
            setOrderList(response?.content);
         })
         .catch(() => showError(errorMessage))
         .finally(() => setLoading(false)); */
   };

   const renderList = () => {
      if (orderList?.length) {
         return (
            <div className="list">
               {orderTypeSelected.order === Order.QUOTED && (
                  <p className="text">
                     * Al iniciar el proceso de pago de un pedido, el mismo
                     quedará pendiente de aprobación. Recordá contar con tu
                     tarjeta a mano antes de iniciar un pago.
                  </p>
               )}
               <List>
                  {orderList.map((order) => (
                     <MyOrderItem
                        key={order?.id}
                        orderNumber={order?.id}
                        purchaseOrderId={order?.purchaseOrderId}
                        pharmacyName={order?.branchOffice?.pharmacy?.name}
                        pharmacy={order?.branchOffice?.name}
                        createdDate={order?.createdDate}
                        quotedDate={order?.quotedDate}
                        prePaidDate={order?.prepaidDate}
                        paidDate={order?.paidDate}
                        sentDate={order?.sentDate}
                        deliveredDate={order?.deliveredDate}
                        rejectedDate={order?.rejectedDate}
                        total={order?.total}
                        state={order?.status}
                        streetName={order?.branchOffice?.streetName}
                        streetNumber={order?.branchOffice?.streetNumber}
                        purchaseOrder={order}
                     />
                  ))}
               </List>
            </div>
         );
      } else {
         return (
            <div className="empty">
               <p className="message">{textByOrderStatus(orderTypeSelected)}</p>
            </div>
         );
      }
   };

   return (
      <div className="order-list">
         {loading ? <LoadingSpinner /> : renderList()}
      </div>
   );
};

export default MyOrders;
