import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Pie } from 'react-chartjs-2';

const useStyles = makeStyles(() => ({
   text: {
      fontFamily: 'Raleway',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '12px',
      /* or 33% */
      
      display: 'flex',
      alignItems: 'center',
      
      color: '#002C54',
   },
   numbers: {
      fontFamily: 'Raleway',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '12px',
      /* or 33% */
      
      display: 'flex',
      alignItems: 'center',
      
      color: '#DA2037',
   },
   margins:{
      margin:10,
      paddingBottom:50
   }
 }));
const data = {
   labels: ['Prestador', 'Obras Sociales', 'Particular', 'Staff Mediline'],
   datasets: [
     {
       data: [12, 19, 3, 5],
       backgroundColor: [
         '#937e88',
         '#fde23e',
         '#f16e23',
         '#57d9ff',
       ],
     },
   ],
 }

const Chart = () => {
   const classes = useStyles();
 
   return <div className="chart">
      <div className={classes.margins}>
      <Typography component="div">
         <Box textAlign="justify" className={classes.text} m={1}>
         Desde el 01/07/2020 hasta el 11/12/2020 
         </Box>
      </Typography>
      </div>
      <div className={classes.margins}>
      <Pie  data={data} width={100}
  height={50} />
      </div>
      <div className={classes.margins}>
      <Typography component="div">
         <Grid container spacing={3}>
            <Grid item xs={2}>
            <Box textAlign="justify" className={classes.numbers} m={1}>
               35
            </Box>
            </Grid>
            <Grid item xs={10}>
            <Box textAlign="justify" className={classes.text} m={1}>
               consultas PARTICULARES.
            </Box>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xs={2}>
            <Box textAlign="justify" className={classes.numbers} m={1}>
               300 
            </Box>
            </Grid>
            <Grid item xs={10}>
            <Box textAlign="justify" className={classes.text} m={1}>
               consultas con PRESTADORES.
            </Box>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xs={2}>
            <Box textAlign="justify" className={classes.numbers} m={1}>
               352 
            </Box>
            </Grid>
            <Grid item xs={10}>
            <Box textAlign="justify" className={classes.text} m={1}>
               consultas con OBRA SOCIAL.
            </Box>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xs={2}>
            <Box textAlign="justify" className={classes.numbers} m={1}>
               70
            </Box>
            </Grid>
            <Grid item xs={10}>
            <Box textAlign="justify" className={classes.text} m={1}>
               consultas con STAFF MEDILINE.
            </Box>
            </Grid>
         </Grid>
    </Typography>

      </div>
   </div>;
};

export default Chart;
