import HttpClient from './fetch_api';
import {
    MEDICATION_SERVICE
} from '../conf/appSetting';

export const getMedicationSearch = async (params, token) => {
    let medications = await HttpClient.get(
        HttpClient.concatNotNullParam(
            MEDICATION_SERVICE.base,
            params,
        ), {Authorization: `Bearer ${token}`}
    );

    return medications && medications.content && medications.content.map(medication => ({
        value: medication.id, label: medication.description, name: medication.description, id: medication.id
    }))
};

