import * as Yup from 'yup';

export const PasswordScheme = {
    password: Yup.string()
      .min(6, 'Debes ingresar al menos 6 caracteres')
      .required('La contraseña es requerida'),
   passwordConfirmation: Yup.string()
      .required('La confirmación de la contraseña es requerida')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
}


export default PasswordScheme;