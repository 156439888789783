import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Drawer,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';

import HeaderMenu from './Menu';
import Logo from '../../../assets/img/logo.png';
import { OK } from '../../../utils/ServiceUtil';
import { showToast, ToastType } from '../../../utils/ToastUtil';
import { Role, NotificationStatus, Gender } from '../../../models/enums';
import { useToast } from '../../../context/Toast/ToastProvider';
import { useStateAuthValue } from '../../../context/Auth/AuthState';
import { getNotificationsQuantity } from '../../../services/notificationService';
import { NOTIFICATIONS_QUANTITY_INTERVAL } from '../../../conf/appSetting';
import useInterval from '../../../hooks/UseInterval';
/* import NetworkState from '../../../components/NetworkState';
 */
const drawerWidth = 240;

const Header = () => {
  const history = useHistory();
  const [{ userData, userToken }, dispatch] = useStateAuthValue();
  const toastDispatch = useToast();
  const currentRole = userData?.active_role;
  const isProfessional = currentRole === Role.PROFESSIONAL;
  const [open, setOpen] = useState(false);
  const [notificationQuantity, setNotificationQuantity] = useState('');
  const areNotifications = notificationQuantity > 0;
  const [userName, setUserName] = useState('');
  const [titleByGender, setTitleByGender] = useState('Dr/a.');
  const headerTitle = `Hola, ${
    isProfessional ? titleByGender : ''
  } ${userName}!`;

  const useStyles = makeStyles((theme) => ({
    appBar: {
      display: 'block',
      backgroundColor: '#002C54',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: theme.zIndex.drawer + 1,
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbarNav: {
      backgroundColor: '#002C54',
      height: '80px',
      width: '100%',
      maxWidth: '1440px',
      margin: '0 auto',
    },
    titleHeader: {
      fontSize: '32px',
      fontWeight: '800',
      lineHeight: '40px',
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    imageLogo: {
      width: '70px',
      marginLeft: '16px',
    },
  }));

  const classes = useStyles();

  const detectTitleByGender = (gender) => {
    if (gender === Gender.FEMALE) {
      setTitleByGender('Dra.');
    } else if (gender === Gender.MALE) {
      setTitleByGender('Dr.');
    } else {
      setTitleByGender('Dr/a.');
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (userToken) {
      getData(isMounted);
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  useInterval(() => {
    if (userData) {
      getData();
    }
  }, NOTIFICATIONS_QUANTITY_INTERVAL);

  useEffect(() => {
    if (userData) {
      if (userData?.username) {
        setUserName(userData?.alias || userData?.username);
      } else if (userData?.gender) {
        setTitleByGender('Dra');
      } else {
        dispatch({ type: 'GET_SESSION' });
      }
    } else {
      history.push('/login');
    }
  }, [userData, dispatch, history]);

  useEffect(() => {
    if (userData?.sex_str) {
      detectTitleByGender(userData.sex_str);
    }
  }, [userData]);

  const showError = (msgError) => {
    showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
  };

  const getData = async (isMounted = true) => {
    const errorMessage =
      'Ocurrió un error en la actualización de cantidad de notificaciones';
    await getNotificationsQuantity(
      { meanType: 'INBOX', deliveryStatus: NotificationStatus.NEW },
      userToken,
      showError
    )
      .then((response) => {
        if (isMounted && OK === response?.status.toUpperCase()) {
          if (response?.data.length) {
            setNotificationQuantity(response?.data[0].quantity);
          } else {
            setNotificationQuantity(0);
          }
        } else {
          showError(errorMessage);
        }
      })
      // TODO: Add errorMessage when this service it's fixed
      .catch();
  };

  const toogleMenu = () => {
    const consult = localStorage.getItem('consult');
    if (consult !== 'true') setOpen(!open);
  };

  const handleClick = () => {
    const consult = localStorage.getItem('consult');
    if (consult !== 'true') {
      if (window.location.pathname === '/notificaciones') {
        window.location.reload();
      } else {
        history.push('/notificaciones');
      }
    }
  };

  return (
    <div className="header">
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbarNav}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toogleMenu}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.titleHeader} noWrap>
            {headerTitle}
          </Typography>
          {/* <NetworkState /> */}
          <IconButton color="inherit" onClick={handleClick}>
            {areNotifications ? (
              <Badge badgeContent={notificationQuantity} color="secondary">
                <NotificationsIcon />
              </Badge>
            ) : (
              <NotificationsIcon />
            )}
          </IconButton>
          <img src={Logo} alt="Logo" className={classes.imageLogo} />
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={toogleMenu}>
        <HeaderMenu setOpen={setOpen} />
      </Drawer>
    </div>
  );
};

export default Header;
