import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import Modal from "../../../../components/Modal";
import { ApplicationStyle } from "../../../../theme";
import UserCheckIcon from "../../../../assets/img/user-check.svg";
import WifiIcon from "../../../../assets/img/wifi.svg";
import FileIcon from "../../../../assets/img/file.svg";
import ShusFaceIcon from "../../../../assets/img/face-shush.svg";
import VideoIcon from "../../../../assets/img/video.svg";
import ChatsIcon from "../../../../assets/img/chats.svg";

const PreviousRecommendationsModal = ({
  open,
  handleClose,
  handleContinue,
}) => {
  const MedilineStyle = makeStyles(ApplicationStyle);
  const MedilineClasses = MedilineStyle();

  return (
    <Modal
      isActive={open}
      handleClose={() => handleClose(false)}
      className="modal2"
    >
      <div className={MedilineClasses.btnBoxCenterM0} style={{}}>
        <h4 style={{ fontSize: "35px", fontWeight: 600, color: "#002C54" }}>
          Recomendaciones
        </h4>
        <div className="recomendations_modal">
          <div
            style={{
              textAlign: "left",
            }}
          >
            <div className="item-container">
              <div className="icon-container">
                <img alt="user" src={UserCheckIcon} />
              </div>
              <p>
                Asegurate de tener tus datos cargados y correctos en el perfil
              </p>
            </div>
            <div className="item-container">
              <div className="icon-container">
                <img alt="user" src={WifiIcon} />
              </div>
              <p>Asegurate que tu conectividad a internet sea estable</p>
            </div>
            <div className="item-container">
              <div className="icon-container">
                <img alt="user" src={FileIcon} />
              </div>
              <p>Tené a mano información relevante para tu consulta</p>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
            }}
          >
            <div className="item-container">
              <div className="icon-container">
                <img alt="user" src={ShusFaceIcon} />
              </div>
              <p>Buscá un lugar tranquilo, privado y bien iluminado</p>
            </div>
            <div className="item-container">
              <div className="icon-container">
                <img alt="user" src={VideoIcon} />
              </div>
              <p>Debés tener el micrófono y cámara activos en tu dispositivo</p>
            </div>
            <div className="item-container">
              <div className="icon-container">
                <img alt="user" src={ChatsIcon} />
              </div>
              <p>Participá activamente de la consulta</p>
            </div>
          </div>
        </div>
        <Button
          className={MedilineClasses.btnBlueNext}
          onClick={handleContinue}
        >
          Continuar
        </Button>
      </div>
    </Modal>
  );
};

export default PreviousRecommendationsModal;
