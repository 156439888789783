import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Link, List, Tooltip } from '@material-ui/core';
import RefreshRounded from '@material-ui/icons/RefreshRounded';

import ListItem from './Item';
import HeaderImage from '../../../../assets/img/MisPedidos.svg';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { useStateAuthValue } from '../../../../context/Auth/AuthState';
import { useToast } from '../../../../context/Toast/ToastProvider';
import { Order } from '../../../../models/enums';
import { getOrderStatus } from '../../../../services/pharmacyService';
import { showToast, ToastType } from '../../../../utils/ToastUtil';

const OrderList = () => {
   const [{ userToken, userData }] = useStateAuthValue();
   const [listPrescriptionStatuses, setListPrescriptionStatuses] = useState([]);
   const [loading, setLoading] = useState(false);
   const toastDispatch = useToast();
   const history = useHistory();

   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, 'Error');
   };

   useEffect(() => {
      let isMounted = true;

      if (userToken && userData) {
         getData(isMounted);
      }

      return () => (isMounted = false);
   }, [userToken, userData]);

   const getData = async (isMounted) => {
      const errorMessage = 'Ocurrió un error al obtener los pedidos';

      if (isMounted) {
         setLoading(true);
      }

      await getOrderStatus(
         {
            patient_login: userData?.email,
            statuses: `${Order.QUOTED},${Order.SENT}`,
            page_size: 20,
            sort: 'id,asc',
         },
         userToken,
      )
         .then((response) => {
            setListPrescriptionStatuses(response?.content);
         })
         .catch(() => showError(errorMessage))
         .finally(() => setLoading(false));
   };

   const disableDivStyles = { 
      pointerEvents: "none",
      opacity: "35%"
   }

   return (
      <div className="order" >
         <div style={disableDivStyles} className="order__title">
            <div className="title-wrapper">
               <img className="image" src={HeaderImage} alt="Pedidos" />
               <Link
                  className="title"
                  onClick={() => {
                     history.push('/paciente/mis-pedidos');
                  }}
               >
                  Pedidos
               </Link>
            </div>
            <Tooltip title="Actualizar Pedidos" component={'span'}>
               <IconButton disabled={loading} onClick={() => getData(true)}>
                  <RefreshRounded />
               </IconButton>
            </Tooltip>
         </div>
         {loading ? (
            <LoadingSpinner />
         ) : (
            <div className="list">
               <List>
                  {listPrescriptionStatuses ? listPrescriptionStatuses.map((order) => {
                     return (
                        <ListItem
                           key={order?.id}
                           purchaseOrderId={order?.purchaseOrderId}
                           status={order?.status}
                           pharmacy={order?.branchOffice?.pharmacy.name}
                           branchOffice={order?.branchOffice?.name}
                           total={order?.total}
                           quotedDate={order?.quotedDate}
                           sentDate={order?.sentDate}
                           expiration={order?.expirationDate}
                        />
                     );
                  }) : null}
               </List>
            </div>
         )}
      </div>
   );
};

export default OrderList;
