import React, { useState, useEffect, useRef } from 'react';
import Modal from '../components/Modal';
import { Button } from '@material-ui/core';
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';
import { ApplicationStyle } from '../theme';
import { makeStyles } from '@material-ui/core/styles';

const MedilineStyle = makeStyles(ApplicationStyle);

export const CheckPermission = ({
  setHasPermission,
  setAskPermission,
  askPermission,
}) => {
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);
  const MedilineClasses = MedilineStyle();
  const isMountedRef = useRef(true);

  useEffect(() => {
    if (isMountedRef.current) {
      checkExistingPermissions();
    }
    return () => (isMountedRef.current = false);
  }, [isMountedRef.current]);

  useEffect(() => {
    if (askPermission) {
      if (error) {
        setOpen(true);
      } else {
        checkExistingPermissions();
      }
    }
    return () => setAskPermission(false);
  }, [askPermission]);

  useEffect(() => {
    if (error) {
      setOpen(true);
    }
  }, [error]);

  /*     useEffect(() => {
        function checkUserData() {
          const consult = localStorage.getItem('consult')
      
          if(consult === "true") checkExistingPermissions();
        }
      
        window.addEventListener('storage', checkUserData)
      
        return () => {
          window.removeEventListener('storage', checkUserData)
        }
      }, []);  */

  const checkExistingPermissions = async () => {
    await requestMediaPermissions()
      .then(() => {
        setError(null);
        setHasPermission(true);
      })
      .catch((err) => {
        const { type, message } = err;
        if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
          // browser does not have permission to access camera or microphone
          setError(1);
        } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
          // user didn't allow app to access camera or microphone
          setError(2);
        } else if (
          type === MediaPermissionsErrorType.CouldNotStartVideoSource
        ) {
          setError(3);
          // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
        } else if (message === 'Permission dismissed') {
          setError(4);
          // not all error types are handled by this library
        } else {
          // setError(err);
          setError(
            'Lo sentimos, no podemos acceder a tu cámara y micrófono en este momento. Esto podría deberse a que has denegado el permiso para acceder a ellos. Para permitir el acceso, por favor revisa la configuración de tu navegador y asegúrate de habilitar los permisos para la cámara y el micrófono para poder usar la plataforma.'
          );
        }
        setHasPermission(false);
      });
  };

  const handleClose = async () => {
    setOpen(false);
  };

  return (
    <Modal isActive={open} handleClose={handleClose}>
      <div className={MedilineClasses.btnBoxCenterM0}>
        <div className={MedilineClasses.titleBlue}>
          Permisos de micrófono y cámara
        </div>
        <div className={MedilineClasses.subtitleBlue}>
          {(error === 1 && (
            <>
              Ups ! Notamos que el navegador no tiene acceso a la cámaro o al
              micrófono.
              <br />
              Para establecer la videollamada es necesario que soluciones este
              problema.
            </>
          )) ||
            ((error === 2 || error === 4) && (
              <>
                Ups ! Notamos que no has permitido el acceso a la cámaro o el
                micrófono.
                <br />
                Para establecer la videollamada es necesario que soluciones este
                problema.
                <br />
                Te dejamos atajos para resolver el problema según sea tu
                navegador{' '}
              </>
            )) ||
            (error === 3 && (
              <>
                Ups ! Notamos que otra aplicación está usando la cámaro o el
                micrófono.
                <br />
                Para establecer la videollamada es necesario que soluciones este
                problema.
              </>
            )) ||
            (error && error.message)}
        </div>
        <div className={MedilineClasses.btnSpacing}>
          {(error === 2 || error === 4) && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginBottom: '34px',
              }}
            >
              <a
                href="https://support.google.com/chrome/answer/114662?hl=es&co=GENIE.Platform%3DDesktop"
                target="_blank"
                rel="noreferrer"
              >
                Permitir acceso en Chrome
              </a>
              <a
                href="https://support.mozilla.org/es/kb/como-administrar-los-permisos-de-tu-camara-y-micro"
                target="_blank"
                rel="noreferrer"
              >
                Permitir acceso en Firefox
              </a>
              <a
                href="https://support.apple.com/es-lamr/guide/safari/ibrw7f78f7fe/mac"
                target="_blank"
                rel="noreferrer"
              >
                Permitir acceso en Safari
              </a>
            </div>
          )}
        </div>
        <Button
          className={MedilineClasses.btnBlueNext}
          onClick={() => handleClose(true)}
        >
          Aceptar
        </Button>
      </div>
    </Modal>
  );
};
