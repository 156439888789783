import { PrescriptionType } from '../models/enums';

// Helps to get a not found text according order status
export const textByPrescriptionType = (prescriptionStatusToChange) => {
   let prescriptionType;
   switch (prescriptionStatusToChange) {
      case PrescriptionType.NEW:
         prescriptionType = 'nuevas';
         break;
      case PrescriptionType.DELIVERED:
         prescriptionType = 'enviadas';
         break;
      case PrescriptionType.EXPIRED:
         prescriptionType = 'vencidas';
         break;
      default:
         prescriptionType = '';
         break;
   }
   return `No hay recetas ${prescriptionType} que mostrar`;
};
