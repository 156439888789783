import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HeaderImage from "../../assets/img/preguntasFrecuentes.svg";
import { useStateAuthValue } from "../../context/Auth/AuthState";
import { Role } from "../../models/enums";

const FrequentQuestions = () => {
  const [{ userData }] = useStateAuthValue();
  const currentRole = userData?.active_role;
  // 'PATIENT' || 'PROFESSIONAL'
  const isPatient = currentRole === Role.PATIENT;

  return (
    <div className="help">
      <div className="container">
        <div className="header">
          <img className="image" src={HeaderImage} alt="Ayuda" />
          <h2>Preguntas Frecuentes</h2>
        </div>
        <div className="accordion-wrapper">
          {isPatient && (
            <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Qué requisitos tengo que tener para usar el servicio?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Ser mayor de 18 años. En caso de tener menores a cargo
                    podrás acceder al servicio y usarlo en su nombre.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Cómo accedo a una teleconsulta?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Una vez registrado en la plataforma, podés hacerlo
                    programando un turno o pidiendo la atención inmediata de un
                    profesional de guardia.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Tengo que pagar para acceder al servicio?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Podrás descargar la aplicación de manera gratuita. Para
                    consultas médicas: Si accedés a MediLine a través de una
                    prepaga, de tu empresa o aseguradora, podrás realizarlas
                    manera gratuita; Si no, podrás acceder abonando alguno de
                    nuestros planes MediLine.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Cómo se seleccionan a los médicos de la plataforma?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    El cuerpo médico de MediLine está formado por un equipo de
                    profesionales que han sido evaluados y capacitados en forma
                    previa a su incorporación a la plataforma. Cualquiera de los
                    profesionales puede responder las consultas de la
                    especialidad a la que pertenecen.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Puedo llamar a un médico para que atienda a una persona a
                    cargo?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Si. Se puede llamar en nombre de cualquier integrante del
                    grupo familiar declarado en la plataforma. La persona debe
                    estar presente al momento de realizar la consulta.{" "}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Puedo seleccionar a un médico por su nombre en caso de
                    conocerlo?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Sí, MediLine además de contar con su cuerpo médico propio
                    también trabaja con médicos referentes. Luego de elegir la
                    especialidad deseada, podrás escoger al profesional que
                    prefieras, siempre y cuando el mismo esté asociado a la
                    plataforma.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Qué pasa si ya he consumido todas las consultas de mi plan?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Si ya consumiste todas las consultas del mes la plataforma
                    no te dejará hacer una nueva consulta a un profesional. Si
                    el profesional que te atiende está registrado como
                    particular dentro de la plataforma, podrás sacar un turno y
                    pagar en forma particular. Si ya consumiste todas las
                    consultas del mes, podrás acceder a nuevas consultas
                    abonando particularmente.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Qué puedo obtener de una consulta médica?{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    En cada consulta médica el profesional que te atiende podrá:
                    registrar la consulta en tu historia clínica, realizar una
                    receta electrónica, pedirte estudios, realizar un
                    certificado laboral y derivarte a un especialista, todo sin
                    salir de la plataforma y sin envíos a tu correo electrónico.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Qué tipo de consultas puedo realizar a través de MediLine?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    MediLine es una plataforma para atender consultas virtuales
                    de casos de baja complejidad y atención primaria de salud.
                    Si bien la plataforma permite comunicarte con Emergencias no
                    se atienden este tipo de casos en la plataforma.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Puedo utilizar MediLine en caso de una Emergencia Médica?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    No, MediLine es un servicio basado en telemedicina y
                    atendemos problemas de salud de baja complejidad y de
                    atención primaria. En caso de emergencia, urgencia,
                    intervención quirúrgica o internación, debes asistir al
                    centro de salud más cercano o comunicarte a un servicio de
                    emergencias médicas de tu localidad.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Puedo hacer consultas desde otro lugar además de mi
                    domicilio declarado?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Sí, al ser una plataforma virtual esta es una de las
                    principales ventajas. Podés acceder a una consulta o
                    programar un turno desde cualquier lugar donde te
                    encuentres, mientras dispongas de buena conexión a internet
                    y un dispositivo electrónico con cámara.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿MediLine ofrece receta electrónica?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Sí, durante la consulta el profesional médico realiza la
                    receta la cual le llega al usuario por medio de una
                    notificación interna dentro de la misma plataforma, no llega
                    al correo electrónico para evitar que te llegue a la bandeja
                    de correos no deseados. Muchos usuarios ya no leen tanto el
                    mail y de esta forma todo queda guardado en un mismo lugar.
                    El profesional médico realizará la receta y te la enviará
                    durante la misma consulta médica sin salir de la plataforma.
                    Luego, podrás visualizarla en tu panel de notificaciones de
                    MediLine.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Qué pasa si los datos de la receta no son correctos?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Los datos de la receta son tomados del sistema por lo que te
                    recomendamos que mantengas actualizado tu perfil con tus
                    datos personales y de tu obra social. No obstante, el
                    profesional que te atienda validará los datos en forma
                    previa a la confección de la receta. De tener algún
                    inconveniente podés escribirnos un mail a:
                    atencionalcliente@mediline.com.ar para que podamos
                    solucionar el inconveniente.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Qué pasa si tengo que repetir la receta de forma periódica
                    por ser paciente con una enfermedad crónica?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    MediLine te ofrece la repetición de recetas electrónicas sin
                    tener que consultar al profesional médico ya que es el mismo
                    profesional quien te programa la cantidad de repeticiones
                    antes de la nueva consulta.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Puedo obtener un certificado médico para presentar en mi
                    trabajo?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Sí, el profesional que te atiende puede generar un
                    certificado médico para ser presentado a donde se lo
                    indiques. Una vez realizado el mismo te llega en forma
                    electrónica a tu buzón de Notificaciones de la plataforma,
                    desde allí lo podrás enviar al mail que indiques.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Puedo comprar los medicamentos recetados a través de
                    MediLine?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Sí, MediLine tiene una amplia red de farmacias asociadas a
                    la plataforma y podrás elegir la que más te convenga ya sea
                    por cercanía a tu domicilio como por preferencia. Luego de
                    recibir la receta electrónica en tu buzón de Notificaciones
                    podrás acceder al listado de farmacias asociadas para
                    comprar tu medicación sin moverte de tu casa o lugar donde
                    te encuentres.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Cómo hago para obtener una indicación o una receta
                    posterior a mi consulta?{" "}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Si tenés un plan MediLine podrás hacer una nueva consulta a
                    cualquier profesional de guardia de la especialidad que
                    estás necesitando para que atienda tu inquietud. Si tu
                    servicio es a través de una obra social o prestador médico
                    deberás realizar una nueva consulta ya sea programada o
                    espontánea con los profesionales habilitados para cada caso.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Puedo cancelar un turno programado?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Sí, podrás cancelar o reprogramar tu consulta. Para más
                    información te invitamos a leer nuestros Términos y
                    Condiciones.{" "}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="title">
                    ¿Puedo evaluar la calidad del servicio y la atención médica?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Si, luego de terminar la consulta tendrás la posibilidad de
                    calificar el servicio y contarnos tu experiencia. Tu
                    calificación es muy importante y nos ayuda a mejorar.{" "}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </div>
        <div className="contact-wrapper">
          <div className="contact">
            <p>
              Ante cualquier duda podés comunicarte con nosotros por email
              <strong>
                {" "}
                <a href="mailto:contacto@mediline.com.ar">
                  contacto@mediline.com.ar
                </a>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrequentQuestions;
