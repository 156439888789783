import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
// Components
import InputField from '../../../../../components/FormFields/InputField';
import AutocompleteAndList from '../../../../../components/FormFields/AutocompleteAndList';
import AutocompleteListAndField from '../../../../../components/FormFields/AutocompleteListAndField';
// Services and contexts
import { GetSpecialtiesRequest } from '../../../../../services/userService';
import { UseStateVirtualGuard } from '../../../../../context/VirtualGuard/VirtualGuardProvider';
import { useStateAuthValue } from '../../../../../context/Auth/AuthState';
import { useFormikContext } from 'formik';

const Derivation = ({
  formField,
  parentValues,
  hasErrors,
  errors,
  setFieldValue,
  handleDisabled
}) => {
  const baseForm = 'specialties.';
  const [specialties, setSpecialties] = useState([]);
  const {
    diagnosis,
    specialties: { 
      specialtyNames,
      addTooltip,
      uniqueMsg,
      searchTextLabel,
      searchTextRequired,
      comment,
    }
  } = formField;
  const [title, setTitle] = useState(
    'Habiéndose realizado el conocimiento del Sr/a: Paciente, DNI: Nro y según el diagnóstico identificado, Se sugiere atención médica especializada en'
  );
  const [{ consultation }] = UseStateVirtualGuard();
  const [{ userToken }] = useStateAuthValue();
  const [list, setList] = useState(parentValues?.specialtyNames || []);
  const { values } = useFormikContext();

  useEffect(() => {
    if (consultation) {
      const patient = consultation?.patient_dto?.user;
      const fullName = patient?.first_name
        ? `${patient?.first_name} ${patient?.last_name || ''}`
        : patient?.alias || patient?.username || 'Paciente';
      setTitle(
        `Habiéndose realizado el conocimiento del Sr/a: ${fullName}, DNI: ${
          patient?.id_number || 'Nro'
        }, y según el diagnóstico identificado, Se sugiere atención médica especializada en`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultation]);

  useEffect(() => {
    if(values?.specialties && !values.specialties.diagnosis){
      values.specialties.diagnosis = values.Diagnosis;
    }
  }, [values?.specialties]); 

  useEffect(() => {
    handleDisabled(hasErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasErrors]);

  useEffect(() => {
    if (parentValues?.specialtyNames) {
      setList(parentValues.specialtyNames);
    } else if (parentValues?.diagnosis) {
      setFieldValue(baseForm + specialtyNames.name, []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValues]);

  useEffect(() => {
    let isMounted = true;
    if (userToken) {
      GetSpecialtiesRequest(userToken, (specialtiesResponse) => {
        if (isMounted && specialtiesResponse.length) {
          setSpecialties(specialtiesResponse);
        }
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  return (
    <>
      <Typography component={'span'}>{title}</Typography>
      <InputField
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        name={baseForm + diagnosis.name}
        label={diagnosis.label + '*'}
        value={values?.specialties?.diagnosis ? values.specialties.diagnosis : values?.Diagnosis}
        className="field"
      />
      <AutocompleteListAndField
        allowFreeText={true}
        options={specialties}
        stateList={list}
        setStateList={setList}
        ListFieldLabel={baseForm + specialtyNames.name}
        setFieldListValue={(newList) => setFieldValue(baseForm + specialtyNames.name, newList)}
        handleDisabled={handleDisabled}
        errorList={errors?.specialtyNames}
        searchTextRequired={searchTextRequired}
        uniqueMsg={uniqueMsg}
        searchTextLabel={searchTextLabel}
        addTooltip={addTooltip}
        field={comment}
      />
    </>
  );
};

export default Derivation;
