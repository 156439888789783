import React from 'react';

import { srcFromFile } from '../../../../utils/FormUtil';

const ProfilePhotoDetail = ({ formValues }) => {
   const {
      professional_dto: { photo_encode },
   } = formValues;

   return (
      <div className="detail">
         <div className="header header--detail">
            <h2>Foto de Perfil</h2>
         </div>
         <div className="data-wrapper profile-photo">
            <img
               className="photo"
               src={srcFromFile(photo_encode)}
               alt="Foto de perfil"
            />
         </div>
      </div>
   );
};

export default ProfilePhotoDetail;
