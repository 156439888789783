const Colors = {
  AzulMediline: '#002C54',
  RojoMediline: '#DA2037',
  Negro: '#000000',
  GrisMedio: '#757575',
  Error: '#EB5757',
  GrisClaro: '#BDBDBD',
  Azul75: 'rgba(0, 44, 84, 0.75)',
  Orange: '#EB6836'
};
export default Colors;
