import React, { useEffect, useState } from "react";
import axios from "axios";
import AgoraUIKit from "agora-react-uikit";
import {FilterType} from "../../../utils/FilterUtil"
import { useStateProfConsultFilterValue } from "../../../context/profConsultFilterState";

const PatientVideoCall = ({ videoCallData, setVideoCall, setMessage }) => {
  const [{ data, stomp }, dispatch] = useStateProfConsultFilterValue();
  const role = "subscriber";
  const [token, setToken] = useState("");

  useEffect(() => {
    const getToken = async () => {
      const url = `${process.env.REACT_APP_AGORA_TOKEN_SERVICE}/rtc/${videoCallData.queueId}/${role}/uid/${videoCallData.patientId}`;
      try {
        const response = await axios.get(url);
        const token = response.data.rtcToken;
        setToken(token);
      } catch (err) {
        alert(err);
      }
    };
    getToken();
  }, [videoCallData]);

  const leaveConsult = () => {
    var wsMessage = {
       patientId : data?.patientId,
       orderNumber: data?.orderNumber,
       specialtyId: data?.specialtyId,
       command: "PatientFinish",
       professionalId: data?.professionalId,
       queueId: data?.queueId,
       date: data?.date
     };
    firebase.put({...wsMessage, status: 'FINISHED'})
    stomp.send("/app/message", {}, JSON.stringify(wsMessage));
   }

  return (
    token && (
      <div className="videocall" style={{ 
        width: "100vw", 
        height: "85vh", 
        gridTemplateColumns : "1fr",
        }}>         
         <div className="container" style={{gridTemplateColumns : "1fr"}}>
          <div style={{
              display : "flex",
              borderRadius: "0 40px 40px 40px",
              overflow: "hidden"
              }}>
            <AgoraUIKit
               rtcProps={{
                  appId: process.env.REACT_APP_AGORA_APP_ID,
                  channel: videoCallData.queueId,
                  token: token,
                  uid: videoCallData.patientId,
                  layout: 1,
                }}
               callbacks={{
                  EndCall: () => {
                    leaveConsult();
                    setMessage("Consulta finalizada");
                    dispatch({
                      type: 'LOADING',
                      clickButton: FilterType.FINISH_CONSULTATION,
                      loading: false
                    });
                    setVideoCall(false);
                    setMessage("")
                  },
                  /* "user-joined": () =>
                    console.log("MediLine - 🧍 Remote User joined"), */
            "user-left": () => {
              setMessage("El profesional se desconecto");
              dispatch({
                type: "LOADING",
                clickButton: null,
                loading: false,
              });
              setVideoCall(false);
              setMessage("");
            },
            /* "user-unpublished": () =>
                    console.log("MediLine - 🧍 Remote user unpublished"),
                  "user-published": () =>
                    console.log("MediLine - 🧍 Remote User Publish event"), */
          }}
          />
        </div>
        </div>
      </div>
      
    )
  );
};

export default PatientVideoCall;
