import Specialities from './../models/Specialties';
import UserWrapper from "@mediline/mediline-api-middleware/src/wrappers/user.wrapper";

const usrw = new UserWrapper();

export const GetPreexistingDisease = async (token, displayError = null) =>
	usrw.getPreexistingDisease(token, displayError);

export const GetSurgerys = async (token, displayError = null) =>
	usrw.getSurgeries(token, displayError);

export const GetFrequentMedication = async (token, displayError = null) =>
	usrw.getFrequentMedication(token, displayError);

export const GetAttentionTypes = async (token, displayError = null) =>
	usrw.getAttentionTypes(token, displayError);

export const GetSpecialties = async (token, displayError = null) =>
	usrw.getSpecialties(token, displayError);

export const GetSpecialtiesAll = async (token, displayError = null) =>
	usrw.getSpecialtiesAll(token, displayError);

export const GetSpecialtiesProfessional = async (token, displayError = null) =>
	usrw.getSpecialties(token, displayError);

export const GetSpecialtiesRequest = (userToken, setSpecialties, displayError = null) => {
	GetSpecialtiesAll(userToken, displayError).then(response => {
		const specialtiesArray = [];
		if (response.data) {
			response.data.forEach(specialty => specialtiesArray.push({...specialty, descr: specialty.name || ''}));
		}
		setSpecialties(specialtiesArray);
	}).catch(() => setSpecialties(Specialities));
};

export const GetSpeciality = async (token, name, displayError = null) =>
	usrw.getSpeciality(token, {name: name}, displayError);

export const GetPrepaids = async (token, displayError = null) =>
	usrw.getPrepaids(token, displayError);

export const GetPrepaid = async (token, name, displayError = null) =>
	usrw.getPrepaid(token, {name: name}, displayError);

export const GetProviders = async (token, displayError = null) =>
	usrw.getProviders(token, displayError);

export const GetProvider = async (token, name, displayError = null) =>
	usrw.getProvider(token, {name: name}, displayError);
