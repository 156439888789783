/* eslint-disable no-undef */
export const GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL;
export const FIREBASE_URL = process.env.REACT_APP_FIREBASE_URL;
const FRONT_URL = process.env.REACT_APP_FRONT_URL;

const config = {
   AUTH0_GOOGLE_AUTHORIZE_REDIRECT_URL: `${FRONT_URL}/authorizeredirect`,
   GMAIL_GOOGLE_AUTHORIZE_REDIRECT_URL: `${GATEWAY_URL}/api/login/gmailCallback`,
   INITIAL_REDIRECT: `${FRONT_URL}/logout`,
   GATEWAY_ACCOUNT: `${GATEWAY_URL}/api/account`,
};

// Consts for Videocall patient access
export const MINUTES_AFTER_VIDEOCALL = 5;
export const MINUTES_BEFORE_VIDEOCALL = 15;

export const VIRTUAL_GUARD_INTERVAL = 54000;
export const MINUTES_INTERVAL = 20;

// Const for update the notifications quantity (showed on header) at every 30 seconds (expressed on ms)
export const NOTIFICATIONS_QUANTITY_INTERVAL = 30000;

// Chime-SDK allows up to 16 attendee videos
export const CHIME_ROOM_MAX_ATTENDEE =
   process.env.REACT_APP_CHIME_ROOM_MAX_ATTENDEE;

// PLAYBACKURL to use in videocall, in this case not used
export const PLAYBACKURL = 'a';

// Default video stream to play inside the video player
export const DEFAULT_VIDEO_STREAM = process.env.REACT_APP_DEFAULT_VIDEO_STREAM;

// Default Chat websocket link
export const CHAT_WEBSOCKET = process.env.REACT_APP_CHAT_WEBSOCKET;

// Chime-SDK logging level: INFO, WARN, ERROR, DEBUG
export const CHIME_LOG_LEVEL = process.env.REACT_APP_CHIME_LOG_LEVEL;

// Chime-Web UI debugging logging: true / false
export const DEBUG = process.env.REACT_APP_DEBUG;

export const MEDICATION_SERVICE = {
   base: `${GATEWAY_URL}/services/vademecum/api/medications`,
};

// Pharmacy urls
export const PHARMACY_SERVICE = {
   base: `${GATEWAY_URL}/services/pharmacy/api/business/branch-offices/pharmacies`,
   search: '/search',
};

export const PURCHASE_ORDER = {
   base: `${GATEWAY_URL}/services/pharmacy/api/business/purchase-orders`,
};

export const AUTH_GATEWAY = {
   register: `${GATEWAY_URL}/api/register`,
   base: `${GATEWAY_URL}/api/authenticate`,
   social: `${GATEWAY_URL}/api/authenticate-social`,
   activate: `${GATEWAY_URL}/api/activate`,
   passwordUpdate:  `${GATEWAY_URL}/api/account/change-password`,
   recover: `${GATEWAY_URL}/api/reset-password/init`,
   recoverFinish: `${GATEWAY_URL}/api/reset-password/finish`,
}
export const PROFESSIONAL_LOG = {
   log: `${GATEWAY_URL}/services/parametrics/api/professional-log`
}
export const STATE_PRESCRIPTION = {
   base: `${GATEWAY_URL}/services/pharmacy/api/business/purchase-orders/search`,
};
export const PRESCIPTION_ORDER = {
   base: `${GATEWAY_URL}/services/pharmacy/api/business/prescriptions/purchase-orders`,
   prescriptionBase: `${GATEWAY_URL}/services/pharmacy/api/business/branch-offices/prescription/search`,
};
export const PRESCRIPTION_MEDICATION = {
   base: `${GATEWAY_URL}/services/pharmacy/api/business/prescription-medications/prescriptions`,
};
export const GET_EXTRA = {
   base: `${GATEWAY_URL}/services/pharmacy/api/business/extras/purchase-orders`,
};
export const CHANGE_STATE = {
   base: `${GATEWAY_URL}/services/pharmacy/api/business/purchase-orders`,
};

export const HISTOY_CLINIC = {
   base: `${FRONT_URL}/historia-clinica/`,
};

export const ORDER_PAYMENT ={
   base: `${GATEWAY_URL}/services/payment/api`,
   invoice: '/invoices'
}

export const CONSULTATION = {
   base: `${GATEWAY_URL}/services/consultation/api/business/consultations`,
};

export const GET_ORDER_PRESCRIPTION = {
   base: `${GATEWAY_URL}/services/consultation/api/business/prescriptions/`,
};

export default config;
