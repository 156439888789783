import React from "react";

import Header from "./Header";
import HeaderLight from "../layout/HeaderLight";
import { sessionGet } from "../../services/authService";
import pkg from "../../../package.json";

const Layout = ({ children }) => {
  const logged = sessionGet();

  return (
    <div className="layout">
      {logged ? <Header /> : <HeaderLight />}
      <main className="main">{children}</main>
      {pkg?.version && (
        <div className="version">{"Versión: " + pkg?.version}</div>
      )}
    </div>
  );
};

export default Layout;
