import ActionPanel from './ActionPanel';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AgoraUIKit from 'agora-react-uikit';
import { useStateAuthValue } from '../../../context/Auth/AuthState';
import { PatientGet } from '../../../services/patientService';
import {
  ConsultationCreate,
  ConsultationUpdate,
} from '../../../services/consultationService';
import TimeWatch from '../../../components/timeWatch/index';
import { useStateProfessionalDashboardValue } from '../../../context/profesionalPage/profesionalDashboardState';
//import { styleProps } from "../../../components/AgoraVideoCall/styleProps";

const ProfessionalVideoCall = ({ videoCallData, setVideoCall, setMessage }) => {
  const [{ userData, userToken }] = useStateAuthValue();
  const [{ data, stomp }] = useStateProfessionalDashboardValue();
  const [time, setTime] = useState(0);
  const [isVideocallFinished, setIsVideocallFinished] = useState(false);
  const [consultIsSubmitted, setConsultIsSubmitted] = useState(false);
  const [patient, setPatient] = useState([]);
  const [startConsultCalled, setStartConsultCalled] = useState(false);
  const role = 'publisher';
  const [token, setToken] = useState('');

  useEffect(() => {
    if (consultIsSubmitted) {
      setIsVideocallFinished(true);
      setMessage('Consulta finalizada');
      leaveConsult();
      setVideoCall(false);
    }
  }, [consultIsSubmitted]);

  useEffect(() => {
    // console.log("url",`${process.env.REACT_APP_AGORA_TOKEN_SERVICE}/rtc/${videoCallData.queueId}/${role}/uid/${videoCallData.professionalId}`)
    // console.log(process.env.REACT_APP_AGORA_TOKEN_SERVICE)
    const getToken = async () => {
      const url = `${process.env.REACT_APP_AGORA_TOKEN_SERVICE}/rtc/${videoCallData.queueId}/${role}/uid/${videoCallData.professionalId}`;
      try {
        const response = await axios.get(url);
        const token = response.data.rtcToken;
        setToken(token);
      } catch (err) {
        alert(err);
      }
    };
    getToken();
  }, [videoCallData]);

  useEffect(() => {
    const getPatient = async () => {
      await PatientGet(videoCallData?.patientId, userToken)
        .then((response) => {
          setPatient({
            ...response?.data,
            professional_dto: {
              drName: userData.alias,
              email: userData.email,
            },
          });
        })
        .catch(() => {
          setIsVideocallFinished(true);
          setMessage('Ocurrió un error al obtener los datos del paciente');
          leaveConsult();
          setVideoCall(false);
        });
    };

    getPatient();
  }, []);

  useEffect(() => {
    if (patient?.professional_dto?.email) {
      // console.log(patient)
      const startConsult = async () => {
        setStartConsultCalled(true);
        await ConsultationCreate(patient, userToken)
          .then((r) => {
            // console.log("res consult", r)
          })
          .catch((r) => {
            // console.log("error consult",r)
          });
      };
      if (!startConsultCalled) {
        startConsult();
      }
    }
  }, [patient]);

  const leaveConsult = () => {
    var wsMessage = {
      patientId: data?.patientId,
      orderNumber: data?.orderNumber,
      specialtyId: data?.specialtyId,
      command: 'ProfessionalFinish',
      dateTimeProfessionalHangup: time,
      professionalId: data?.professionalId,
      queueId: data?.queueId,
      date: data?.date,
    };
    stomp.send('/app/message', {}, JSON.stringify(wsMessage));
  };

  return (
    <>
      {token !== '' && (
        <div className="videocall" style={{ width: '100vw', height: '85vh' }}>
          <div className="container">
            <div
              style={{
                display: 'flex',
                borderRadius: '0 40px 40px 40px',
                overflow: 'hidden',
              }}
            >
              <AgoraUIKit
                rtcProps={{
                  appId: process.env.REACT_APP_AGORA_APP_ID,
                  channel: videoCallData.queueId,
                  token: token,
                  uid: videoCallData.professionalId,
                  layout: 1,
                }}
                callbacks={{
                  /* "user-joined": () =>
                    console.log("MediLine - 🧍 Remote User joined"), */
                  'user-left': () => {
                    setMessage(
                      'El paciente se desconecto, por favor, complete el registro de consulta antes de finalizar la llamada'
                    );
                  } /* 
                  "user-unpublished": () =>
                    console.log("MediLine - 🧍 Remote user unpublished"),
                  "user-published": () =>
                    console.log("MediLine - 🧍 Remote User Publish event"), */,
                }}
              />
            </div>
            <div>
              <TimeWatch
                setTime={setTime}
                isVideocallFinished={isVideocallFinished}
              />
              <ActionPanel
                data={videoCallData}
                setConsultIsSubmitted={setConsultIsSubmitted}
                setMessage={setMessage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfessionalVideoCall;
