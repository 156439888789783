const patientFormModel = {
    alias: {
       name: 'alias',
       label: 'Alias',
    },
    first_name: {
       name: 'first_name',
       label: 'Nombre',
       stringErrorMsg: 'Ingresa sólo letras',
       requiredErrorMsg: 'El nombre es requerido',
    },
    last_name: {
       name: 'last_name',
       label: 'Apellido',
       requiredErrorMsg: 'El apellido es requerido',
    },
    id_number: {
       name: 'id_number',
       label: 'DNI',
       minErrorMsg: 'Ingresa un DNI válido',
       maxErrorMsg: 'Ingresa un DNI válido',
       requiredErrorMsg: 'El DNI es requerido',
    },
    birth_date: {
       name: 'birth_date',
       label: 'Fecha de Nacimiento',
       minDate: '1900/01/01',
       minAgeErrorMsg: 'Debe ser mayor a 1 año',
       requiredErrorMsg: 'La fecha de nacimiento es requerida',
    },
    phone_number: {
       name: 'phone_number',
       label: 'Teléfono',
       minErrorMsg:
       'Ingresa un teléfono válido. Escribe el código de área sin 0 y el número sin 15. Ej. 1123456789',
       maxErrorMsg:
       'Ingresa un teléfono válido. Escribe el código de área sin 0 y el número sin 15. Ej. 1123456789',
       requiredErrorMsg: 'El teléfono es requerido',
       extraLabel: 'Código de área sin 0 y el número sin 15. Ej. 1123456789',
    },
    sex_str: {
       name: 'sex_str',
       label: 'Sexo',
       requiredErrorMsg: 'El sexo es requerido',
       extraLabel: 'Como figura en DNI',
    },
    relationship_to_titular: {
        name: 'relationship_to_titular',
        label: 'Relación con el titular',
        requiredErrorMsg: 'La relación es requerida',
     },
    address_dto: {
       street: {
          name: 'address_dto.street',
          label: 'Calle',
          requiredErrorMsg: 'La calle es requerida',
       },
       number: {
          name: 'address_dto.number',
          label: 'Número',
          minErrorMsg: 'Ingresa un número válido',
          maxErrorMsg: 'Ingresa un número válido',
          requiredErrorMsg: 'El número es requerido',
       },
       number_department: {
          name: 'address_dto.number_department',
          label: 'Distrito/Barrio',
       },
       floor: {
          name: 'address_dto.floor',
          label: 'Piso/Depto',
       },
       postal_code: {
          name: 'address_dto.postal_code',
          label: 'CP',
          minErrorMsg: 'Ingresa un CP válido',
          maxErrorMsg: 'Ingresa un CP válido',
          requiredErrorMsg: 'El CP es requerido',
       },
       between_streets: {
          name: 'address_dto.between_streets',
          label: 'Entre calles',
          minErrorMsg: 'Ingresa un dato válido',
          maxErrorMsg: 'Ingresa un dato válido',
       },
       city: {
          name: 'address_dto.city',
          label: 'Ciudad',
          minErrorMsg: 'Ingresa una ciudad válida',
          maxErrorMsg: 'Ingresa una ciudad válida',
          requiredErrorMsg: 'La ciudad es requerida',
       },
       country: {
          name: 'address_dto.country',
          label: 'País',
          requiredErrorMsg: 'El país es requerido',
       },
       province: {
          name: 'address_dto.province',
          label: 'Provincia o Estado',
          minErrorMsg: 'Ingresa una provincia válida',
          maxErrorMsg: 'Ingresa una provincia válida',
          requiredErrorMsg: 'La provincia o estado es requerido',
       },
    },
    patient_dto: {
       pre_existing_disease: {
          name: 'patient_dto.pre_existing_disease',
          label: 'Enfermedades Preexistentes',
          placeholder: 'Selecciona una o más enfermedades preexistentes',
       },
       frequent_medication: {
          name: 'patient_dto.frequent_medication',
          label: 'Medicaciones Frecuentes',
          searchTextLabel: 'Ingrese una medicación frecuente',
          searchTextMinRequired: 'Ingrese al menos 3 caracteres'
       },
       surgeries: {
          name: 'patient_dto.surgeries',
          label: 'Cirugías',
          placeholder: 'Selecciona una o más cirugías',
       },
       size: {
          name: 'patient_dto.size',
          label: 'Altura',
          requiredErrorMsg: 'La altura es requerida',
       },
       weight: {
          name: 'patient_dto.weight',
          label: 'Peso',
          requiredErrorMsg: 'El peso es requerido',
       },
       allergies: {
          name: 'patient_dto.allergies',
          label: 'Alergias',
       },
       family_history: {
          name: 'patient_dto.family_history',
          label: 'Antecedentes Familiares',
       },
       vaccines: {
          name: 'patient_dto.vaccines',
          label: 'Vacunas',
       },
       gynecological_data: {
          name: 'patient_dto.gynecological_data',
          label: 'Ginecológicos y Obstétricos',
       },
       patientAge: {
          name: 'patient_dto.age',
          label: 'Edad',
       },
       attention_type: {
          name: 'patient_dto.attention_type',
          label: 'Tipo de Atención',
          requiredErrorMsg: 'El tipo de atención es requerido',
       },
       affiliate_number: {
          name: 'patient_dto.affiliate_number',
          label: 'N° de asociado',
          requiredErrorMsg: 'El número de asociado es requerido',
       },
       patientStudy: {
         name: 'patient_dto.study',
         label: 'Adjuntar Estudios Médicos',
      },
    },
 };
 
 export default patientFormModel;
 