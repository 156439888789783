import moment from 'moment-timezone';

import { MINUTES_INTERVAL } from '../conf/appSetting';

export const weekDays = [
   { key: 'SUNDAY', label: 'Domingo', selected: false },
   { key: 'MONDAY', label: 'Lunes', selected: false },
   { key: 'TUESDAY', label: 'Martes', selected: false },
   { key: 'WEDNESDAY', label: 'Miércoles', selected: false },
   { key: 'THURSDAY', label: 'Jueves', selected: false },
   { key: 'FRIDAY', label: 'Viernes', selected: false },
   { key: 'SATURDAY', label: 'Sábado', selected: false },
   // FIXME: Remove this below when /time/availability service change
   { key: 'SUN.', label: 'Dom.', selected: false },
   { key: 'MON.', label: 'Lun.', selected: false },
   { key: 'TUE.', label: 'Mar.', selected: false },
   { key: 'WED.', label: 'Mié.', selected: false },
   { key: 'THU.', label: 'Jue.', selected: false },
   { key: 'FRI.', label: 'Vie.', selected: false },
   { key: 'SAT.', label: 'Sáb.', selected: false },
   { key: 'DOM.', label: 'Dom.', selected: false },
   { key: 'LUN.', label: 'Lun.', selected: false },
   { key: 'MAR.', label: 'Mar.', selected: false },
   { key: 'MIÉ.', label: 'Mié.', selected: false },
   { key: 'JUE.', label: 'Jue.', selected: false },
   { key: 'VIE.', label: 'Vie.', selected: false },
   { key: 'SÁB.', label: 'Sáb.', selected: false },
   { key: 'SUN', label: 'Dom.', selected: false },
   { key: 'MON', label: 'Lun.', selected: false },
   { key: 'TUE', label: 'Mar.', selected: false },
   { key: 'WED', label: 'Mié.', selected: false },
   { key: 'THU', label: 'Jue.', selected: false },
   { key: 'FRI', label: 'Vie.', selected: false },
   { key: 'SAT', label: 'Sáb.', selected: false },
   { key: 'DOM', label: 'Dom.', selected: false },
   { key: 'LUN', label: 'Lun.', selected: false },
   { key: 'MAR', label: 'Mar.', selected: false },
   { key: 'MIÉ', label: 'Mié.', selected: false },
   { key: 'JUE', label: 'Jue.', selected: false },
   { key: 'VIE', label: 'Vie.', selected: false },
   { key: 'SÁB', label: 'Sáb.', selected: false },
];

let currentDate = new Date();
currentDate.setMinutes(
   Math.floor(currentDate.getMinutes() / MINUTES_INTERVAL) * MINUTES_INTERVAL +
      (currentDate.getMinutes() % MINUTES_INTERVAL !== 0) * MINUTES_INTERVAL,
);

export const currentDateWithInterval = currentDate;

export const validateHour = (value) => {
   if (value && value.length) {
      const currentMinutes = parseInt(value.split(':')[1]);
      return currentMinutes % 20 === 0;
   }
   return false;
};

export const hourToString = (value) => moment(value).format('HH:mm');

// Helps to convert an array of keys into a string made with the corresponding labels according to weekDays
export const daysToString = (days) =>
   weekDays
      .filter((day) => days.includes(day.key))
      .map((day) => day.label)
      .join(', ');

// Helps to format a String week day to spanish
export const dayToString = (day) => {
   const dayUpperCase = day.toUpperCase();
   return weekDays.find((d) => d.key === dayUpperCase).label;
};

// Helps to format a date to 'DD/MM'
export const formatDayMonth = (date) => moment(date).format('DD/MM');

// Helps to format a date to 'DD/MM/YYYY'
export const formatDate = (date) => moment(date).format('DD/MM/YYYY');

// Helps to format a date to 'Day DD/MM' for ResultListItem (Selected Profesional Item)
export const formatDateSelectedProfesional = (date) => {
   const dayString = moment(date).format('dddd');
   return `${dayToString(dayString)} ${formatDayMonth(date)}`;
};

// Helps to convert a date to same date but X months earlier
export const substractMonths = (date, months) =>
   moment(date).subtract(months, 'months');

export const addMonths = (date, months) => moment(date).add(months, 'months');

// Helps to convert a date to same date but X minutes earlier
export const subtractMinutes = (date, minutes) =>
   moment(date).subtract(minutes, 'minutes').toDate();

// Helps to convert a date to same date but X minutes later
export const addMinutes = (date, minutes) =>
   moment(date).add(minutes, 'minutes').toDate();

// Helps to convert a timeAvailability date
export const dayToStringWithDate = (date) => {
   const daySplited = date.split(' ')[0];
   const dateSplited = date.split(' ')[1];
   const dateFormated = dayToString(daySplited);
   return `${dateFormated.substring(0, 3)}. ${dateSplited}`;
};

// Helps to calculate age by date
export const calculateAge = (date) => {
   let now = new Date();
   let birth_date = new Date(date);
   let age = now.getFullYear() - birth_date.getFullYear();
   let month = now.getMonth() - birth_date.getMonth();
   if (month < 0 || (month === 0 && now.getDate() < birth_date.getDate())) {
      age--;
   }
   return age;
};

export const isTimeBefore = (timeAfter, timeBefore) =>
   moment(timeAfter, 'HH:mm').isBefore(moment(timeBefore, 'HH:mm'));

export const getDifferenceInYears = (date) =>
   moment().diff(moment(date), 'years');

export const momentDate = (date = null) =>
   date ? moment(new Date(date)) : moment(new Date());

export const isBetweenDates = (date, startDate, endDate) => {
   let time = moment(date).unix(),
      startTime = moment(startDate).unix(),
      endTime = moment(endDate).unix();

   return time >= startTime && time <= endTime ? true : false;
};

export function formatDateTime(dateTimeString) {
   const providedDate = new Date(dateTimeString);
   const currentDate = new Date();
   const differenceInMilliseconds = currentDate - providedDate;
   const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));

   const optionsDate = {
     day: '2-digit',
     month: '2-digit',
     year: 'numeric'
   };

   const optionsTime = {
     hour: '2-digit',
     minute: '2-digit'
   };

   const formattedDateTime = `${providedDate.toLocaleDateString('en-US', optionsDate)} ${providedDate.toLocaleTimeString('en-US', optionsTime)}`;

   return `${differenceInMinutes} minutos`;
};

export function formatDateHourAndMinute(dateTimeString) {
   const providedDate = new Date(dateTimeString);

   const optionsTime = {
     hour: '2-digit',
     minute: '2-digit',
     hour12: false  // Esto asegura que el formato sea de 24 horas
   };

   return providedDate.toLocaleTimeString('en-US', optionsTime);
}