import React, { useEffect } from 'react';
import {
   FormControl,
   FormHelperText,
   InputLabel,
   MenuItem,
   Select,
} from '@material-ui/core';

const SelectObjectField = (props) => {
   const {
      label,
      value,
      setValue,
      data,
      isError,
      errorMsg,
      className,
      disabled = false,
      extraLabel = '',
      requiredField,
      withDefaultValue = false,
      setChanged,
      name='',
      containerStyle={}
   } = props;
   const labelField = requiredField ? `${label}*` : label;

   useEffect(() => {
      if (withDefaultValue) {
         if (data?.length) {
            setValue(data[0]);
         } else {
            setValue({ id: 0, name: 'No hay opciones' });
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data, withDefaultValue]);

   const renderHelperText = () => {
      if (isError) {
         return <FormHelperText>{errorMsg}</FormHelperText>;
      } else if (extraLabel) {
         return <FormHelperText>{extraLabel}</FormHelperText>;
      }
   };

   const onChange = (e) => {
      setValue(e.target.value);
      if (setChanged) {
         setChanged(true);
      }
   };

   return (
      <FormControl error={isError} className={className} style={containerStyle}>
         <InputLabel>{labelField}</InputLabel>
         <Select
            name={name}
            value={value || {}}
            onChange={onChange}
            renderValue={(v) => v?.name || ''}
            defaultValue=""
            disabled={disabled}
         >
            {data.map((item, index) => (
               <MenuItem key={index} value={item || {}}>
                  {item?.name || ''}
               </MenuItem>
            ))}
         </Select>
         {renderHelperText()}
      </FormControl>
   );
};

export default SelectObjectField;
