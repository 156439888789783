import React from 'react';

const EmptyLayout = ({ children, ...restProps }) => (
   <div className="empty-layout">
      <main className="main" {...restProps}>
         {children}
      </main>
   </div>
);

export default EmptyLayout;
