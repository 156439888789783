import { Button } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';
import { Delete } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import { iconsFile } from '../../utils/FormUtil';
import React, { useEffect, useState } from 'react';
import { Field, useField } from 'formik';

const FilesUploadField = (props) => {
    const [files, setFiles] = useState([]);
    const [field, meta, helpers] = useField(props.name);
    const { value } = field;
    const { touched, error } = meta;
    const { setValue } = helpers;

    useEffect(() => {
        let newFiles = [];
        if (value == undefined) return;
        if (value?.length !== 0){
            value.map((element) => {
                if (element instanceof File) {
                    newFiles.push(element);
                } else {
                    let { base64, filename, mimetype} = element;
                    let newFile = new File([base64], filename, { type: mimetype });
                    newFiles.push(newFile);
                }
            })
            setFiles(newFiles);
        }
    }, [])

    const handleFileChange = (event) => {
        const fileList = event.target.files;
        const newFiles = Array.from(fileList);
        let newFilesCopy = [...files, ...newFiles]
        setFiles(newFilesCopy);
        setValue(newFilesCopy);
    };
  
    const handleDeleteFile = (fileToDelete) => () => {
        let newFiles = [...files]
        newFiles = newFiles.filter((file) => file !== fileToDelete);
        setFiles(newFiles);
        setValue(newFiles);
    };

    return (
        <div>
            <span style={{color: '#757575'}}>{props.label}</span>
            <Button
                component="label"
                startIcon={<CloudUpload/>}
                sx={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    boxShadow: 'none',
                    padding: 0,
                    '&:hover': {
                        backgroundColor: 'transparent',
                        border: 'none',
                        boxShadow: 'none',
                    },
                    '&:active': {
                        backgroundColor: 'transparent',
                        border: 'none',
                        boxShadow: 'none',
                    },
                }}
            >
            <input
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept=".pdf, .doc, .docx, .jpeg, .jpg, .png"
                multiple
            />
            </Button>
            <Box
                sx={{
                    p: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                    borderBottom: '1px solid #8c8c8c',
                }}
            >
                {files.map((file, index) => (
                    <Chip
                        key={index}
                        avatar={iconsFile(file.type)}
                        label={file.name}
                        onDelete={handleDeleteFile(file)}
                        deleteIcon={<Delete />}
                    />
                ))}
            </Box>
        </div>
    );
}

export default FilesUploadField;