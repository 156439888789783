const Specialties =[
        {id:72, name: 'Alergia e inmunología', enabled: true},
        {id:76, name: 'Cardiología', enabled: true},
        {id:85, name: 'Clínica médica', enabled: true},
        {id:88, name: 'Dermatología', enabled: true},
        {id:92, name: 'Endocrinología', enabled: true},
        {id:96, name: 'Gastroenterología', enabled: true},
        {id:100, name: 'Ginecología', enabled: true},
        {id:108, name: 'Medicina del deporte', enabled: true},
        {id:114, name: 'Nefrología', enabled: true},
        {id:123, name: 'Obstetricia', enabled: true},
        {id:125, name: 'Oncología', enabled: true},
        {id:129, name: 'Pediatría', enabled: true},
        {id:1237, name: 'Tocoginecología', enabled: true},
        {id:139, name: 'Urología', enabled: true}
     ]
  ;
  
  export default Specialties;