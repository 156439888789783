import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  ListItem,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PaymentIcon from "@material-ui/icons/Payment";

import PrescriptionOfOrder from "../prescriptionOfOrder";
import Modal from "../../../../components/Modal";
import { useStateAuthValue } from "../../../../context/Auth/AuthState";
import { useToast } from "../../../../context/Toast/ToastProvider";
import { Order } from "../../../../models/enums";
import { paymentInvoice } from "../../../../services/paymentService";
import {
  changeState,
  getExtra,
  getPrescriptionOfOrder,
} from "../../../../services/pharmacyService";
import { formatDate } from "../../../../utils/DateUtil";
import { orderFormatedNumber } from "../../../../utils/OrderFormatedNumber";
import { showToast, ToastType } from "../../../../utils/ToastUtil";

const MyOrderItem = (props) => {
  const {
    orderNumber,
    purchaseOrderId,
    pharmacyName,
    total,
    state,
    pharmacy,
    streetName,
    streetNumber,
    createdDate,
    quotedDate,
    prePaidDate,
    paidDate,
    sentDate,
    deliveredDate,
    rejectedDate,
    purchaseOrder,
  } = props;
  const [{ userToken }] = useStateAuthValue();
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [openIncreaseModal, setOpenIncreaseModal] = useState(false);
  const [increaseLink, setIncreaseLink] = useState("");
  const [prescriptions, setPrescriptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [openExtra, setOpenExtra] = useState(false);
  const [extra, setExtra] = useState([]);
  const [extraSubTotal, setExtraSubTotal] = useState();
  const [extraCalculatedDiscount, setExtraCalculatedDiscount] = useState();
  const [extraTotal, setExtraTotal] = useState();
  const [subtotal, setSubtotal] = useState(0);
  const toastDispatch = useToast();

  const showError = (msgError) => {
    showToast(toastDispatch, msgError, ToastType.ERROR, "Error");
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && userToken) {
      getData();
      getExtraOrder();
    }

    return () => {
      isMounted = false;
    };
  }, [userToken]);

  useEffect(() => {
    let isMounted = true;
    if (purchaseOrder?.discountExtra && isMounted) {
      const calculatedDiscount =
        (extraSubTotal * purchaseOrder.discountExtra) / 100;
      setExtraCalculatedDiscount(calculatedDiscount);
      setExtraTotal(extraSubTotal - calculatedDiscount);
    }
    if (purchaseOrder.total) {
      let sum = purchaseOrder.total - purchaseOrder.deliveryCost;
      if (purchaseOrder.discountOrder) {
        sum = (sum * 100) / (100 - purchaseOrder.discountOrder);
      }
      setSubtotal(sum);
    }
    return () => {
      isMounted = false;
    };
  }, [purchaseOrder, extraSubTotal]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickExtra = () => {
    setOpenExtra(!openExtra);
  };

  const getData = async () => {
    await getPrescriptionOfOrder(orderNumber, userToken)
      .then((response) => {
        setPrescriptions(response?.content);
      })
      .catch(() => showError("Ocurrió un error al obtener las recetas"));
  };

  const getExtraOrder = async () => {
    await getExtra(orderNumber, userToken)
      .then((response) => {
        let sum = 0;
        response.content.forEach((extraItem) => {
          extraItem.subtotal = extraItem.unitPrice * extraItem.quantity;
          extraItem.calculatedDiscount =
            (extraItem.subtotal * extraItem.discount) / 100;
          extraItem.total = extraItem.subtotal - extraItem.calculatedDiscount;
          sum += extraItem.total;
        });
        setExtraSubTotal(sum);
        setExtraTotal(sum);
        setExtra(response?.content);
      })
      .catch(() => showError("Ocurrió un error al obtener los extras"));
  };

  const changeStatusRejected = async () => {
    setLoadingIcon(true);

    await changeState(
      {
        purchaseOrder: {
          id: orderNumber,
          status: Order.REJECTED,
          statusReason: "El paciente rechazó la cotización de la orden",
          paymentDetail: "Se rechazó el pedido",
        },
      },
      userToken
    )
      .then()
      .catch(() => showError("Ocurrió un error con el servicio de pago"))
      .finally(() => setLoadingIcon(false));
  };

  const changeStatusDelivered = async () => {
    const sendSent = {
      purchaseOrder: {
        id: orderNumber,
        status: Order.DELIVERED,
        statusReason: "El paciente acepto el envio",
      },
    };

    await changeState(sendSent, userToken)
      .then(window.location.reload())

      .catch(() => {
        showError("Ocurrió un error al aceptar la entrega");
      });
  };

  const handlePaid = async () => {
    setLoadingIcon(true);

    await paymentInvoice(
      {
        amount: total || 0,
        currency: "ARS",
        entityName: "PURCHASE_ORDER",
        entityId: orderNumber,
      },
      userToken
    )
      .then((response) => {
        setOpenIncreaseModal(true);
        setIncreaseLink(response?.checkoutLink);
        if (response?.id) {
          changeState(
            {
              purchaseOrder: {
                id: orderNumber,
                status: Order.PRE_PAID,
                statusReason: "Proceso de pago iniciado",
                paymentDetail: `El id de la orden es ${response?.id}`,
              },
            },
            userToken
          )
            .then()
            .catch(() => {
              showError("Ocurrió un error al iniciar el proceso de pago");
            });
        }
      })
      .catch(() => showError("Ocurrió un error con el servicio de pago"))
      .finally(() => setLoadingIcon(false));
  };

  const handleClose = () => {
    setOpenIncreaseModal(false);
    window.location.reload();
  };

  const divState = () => {
    if (state === Order.QUOTED) {
      return (
        <div className="order-number__stateQuoted">
          <div className="div-state">Cotizado</div>
          <div className="div-button">
            <Tooltip title="Rechazar cotización">
              <IconButton onClick={changeStatusRejected}>
                {loadingIcon ? <CircularProgress size={24} /> : <ClearIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Procesar pago">
              <IconButton onClick={handlePaid} className="prepaid-button">
                {loadingIcon ? <CircularProgress size={24} /> : <PaymentIcon />}
              </IconButton>
            </Tooltip>
          </div>
        </div>
      );
    } else if (state === Order.PRE_PAID) {
      return <div className="order-number__statePrepaid">Procesando</div>;
    } else if (state === Order.CREATED) {
      return <div className="order-number__stateCreated">Creados</div>;
    } else if (state === Order.PAID) {
      return <div className="order-number__statePaid">Pagado</div>;
    } else if (state === Order.DELIVERED) {
      return <div className="order-number__stateDelivered">Entregado</div>;
    } else if (state === Order.SENT) {
      return (
        <div className="order-number__stateSent">
          <div className="div-state-sent">Enviado</div>
          <div className="div-button-sent">
            <Button variant="contained" onClick={changeStatusDelivered}>
              Aceptar Entrega
            </Button>
          </div>
        </div>
      );
    } else {
      return <div className="order-number__stateRejected">Rechazado</div>;
    }
  };

  let created = formatDate(createdDate);
  let quoted = formatDate(quotedDate);
  let prepaid = formatDate(prePaidDate);
  let paid = formatDate(paidDate);
  let sent = formatDate(sentDate);
  let delireved = formatDate(deliveredDate);
  let rejected = formatDate(rejectedDate);

  const spanVencimiento = () => {
    switch (state) {
      case Order.CREATED:
        return <span className="created">Creado: {created}</span>;
      case Order.QUOTED:
        return <span className="quoted">Cotizado: {quoted}</span>;
      case Order.PRE_PAID:
        return <span className="pre_paid">Procesado: {prepaid}</span>;
      case Order.PAID:
        return <span className="paid">Pagado: {paid}</span>;
      case Order.SENT:
        return <span className="sent">Enviado: {sent}</span>;
      case Order.DELIVERED:
        return <span className="delivered">Entregado: {delireved}</span>;
      case Order.REJECTED:
        return <span className="rejected">Rechazado: {rejected}</span>;
      default:
    }
  };

  return (
    <>
      <ListItem className="list-item-order">
        <div className="order-data">
          <div className="order-number">
            <span className="order-number__span">Pedido N°</span>
            <span className="order-number__number">{purchaseOrderId}</span>
            {divState()}
          </div>
          <div className="pharmacy-expiration">
            <span>{`${pharmacyName} - ${pharmacy}`}</span>
            <span className="pharmacy-expiration__direction">{`${streetName} ${streetNumber} , ${pharmacy}`}</span>
            {spanVencimiento()}
          </div>
          <div className="total">
            <div className="description">
              <div className="MuiButtonBase-rootMuiListItem-rootMuiListItem-guttersMuiListItem-button">
                <ListItem button onClick={handleClick}>
                  <span>Recetas</span>
                  <ListItemText />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              </div>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <div className="MuiCollapse-wrapperInner">
                  <div className="collapse">
                    {prescriptions.map((prescription) => (
                      <PrescriptionOfOrder
                        key={prescription?.id}
                        idPrescription={prescription?.id}
                        createdDate={prescription?.creationDate}
                        diagnosis={prescription?.diagnosis}
                        professionalName={prescription?.professionalName}
                        professionalLicense={prescription?.professionalLicense}
                        orderNumber={orderNumber}
                        numberPrescription={prescription?.prescriptionId}
                      />
                    ))}
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="extras">
              <div className="title">
                <ListItem button onClick={handleClickExtra}>
                  <span className="extra-title">Extras</span>
                  <ListItemText />
                  {openExtra ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              </div>
              <Collapse in={openExtra} timeout="auto" unmountOnExit>
                <div className="collapse-extras">
                  <div className="cant-descrip">
                    <span className="cant">Cant.</span>
                    <span className="description">Descripción</span>
                    <span className="price">Precio</span>
                    <span className="price">Subtotal</span>
                    <span className="min"></span>
                    <span className="min">Desc %</span>
                    <span className="price">Desc $</span>
                    <span className="price">Total</span>
                  </div>

                  <div className="extras">
                    {extra.map((data) => {
                      return (
                        <div key={data?.quantity} className="extra-data">
                          <span className="extra-number">
                            {data?.quantity || ""}
                          </span>
                          <span className="extra-name">{data?.name}</span>
                          <div className="extra-price">
                            $ {orderFormatedNumber(data?.unitPrice)}
                          </div>
                          <div className="extra-price">
                            $ {orderFormatedNumber(data.subtotal)}
                          </div>
                          <div className="extra-min"></div>
                          <div className="extra-min">
                            {data?.discount ? `${data.discount} %` : ""}
                          </div>
                          <div className="extra-price">
                            {data.discount
                              ? `$ ${orderFormatedNumber(
                                  data.calculatedDiscount
                                )}`
                              : ""}
                          </div>
                          <div className="extra-price">
                            $ {orderFormatedNumber(data.total)}
                          </div>
                        </div>
                      );
                    })}

                    {purchaseOrder?.discountExtra > 0 &&
                      extraCalculatedDiscount && (
                        <div className="extra-data">
                          <span className="extra-number" />
                          <span className="extra-name" />
                          <div className="extra-price" />
                          <div className="extra-discounts">
                            Descuentos Varios
                          </div>
                          <div className="extra-min">
                            {purchaseOrder?.discountExtra
                              ? `${purchaseOrder.discountExtra} %`
                              : ""}
                          </div>
                          <div className="extra-price">
                            {extraCalculatedDiscount
                              ? `$ ${orderFormatedNumber(
                                  extraCalculatedDiscount
                                )}`
                              : ""}
                          </div>
                          <div className="extra-price">
                            {extraCalculatedDiscount
                              ? `- $ ${orderFormatedNumber(
                                  extraCalculatedDiscount
                                )}`
                              : ""}
                          </div>
                        </div>
                      )}
                    <div className="extra-data">
                      <span className="extra-number" />
                      <span className="extra-name" />
                      <div className="extra-price" />
                      <div className="extra-discounts" />
                      <div className="extra-min" />
                      <div className="extra-price">Total extras</div>
                      <div className="extra-price">
                        {extraTotal
                          ? `$ ${orderFormatedNumber(extraTotal)}`
                          : "$ 0.00"}
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="order-discounts">
              <div className="order-data mt-25">
                <span className="order-number" />
                <span className="order-name" />
                <span className="order-price" />
                <span className="order-discounts" />
                <div className="order-min">Subtotal:</div>
                <div className="order-price">Receta + Extras</div>
                <div className="order-price">
                  {subtotal ? `$ ${orderFormatedNumber(subtotal)}` : "$ 0.00"}
                </div>
              </div>
              {purchaseOrder?.deliveryCost > 0 && (
                <div className="order-data">
                  <span className="order-number" />
                  <span className="order-name" />
                  <span className="order-price" />
                  <span className="order-discounts">Costo de entrega</span>
                  <div className="order-min" />
                  <div className="order-price" />
                  <div className="order-price">
                    $ {orderFormatedNumber(purchaseOrder.deliveryCost)}
                  </div>
                </div>
              )}
              {purchaseOrder?.discountOrder > 0 && (
                <div className="order-data">
                  <span className="order-number" />
                  <span className="order-name" />
                  <span className="order-price" />
                  <span className="order-discounts">Descuento Farmacia</span>
                  <div className="order-min">
                    {purchaseOrder?.discountOrder
                      ? `${purchaseOrder.discountOrder} %`
                      : ""}
                  </div>
                  <div className="order-price">
                    {subtotal
                      ? `$ ${orderFormatedNumber(
                          (subtotal * purchaseOrder.discountOrder) / 100
                        )}`
                      : ""}
                  </div>
                  <div className="order-price">
                    {subtotal
                      ? `- $ ${orderFormatedNumber(
                          (subtotal * purchaseOrder.discountOrder) / 100
                        )}`
                      : ""}
                  </div>
                </div>
              )}
            </div>
            <span className="spantotal">
              Total: $ {orderFormatedNumber(total || 0)}
            </span>
          </div>
        </div>
      </ListItem>
      <Modal
        isActive={openIncreaseModal}
        handleClose={handleClose}
        fullWidth={true}
        fullScreenChildren
      >
        <iframe
          style={{ display: "block" }}
          className="increase-modal"
          src={increaseLink}
        />
      </Modal>
    </>
  );
};

export default MyOrderItem;
