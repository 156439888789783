import HttpClient from './fetch_api';
import { FIREBASE_URL } from '../conf/appSetting';

function generateUUID() {
	function separateUUID() {
		return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
	}
	return separateUUID() + separateUUID() + '-' + 
		   separateUUID() + '-' + 
		   separateUUID() + '-' + 
		   separateUUID() + '-' + 
		   separateUUID() + separateUUID() + separateUUID();
}

const getById = async (patientId) => {
    let url = FIREBASE_URL + '/messages/' + patientId + '.json';
    return HttpClient.get(
        url,
    );
}

const get = async () => {
    let url = FIREBASE_URL + '/messages.json';
    return HttpClient.get(
        url,
    );
}

const post = async (message) => {
    let messageValue =
        {
            "command": message?.command ? message?.command : "",
            "createdDate": new Date().toString(),
            "orderNumber": message?.order ? message?.order : null,
            "patientId": message?.patientId ? message?.patientId : null,
            "profSpecialty": message?.profSpecialty ? message?.profSpecialty : null,
            "profesionalName": message?.profesionalName ? message?.profesionalName : null,
            "professionalId": message?.professionalId ? message?.professionalId : null,
            "reason": message?.reason ? message?.reason : "",
            "specialtyId": message?.specialtyId ? message?.specialtyId : null,
            "status": 'WAITING',
            "familymember": message?.familymember ? message?.familymember : "",
        }
    let messageGet = await get(message?.patientId);
    if (messageGet) messageGet = messageGet[message?.patientId];
    else messageGet = {};
    HttpClient.put(
        FIREBASE_URL + '/messages/' + message?.patientId + '.json', {...messageGet, ...messageValue}
    );
}

const put = async (message) => {
    let messageValue = 
        {
            "command": message?.command ? message?.command : null,
            "createdDate": new Date().toString(),
            "orderNumber": message?.order ? message?.order : null,
            "patientId": message?.patientId ? message?.patientId : null,
            "profSpecialty": message?.profSpecialty ? message?.profSpecialty : null,
            "profesionalName": message?.profesionalName ? message?.profesionalName : null,
            "professionalId": message?.professionalId ? message?.professionalId : null,
            "reason": message?.reason ? message?.reason : null,
            "specialtyId": message?.specialtyId ? message?.specialtyId : null,
            "status": message?.status ? message?.status : null,
            "familymember": message?.familymember ? message?.familymember : null,
        }
    let messageGet = await get(message?.patientId);
    if (messageGet) messageGet = messageGet[message?.patientId];
    else messageGet = {};
    HttpClient.put(
        FIREBASE_URL + '/messages/' + message?.patientId + '.json', {...messageGet, ...messageValue}
    );
}

const deleteById = async (patientId) => {
    let url = FIREBASE_URL + '/messages/' + patientId + '.json';
    return HttpClient.delete(
        url,
    );
}

const del = async () => {
    let url = FIREBASE_URL + '/messages.json';
    return HttpClient.delete(
        url,
    );
}

export default {
    get,
    post,
    put,
    delete: del,
    getById: getById,
    deleteById: deleteById,
};