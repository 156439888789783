import React, { useEffect, useState, useRef } from "react";
import "./styles.css";
import ResultDefault from "./Default";
import TestConnection from "./TestConnection";
import { makeStyles } from "@material-ui/core";
import { getEstimatedTime } from "../../../services/queueService";
import { useStateAuthValue } from "../../../context/Auth/AuthState";
import { useStateProfConsultFilterValue } from "../../../context/profConsultFilterState";
import { GetProfessionalProfileById } from "../../../services/professionalService";
import { FilterType } from "../../../utils/FilterUtil";
import { ApplicationStyle } from "../../../theme";
import { showToast, ToastType } from "../../../utils/ToastUtil";
import { useToast } from "../../../context/Toast/ToastProvider";
import PhoneIcon from "@material-ui/icons/Phone";
import LoadingSpinner from "../../../components/LoadingSpinner";
import dialtone from "../../../assets/notifications/dialtone.mp3";
import CancelIcon from "@material-ui/icons/Clear";
import firebase from "../../../services/firebase";

const PatientResult = ({audioElement, stopRingCall}) => {
  const MedilineStyle = makeStyles(ApplicationStyle);
  const MedilineClasses = MedilineStyle();
  const toastDispatch = useToast();
  const [{ clickButton }, dispatch] = useStateProfConsultFilterValue();
  const [{ data, loading, stomp }] = useStateProfConsultFilterValue();
  const [{ userToken }] = useStateAuthValue();
  const [profName, setProfName] = useState("");
  //const [profImage, setProfimage] = useState();
  //eslint-disable-next-line
  const [queueLength, setQueueLength] = useState(0);
  const [disableCancelButton, setDisableCancelButton] = useState(true);

  useEffect(() => {
    if (clickButton === FilterType.SEARCH_PROFESSIONAL) {
      setTimeout(() => {
        setDisableCancelButton(false);
      }, 3000);
    }
  }, [clickButton]);

  useEffect(() => {
    if (data?.professionalId) {
      const getProfData = async () => {
        await GetProfessionalProfileById(userToken, data?.professionalId)
          .then((res) => {
            setProfName(res.data.first_name + " " + res.data.last_name);
            /* setProfimage(
              `data:image/png;base64,${res.data.professional_dto.photo_encode}`
            ); */
          })
          .catch((e) => {
            showToast(
              toastDispatch,
              e.message,
              ToastType.ERROR,
              ToastType.ERROR
            );
          });
      };
      getProfData();
    }
  }, [data]);

  useEffect(() => {
    getQueueInfo();
    const intervalId = setInterval(() => {
      getQueueInfo();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [data]);

  useEffect(() => {
    // Function to play the audio and loop it
    if (clickButton === FilterType.SEARCH_PROFESSIONAL) {
      // Play the audio
      audioElement.current.play();

      audioElement.current.addEventListener(
        "ended",
        () => {
          audioElement.current.currentTime = 0;
          audioElement.current.play();
        },
        false
      );
    } else {
      stopRingCall()
    }
    return stopRingCall
  }, [clickButton]);

  const getQueueInfo = async () => {
    if (data?.specialtyId && clickButton === FilterType.SEARCH_PROFESSIONAL) {
      await getEstimatedTime(userToken, data?.specialtyId, data?.queueId)
        .then((res) => {
          setQueueLength(res);
        })
        .catch((err) => {
          if (process.env.REACT_APP_DEBUG === "true") {
            const errorMessage =
              "Error:  ~ file: Result index.js:85 ~ getQueueInfo ~ err:" + err;
            showToast(
              toastDispatch,
              errorMessage,
              ToastType.ERROR,
              ToastType.ERROR
            );
          }
        });
    }
  };

  const handleConsultCancelation = () => {
    if (!disableCancelButton) {
      var wsMessage = {
        patientId: data?.patientId,
        orderNumber: data?.orderNumber,
        specialtyId: data?.specialtyId,
        command: "PatientCancel",
        professionalId: data?.professionalId,
        queueId: data?.queueId,
        date: data?.date,
        familymember: data?.familymember,
        reason: data?.reason,
      };
      firebase.put({...wsMessage, status: 'QUIT'});
      stomp.send("/app/message", {}, JSON.stringify(wsMessage));
      localStorage.setItem("consult", null);
      dispatch({
        type: "LOADING",
        clickButton: FilterType.FINISH_CONSULTATION,
        loading: false,
      });
    }
  };

  /* texto para cantidad de gente delante del paciente
   {queueLength !== 0 ? (<div style={{ padding: "10px"}}>
                     <p>Hay {queueLength} {queueLength === 1 ? "persona" : "personas"} delante de usted.</p>
                  </div>) : (<div style={{ padding: "10px"}}>
                     <p>No hay personas delante de usted.</p>
                     </div>)}
   */

  const renderResult = () => {
    if (clickButton === FilterType.VIRTUAL_GUARD_CALLED) {
      return null;
    }
    if (clickButton === FilterType.TEST_CONNECTION) {
      return <TestConnection />;
    }
    if (clickButton === FilterType.FINISH_CONSULTATION) {
      return <ResultDefault />;
    }
    if (loading) {
      return (
        <div className="list">
          <div className="result__title">
            {!loading && <h2>Profesionales</h2>}
          </div>
          <p className="text-red">Buscando profesionales ...</p>
          <LoadingSpinner />
        </div>
      );
    }
    if (clickButton === FilterType.SEARCH_PROFESSIONAL) {
      //
      return (
        <>
          <div className="result__title">
            {!loading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className={MedilineClasses.titleBlue}
                  style={{ margin: "1rem 0 2rem 0", fontSize: "20px" }}
                >
                  Llamando ...
                </div>
                <div>
                  <h3
                    style={{
                      fontSize: "15px",
                      padding: "10px",
                      fontWeight: "600",
                    }}
                  >
                    Nuestros profesionales se encuentran atendiendo. Aguarda
                    unos minutos, en breve seras llamado por uno de ellos.
                    Gracias...
                  </h3>
                </div>
                <div className={`phone`}>
                  <PhoneIcon className="phone-icon" fontSize="large" />
                </div>
                <div
                  onClick={handleConsultCancelation}
                  style={{
                    backgroundColor: disableCancelButton
                      ? "rgba(0, 0, 0, 0.26)"
                      : "#d22a2a",
                    padding: "10px",
                    marginTop: "20px",
                    width: "fit-content",
                    borderRadius: "40px",
                    position: "relative",
                    cursor: disableCancelButton ? "default" : "pointer",
                  }}
                >
                  <CancelIcon
                    className="icon"
                    style={{
                      fill: "#fff",
                      height: "40px",
                      width: "40px",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      );
    } else if (clickButton === FilterType.PROFESIONAL_FOUND && profName) {
      return null;
    } else {
      return <ResultDefault />;
    }
  };

  return (
    <div className="result">
      {!clickButton ? <ResultDefault /> : renderResult()}
    </div>
  );
};

export default PatientResult;
