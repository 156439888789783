const professionalFormModel = {
   alias: {
      name: 'alias',
      label: 'Alias',
   },
   first_name: {
      name: 'first_name',
      label: 'Nombre',
      stringErrorMsg: 'Ingresa sólo letras',
      requiredErrorMsg: 'El nombre es requerido',
   },
   last_name: {
      name: 'last_name',
      label: 'Apellido',
      requiredErrorMsg: 'El apellido es requerido',
   },
   sex_str: {
      name: 'sex_str',
      label: 'Sexo',
      requiredErrorMsg: 'El sexo es requerido',
      extraLabel: 'Como figura en DNI',
   },
   birth_date: {
      name: 'birth_date',
      label: 'Fecha de Nacimiento',
      minDate: '1900/01/01',
      minAgeErrorMsg: 'Debes ser mayor a 18 años',
      requiredErrorMsg: 'La fecha de nacimiento es requerida',
   },
   id_number: {
      name: 'id_number',
      label: 'DNI',
      minErrorMsg: 'Ingresa un DNI válido',
      maxErrorMsg: 'Ingresa un DNI válido',
      requiredErrorMsg: 'El DNI es requerido',
   },
   phone_number: {
      name: 'phone_number',
      label: 'Teléfono',
      minErrorMsg:
         'Ingresa un teléfono válido. Escribe el código de área sin 0 y el número sin 15. Ej. 1123456789',
      maxErrorMsg:
         'Ingresa un teléfono válido. Escribe el código de área sin 0 y el número sin 15. Ej. 1123456789',
      requiredErrorMsg: 'El teléfono es requerido',
      extraLabel: 'Código de área sin 0 y el número sin 15. Ej. 1123456789',
   },
   professional_dto: {
      photo_encode: {
         name: 'professional_dto.photo_encode',
         label: 'Foto',
         typeErrorMsg: 'El tipo de archivo no es válido',
         size: 500000,
         sizeErrorMsg:
            'Tamaño de archivo máximo permitido: 500 KB',
         requiredErrorMsg: 'La foto de perfil es requerida',
      },
      sign_photo: {
         name: 'professional_dto.sign_photo',
         label: 'Foto',
         typeErrorMsg: 'El tipo de archivo no es válido',
         size: 500000,
         sizeErrorMsg:
            'Tamaño de archivo máximo permitido: 500 KB',
         requiredErrorMsg: 'La firma del profesional es requerida',
      },
      licence_str: {
         name: 'professional_dto.licence_str',
         label: 'Matrícula',
         requiredErrorMsg: 'La matrícula es requerida',
      },
      type_licence_str: {
         name: 'professional_dto.type_licence_str',
         label: 'Tipo de Matrícula',
         requiredErrorMsg: 'El tipo de matrícula es requerido',
      },
      specialties: {
         name: 'professional_dto.specialties[0]',
         label: 'Especialidad',
         placeholder: 'Selecciona una especialidad',
         requiredErrorMsg: 'Al menos una especialidad es requerida',
      },
      academic_training: {
         name: 'professional_dto.academic_training',
         label: 'Formación Académica y Reconocimientos',
         requiredErrorMsg:
            'La formación académica y reconocimientos son requeridos',
      },
      attention_particular: {
         name: 'professional_dto.attention_particular',
         label: 'Particular',
         requiredErrorMsg: 'Por favor selecciona al menos una forma de atención'
      },
      prepaid_healths: {
         name: 'professional_dto.prepaid_healths',
         label: 'Obras Sociales',
         placeholder: 'Selecciona una o más obras sociales',
      },
   },
};

export default professionalFormModel;
