export default class Notification {
   // API model
   id;
   status;
   subject;
   description;
   creation_date;

   constructor(id, status, subject, description, creation_date) {
      this.id = id;
      this.status = status;
      this.subject = subject;
      this.description = description;
      this.creation_date = creation_date;
   }

   static NotificationDto(id, status, subject, description, creation_date) {
      return new Notification(id, status, subject, description, creation_date);
   }
}
