import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ListItemText, ListItem, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import VideocamIcon from '@material-ui/icons/Videocam';

import { useStateAuthValue } from '../../../../../context/Auth/AuthState';
import { useToast } from '../../../../../context/Toast/ToastProvider';
import { getConsultationByAppointment } from '../../../../../services/consultationService';
import { isVideocallReady } from '../../../../../utils/AppointmentUtil';
import { showToast, ToastType } from '../../../../../utils/ToastUtil';

const TurnListItem = ({ item, handleDelete }) => {
   const { id, professional_dto, hour, date, specialty, appointment_date } =
      item;
   const [{ userToken }] = useStateAuthValue();
   const history = useHistory();
   const toastDispatch = useToast();
   const [roomId, setRoomId] = useState('');
   const showVideocam = isVideocallReady(appointment_date);
   const videocallReady = showVideocam && !!roomId;
   const videocallMessage = videocallReady
      ? 'Acceder a turno'
      : 'El/la profesional todavía no ha iniciado la videoconsulta, por favor actualizá nuevamente en unos minutos';

   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, 'Error');
   };

   useEffect(() => {
      let isMounted = true;
      if (isMounted && showVideocam && userToken) {
         getData();
      }
      return () => {
         isMounted = false;
      };
   }, [showVideocam]);

   const getData = async () => {
      const errorMessage = 'Ocurrió un error la consulta para este turno';

      await getConsultationByAppointment(userToken, {
         appointmentId: id,
      })
         .then((response) => {
            if (response?.length > 2) {
               showError(errorMessage);
            } else {
               setRoomId(response[0]?.id);
            }
         })
         .catch(() => showError(errorMessage))
         .finally();
   };

   const handleClick = () => {
      setTimeout(() => {
         history.push('/paciente/videollamada?room=room' + roomId);
      }, 200);
   };

   const renderItemActions = () =>
      showVideocam ? (
         <div title={videocallMessage}>
            <IconButton
               onClick={handleClick}
               disabled={!videocallReady}
               className={`videocam-icon ${!videocallReady ? 'disabled' : ''}`}
            >
               <VideocamIcon />
            </IconButton>
         </div>
      ) : (
         <Tooltip title="Cancelar turno">
            <IconButton onClick={handleDelete}>
               <DeleteIcon />
            </IconButton>
         </Tooltip>
      );

   return (
      <ListItem className="list-item">
         <ListItemText
            className="item-text item-text--date-time"
            primary={hour || ''}
            secondary={date || ''}
         />
         <ListItemText
            className="item-text item-text--name"
            primary={professional_dto?.aliasOrFullName}
            secondary={specialty || ''}
         />
         <div className="icon-wrapper">{renderItemActions()}</div>
      </ListItem>
   );
};

export default TurnListItem;
