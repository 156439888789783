import React from 'react';

import NotificationList from './NotificationList';
import NotificationIcon from '../../assets/img/alarma.svg';

const Notifications = () => {
   return (
      <div className="notifications">
         <div className="header">
            <img src={NotificationIcon} alt="Notificaciones" />
            <h2>Notificaciones</h2>
         </div>
         <div className="container">
            <NotificationList />
         </div>
      </div>
   );
};

export default Notifications;
