import React from 'react';

import {
   Chip,
   IconButton,
   ListItem,
   ListItemSecondaryAction,
   ListItemText,
   Tooltip
} from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const ScheduleItem = ({
   schedule,
   index,
   remove,
   removeTooltip
}) => {
   const {attentionType, time, label, lastTimeAvailability} = schedule;

   return (
      <ListItem className="list-item list-item--time-availability" key={index}>
         <AccessTimeIcon />
         <div className="text-chip-wrapper">
            {/* FIXME: Remove hardcoded attention type, it's preventing time_availability created from mobile */}
            <Chip className={attentionType === 'Particular' ? "item-chip" : "item-chip-os"} label={attentionType} />
            <ListItemText className="item-text" primary={time} />
         </div>
         <ListItemText
            primary={label}
            secondary={`Programado hasta el día: ${lastTimeAvailability}`}
         />
         <ListItemSecondaryAction>
            <Tooltip title={removeTooltip}>
            <IconButton onClick={() => remove(index)}>
               <DeleteIcon />
            </IconButton>
            </Tooltip>
         </ListItemSecondaryAction>
      </ListItem>
   );
};

export default ScheduleItem;
