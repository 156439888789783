import ProfessionalWrapper from "@mediline/mediline-api-middleware/src/wrappers/professional.wrapper";
import { config} from '../../node_modules/@mediline/mediline-api-middleware/src/config/index';
import HttpClient from './fetch_api';

const pfw = new ProfessionalWrapper();

export const GetProfessional = async (token, params) =>
  HttpClient.get( `${config.api.user_profile.PROFESSIONAL_USER_PROFILE_ENDPOINT}/${params.professionalLogin}`, {
  Authorization: `Bearer ${token}`
});

export const ProfessionalPhoto = async (userId, token, displayError = null) =>
    pfw.professionalPhoto(token,userId, displayError);

export const GetProfessionalProfileById = async (token, id) =>
    pfw.getProfessionalProfileById(token,id);

export const ProfessionalProfile = async (params, token, displayError = null) =>
	pfw.professionalProfile(token, params, displayError);

export const PutVirtualGuard = async (professionalId, status, userToken, displayError = null) =>
	pfw.putVirtualGuard(userToken,professionalId,{virtualGuardAvailability: status},displayError);

export const GetProfessionalAvailable = async (params, userToken, displayError = null) =>
	pfw.getProfessionalAvailable(userToken,params, displayError);

export const GetSelectedProfessionals = async (params, token, displayError = null) =>
    pfw.getSelectedProfessionals(token, params, displayError);
