import React from 'react';
import PropagateLoader from "react-spinners/PropagateLoader";

const LoadingSpinner = () => {
   return (
      <div className="loading-spinner">
         <PropagateLoader
           color={"#ff4747"}
           loading={true}
           size={15}
           aria-label="Loading Spinner"
           data-testid="loader"
         />      
      </div>
   );
};

export default LoadingSpinner;
