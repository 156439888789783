import React from 'react';
import { useHistory } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { IconButton, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import LoginSlider from './LoginSlider';
import AuthFormLogin from '../../components/AuthFormLogin';
import AuthFormSignup from '../../components/AuthFormSignup';
import Activate from '../../components/Activate';
import ActivateNewPass from '../../components/ActivateCreatePass';
import CreatedAccount from '../../components/CreatedAccount';
import RecoverPass from '../../components/RecoverPass';
import NewPass from '../../components/NewPass';
import RecoverMailSent from '../../components/RecoverMailSent';
import ResetedPass from '../../components/ResetedPass';

export const STATE_LOGIN = 2;
export const STATE_SIGNUP = 1;
export const STATE_CREATED = 3;
export const STATE_ACTIVATE = 4;
export const STATE_RECOVER = 5;
export const STATE_RECOVER_FINISH = 6;
export const STATE_RECOVER_MAIL_SENT = 7;
export const STATE_RESETED_PASS = 8;
export const STATE_ACTIVATE_CREATE_NEW_PASS = 9;


const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  keyBackSpaceIcon: {
    color: '#002C54',
  },
  greeting: {
    color: '#002C54',
    float: 'right',
    fontSize: '50px',
    fontWeight: 'bold',
  },
  formHeadConent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
  },
}));

const SignInSide = ({ authState, roleAssigned, location }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid component="main" className="login">
      <CssBaseline />
      <Grid item className="slider">
        <LoginSlider />
      </Grid>
      <Grid item className="form">
        <div className={classes.paper}>
          <div className={classes.formHeadConent}>
          <IconButton aria-label="volver" onClick={() => authState > 2? history.push('') : history.goBack()}>
              <KeyboardBackspaceIcon fontSize="large" className={classes.keyBackSpaceIcon}/>
            </IconButton>
            <Typography className={classes.greeting}>Hola!</Typography>
          </div>

          <Grid container justifyContent="center">
            <Grid item xs={12} sm={7} md={7}>
              {authState === STATE_LOGIN && <AuthFormLogin />}
              {authState === STATE_SIGNUP && <AuthFormSignup roleAssigned={roleAssigned}/>}
              {authState === STATE_ACTIVATE && <Activate location={location}/>}
              {authState === STATE_CREATED && <CreatedAccount/>}
              {authState === STATE_RECOVER && <RecoverPass/>}
              {authState === STATE_RECOVER_FINISH && <NewPass location={location}/>}
              {authState === STATE_RECOVER_MAIL_SENT && <RecoverMailSent/>}
              {authState === STATE_RESETED_PASS && <ResetedPass/>}
              {authState === STATE_ACTIVATE_CREATE_NEW_PASS && <ActivateNewPass location={location}/> }
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default SignInSide;
