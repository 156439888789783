import AppointmentWrapper from "@mediline/mediline-api-middleware/src/wrappers/appointment.wrapper";

const aw = new AppointmentWrapper();
const createAppointment = async (params, token, displayError = null) => aw.createAppointment(token, params, displayError);

const getAppointments = async (params, token, displayError = null) => await aw.getAppointments(token, params, displayError);

const putAppointment = async (id, body, token, displayError = null) => aw.putAppointment(token, id, body, displayError);

export {createAppointment, getAppointments, putAppointment};
