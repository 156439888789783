import React,{useState} from "react";
import Modal from "./Modal";
import { Grid, Button } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { ApplicationStyle } from "../theme";
import { makeStyles } from "@material-ui/core/styles";

const MedilineStyle = makeStyles(ApplicationStyle);
const TechnicalDifficultiesModal = ({
  open,
  setOpen,
  title,
  subtitle,
  setValue,
  confirmButton,
  cancelButton,
  handleCloseParent,
  outlinedCancel = false,
  outlinedConfirm = false,
}) => {
  const MedilineClasses = MedilineStyle();
  const [difficulty, setDifficulty] = useState();

  const handleClose = async (value) => {
    setOpen(false);
    if (value) {
      setValue(difficulty);
      handleCloseParent();
    }
 };

  return (
    <Modal isActive={open} handleClose={handleClose}>
      <div className={MedilineClasses.btnBoxCenterM0}>
        <div className={MedilineClasses.titleBlue}>{title}</div>
        { subtitle && (
          <div className={MedilineClasses.subtitleBlue}>{subtitle}</div>
        )}
        <div className={MedilineClasses.btnSpacing}>
        <TextField
          id="standard-multiline-static"
          label="Relate el problema"
          multiline
          minRows={4}
          variant="filled"
          style={{width : "80%"}}
          fullWidth={false}
          inputProps={{ maxLength: 256 }}
          onChange={(evt) => setDifficulty(evt.target.value)}
        />
          <Grid container spacing={3} style={{marginTop: "40px"}}>
            <Grid item xs={6}>
              <Button className={outlinedCancel? MedilineClasses.btnRedNextOutline : MedilineClasses.btnSubmitRed}
                onClick={() => handleClose(false)}>
                 {cancelButton}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={outlinedConfirm? MedilineClasses.btnBlueOutline : MedilineClasses.btnBlueNext}
                onClick={() => handleClose(true)}
              >
                {confirmButton}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};

export default TechnicalDifficultiesModal;
