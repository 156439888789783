import React from 'react';

import { srcFromFile } from '../../../../utils/FormUtil';

const ProfileSignPhotoDetail = ({ formValues }) => {
   const {
      professional_dto: { sign_photo },
   } = formValues;

   return (
      <div className="detail">
         <div className="header header--detail">
            <h2>Firma Profesional</h2>
         </div>
         <div className="data-wrapper profile-photo">
            <img
               className="photo"
               src={srcFromFile(sign_photo)}
               alt="Foto de perfil"
            />
         </div>
      </div>
   );
};

export default ProfileSignPhotoDetail;
