import React from "react";
import { FormControl, TextField, Grid} from "@material-ui/core";
import { MINUTES_INTERVAL } from "../../../../conf/appSetting";

export default function TimeRange({values, defaultDate, setFieldValueStart, setFieldValueEnd}) {
  const intervalProps = { step: MINUTES_INTERVAL * 60 };
  return (
    <>
      <Grid item sm={4} style={{marginLeft: 20}}>
        <FormControl style={{minWidth: 40}}>
          <TextField
            id="time"
            label="Desde"
            type="time"
            defaultValue={values.checkIn || defaultDate}
            onChange={(ev) => setFieldValueStart(ev.target.value)}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={intervalProps}
          />
        </FormControl>
        </Grid>
        <Grid item sm={2}></Grid>
        <Grid item sm={4} style={{ marginLeft: 20 }}>
        <FormControl style={{minWidth: 40}}>
          <TextField
            id="time"
            label="Hasta"
            type="time"
            defaultValue={values.checkOut}
            onChange={(ev) => setFieldValueEnd(ev.target.value)}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={intervalProps}
          />
        </FormControl>
      </Grid>
    </>
  );
}
