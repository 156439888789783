import React,{ useEffect, useState } from "react";
import { useStateProfessionalDashboardValue } from "../../context/profesionalPage/profesionalDashboardState";
import ActionPanel from "../../pages/professional/VideoCall/ActionPanel";
import axios from "axios";
import { Call } from "./Call";
import TimeWatch from "../timeWatch/index";
import { useToast } from "../../context/Toast/ToastProvider";
import { showToast, ToastType } from "../../utils/ToastUtil";


const VideoCallProfesional = ({ videoCallData, setVideoCall, setMessage }) => {
  const [token, setToken] = useState("");
  const [patientLeftContainerStyle, setPatientLeftContainerStyle] = useState({});
  const [consultFinishedWithError,setConsultFinishedWithError] = useState(false);
  const [consultIsSubmitted ,setConsultIsSubmitted] = useState(false);
  const [{ stomp }] = useStateProfessionalDashboardValue();
  const [time, setTime] = useState(0);
  const toastDispatch = useToast();
  const [
    patientLeftProfessionalContainerStyle,
    setPatientLeftProfessionalContainerStyle,
  ] = useState({ display: "flex" });
  const [patientFinish, setPatientFinish] = useState(false);
  const [localTracks, setLocalTracks] = useState({
    audioTrack: null,
    videoTrack: null,
  });

  useEffect(() => {
    if(videoCallData.channel && videoCallData.uid && videoCallData.role){
      const getToken = async () => {
      const url = `${process.env.REACT_APP_AGORA_TOKEN_SERVICE}/rtc/${videoCallData.channel}/${videoCallData.role}/uid/${videoCallData.uid}`;
      try {
        const response = await axios.get(url);
        const token = response.data.rtcToken;
        setToken(token);
      } catch (err) {
        if(process.env.REACT_APP_DEBUG === "true"){
          const errorMessage = "Error:  ~ file: videoCallProfesional.js:28 ~ getToken ~ err" + err;
          showToast(toastDispatch, errorMessage, ToastType.ERROR, ToastType.ERROR);
         }
      }
      };
      getToken();
    }
    
  }, [videoCallData]);
  
  return (
    token && (
      <div>
          <div className="videocall">
            <div className="container" style={patientLeftContainerStyle}>
             <Call
              rtcProps={{
                appId: process.env.REACT_APP_AGORA_APP_ID,
                channel: videoCallData.channel,
                token: token,
                uid: videoCallData.uid,
              }}
              localTracks={localTracks}
              setLocalTracks={setLocalTracks}
              setContainerStyle={setPatientLeftContainerStyle}
              setPatientFinish={setPatientFinish}
              patientLeftProfessionalContainerStyle={patientLeftProfessionalContainerStyle}
              setPatientLeftProfessionalContainerStyle={setPatientLeftProfessionalContainerStyle}
              setVideoCall={setVideoCall}
              setMessage={setMessage}
              consultIsSubmitted={consultIsSubmitted}
              consultFinishedWithError={consultFinishedWithError}
              stomp={stomp}
              consultDuration={time}
              />
              <div style={{height: "80vh"}}>
                <TimeWatch setTime={setTime} isVideocallFinished={consultIsSubmitted}/>
                <ActionPanel setPatientFinish={setPatientFinish} patientFinish={patientFinish} setContainerStyle={setPatientLeftContainerStyle} setPatientLeftProfessionalContainerStyle={setPatientLeftProfessionalContainerStyle} setConsultFinishedWithError={setConsultFinishedWithError} stomp={stomp} data={videoCallData} setConsultIsSubmitted={setConsultIsSubmitted} setMessage={setMessage}/>
              </div>
            </div>
          </div>
      </div>
      
    )
  );
};

export default VideoCallProfesional;
