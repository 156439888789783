const professionalInitialValues = {
   alias: '',
   first_name: '',
   last_name: '',
   sex_str: '',
   birth_date: '',
   id_number: '',
   phone_number: '',
   professional_dto: {
      licence_str: '',
      type_licence_str: '',
      specialties: [],
      academic_training: '',
      attention_particular: false,
      prepaid_healths: [],
   },
};

export default professionalInitialValues;
