import React from 'react';

import Chart from './Chart';
import Information from './Information';
import Payment from './Payment';
import HeaderImage from '../../../assets/img/cobros-particulares-y-seguros.svg';

const Liquidation = () => (
   <div className="liquidation">
      <div className="header">
         <img src={HeaderImage} alt="Liquidaciones" />
         <h2>Liquidaciones</h2>
      </div>
      <div className="column">
         <Chart />
      </div>
      <div className="column">
         <Payment />
      </div>
      <div className="column column--information">
         <Information />
      </div>
   </div>
);

export default Liquidation;
