import React, { useEffect, useState } from 'react';
import { List } from '@material-ui/core';

import NotificationItem from './NotificationItem';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useStateAuthValue } from '../../context/Auth/AuthState';
import { useToast } from '../../context/Toast/ToastProvider';
import Notification from '../../models/Notification';
import { NotificationStatus } from '../../models/enums';
import {
   getNotifications,
   processNotification,
   putNotification,
} from '../../services/notificationService';
import { removeIndexFromList } from '../../utils/FormUtil';
import { OK } from '../../utils/ServiceUtil';
import { showToast, ToastType } from '../../utils/ToastUtil';

const NotificationList = () => {
   const [{ userToken }] = useStateAuthValue();
   const [notificationList, setNotificationList] = useState([]);
   const [selectedNotification, setSelectedNotification] = useState();
   const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
   const [loading, setLoading] = useState(true);
   const toastDispatch = useToast();

   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, 'Error');
   };

   useEffect(() => {
      let isMounted = true;
      if (userToken) {
         getData(isMounted);
      }
      return () => (isMounted = false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userToken]);

   const getData = async (isMounted) => {
      if (isMounted) {
         setLoading(true);
      }
      const errorMessage = 'Ocurrió un error en el servicio de notificaciones';

      await getNotifications(
         {
            means: 'INBOX',
            statuses: [
               NotificationStatus.NEW,
               NotificationStatus.PROCESSING,
               NotificationStatus.ACCEPTED,
            ],
            sort: 'creationDate,desc',
         },
         userToken,
         showError,
      )
         .then((response) => {
            if (isMounted && OK === response?.status) {
               const notificationDtos = response.data.map((notification) =>
                  Notification.NotificationDto(
                     notification.id,
                     notification.status,
                     notification.subject,
                     notification.description,
                     notification.creation_date,
                  ),
               );
               setNotificationList(notificationDtos);

               let notificationNewList = notificationDtos
                  .filter(({ status }) => status === NotificationStatus.NEW)
                  .map(({ id }) => id);

               if (notificationNewList.length) {
                  processNotification(
                     {
                        status: NotificationStatus.PROCESSING,
                        notificationDeliveries: notificationNewList,
                     },
                     userToken,
                     showError,
                  )
                     .then((res) => {
                        if (OK !== res?.status) {
                           showError(errorMessage);
                        }
                     })
                     .catch(() => showError(errorMessage))
                     .finally(() => {
                        notificationNewList = [];
                     });
               }
            } else {
               showError(errorMessage);
            }
         })
         .catch(() => showError(errorMessage))
         .finally(() => setLoading(false));
   };

   const handleStatusChange = async (
      statusToChange,
      selectedNotificationId = '',
   ) => {
      const errorMessage =
         'Ocurrió un error en la actualización de la notificación';
      const isDeleted = statusToChange === NotificationStatus.DELETED;
      const notificationId = isDeleted
         ? selectedNotification?.id
         : selectedNotificationId;

      if (isDeleted) {
         setLoading(true);
      }

      await putNotification(
         {
            status: statusToChange,
            id: notificationId,
         },
         userToken,
         showError,
      )
         .then((response) => {
            if (OK === response?.status) {
               if (isDeleted) {
                  removeIndexFromList(
                     selectedNotification.index,
                     notificationList,
                     setNotificationList,
                  );
                  setSelectedNotification(null);
               }
            } else {
               showError(errorMessage);
            }
         })
         .catch(() => showError(errorMessage))
         .finally(() => {
            if (isDeleted) {
               setLoading(false);
            }
         });
   };

   const handleClose = (confirmResponse) => {
      setOpenConfirmationModal(false);

      if (confirmResponse) {
         handleStatusChange(NotificationStatus.DELETED);
      }
   };

   const renderList = () => {
      if (notificationList.length) {
         return (
            <div className="list">
               <List>
                  {notificationList.map((notification, index) => (
                     <NotificationItem
                        notification={notification}
                        key={index}
                        handleDelete={() => {
                           notification.index = index;
                           setSelectedNotification(notification);
                           setOpenConfirmationModal(true);
                        }}
                        handleRead={() =>
                           handleStatusChange(
                              NotificationStatus.ACCEPTED,
                              notification?.id,
                           )
                        }
                     />
                  ))}
               </List>
            </div>
         );
      } else {
         return (
            <div className="empty">
               <p className="message">No hay notificaciones que mostrar</p>
            </div>
         );
      }
   };
   return (
      <div className="notification-list">
         {loading ? <LoadingSpinner /> : renderList()}
         <ConfirmationModal
            open={openConfirmationModal}
            handleClose={handleClose}
            title="¿Deseas borrar la notificación?"
            confirmButton="Confirmar"
            cancelButton="Volver"
            maxWidth="sm"
         />
      </div>
   );
};

export default NotificationList;
