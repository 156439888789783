import React from 'react';

import ScheduleList from './ScheduleList';
import AvailabilityIcon from '../../../assets/img/disponibilidad.svg';

const TimeAvailabilitySchedule = () => {
   return (
      <div className="schedule">
         <div className="header">
            <img src={AvailabilityIcon} alt="Mi Disponibilidad" />
            <h2>Mi Disponibilidad</h2>
         </div>
         <div className="container">
            <ScheduleList />
         </div>
      </div>
   );
};

export default TimeAvailabilitySchedule;
