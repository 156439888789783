import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  IconButton,
  Icon,
  Button,
  makeStyles,
  Grid,
  SvgIcon,
  FormControlLabel,
  Checkbox,
  withStyles,
  Paper,
  Link,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

import RoleIcon from './RoleIcon/index';
import FormikField from '../FormikField';
import PasswordFields from '../components/PasswordFields';
import { useToast } from '../context/Toast/ToastProvider';
import { Role } from '../models/enums';
import { UserFirstRegister } from '../services/apiService';
import PasswordScheme from './PasswordScheme';
import {
  AuthGoogle,
  AUTH_SUBFIX_GATEWAY,
  getDecodedToken,
  Signup,
} from '../services/authService';
import { showToast, ToastType } from '../utils/ToastUtil';

const useStyles = makeStyles((theme) => ({
  submit: {
    backgroundColor: '#DA2037',
    '&:hover': {
      backgroundColor: '#e84353',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 36,
      marginRight: -56,
    },
    margin: theme.spacing(3, 0, 2),
    borderRadius: '20px',
    textTransform: 'capitalize',
  },
  btnSecondary: {
    backgroundColor: '#002C54',
    '&:hover': {
      backgroundColor: '#003f77',
    },
    '& .MuiButton-startIcon': {
      marginLeft: -36,
      marginRight: 36,
    },
    margin: theme.spacing(0, 0, 4),
    borderRadius: '20px',
    textTransform: 'capitalize',
  },
  register: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: '20px',
    borderColor: 'white',
    textTransform: 'capitalize',
    color: '#002C54',
  },
  checkLabel: {
    fontSize: '10px',
  },
  paperForm: {
    padding: '20px',
  },
  contentCenter: {
    textAlign: 'center',
  },
  roleIconWrapper: {
    marginBottom: 20,
  },
  roleTitle: {
    textAlign: 'center',
    color: '#002C54',
  },
}));

const initialValuesRegister = {
  email: '',
  password: '',
  passwordConfirmation: '',
};
const initialValuesRegisterProfessional = {
  dni: '',
};
const RegisterScheme = Yup.object().shape({
  email: Yup.string()
    .email('Debes ingresar un email válido')
    .required('El email es requerido'),
  ...PasswordScheme,
});
const RegisterProfessionalScheme = Yup.object().shape({
  dni: Yup.string()
    .min(7, 'Ingresa un DNI válido')
    .max(8, 'Ingresa un DNI válido')
    .required('El DNI es requerido'),
});

const AuthFormSignup = ({ roleAssigned }) => {
  const classes = useStyles();
  const history = useHistory();
  const [firstStepState, setFirstStepState] = useState(true);
  const [termConditions, setTermConditions] = useState(false);
  const [email, setEmail] = useState('');
  const [tempPass, setTempPass] = useState('');
  const toastDispatch = useToast();
  const [roleSelected, setRoleSelected] = useState(null);
  const isProfessional = roleAssigned === Role.PROFESSIONAL;
  const isPatient = roleAssigned === Role.PATIENT;

  // From GmailCallback
  const { id_token } = useParams();
  const isIdToken = id_token?.length;

  // To set the RoleIcon active according to the current selected role
  const activeRoleIcon = (role) => role === roleSelected;

  useEffect(() => {
    if (isIdToken && isPatient) {
      const googleData = getDecodedToken(id_token);
      const userEmail = googleData?.sub;
      setEmail(userEmail);
      setTempPass(null);
      setFirstStepState(false);
    }
  }, [id_token, isPatient]);

  useEffect(() => {
    if (roleAssigned === Role.PROFESSIONAL) {
      setRoleSelected(Role.PROFESSIONAL);
      activeRoleIcon(Role.PROFESSIONAL);
    }
    if (roleAssigned === Role.PATIENT) {
      setRoleSelected(Role.PATIENT);
      activeRoleIcon(Role.PATIENT);
    }
  }, [roleAssigned]);

  const handleCheckTermCondition = () => setTermConditions(!termConditions);

  const handleSubmitRegisterFirst = async (event) => {
    setEmail(event?.email);
    setTempPass(event?.password);
    setFirstStepState(false);
  };

  const backFirstStep = () => setFirstStepState(!firstStepState);

  const goLogin = () => history.push('/login');

  const showError = (msg) =>
    showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);

  const handleSubmitRegisterSecond = async (values, actions) => {
    const msgError =
      'Error durante la registración, por favor revise los datos ingresados.';

    const userRole =
      roleSelected === Role.PATIENT
        ? `ROLE_${Role.PATIENT}`
        : `ROLE_${Role.PROFESSIONAL}`;

    if (isIdToken && isPatient) {
      await Signup({
        authorities: [`ROLE_${Role.PATIENT}`],
        idNumber: values?.dni,
        email: email,
        password: tempPass,
        login: email,
        authProvider: 'Google',
      })
        .then((res) => {
          if (res.status === 'error' && res.data.status === 400) {
            showError(msgError);
          } else {
            showToast(
              toastDispatch,
              '¡Cuenta creada exitosamente!',
              ToastType.SUCCESS
            );
            history.push('cuenta-creada');
          }
        })
        .catch(() => showError(msgError));
    } else {
      await UserFirstRegister({
        method_auth: AUTH_SUBFIX_GATEWAY,
        active_role: userRole,
        id_number: values?.dni,
        email: email,
        password: tempPass,
        username: email?.substring(0, email?.lastIndexOf('@')),
      })
        .then((res) => {
          if (res.status === 'error' && res.data.status === 400) {
            showError(msgError);
          } else {
            showToast(
              toastDispatch,
              '¡Cuenta creada exitosamente!',
              ToastType.SUCCESS
            );
            history.push('cuenta-creada');
          }
        })
        .catch(() => showError(msgError));
    }
  };

  const BlueCheckbox = withStyles({
    root: {
      '&$checked': {
        color: blue[600],
      },
    },
    checked: {},
  })((checkboxProps) => <Checkbox color="default" {...checkboxProps} />);

  const RegisterForm = (
    <Formik
      initialValues={initialValuesRegisterProfessional}
      onSubmit={
        firstStepState ? handleSubmitRegisterFirst : handleSubmitRegisterSecond
      }
      validationSchema={RegisterProfessionalScheme}
    >
      {({ dirty, isValid }) => {
        return (
          <Form>
            <Paper elevation={3} className={classes.paperForm}>
              <FormikField name="dni" label="DNI*" />
              <FormControlLabel
                control={
                  <BlueCheckbox
                    checked={termConditions}
                    onChange={handleCheckTermCondition}
                    name="checkedTermConditions"
                  />
                }
                label={
                  <span style={{ fontSize: '12px' }}>
                    Declaro haber leído y aceptado los{' '}
                    <Link href="/terminos-y-condiciones" target="_blank">
                      Términos y Condiciones
                    </Link>
                    .
                  </span>
                }
                className={classes.checkLabel}
              />
            </Paper>
            <Button
              disabled={!(isValid && dirty && roleSelected && termConditions)}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={<Icon>chevron_right</Icon>}
            >
              Crear Cuenta
            </Button>
            {isIdToken && isPatient && (
              <Button
                startIcon={<Icon>chevron_left</Icon>}
                fullWidth
                variant="contained"
                color="primary"
                onClick={backFirstStep}
                className={classes.btnSecondary}
              >
                Volver
              </Button>
            )}
          </Form>
        );
      }}
    </Formik>
  );

  const secondStep = (
    <>
      {/* <h2 className={classes.roleTitle}>Este es tu rol:</h2> */}
      <Grid
        className={classes.roleIconWrapper}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {isProfessional ? (
          <Grid item xs={6} className={classes.contentCenter}>
            <RoleIcon
              setRoleSelected={setRoleSelected}
              role={Role.PROFESSIONAL}
              active={activeRoleIcon(Role.PROFESSIONAL)}
            />
          </Grid>
        ) : (
          <Grid item xs={6} className={classes.contentCenter}>
            <RoleIcon
              setRoleSelected={setRoleSelected}
              role={Role.PATIENT}
              active={activeRoleIcon(Role.PATIENT)}
            />
          </Grid>
        )}
      </Grid>
      {RegisterForm}
    </>
  );

  const firstStep = (
    <Formik
      initialValues={initialValuesRegister}
      onSubmit={handleSubmitRegisterFirst}
      validationSchema={RegisterScheme}
    >
      {({ dirty, isValid }) => {
        return (
          <Form>
            <Paper elevation={3} className={classes.paperForm}>
              <FormikField name="email" label="Email" />
              <PasswordFields />
            </Paper>
            <Button
              disabled={!(isValid && dirty)}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={<Icon>chevron_right</Icon>}
            >
              Continuar
            </Button>
            <Button
              startIcon={<Icon>chevron_left</Icon>}
              fullWidth
              variant="contained"
              color="primary"
              onClick={goLogin}
              className={classes.btnSecondary}
            >
              Ya tengo cuenta
            </Button>
            {isIdToken &&
              isPatient &&
              process.env.REACT_APP_ENABLE_GOOGLE_SIGNUP === 'true' && (
                <Grid container justifyContent="center">
                  <Grid item>
                    <IconButton onClick={AuthGoogle}>
                      <SvgIcon
                        width="41"
                        height="40"
                        viewBox="0 0 41 40"
                        fill="none"
                      >
                        <path
                          d="M9.60938 20C9.60938 18.0182 10.169 16.1617 11.1413 14.5721V7.82932H4.22997C1.48689 11.3049 0 15.5408 0 20C0 24.4592 1.48689 28.6951 4.22997 32.1707H11.1413V25.4279C10.169 23.8383 9.60938 21.9818 9.60938 20Z"
                          fill="#FBBD00"
                        />
                        <path
                          d="M20.5 30.625L15.6953 35.3125L20.5 40C25.0708 40 29.4125 38.5494 32.975 35.8732V29.1376H26.071C24.4274 30.0896 22.5164 30.625 20.5 30.625Z"
                          fill="#0F9D58"
                        />
                        <path
                          d="M11.1414 25.4279L4.22998 32.1707C4.77307 32.8588 5.36445 33.5178 6.00435 34.1422C9.87629 37.9196 15.0243 40 20.5 40V30.625C16.5262 30.625 13.0434 28.5375 11.1414 25.4279Z"
                          fill="#31AA52"
                        />
                        <path
                          d="M41 20C41 18.7832 40.8871 17.5641 40.6643 16.3768L40.4841 15.4159H20.5V24.7909H30.2256C29.2812 26.6238 27.8291 28.1192 26.0709 29.1376L32.9749 35.8733C33.6802 35.3434 34.3557 34.7665 34.9957 34.1422C38.8676 30.3646 41 25.3422 41 20Z"
                          fill="#3C79E6"
                        />
                        <path
                          d="M28.2009 12.487L29.0502 13.3155L35.8451 6.68648L34.9957 5.85789C31.1238 2.08039 25.9758 0 20.5 0L15.6953 4.6875L20.5 9.375C23.4089 9.375 26.1438 10.4802 28.2009 12.487Z"
                          fill="#CF2D48"
                        />
                        <path
                          d="M20.5 9.375V0C15.0242 0 9.87625 2.08039 6.00423 5.85781C5.36433 6.48211 4.77295 7.14109 4.22986 7.8293L11.1412 14.5721C13.0433 11.4625 16.5262 9.375 20.5 9.375Z"
                          fill="#EB4132"
                        />
                      </SvgIcon>
                    </IconButton>
                  </Grid>
                </Grid>
              )}
          </Form>
        );
      }}
    </Formik>
  );

  return (
    <>
      {firstStepState && firstStep}
      {!firstStepState && secondStep}
    </>
  );
};

export default AuthFormSignup;
