export const initialStateProf = {
   clickButton: null,
   data: [],
   stomp: [],
   filterForm:[],
   turn:[]
};

const ProfConsultFilterReducer = (state, action) => {
   switch (action.type) {
      case 'SAVE_TURN':
         return {
            ...state,
            turn: action.turn,
         };
      case 'LOADING':
         return {...state, clickButton: action.clickButton, loading: action.loading};
      case "VIRTUAL_GUARD":
         return{
            ...state,
            data: action.data,
            stomp : action.stomp
         }
      case 'SET_FILTER_FORM':
      return {
         ...state,
         clickButton: action.clickButton,
         data: action.data,
         attentionType: action.attentionType,
         filterForm: action.filterForm,
      };

      default:
         return state;
   }


};

export default ProfConsultFilterReducer;
