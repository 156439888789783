import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FinishRecoverPass } from '../services/authService';
import { useToast } from '../context/Toast/ToastProvider';
import PasswordFields from '../components/PasswordFields';
import { showToast, ToastType } from '../utils/ToastUtil';
import queryString from 'query-string';
import PasswordScheme from './PasswordScheme';

const useStyles = makeStyles((theme) => ({
  submit: {
    backgroundColor: '#DA2037',
    margin: theme.spacing(3, 0, 2),
    borderRadius: '20px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#e84353',
    }
  },
  paper: {
    justifyContent: 'center',
    height: 200,
  },
  paperForm: {
    padding: '20px',
  },
  title: {
    color: '#002C54'
  }
}));

const ActivateNewPass = ({location}) => {
  const classes = useStyles();
  const history = useHistory();
  const toastDispatch = useToast();
  const initialValuesRecover = {
    password: '',
    passwordConfirmation: ''
  };

  const handleSubmitRecoverPass = (values) => {
    const params = queryString.parse(location.search)
   FinishRecoverPass({...params, newPassword: values?.passwordConfirmation})
      .then(() => {
        history.push({pathname:"/activate", state:{activated:true}})
      })
      .catch((error) => {
        let message = 'Ocurrió un error guardando la contraseña';
        if (error.data && (error.data.message || error.data.description)) {
          message = error.data.message || error.data.description;
        }
        showToast(toastDispatch, message, ToastType.ERROR, ToastType.ERROR);
      })
  };

  const RecoverScheme = Yup.object().shape(PasswordScheme);


  return (
    <>
    <div style={{ color: "#002C54" }}>
      <h3>Para activar tu cuenta, Ingresá tu contraseña</h3>
    </div>
      <Formik
        initialValues={initialValuesRecover}
        onSubmit={(values) => handleSubmitRecoverPass(values)}
        validationSchema={RecoverScheme}
      >
        {({isValid, dirty}) => {
            return (
              <Form>
                <Paper elevation={3} className={classes.paperForm}>
                  <PasswordFields/>
                </Paper>
                <Button
                  type="submit"
                  disabled={!(isValid && dirty)}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  endIcon={<Icon>chevron_right</Icon>}
                >
                  Guardar Contraseña
                </Button>
              </Form>
        )}}
      </Formik>
    </>
  );
}

export default ActivateNewPass;
