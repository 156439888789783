import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
   Avatar,
   Divider,
   List,
   ListItem,
   ListItemIcon,
   ListItemText
} from '@material-ui/core';
import ExitToApp from '@material-ui/icons/ExitToApp';
import LockOutlined from '@material-ui/icons/LockOutlined';
import AppointmentsIcon from '../../../../assets/img/menu-appointments-icon.svg';
import PlanCardIcon from "../../../../assets/img/identification-card.svg";
import AppointmentScheduleIcon from '../../../../assets/img/menu-appointment-schedule-icon.svg';
import AvailabilityIcon from '../../../../assets/img/menu-availability-icon.svg';
import HelpIcon from '../../../../assets/img/menu-help-icon.svg';
import HomeIcon from '../../../../assets/img/menu-home-icon.svg';
import MedicalRecordIcon from '../../../../assets/img/menu-medical-record-icon.svg';
import OrdersIcon from '../../../../assets/img/menu-orders-icon.svg';
import ProfileIcon from '../../../../assets/img/menu-profile-icon.svg';
import FamilyIcon from "../../../../assets/img/family.svg"
import RecipesIcon from '../../../../assets/img/menu-recipes-icon.svg';
import TermsAndConditionsIcon from '../../../../assets/img/menu-terms-and-conditions-icon.svg';
import { useStateAuthValue } from '../../../../context/Auth/AuthState';
import { Role, ProfessionalGuardEnum } from '../../../../models/enums';
import { GetSessionToken, GetUserBySession, Logout, ProfessionalLog } from '../../../../services/authService';
import { srcFromFile } from '../../../../utils/FormUtil';
import packageJson from '../../../../../package.json';
import { PutVirtualGuard } from '../../../../services/professionalService';
import Professional from '../../../professional';
import { GetUserProfile } from '../../../../services/apiService';
import { showToast } from '../../../../utils/ToastUtil';
import { useProfessionalLogState } from '../../../../context/profesionalPage/professionalLog/professionalLogState';

const HeaderMenu = ({ setOpen }) => {
   const history = useHistory();
   const [{ userData, userToken }, dispatch] = useStateAuthValue();
   const [state, dispatchProfessionalLog = dispatch] = useProfessionalLogState();
   const userProfile = GetUserBySession();
   const currentRole = userData?.active_role;
   const isPatient = currentRole === Role.PATIENT;
   const isProfessional = currentRole === Role.PROFESSIONAL;
   const [userPhoto, setUserPhoto] = useState();
   const [userDashboardLink, setUserDashboardLink] = useState();
   const [userRole, setUserRole] = useState();
   const [fullProfile, setfullProfile] = useState(false);
   

   useEffect(() => {
      if (userData && userProfile) {
         if (isProfessional) {
            setfullProfile(userData?.professional_dto?.full_profile);
            setUserRole('Profesional');
            setUserDashboardLink('/profesional');
            setUserPhoto(
               srcFromFile(userData?.professional_dto?.photo_encode) ||
               userProfile?.picture,
            );
         } else if (isPatient) {
            setfullProfile(userData?.patient_dto?.full_profile);
            setUserRole('Paciente');
            setUserDashboardLink('/paciente');
         }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userData, userProfile, isProfessional, isPatient]);

   const useStyles = makeStyles(() => ({
      avatar: {
         boxShadow: '0px 0px 3px #ffffff',
         marginBottom: '5px',
      },
      exitToApp: {
         color: '#da2037',
         '& svg': {
            fill: '#da2037',
         },
      },
      menu: {
         display: 'flex',
         flexDirection: 'column',
         height: '100%',
         justifyContent: 'space-between',
         minWidth: '350px',
      },
      menuList: {
         backgroundColor: 'white',
         color: '#002c54',
         '& svg': {
            fill: '#002c54',
         },
      },
      menuTop: {
         overflow: 'auto',
      },
      profilePhoto: {
         backgroundBlendMode: 'saturation',
         backgroundColor: isProfessional ? 'none' : '#002c54',
         backgroundImage: isProfessional ? `url(${userPhoto})` : 'none',
         backgroundPosition: 'center center',
         backgroundSize: 'cover',
         height: '150px',
         filter: isProfessional ? 'blur(7.5px)' : 'none',
      },
      toolbarProfile: {
         position: 'relative',
      },
      userData: {
         bottom: '10px',
         display: 'flex',
         flexDirection: 'column',
         height: '100%',
         justifyContent: 'flex-end',
         left: '20px',
         position: 'absolute',
      },
      userEmail: {
         color: 'white',
         lineHeight: '128.91%',
      },
      userName: {
         color: 'white',
         lineHeight: '128.91%',
      },
      userRole: {
         color: 'white',
         fontWeight: '600',
         lineHeight: '128.91%',
      },
      version: {
         alignItems: 'center',
         color: '#b0b0b0',
         display: 'flex',
         justifyContent: 'center',
      },
   }));

   const classes = useStyles();
   
   const handleCloseSessionProf = () => {
      const virtualGuardStatus = ProfessionalGuardEnum.FREE;
      PutVirtualGuard(userData.professional_dto.id, virtualGuardStatus, userToken)
         .then(
           
               dispatch({ type: 'SET_VIRTUAL_GUARD', virtual_guard_availability_str: virtualGuardStatus })   
         )
         .catch()
         .finally(() =>
         {  
            dispatchProfessionalLog({
          type: "SEND_MESSAGE",
          payload: {
            message: {
              professionalId: userData.email,
              command: "LogOut",
            },
          },
        });
        dispatchProfessionalLog({
          type: "SET_STOMP_CLIENT",
          payload: null,
        });
        Logout();
        history.push("/login");
         });
            
            
   };
   const handleCloseSessionPat = () => {

      Logout();

      history.push('/login');

   };

   const showIcon = (icon) => (
      <img src={icon} style={{ fill: '#002c54' }} alt="Item menú" />
   );

   return (
      <div className={classes.menu}>
         <div className={classes.menuTop}>
            <div className={classes.toolbarProfile}>
               <div className={classes.profilePhoto}></div>
               <div className={classes.userData}>
                  <Avatar
                     className={classes.avatar}
                     alt="Foto de Perfil"
                     src={userPhoto}
                  />
                  <div className={classes.userName}>
                     {userData.alias || userProfile?.nickname}
                  </div>
                  <div className={classes.userRole}>{userRole}</div>
                  <div className={classes.userEmail}>{userProfile?.email}</div>
               </div>
            </div>
            <Divider />
            <List className={classes.menuList}>
               <ListItem
                  button
                  onClick={() => {
                     setOpen(false)
                     history.push(userDashboardLink);
                  }}
               >
                  <ListItemIcon>{showIcon(HomeIcon)}</ListItemIcon>
                  <ListItemText primary="Inicio" />
               </ListItem>
               <ListItem
                  button
                  onClick={() => {
                     setOpen(false)
                     history.push('/registro-completo');
                  }}
               >
                  <ListItemIcon>{showIcon(ProfileIcon)}</ListItemIcon>
                  <ListItemText primary="Mi perfil" />
               </ListItem>
               {isPatient && (
                  <ListItem
                  button
                  onClick={() => {
                     setOpen(false)
                     history.push('/grupo-familiar');
                  }}
                  >
                  <ListItemIcon>{showIcon(FamilyIcon)}</ListItemIcon>
                  <ListItemText primary="Mi grupo familiar" />
                  </ListItem>
               )}
               {isPatient && fullProfile && (
                  <>
                     {/* <ListItem
                        button
                        onClick={() => {
                           setOpen(false)
                           history.push('/paciente/mis-pedidos');
                        }}
                     >
                        <ListItemIcon>{showIcon(OrdersIcon)}</ListItemIcon>
                        <ListItemText primary="Mis pedidos" />
                     </ListItem> */}
                     <ListItem
                        button
                        onClick={() => {
                           setOpen(false)
                           history.push('/paciente/mis-recetas');
                        }}
                     >
                        <ListItemIcon>{showIcon(RecipesIcon)}</ListItemIcon>
                        <ListItemText primary="Mis recetas" />
                     </ListItem>
                     {/* <ListItem
                        button
                        onClick={() => {
                           setOpen(false)
                           history.push('/paciente/mis-turnos');
                        }}
                     >
                        <ListItemIcon>
                           {showIcon(AppointmentsIcon)}
                        </ListItemIcon>
                        <ListItemText primary="Mis turnos" />
                     </ListItem> */}
                     <ListItem
                        button
                        onClick={() => {
                           history.push('/mi-plan')
                        }}
                     >
                        <ListItemIcon>
                           {showIcon(PlanCardIcon)}
                        </ListItemIcon>
                        <ListItemText primary="Mi plan" />
                     </ListItem>
                  </>
               )}
               {isProfessional && fullProfile && (
                  <>
                     {/* <ListItem
                        button
                        onClick={() => {
                           setOpen(false)
                           history.push('/profesional/agenda-de-turnos');
                        }}
                     >
                        <ListItemIcon>
                           {showIcon(AppointmentScheduleIcon)}
                        </ListItemIcon>
                        <ListItemText primary="Agenda de turnos" />
                     </ListItem> */}
                     <ListItem
                        button
                        onClick={() => {
                           setOpen(false)
                           history.push('/historia-clinica');
                        }}
                     >
                        <ListItemIcon>
                           {showIcon(MedicalRecordIcon)}
                        </ListItemIcon>
                        <ListItemText primary="Historia clínica" />
                     </ListItem>
                     {/* <ListItem
                        button
                        onClick={() => {
                           setOpen(false)
                           history.push('/profesional/disponibilidad');
                        }}
                     >
                        <ListItemIcon>
                           {showIcon(AvailabilityIcon)}
                        </ListItemIcon>
                        <ListItemText primary="Mi disponibilidad" />
                     </ListItem> */}
                  </>
               )}
               <Divider />
               <List className={classes.menuList}>
                  <ListItem
                     button
                     onClick={() => {
                        setOpen(false)
                        history.push('/actualizar-contrasenia');
                     }}
                  >
                     <ListItemIcon style={{marginLeft: '-2px'}}><LockOutlined/></ListItemIcon>
                     <ListItemText primary="Actualizar contraseña" />
                  </ListItem>
                  <ListItem
                     button
                     onClick={() => {
                        setOpen(false)
                        history.push('/ayuda');
                     }}
                  >
                     <ListItemIcon>{showIcon(HelpIcon)}</ListItemIcon>
                     <ListItemText primary="Ayuda" />
                  </ListItem>
                  <ListItem
                     button
                     onClick={() => {
                        setOpen(false)
                        history.push('/terminos-y-condiciones');
                     }}
                  >
                     <ListItemIcon>
                        {showIcon(TermsAndConditionsIcon)}
                     </ListItemIcon>
                     <ListItemText primary="Términos y condiciones" />
                  </ListItem>
               </List>
            </List>
         </div>
         <div>
            <List className={(classes.menuList, classes.exitToApp)}>
               <ListItem button onClick={isProfessional ? handleCloseSessionProf : handleCloseSessionPat}>
                  <ListItemIcon>
                     <ExitToApp />
                  </ListItemIcon>
                  <ListItemText primary="Cerrar sesión" />
               </ListItem>
            </List>
            <Divider />
            <List>
               <ListItem className={classes.version}>
                   {packageJson.version}
               </ListItem>
            </List>
         </div>
      </div>
   );
};

export default HeaderMenu;
