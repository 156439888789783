import { formatDayMonth, hourToString } from '../utils/DateUtil'
export default class Appointment {
    hour = '';
    date = '';
    idTimeAvailability = '';
    idSchedule = '';
    confirm = [];
    // Api model
    id;
    appointment_date;
    status;
    patient_dto;
    professional_dto;
    time_availability_id;
    schedule_dto;
    attention_type_id;
    specialty;

    constructor(id, appointment_date, status, patient_dto, professional_dto, idTimeAvailability, idSchedule, confirm) {
        this.id = id;
        this.appointment_date = appointment_date;
        this.status = status;
        this.patient_dto = patient_dto || {};
        this.patient_dto.aliasOrFullName = this.getFullName(patient_dto, 'Paciente');
        this.professional_dto = professional_dto || {};
        this.professional_dto.aliasOrFullName = this.getFullName(professional_dto, 'Profesional');
        this.appointmentDate = appointment_date;
        this.hour = hourToString(appointment_date);
        this.date = formatDayMonth(appointment_date);
        this.idTimeAvailability = idTimeAvailability;
        this.time_availability_id = idTimeAvailability;
        this.idSchedule = idSchedule;
        this.confirm = confirm;
    }

    getFullName(user, withoutName) {
        return user?.first_name ? `${user.first_name} ${user.last_name || ''}`: user?.alias || user?.username || withoutName;
    }

    static AppointmentDto(id, appointment_date, status, patient_dto, professional_dto) {
        return new Appointment(id, appointment_date, status, patient_dto, professional_dto);
    }

    static AppointmentFront(hour, date, idTimeAvailability, appointment_date, idSchedule, confirm) {
        const appointment = new Appointment(null, appointment_date, null, null, null, idTimeAvailability, idSchedule, confirm);
        appointment.hour = hour;
        appointment.date = date;
        return appointment;
    }

    setCreationData(time_availability_id, schedule_dto, attention_type_id, specialty) {
        this.time_availability_id = time_availability_id;
        this.attention_type_id = attention_type_id;
        this.schedule_dto = schedule_dto;
        this.specialty = specialty;
    }
}
