import React, { useEffect, useState } from 'react';

import { InputField, AutocompleteField, AsyncAutocomplete } from '../../../../components/FormFields';

import {
   GetPreexistingDisease,
   GetSurgerys,
} from '../../../../services/userService';
import { useStateAuthValue } from '../../../../context/Auth/AuthState';
import { showToast, ToastType } from '../../../../utils/ToastUtil';
import { useToast } from '../../../../context/Toast/ToastProvider';
import { useFormikContext } from 'formik';
import { calculateAge } from '../../../../utils/DateUtil';
import { getMedicationSearch } from '../../../../services/medicationService';
import FilesUploadField from '../../../../components/FormFields/FilesUploadField';

const MedicalRecordForm = ({ formModel }) => {
   const {
      patient_dto: { pre_existing_disease,
         frequent_medication,
         surgeries,
         size,
         weight,
         allergies,
         family_history,
         vaccines,
         gynecological_data,
         patientAge,
         patientStudy
      },
   } = formModel;
   const [{ userToken }] = useStateAuthValue();
   const toastDispatch = useToast();
   const [preexistingDiseaseList, setPreexistingDiseaseList] = useState([]);
   const [frequentMedicationList, setFrequentMedicationList] = useState([]);
   const [surgeriesList, setSurgeriesList] = useState([]);
   const [ageCalculate, setAgeCalculate] = useState([]);
   const { values, setFieldValue } = useFormikContext();

   useEffect(() => {
      setAgeCalculate(calculateAge(values.birth_date));
   }, [values.birth_date]);

   useEffect(() => {
      let isMounted = true;

      const getData = async () => {
         await GetPreexistingDisease(userToken, (sessionError) => showToast(toastDispatch, sessionError, ToastType.ERROR, ToastType.ERROR))
            .then((response) => {
               if (!response.data.error && isMounted) {
                  setPreexistingDiseaseList(response.data);
               }
            })
            .catch((err) => {
               showToast(toastDispatch, err.message, ToastType.ERROR);
            });
         GetSurgerys(userToken, (sessionError) => showToast(toastDispatch, sessionError, ToastType.ERROR, ToastType.ERROR))
            .then((response) => {
               if (!response.data.error && isMounted) {
                  setSurgeriesList(response.data);
               }
            })
            .catch((err) => {
               showToast(toastDispatch, err.message, ToastType.ERROR);
            });
      };

      if (userToken) {
         getData();
      }

      return () => {
         isMounted = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userToken]);

   const searchFrequentMedications = async (query) => {
      setFrequentMedicationList([]);
     if (query && query.length > 2) {
       getMedicationSearch({description: query}, userToken).then(results=> 
         setFrequentMedicationList(results)
      );
     }
   };
   
   return (
      <>
         <div className="header">
            <h2>Antecedentes Médicos</h2>
         </div>
         <div className="autocomplete-wrapper">
            <div className="detail-wrapper">
               <InputField
                  name={patientAge.name}
                  label={patientAge.label}
                  value={ageCalculate ? ageCalculate : 0}
                  className="field"
                  disabled
               />
               <InputField
                  name={weight.name}
                  label={weight.label}
                  className="field"
                  requiredField
               />
               <InputField
                  name={size.name}
                  label={size.label}
                  className="field"
                  requiredField
                  isSizeInput
               />
            </div>
            <AutocompleteField
               name={pre_existing_disease.name}
               label={pre_existing_disease.label}
               options={preexistingDiseaseList}
               className="field"
               placeholder={pre_existing_disease.placeholder}
            />
            <AsyncAutocomplete
               className="field"
               name={frequent_medication.name}
               placeholder={frequent_medication.placeholder}
               options={frequentMedicationList}
               selected={values?.patient_dto?.frequent_medication}
               label={frequent_medication.label}
               searchTextLabel={frequent_medication.searchTextLabel}
               searchTextMinRequired={frequent_medication.searchTextMinRequired}
               requestMethod={textSearch => searchFrequentMedications(textSearch)}
               />
            <InputField
               name={frequent_medication.name}
               label="Otro Medicamento Frecuente"
               className="field"
               value={values.anotherMedication}
               onChange={(e) => {
                  setFieldValue("anotherMedication",e.target.value);
               }}
            />
            <AutocompleteField
               name={surgeries.name}
               label={surgeries.label}
               options={surgeriesList}
               className="field"
               placeholder={surgeries.placeholder}
            />
            <InputField
               name={allergies.name}
               label={allergies.label}
               className="field"
               rows={3}
               style={{width : "100%"}}
            />
            <InputField
               name={family_history.name}
               label={family_history.label}
               className="field"
               rows={3}
               style={{width : "100%"}}
            />
            <InputField
               name={vaccines.name}
               label={vaccines.label}
               className="field"
               rows={3}
               style={{width : "100%"}}
            />
            <InputField
               name={gynecological_data.name}
               label={gynecological_data.label}
               className="field"
               rows={3}
               style={{width : "100%"}}
            />
            <FilesUploadField
               label={patientStudy.label}
               name={patientStudy.name}
               setValues={setFieldValue}
            />
         </div>
      </>
   );
};

export default MedicalRecordForm;
