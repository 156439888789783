import { sessionProfileSet, sessionProfileGet } from '../../services/authService';

export const initialState = {
    userToken: null,
    userAuthId: null,
    userData: [],
    isRegisterForm: false,
    singUpData: [],
  };
  
  const AuthReducer = (state, action) => {
    switch (action.type) {
      case 'LOG_IN':
        return {
          ...state,
          userToken: action.userToken,
        };

      case 'LOG_OUT':
        return {
          ...state,
          userToken: null,
          userAuthId: null,
        };

      case 'SIGN_UP':
        return {
          ...state,
          isRegisterForm: action.isRegisterForm,
          singUpData: action.singUpData,
        };

      case 'SAVE_USER_INFO':
        return {
          ...state,
          userAuthId: action.userAuthId,
          userData: action.userData,
        };

      case 'SAVE_SESSION':
        sessionProfileSet(state);
        return {
          ...state
        };
      
      case 'GET_SESSION': {
        let oldSession= sessionProfileGet();
        if (!oldSession) {
          oldSession = initialState
        }
        return oldSession;
      }

      case 'SET_VIRTUAL_GUARD': {
        const oldState = {...state};
        if ( oldState.userData.professional_dto ) {
          oldState.userData.professional_dto.virtual_guard_availability_str = action.virtual_guard_availability_str;
        }
        sessionProfileSet(oldState);
        return {
          ...state,
          userData: oldState.userData,
        };
      }
      default:
        return state;
    }
  };
  
  export default AuthReducer;
  