import React, { useEffect, useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  Link,
  Box,
  Button,
  makeStyles,
} from "@material-ui/core";
import { over } from "stompjs";
import SockJS from "sockjs-client";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterButton from "./Button";
import HeaderImage from "../../../assets/img/medicos_disponibles.svg";
import { GetSpecialtiesProfessional } from "../../../services/userService";
import {
  GetProfessionalAvailable,
  GetSelectedProfessionals,
} from "../../../services/professionalService";
import professionalFoundSound from "../../../assets/notifications/new-patient.wav";
import { useStateAuthValue } from "../../../context/Auth/AuthState";
import { useStateProfConsultFilterValue } from "../../../context/profConsultFilterState";
import { useToast } from "../../../context/Toast/ToastProvider";
import { showToast, ToastType } from "../../../utils/ToastUtil";
import { FilterType } from "../../../utils/FilterUtil";
import { OK } from "../../../utils/ServiceUtil";
import { UseStateVirtualGuard } from "../../../context/VirtualGuard/VirtualGuardProvider";
import iconEmergency from "../../../assets/img/emergenciaBlanco.svg";
import Modal from "../../../components/Modal";
import EmergencyModal from "./EmergencyModal";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import Tooltip from "@material-ui/core/Tooltip";
import { AttentionTypeEnum } from "../../../models/enums";
import ConsultationReason from "../ModalList/ConsultationReason";
import { postPatientNotifications } from "../../../services/notificationService";
import { EventLog } from "../../../services/medilinePrepaidService";
import { ApplicationStyle } from "../../../theme";
import PreviousRecommendationsModal from "./PreviousRecommendationsModal";
import firebase from "../../../services/firebase";
import { AgoraContext } from "../../../context/Agora/AgoraContext";

// websocket url to connect
let stompClient = null;
const UrlWS = process.env.REACT_APP_WS_URL;

const PatientFilter = ({
  setVideoCallData,
  setVideoCall,
  stopRingCall,
  toastMessage,
  setConsultSuccess,
  hasPermission,
  setAskPermission,
  localTracks
}) => {
  const [{ userToken, userData }] = useStateAuthValue();
  const [, dispatch] = useStateProfConsultFilterValue();
  const [{ consultation }] = UseStateVirtualGuard();
  const id = userData?.patient_dto?.auth_id;
  const scrollRef = useRef(null);
  const toastDispatch = useToast();
  const history = useHistory();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [
    openPreviousRecommendationsModal,
    setOpenPreviousRecommendationsModal,
  ] = useState(false);
  const [specialtiesList, setSpecialtiesList] = useState([]);
  const [specialtieId, setSpecialtieId] = useState(0);
  const [patientsList, setPatientsList] = useState([]);
  const [openNumEmergencia, setOpenNumEmergencia] = useState(false);
  const [filterTypeSelected, setFilterTypeSelected] = useState("");
  const [prepaidHealthCategoryName, setPrepaidHealthCategoryName] = useState();
  const [disableVirtualGuard, setDisableVirtualGuard] = useState(false);
  /*    const [disabledRadioObraSocial, setDisabledRadioObraSocial] = useState(false);
   const [disabledRadioParticular, setDisabledRadioParticular] = useState(false);
   const [disabledRadioMediline, setDisabledRadioMediline] = useState(false);
   const [checkedObraSocial, setCheckedObraSocial] = useState(false);
   const [checkedParticular, setCheckedObraParticular] = useState(false);
   const [checkedMediline, setCheckedMediline] = useState(false); */
  const { client } = useContext(AgoraContext);
  const isVirtualGuard = filterTypeSelected === FilterType.VIRTUAL_GUARD_CALLED;
  const isSelectedProfesional =
    filterTypeSelected === FilterType.SELECTED_PROFESSIONAL;
  const [{ clickButton }] = useStateProfConsultFilterValue();
  const [virtualGuardData, setVirtualGuardData] = useState([]);
  const [privatemessagews, setPrivatemessagews] = useState(new Map());
  const [patientLoginData, setPatientLoginData] = useState(userData?.email);
  const [patientData, setPatientData] = useState({
    patientId: id,
    familymember: patientLoginData,
    professionalId: "",
    profesionalName: "",
    profSpecialty: "",
    specialtyId: "",
    reason: "",
    queueId: "",
    connected: true,
    orderNumber: "",
  });
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      let patients = [];
      if (userData?.patient_dto?.family_group.length !== 0) {
        userData?.patient_dto?.family_group.map((fam) => {
          patients.push(fam.first_name + " " + fam.last_name);
        });
      }
      patients.push(userData?.first_name + " " + userData.last_name);
      setPatientsList(patients);
      await GetSpecialtiesProfessional(userToken, showError)
        .then((response) => {
          if (!response.data.error && isMounted) {
            setSpecialtiesList(response.data);
          }
        })
        .catch((err) => {
          showError("Ocurrió un error en la búsqueda de especialidades");
          showError(err.message);
          setSpecialtiesList([{ name: "", id: 1 }]);
        });
    };

    if (userToken) {
      getData();
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, consultation]);

  useEffect(() => {
    if (clickButton === null) {
      setDisableVirtualGuard(false);
    } else if (
      attentionTypeNotValid ||
      !userData?.patient_dto?.full_profile ||
      clickButton !== FilterType.FINISH_CONSULTATION
    ) {
      setDisableVirtualGuard(true);
    } else {
      setDisableVirtualGuard(false);
    }
  }, [clickButton]);

  useEffect(() => {
    setPrepaidHealthCategoryName(
      userData?.patient_dto?.prepaid_health_category
    );
  }, [userData]);

  /*    useEffect(() => {
      if (prepaidHealthCategoryName) {
         setDisabledRadioObraSocial(false);
         setCheckedObraSocial(true);
         setCheckedMediline(false);
         setDisabledRadioParticular(true);
         setDisabledRadioMediline(true);
         setCheckedObraParticular(false);
         handleChangeAttentionTypes(prepaidHealthCategoryName);
      } else {
         setDisabledRadioObraSocial(true);
         setCheckedObraSocial(false);
         setDisabledRadioParticular(false);
         setCheckedMediline(true);
         handleChangeAttentionTypes(AttentionTypeEnum.PARTICULAR);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [prepaidHealthCategoryName]) */

  useEffect(() => {
    if (
      virtualGuardData &&
      clickButton === FilterType.START_VIRTUAL_GUARD_CONNECTION
    ) {
      setVideoCallData(virtualGuardData);
      setVideoCall(true);
    }
  }, [virtualGuardData, clickButton]);

  useEffect(() => {
    setPrepaidHealthCategoryName(
      userData?.patient_dto?.prepaid_health_category
    );
  }, [userData]);

  useEffect(() => {
    if (toastMessage !== "") {
      showToast(toastDispatch, toastMessage, ToastType.INFO, ToastType.INFO);
    }
  }, [virtualGuardData]);

  // connect to ws
  useEffect(() => {
    if (id && clickButton === FilterType.VIRTUAL_GUARD_CALLED) {
      let Sock = new SockJS(UrlWS);
      stompClient = over(Sock);
      stompClient.connect({}, onConnectedPatient, showError);
    }
  }, [id, clickButton]);

  useEffect(() => {
    if (localStorage.getItem('consult') == null) {
      disconnect();
    }
  }, [])

  // Disconnect from ws
  const disconnect = () => {
    var wsMessage = {
      patientId: patientData?.patientId,
      orderNumber: patientData?.orderNumber,
      specialtyId: patientData?.specialtyId,
      command: "PatientCancel",
      professionalId: patientData?.professionalId,
      queueId: patientData?.queueId,
      date: patientData?.date,
    };
    firebase.put({...wsMessage, status: 'QUIT'});
    localStorage.setItem("consult", null);
    stompClient?.send("/app/message", {}, JSON.stringify(wsMessage));
    dispatch({
      type: "LOADING",
      clickButton: FilterType.FINISH_CONSULTATION,
      loading: false,
    });
  };

  window.onbeforeunload = () => {
    if (stompClient) {
      disconnect();
    }
  };

  window.ononline=()=>{setIsConnected(true)};
  window.onoffline=()=>{setIsConnected(false)};

  useEffect(() => {
    console.log('Internet ' + (isConnected ? 'Conectado' : 'Desconectado'));
    if (!isConnected && stompClient){
      disconnect();
    }
  }, [isConnected]);

  const dispatchFilter = (filter, professionals, idButton) => {
    dispatch({
      type: "SET_FILTER_FORM",
      drData: professionals,
      clickButton: idButton,
      filterForm: filter,
    });
  };

  const showError = (msgError) => {
    showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
  };

  // subscribe to ws
  const onConnectedPatient = () => {
    if (id !== "" && id !== undefined) {
      stompClient.subscribe(
        "/user/" + id + "/private",
        onPrivateMessagePatient
      );
      dispatch({
        type: "VIRTUAL_GUARD",
        stomp: stompClient,
      });
    }
  };

  let patientCommand = null;

  /*useEffect(() => {
    const intervalId = setInterval(async () => {
      let getFirebase = await firebase.getById(id);
      if (getFirebase?.command !== patientCommand) {
        onPrivateMessagePatient(getFirebase);
      }
    }, 15000);
    return () => clearInterval(intervalId);
  }, [userData]);*/

  // ws response function management
  const onPrivateMessagePatient = async (payload) => {
    let payloadData = {};
    if (payload?.body) {
      payloadData = JSON.parse(payload.body);
    } else {
      patientCommand = payload?.command;
      payloadData = payload;
    }
    if (privatemessagews.get(payloadData.patientId)) {
      privatemessagews.get(payloadData.patientId).push(payloadData);
      setPrivatemessagews(new Map(privatemessagews));
    } else {
      let list = [];
      list.push(payloadData);
      privatemessagews.set(payloadData.patientId, list);
      setPrivatemessagews(new Map(privatemessagews));
    }
    dispatch({
      type: "VIRTUAL_GUARD",
      stomp: stompClient,
      data: {
        familymember: patientLoginData,
        patientId: payloadData.patientId,
        orderNumber: payloadData.orderNumber,
        professionalId: payloadData.professionalId,
        queueId: payloadData.queueId,
        profesionalName: payloadData.profesionalName,
        specialtyId: payloadData.specialtyId,
        reason: payloadData.reason,
        connected: patientData.connected,
        profImage: payloadData.profImage,
        profSpecialty: payloadData.profSpecialty,
      },
    });

    setPatientData({
      ...patientData,
      patientId: payloadData.patientId,
      orderNumber: payloadData.orderNumber,
      professionalId: payloadData.professionalId,
      queueId: payloadData.queueId,
      profesionalName: payloadData.profesionalName,
      specialtyId: payloadData.specialtyId,
      reason: payloadData.reason,
      connected: patientData.connected,
      profImage: payloadData.profImage,
      profSpecialty: payloadData.profSpecialty,
    });

    // already connected to professional
    if (payloadData.professionalId !== null) {
      // patient cancels or finishes consult
      if (payloadData.command === "PatientFinish") {
        setConsultSuccess(true);
        client.leave();
        await EventLog(userToken, "videocall finish", "paciente", payloadData);
      }
      if (
        payloadData.command === "PatientFinish" ||
        payloadData.command === "PatientCancel"
      ) {
        stompClient.disconnect();
        dispatch({
          type: "LOADING",
          clickButton: FilterType.FINISH_CONSULTATION,
          loading: false,
        });
        setVideoCall(false);
        setVirtualGuardData(false);
        localStorage.setItem("consult", null);

        // professional cancels
      } else if (
        payloadData.command === "PatientTimeoutCancel" ||
        payloadData.command === "ProfessionalTimeoutCancel"
      ) {
        stompClient.disconnect();
        dispatch({
          type: "LOADING",
          clickButton: FilterType.FINISH_CONSULTATION,
          loading: false,
        });
        setVideoCall(false);
        setVirtualGuardData(false);
        setOpenConfirmationModal(true);
        localStorage.setItem("consult", null);
      } else if (
        payloadData.command === "ProfessionalCancel" ||
        payloadData.command === "VideoCallError"
      ) {
        await EventLog(
          userToken,
          "professional cancell",
          "paciente",
          payloadData
        );
        stompClient.disconnect();
        setTimeout(() => {
          dispatch({
            type: "LOADING",
            clickButton: FilterType.FINISH_CONSULTATION,
            loading: false,
          });
          setVideoCall(false);
          setVirtualGuardData(false);
          localStorage.setItem("consult", null);
          showToast(
            toastDispatch,
            "El profesional canceló la consulta",
            ToastType.INFO,
            ToastType.INFO
          );
        }, 2000);
        let patient = {
          ...patientData,
          patientId: payloadData.patientId,
          orderNumber: payloadData.orderNumber,
          professionalId: payloadData.professionalId,
          queueId: payloadData.queueId,
          profesionalName: payloadData.profesionalName,
          specialtyId: payloadData.specialtyId,
          reason: payloadData.reason,
          connected: patientData.connected,
          profImage: payloadData.profImage,
          profSpecialty: payloadData.profSpecialty,
        };
        postPatientNotifications(
          userToken,
          userData,
          patient,
          payloadData.command === "ProfessionalCancel"
            ? "PROFESSIONAL_CANCELLED"
            : "TECHNICAL_FAILURE"
        );
        //consult finished
      } else if (payloadData.command === "ProfessionalFinish") {
        stompClient.disconnect();
        setTimeout(() => {
          dispatch({
            type: "LOADING",
            clickButton: FilterType.FINISH_CONSULTATION,
            loading: false,
          });
          setVideoCall(false);
          setVirtualGuardData(false);
          setConsultSuccess(true);
          localStorage.setItem("consult", null);
        }, 1000);

        // everything ok, start videocall
      } else if (payloadData.command === "SetProfessional") {
        setVirtualGuardData({
          ...payloadData,
          uid: payloadData.patientId,
          role: "subscriber",
          channel: payloadData.queueId,
          layout: 1,
        });
        stopRingCall();
        dispatch({
          type: "VIRTUAL_GUARD",
          stomp: stompClient,
          data: payloadData,
        });
        const audio = new Audio(professionalFoundSound);
        audio.addEventListener("canplaythrough", () => {
          audio.play();
        });
        var wsMessage = {
          patientId: payloadData.patientId,
          orderNumber: payloadData.orderNumber,
          specialtyId: payloadData.specialtyId,
          command: "Accept",
          professionalId: payloadData.professionalId,
          queueId: payloadData.queueId,
          reason: payloadData.reason,
        };
        firebase.put({ ...wsMessage, command: 'Start', status: 'STARTED' })
        stompClient.send("/app/message", {}, JSON.stringify(wsMessage));
        setTimeout(() => {
          dispatch({
            type: "LOADING",
            clickButton: FilterType.START_VIRTUAL_GUARD_CONNECTION,
            loading: false,
          });
        }, 3000);
      }
    }
  };

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      attentionType: "",
      specialty: "",
      specialtyName: "",
      attentionTypeName: "",
      prepaidHealthName: "",
      prepaidHealthCategoryName: "",
      prepaidHealthId: "",
    },
    onSubmit: async () => {
      if (
        (isVirtualGuard &&
          values?.prepaidHealthName &&
          values?.prepaidHealthCategoryName &&
          values?.prepaidHealthId) ||
        (isVirtualGuard && values?.attentionTypeName !== "")
      ) {
        dispatch({
          type: "LOADING",
          clickButton: FilterType.TEST_CONNECTION,
          loading: true,
        });
        await GetProfessionalAvailable(
          {
            prepaidHealth: values?.prepaidHealthId,
          },
          userToken,
          showError
        )
          .then((response) => {
            if (OK === response?.status.toUpperCase()) {
              dispatchFilter(
                values,
                response?.data,
                FilterType.TEST_CONNECTION
              );
            } else {
              showError(
                "Ocurrió un error en la búsqueda de profesionales en guardia"
              );
            }
          })
          .finally(() =>
            dispatch({
              type: "LOADING",
              loading: false,
              clickButton: FilterType.TEST_CONNECTION,
            })
          );
      } else if (isSelectedProfesional && values?.specialty !== "") {
        dispatch({
          type: "LOADING",
          clickButton: FilterType.SELECTED_PROFESSIONAL,
          loading: true,
        });
        await GetSelectedProfessionals(
          {
            particular: values?.attentionType === 1,
            specialtyId: values?.specialty,
            specialtyName: values?.specialtyName,
            prepaidHealthId: values?.prepaidHealthId,
          },
          userToken,
          showError
        )
          .then((response) => {
            if (OK === response?.status) {
              const data = response?.data || [];
              dispatchFilter(values, data, FilterType.SELECTED_PROFESSIONAL);
            } else {
              showError("Ocurrió un error en la búsqueda de profesionales");
            }
          })
          .finally(() =>
            dispatch({
              type: "LOADING",
              loading: false,
              clickButton: FilterType.SELECTED_PROFESSIONAL,
            })
          );
      }
    },
  });

  // Custom validations
  const MedilineStyle = makeStyles(ApplicationStyle);
  const [attentionTypeNotValid, setAttentionTypeNotValid] = useState();
  const [specialtyNotValid, setSpecialtyNotValid] = useState(true);
  const [patientNotValid, setPatientNotValid] = useState(true);
  const [openNoHealthInsurance, setOpenNoHealthInsurance] = useState(false);
  const [openNoHealthInsuranceFamily, setOpenNoHealthInsuranceFamily] = useState(false);
  const MedilineClasses = MedilineStyle();

  const handleDispatchTestConnection = () => {
    dispatch({
      type: "LOADING",
      loading: false,
      clickButton: FilterType.TEST_CONNECTION,
    });
  };
  const handleClick = () => {
    if (hasPermission) {
      let isDataFull = true;
      if (!userData.patient_dto.prepaid_health_category) {
        isDataFull = false;
        setOpenNoHealthInsurance(true);

      } else {
        if (userData.patient_dto.family_group.length) {
          userData.patient_dto.family_group.map((p) => {
            if (!p.patient_dto.prepaid_health_category) {
              isDataFull = false;
              setOpenNoHealthInsuranceFamily(true);
            }
          });
        }
      }

      if (isDataFull) {
        setOpenPreviousRecommendationsModal(true);
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      setAskPermission(true);
    }
  };

  const handleChangeAttentionTypes = (attentionType) => {
    if (attentionType === AttentionTypeEnum.PARTICULAR) {
      setFieldValue("attentionType", 1);
      setFieldValue("attentionTypeName", attentionType);
      setAttentionTypeNotValid(false);
    } else {
      setFieldValue("attentionType", 2);
      setFieldValue("attentionTypeName", AttentionTypeEnum.HEALTHINSURANCE);
      setFieldValue("prepaidHealthName", attentionType?.prepaid_health?.name);
      setFieldValue("prepaidHealthCategoryName", attentionType?.name);
      setFieldValue("prepaidHealthId", attentionType?.prepaid_health?.id);
      setAttentionTypeNotValid(false);
    }
  };

  const handleChangeSpecialties = (e, valuesSpecialties) => {
    if (valuesSpecialties !== "" && valuesSpecialties !== null) {
      setFieldValue("specialty", valuesSpecialties.id);
      setFieldValue("specialtyName", valuesSpecialties.name);
      setSpecialtieId(valuesSpecialties.id);
      handleSubmit();
      setSpecialtyNotValid(false);
    } else {
      setSpecialtyNotValid(true);
    }
  };

  const handlePatientChoiceChange = (e, patientValues) => {
    setPatientLoginData(userData.email);
    userData?.patient_dto?.family_group.map((pat) => {
      const name = pat.first_name + " " + pat.last_name;
      if (name === patientValues) {
        setPatientLoginData(userData.email + "#" + pat.id_number);
      }
    });
    if (patientValues !== "" && patientValues !== null) {
      setFieldValue("patient", patientValues);
      setPatientNotValid(false);
    } else {
      setPatientNotValid(true);
    }
  };

  const handleOpen = () => {
    const consult = localStorage.getItem("consult");
    if (consult !== "true") setOpenNumEmergencia(true);
  };

  const handleClose = () => {
    setOpenNumEmergencia(false);
  };

  //Botón programar turno, función de disabled para implementar cuando exista la api de turnos
  //disabled = {attentionTypeNotValid || !userData?.patient_dto?.full_profile}

  return (
    <>
      <div className="header">
        <img src={HeaderImage} alt="Consulta con profesionales" />
        <hgroup>
          <h2>
            Consulta con <br />
            profesionales
          </h2>
          <h3>Realizá una consulta por videollamada.</h3>
        </hgroup>
      </div>

      <div className="filter">
        <div className="select-wrapper">
          <FormControl component="fieldset">
            <FormLabel>Tipo de Atención</FormLabel>
            <Box sx={{ width: 400 }}>
              <RadioGroup
                row
                aria-label="Tipo de Atención"
                name="controlled-radio-buttons-group"
                values={values.attentionType}
                defaultValue="particular"
                onChange={handleChangeAttentionTypes}
              >
                <FormControlLabel
                  value="planMediline"
                  control={<Radio color="secondary" />}
                  label="Plan Mediline"
                  checked={true}
                  disabled={false}
                />
                <FormControlLabel
                  value="particular"
                  control={<Radio color="secondary" />}
                  label="Particular"
                  checked={false}
                  disabled={true}
                />
                <FormControlLabel
                  value="obraSocial"
                  control={<Radio color="secondary" />}
                  label="Obra Social"
                  checked={false}
                  disabled={true}
                />
              </RadioGroup>
            </Box>
            {/*  {prepaidHealthCategoryName ?
                     <div className="prepaid-health">
                        <FormLabel>Obra Social: {prepaidHealthCategoryName?.prepaid_health?.name}</FormLabel>
                        <FormLabel>Plan: {prepaidHealthCategoryName?.name}</FormLabel>
                     </div>
                     : <></>} */}
          </FormControl>
        </div>
        <div className="button-wrapper">
          <FilterButton
            title="Guardia Virtual"
            subtitle="Encontrá profesionales disponibles para atención inmediata."
            idButton={FilterType.TEST_CONNECTION}
            active={isVirtualGuard}
            disabled={disableVirtualGuard}
            handleClick={handleClick}
            phoneIcon
            hasIcon
          />
          <FilterButton
            title="Programar Turno"
            subtitle="Agendá una videollamada con profesionales."
            idButton={FilterType.SELECTED_PROFESSIONAL}
            active={isSelectedProfesional}
            disabled={true}
            handleClick={handleClick}
          />
          {!userData?.patient_dto?.full_profile && (
            <p className="message">
              Para hacer uso de guardia virtual y/o programar turnos, deberás
              completar tus datos personales en{" "}
              <Link
                underline="always"
                onClick={() => history.push("registro-completo")}
              >
                Mi Perfil
              </Link>
            </p>
          )}
          <Tooltip title="Telèfonos de Emergencia">
            <IconButton className="btn-emergencia" onClick={() => handleOpen()}>
              <img
                src={iconEmergency}
                className="btn-emergencia__icon-emergency"
                alt="Ícono de emergencia"
              />
            </IconButton>
          </Tooltip>

          <Modal
            maxWidth="sm"
            isActive={openNumEmergencia}
            handleClose={handleClose}
            styleTitle="emergency-modal__title"
            styleIcon="emergency-modal__icon"
          >
            <EmergencyModal />
          </Modal>

          <ConfirmationModal
            open={openConfirmationModal}
            handleClose={() => setOpenConfirmationModal(!openConfirmationModal)}
            title="La consulta fue cancelada debido a inactividad."
            cancelButton="Volver"
            confirmButton="Confirmar"
            maxWidth="sm"
          />
          <PreviousRecommendationsModal
            open={openPreviousRecommendationsModal}
            handleClose={() => setOpenPreviousRecommendationsModal(false)}
            handleContinue={() => {
              setOpenPreviousRecommendationsModal(false);
              handleDispatchTestConnection();
            }}
          />
        </div>
      </div>
      <div ref={scrollRef}></div>
      {clickButton === FilterType.VIRTUAL_GUARD_CALLED && (
        <div className="specialties-to-result-column">
          <FormControl error={patientNotValid} className="autocomplete">
            <Autocomplete
              id="patients"
              options={patientsList}
              getOptionLabel={(option) => option}
              onChange={handlePatientChoiceChange}
              noOptionsText={"No paciente"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={patientNotValid}
                  label="Paciente"
                />
              )}
            />
            {patientNotValid && (
              <FormHelperText error>Escoja un paciente</FormHelperText>
            )}
          </FormControl>
          <FormControl error={specialtyNotValid} className="autocomplete">
            <Autocomplete
              id="specialties"
              options={specialtiesList}
              getOptionLabel={(option) => option.name}
              onChange={handleChangeSpecialties}
              noOptionsText={"No hay opciones"}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={specialtyNotValid}
                  label="Especialidad"
                />
              )}
            />
            {specialtyNotValid && (
              <FormHelperText error>
                La especialidad es requerida
              </FormHelperText>
            )}
          </FormControl>
          <ConsultationReason
            patientLoginData={patientLoginData}
            setPatientData={setPatientData}
            stompClient={stompClient}
            patientData={patientData}
            specialty={specialtieId}
            isComplete={!specialtyNotValid && !patientNotValid}
          />
        </div>
      )}
      <Modal
        isActive={openNoHealthInsurance}
        handleClose={() => setOpenNoHealthInsurance(false)}
      >
        <div className={MedilineClasses.btnBoxCenterM0}>
          <h4
            style={{
              fontSize: "20px",
              fontWeight: 600,
              marginBottom: "25px",
            }}
          >
            Tus datos de Obra Social no están completos. Por favor, completar
            estos datos en el menú Mi Perfil
          </h4>
          <Button
            className={MedilineClasses.btnSubmitRed}
            onClick={() => history.push("/registro-completo")}
          >
            Aceptar
          </Button>
        </div>
      </Modal>
      <Modal
        isActive={openNoHealthInsuranceFamily}
        handleClose={() => setOpenNoHealthInsuranceFamily(false)}
      >
        <div className={MedilineClasses.btnBoxCenterM0}>
          <h4
            style={{
              fontSize: "20px",
              fontWeight: 600,
              marginBottom: "25px",
            }}
          >
            Los datos de tus integrantes del plan familiar no están completos. Por favor, completar
            estos datos en el menú Mi Grupo Familiar
          </h4>
          <Button
            className={MedilineClasses.btnSubmitRed}
            onClick={() => history.push("/grupo-familiar")}
          >
            Aceptar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default PatientFilter;
