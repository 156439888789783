import React from 'react';
import { Button } from '@material-ui/core';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';

const EmergencyModal = () => {
   return (
      <div className="emergency-modal1">
         <div className="emergency-modal1__description">
            <h3>Contacto de Emergencias</h3>
            Si la situación implica riesgo de vida oprima llamada directa al
            servcio coordinado de emergencias
         </div>
         <div className="emergency-modal1__phone-numbers-wrapper">
            <Button
               onClick={() => window.open('tel:911', '_self')}
               className="phone-numbers-item"
               startIcon={<CallOutlinedIcon className="phone-number-icon" />}
            >
               <span className="phone-numbers-item__number">911</span> Servicio
               Coordinado
            </Button>
            <Button
               onClick={() => window.open('tel:100', '_self')}
               className="phone-numbers-item"
               startIcon={<CallOutlinedIcon className="phone-number-icon" />}
            >
               <span className="phone-numbers-item__number">100</span> Bomberos
            </Button>
            <Button
               onClick={() => window.open('tel:103', '_self')}
               className="phone-numbers-item"
               startIcon={<CallOutlinedIcon className="phone-number-icon" />}
            >
               <span className="phone-numbers-item__number">103</span> Defensa
               Civil
            </Button>
            <Button
               onClick={() => window.open('tel:105', '_self')}
               className="phone-numbers-item"
               startIcon={<CallOutlinedIcon className="phone-number-icon" />}
            >
               <span className="phone-numbers-item__number">105</span>
               Emergencia Ambiental
            </Button>
            <Button
               onClick={() => window.open('tel:911', '_self')}
               className="phone-numbers-item"
               startIcon={<CallOutlinedIcon className="phone-number-icon" />}
            >
               <span className="phone-numbers-item__number">144</span> Violencia
               de Genero
            </Button>
         </div>
      </div>
   );
};
export default EmergencyModal;
