import React from 'react';

import { booleanToString, renderOptions } from '../../../../utils/FormUtil';

const AttentionDataDetail = ({ formValues }) => {
   const {
      professional_dto: { attention_particular, prepaid_healths },
   } = formValues;

   return (
      <div className="detail">
         <div className="header header--detail">
            <h2>Datos de Atención</h2>
         </div>
         <div className="data-wrapper">
            <span className="data">
               Particular:
               <span className="bold">
                  {booleanToString(attention_particular)}
               </span>
            </span>
            <span className="data">
               Obras sociales:
               <div className="options">{renderOptions(prepaid_healths)}</div>
            </span>
         </div>
      </div>
   );
};

export default AttentionDataDetail;
