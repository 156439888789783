import React, { useState } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';

import AppointmentList from './AppointmentList';
import HeaderImage from '../../../assets/img/appointment-icon.svg';
import AppointmentStatus from '../../../models/enums/AppointmentStatus';

const MyAppointments = () => {
   const [filterStatus, setFilterStatus] = useState(
      AppointmentStatus.PROGRAMMED,
   );

   return (
      <div className="my-appointments">
         <div className="header">
            <div className="image-title">
               <img src={HeaderImage} alt="Mis Turnos" />
               <h2>Mis Turnos</h2>
            </div>
            <ButtonGroup className="btn-group">
               <Button
                  onClick={() => setFilterStatus(AppointmentStatus.PROGRAMMED)}
                  className={`btn ${
                     filterStatus === AppointmentStatus.PROGRAMMED
                        ? 'active'
                        : ''
                  }`}
               >
                  Programados
               </Button>
               <Button
                  onClick={() => setFilterStatus(AppointmentStatus.FINISHED)}
                  className={`btn ${
                     filterStatus === AppointmentStatus.FINISHED ? 'active' : ''
                  }`}
               >
                  Finalizados
               </Button>
               <Button
                  onClick={() => setFilterStatus(AppointmentStatus.CANCELLED)}
                  className={`btn ${
                     filterStatus === AppointmentStatus.CANCELLED
                        ? 'active'
                        : ''
                  }`}
               >
                  Cancelados
               </Button>
            </ButtonGroup>
         </div>
         <div className="container">
            <AppointmentList filterStatus={filterStatus} />
         </div>
      </div>
   );
};

export default MyAppointments;
