import React from 'react';
import Billing from './Billing';
import Data from './Data';

const Information = () => (
   <div className="information">
      <div className="header">
         <h2>Información</h2>
      </div>
      <Data />
      <Billing />
   </div>
);

export default Information;
