import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
   FormControl,
   FormHelperText,
   InputLabel,
   MenuItem,
   Select,
} from '@material-ui/core';

const SelectField = (props) => {
   const { label, data, requiredField, ...rest } = props;
   const [field, meta] = useField(props);
   const { value } = field;
   const { touched, error } = meta;
   const isError = !!(touched && error);
   const labelField = requiredField ? `${label}*` : label;

   const renderHelperText = () => {
      if (isError) {
         return <FormHelperText>{error}</FormHelperText>;
      }
   };

   return (
      <FormControl {...rest} error={isError}>
         <InputLabel>{labelField}</InputLabel>
         <Select {...field} value={value ? value : ''}>
            {data.map((item, index) => (
               <MenuItem key={index} value={item?.name || item?.label || ''}>
                  {item?.name || item?.label || ''}
               </MenuItem>
            ))}
         </Select>
         {renderHelperText()}
      </FormControl>
   );
};

SelectField.defaultProps = {
   data: [],
};

SelectField.propTypes = {
   data: PropTypes.array.isRequired,
};

export default SelectField;
