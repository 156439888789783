import React, { useEffect, useState } from 'react';

import { FileUploadField } from '../../../../components/FormFields';
import { useStateAuthValue } from '../../../../context/Auth/AuthState';

const ProfilePhotoForm = ({ formModel }) => {
   const {
      professional_dto: { photo_encode },
   } = formModel;

   const [{ userData }] = useStateAuthValue();
   const [professionalPhoto, setProfessionalPhoto] = useState();

   useEffect(() => {
      if (userData) {
         setProfessionalPhoto(userData.professional_dto?.photo_encode || '');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userData]);

   return (
      <>
         <div className="header">
            <h2>Foto de Perfil</h2>
         </div>
         <div className="form">
            <FileUploadField
               name={photo_encode.name}
               label={photo_encode.label}
               savedFile={professionalPhoto}
               isImage
               validation={photo_encode}
               className="field"
               requiredField
            />
         </div>
      </>
   );
};

export default ProfilePhotoForm;
