import React from 'react';
import { Button, Grid, Tooltip } from '@material-ui/core';
import AutocompleteFreeText from '../../components/FormFields/AutocompleteFreeText';
import { removeIndexFromList } from '../../utils/FormUtil';
import plus from '../../assets/img/plus.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
// Components
import ListItem from '../../components/ListItem';

const AutocompleteAndList = ({
  allowFreeText = false,
  options,
  stateList,
  setStateList,
  ListFieldLabel,
  setFieldListValue,
  handleDisabled,
  errorList,
  searchTextRequired,
  uniqueMsg,
  searchTextLabel,
  addTooltip,
}) => {
  const schema = Yup.object().shape({
    searchText: Yup.string()
      .min(1, searchTextRequired)
      .required(searchTextRequired)
      .test("unique", uniqueMsg, function (value) {
        return (
          !stateList?.length ||
          (value && !stateList.find((item) => item.name === value))
        );
      }),
  });
  const addItem = (name) => {
    name = name.trim();
    if (name?.length) {
      if (stateList.find((item) => item.name === name)) {
        return;
      }
      const newList = [
        {
          id: Math.random().toString(), // NOSONAR
          name: name,
          primary: name
        },
        ...stateList,
      ];
      setStateList(newList);
      setFieldListValue(newList);
      handleDisabled(false);
    }
  };
  const removeItem = (index) => {
    if (index || index === 0) {
      const newList = removeIndexFromList(index, stateList, setStateList);
      setFieldListValue(newList);
      if (!newList.length) {
        handleDisabled(true);
      }
    }
  };
  return (
    <>
      <Formik
        initialValues={{ searchText: '' }}
        validationSchema={schema}
        validateOnMount
        enableReinitialize
      >
        {({ errors, values, setFieldValue: setField, validateForm }) => (
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
            <Grid item xs={10}>
              <AutocompleteFreeText
                options={options}
                allowFreeText={allowFreeText}
                inputKey="searchText"
                inputValue={values.searchText}
                setField={(value) => {
                  setField("searchText", value);
                  values.searchText = value;
                  validateForm(values);
                }}
                searchTextLabel={searchTextLabel}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                width={40}
                type="button"
                fullWidth={false}
                disabled={
                  !!errors?.searchText ||
                  !values.searchText ||
                  errors?.searchText
                }
                className="plus-button"
                onClick={() => addItem(values.searchText)}
              >
                <Tooltip title={addTooltip}>
                  <img src={plus} alt="plus" />
                </Tooltip>
              </Button>
            </Grid>
            {stateList.length !== 0 && (
              <ListItem
                data={stateList}
                removeItem={removeItem}
                noDataMessage=''
                required={true}
                errors={errorList}
                label={ListFieldLabel}
                className="field"
              />
            )}
          </Grid>
        )}
      </Formik>
    </>
  );
}
export default AutocompleteAndList;
