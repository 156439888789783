import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ToastType } from '../../utils/ToastUtil';

const Toast = ({ dispatch, id, message, title, toastType }) => {
  // Toast Type Action
  const REMOVE_TOAST = 'REMOVE_TOAST';
  const DURATION = parseInt(process.env.REACT_APP_TOAST_DURATION) || 5000;
  const [close, setClose] = useState(false);
  const [width, setWidth] = useState(0);
  const [intervalID, setIntervalID] = useState(null);
  const toastClass = toastType.toString().toLowerCase();

  const handleStartTimer = () => {
    const auxId = setInterval(() => {
      setWidth((prev) => {
        if (prev < 100) {
          return prev + 1;
        }
        clearInterval(auxId);
        return prev;
      });
    }, DURATION / 100);

    setIntervalID(auxId);
  };

  const handlePauseTimer = () => {
    clearInterval(intervalID);
  };

  useEffect(() => {
    if (width === 100) {
      clearInterval(intervalID);
      setClose(true);
      setTimeout(() => {
        dispatch({
          type: REMOVE_TOAST,
          id: id,
        });
      }, DURATION);
    }
  }, [dispatch, setClose, id, intervalID, width]);

  useEffect(() => {
    handleStartTimer();
  }, []);

  return (
    <div
      onMouseEnter={handlePauseTimer}
      onMouseLeave={handleStartTimer}
      className={`toast ${toastClass} ${close && 'close'}`}
    >
      <div className="text-wrapper">
        {title && <h4>{title}</h4>}
        <p>{message}</p>
      </div>
      <div className="bar" style={{ width: `${width}%` }} />
    </div>
  );
};

export default Toast;

Toast.propTypes = {
  toastType: PropTypes.oneOf([
    ToastType.ERROR,
    ToastType.INFO,
    ToastType.SUCCESS,
    ToastType.WARNING,
  ]),
  message: PropTypes.string.isRequired,
};

Toast.defaultProps = {
  message: null,
  title: null,
  toastType: ToastType.INFO,
};
