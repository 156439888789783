import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import Modal from "../../components/Modal";
import { TextField } from "@material-ui/core";
import { showToast, ToastType } from "../../utils/ToastUtil";
import { useToast } from "../../context/Toast/ToastProvider";
import { GetMedilineFinancerByPatient } from "../../services/medilinePrepaidService";
import { ChangeMedilinePlan } from "../../services/patientService";
import { useStateAuthValue } from "../../context/Auth/AuthState";

const MyPlan = () => {
    const [{ userData, userToken }] = useStateAuthValue();
    const history = useHistory();
    const toastDispatch = useToast();
    const name = userData?.last_name + " " + userData?.first_name;
    const number = userData?.phone_number;
    const [open, setOpen] = useState(true);
    const [medPrepaidHealthPlan, setMedPrepaidHealthPlan] = useState("");   

   useEffect(() => {
      const getPlan = async () => {
         await GetMedilineFinancerByPatient(userToken, userData.patient_dto.id)
         .then((res) => {
            setMedPrepaidHealthPlan(res.planName);
         })
         .catch((e) => {
           if(process.env.REACT_APP_DEBUG === "true"){
            const errorMessage = "Error:  ~ file: myPlanModal.js:29 ~ getPlan ~ ~ error:" + e;
            showToast(toastDispatch, errorMessage, ToastType.ERROR, ToastType.ERROR);
           }
         })
       }
       if(userData?.patient_dto?.id){
         getPlan();
       }
    },[userData]);

    const onClickFormHandler = async () => {
        await ChangeMedilinePlan(userToken, userData).then(() => {
            showToast(toastDispatch, "Información enviada correctamente.", ToastType.SUCCESS, ToastType.SUCCESS);
            history.push('/paciente');
        }).catch(() => {
            showToast(toastDispatch, "Hubo un error al enviar la información, por favor, intentelo más tarde.", ToastType.ERROR, ToastType.ERROR);
        });
    }

    const handleClose = () => {
        setOpen(false);
        history.push('/paciente');

    }
    return(
        <Fragment>
            <Modal
            isActive={open}
            handleClose={handleClose}
            >
                <div style={{
                    padding: "20px 40px",
                    position: "relative",
                    bottom: "38px"
                }}>
                    <div style={{
                        color: "black",
                        marginTop: "1rem",
                    }}>
                    <h1 style={{ fontWeight: "600"}}>Mi Plan</h1>
                    <hr style={{ color : "#002C54"}}/>
                    <h3 style={{ fontSize: "22px", fontWeight: "600"}}>Titular: {name}</h3>
                    <h3 style={{ fontSize: "22px", fontWeight: "600"}}>Plan actual: Mediline - {medPrepaidHealthPlan}</h3>                        
                    </div>

                    <h4 style={{
                        marginBottom: "20px",
                        marginTop: "30px",
                        fontSize: "20px"
                    }}>Si quieres cambiar de Plan, agregar o eliminar miembros de tu grupo familiar, por favor, completá los siguientes datos y un asesor de ventas te contactará en breve.</h4>
                    <form style={{ display: "flex", flexDirection: "row"}}>
                            <div style={{
                                marginRight: "10rem"
                            }}>
                                <TextField
                                  id="outlined-read-only-input"
                                  label="Nombre"
                                  defaultValue={name}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div> 
                            <div>
                                <TextField
                                  id="outlined-read-only-input"
                                  label="Celular"
                                  defaultValue={number}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                    </form>
                    <button style={{
                            marginTop: "2rem",
                            width: "9rem",
                            height: "33px",
                            float: "right",
                            borderRadius: "25px",
                            border: "none",
                            backgroundColor: "#002c54",
                            color: "white",
                            cursor: "pointer"
                    }} onClick={onClickFormHandler}>Contáctame</button>
                </div>
            </Modal>
        </Fragment>
    )

};

export default MyPlan;