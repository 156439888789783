import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  submit: {
    backgroundColor: '#DA2037',
    margin: theme.spacing(3, 0, 2),
    borderRadius: '20px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#e84353',
    }
  },
  paper: {
    justifyContent: 'center',
    height: 200,
  }
}));

const ResetedPass = () => {
  const classes = useStyles();
  const history = useHistory();
  

  return <div>
      <div style={{ color: "#002C54" }}>
        <h1>¡Tu contraseña ha sido modificada exitosamente!</h1>
        <Button type="button" fullWidth variant="contained" color="primary" className={classes.submit} onClick={() => history.push("/login")} endIcon={<Icon
            >
              chevron_right
            </Icon>}>
          Ingresar
        </Button>
      </div>
    </div>;
}

export default ResetedPass;
