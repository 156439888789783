import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormHelperText,
  List,
  ListItem as MaterialListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  IconButton,
  FormControl,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(() => ({
  listScroll: {
    minHeight: "50px",
    maxHeight: "200px",
    overflowY: "scroll",
    width: "100%",
    fontSize: "14px",
    marginLeft: "5px",
  },
  listItem: {
    boxShadow:
      '0 10px 20px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04)',
    borderRadius: '0 20px 20px 20px',
    maxWidth: "250px",
  },
  bold: {
    fontWeight: '600',
    display: 'block',
    wordWrap: 'break-word'
  },
  block: {
    display: 'block',
    wordWrap: 'break-word'
  }
}));

export default function ListItem({
  data,
  errors,
  removeItem,
  removeTooltip = "Eliminar item",
  noDataMessage = "",
  required = false,
  displayKeys=['secondary']
}) {
  const classes = useStyles();
  const renderHelperText = () => {
    if (required && !!errors) {
      return <FormHelperText>{errors}</FormHelperText>;
    }
  };

  return (
    <FormControl error={required && !!errors}>
      {renderHelperText()}
      <List className={classes.listScroll} dense={true}>
        {data?.length > 0
          ? data.map((item, index) => {
              return (
                <MaterialListItem key={index} className={classes.listItem}>
                  <ListItemText
                    primary={item.primary}
                    secondary={
                      <React.Fragment>
                        {Object.keys(item).map((key) => {
                          if (displayKeys.includes(key)) {
                            return (
                              <Typography component="span" key={key} className={key === 'name' ? classes.bold : classes.block}>
                                {item[key]}
                              </Typography>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title={removeTooltip}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => removeItem(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </MaterialListItem>
              );
            })
          : noDataMessage}
      </List>
    </FormControl>
  );
}
