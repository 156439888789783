import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from '@material-ui/core/Chip';
import { CloudUpload } from '@material-ui/icons';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
} from "@material-ui/core";

import SpanPanel from "./spanPanel";
import { formatDate } from "../../../utils/DateUtil";
import {
  renderOptions,
  getValuePlain,
  valueToName,
  getAge,
  iconsFile,
  handleDownload,
} from "../../../utils/FormUtil";
import genders from "../../../tests/mocks/genders";

const PersonalData = ({ data }) => {
  const filesStudy = data?.patient_dto?.study || [];

  return (
    <>
      <div>
        <Accordion className="accordion-history-clinic">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="header-accordion">
              Datos Personales
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-detail">
            <TableContainer>
              <Table>
                <TableHead></TableHead>
                <TableBody>
                  <TableRow className="table">
                    <TableCell
                      align="center"
                      variant="head"
                      className="table-cell"
                    >
                      <div className="detail">
                        <div className="data-wrapper">
                          <SpanPanel
                            title="Obra Social"
                            data={getValuePlain(
                              data?.patient_dto?.prepaid_health_category
                                ?.prepaid_health?.name
                            )}
                          ></SpanPanel>
                          <SpanPanel
                            title="Plan"
                            data={getValuePlain(data?.patient_dto?.prepaid_health_category?.name)}
                          ></SpanPanel> 
                          <SpanPanel
                            title="Afiliado N°"
                            data={getValuePlain(data?.patient_dto?.affiliate_number)}
                          ></SpanPanel>  
                          <SpanPanel
                            title="Correo"
                            data={getValuePlain(data?.email)}
                          ></SpanPanel> 
                          <SpanPanel
                            title="Teléfono"
                            data={getValuePlain(data?.phone_number)}
                          ></SpanPanel>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      variant="head"
                      className="table-cell"
                    >
                      <div className="detail">
                        <div className="data-wrapper">
                          <SpanPanel
                            title="Domicilio"
                            data={getValuePlain(
                              data?.address_dto?.street +
                                " " +
                                data?.address_dto?.number
                            )}
                          ></SpanPanel>                          
                          <span className="data">
                            Piso:
                            <span className="normal">
                              {getValuePlain(data?.address_dto?.floor)}
                            </span>
                            <span className="middle">Dpto:</span>
                            <span className="normal">
                              {getValuePlain(
                                data?.address_dto?.number_department
                              )}
                            </span>
                          </span>
                          <SpanPanel
                            title="Entre calles"
                            data={getValuePlain(data?.address_dto?.between_streets)}
                          ></SpanPanel>   
                          <SpanPanel
                            title="Ciudad"
                            data={getValuePlain(data?.address_dto?.city)}
                          ></SpanPanel>  
                          <SpanPanel
                            title="Provincia"
                            data={getValuePlain(data?.address_dto?.province)}
                          ></SpanPanel> 
                          <SpanPanel
                            title="País"
                            data={getValuePlain(data?.address_dto?.country)}
                          ></SpanPanel> 
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      variant="head"
                      className="table-cell"
                    >
                      <div className="detail">
                        <div className="data-wrapper">
                          <SpanPanel
                            title="DNI"
                            data={getValuePlain(data?.id_number)}
                          ></SpanPanel>
                          <SpanPanel
                            title="Sexo"
                            data={data?.sex_str
                              ? valueToName(genders, data?.sex_str)
                              : "-"}
                          ></SpanPanel>      
                          <SpanPanel
                            title="Nacimiento"
                            data={formatDate(data?.birth_date)}
                          ></SpanPanel>  
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </div>
      <div>
        <Accordion className="accordion-history-clinic">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="header-accordion">
              Antecedentes Médicos
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion-detail">
            <TableContainer>
              <Table>
                <TableHead></TableHead>
                <TableBody>
                  <TableRow className="table">
                    <TableCell
                      align="center"
                      variant="head"
                      className="table-cell"
                    >
                      <div className="detail">
                        <div className="data-wrapper">
                          <SpanPanel
                            title="Edad"
                            data={data?.birth_date && getAge(data?.birth_date)}
                          ></SpanPanel>  
                          <SpanPanel
                            title="Peso"
                            data={getValuePlain(data?.patient_dto?.weight)}
                          ></SpanPanel>     
                          <SpanPanel
                            title="Altura"
                            data={getValuePlain(data?.patient_dto?.size)}
                          ></SpanPanel>                       
                          <SpanPanel
                            title="Enf. preexistentes"
                            data={renderOptions(
                              data?.patient_dto?.pre_existing_disease
                            )}
                          ></SpanPanel> 
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      variant="head"
                      className="table-cell"
                    >
                      <div className="detail">
                        <div className="data-wrapper">
                          <SpanPanel
                            title="Medicación"
                            data={renderOptions(data?.patient_dto?.frequent_medication)}
                          ></SpanPanel>                          
                          <SpanPanel
                            title="Alergias"
                            data={getValuePlain(data?.patient_dto?.allergies)}
                          ></SpanPanel>
                          <SpanPanel
                            title="Ant. familiares"
                            data={getValuePlain(data?.patient_dto?.family_history)}
                          ></SpanPanel>
                          <SpanPanel
                            title="Cirugías"
                            data={renderOptions(data?.patient_dto?.surgeries)}
                          ></SpanPanel>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      variant="head"
                      className="table-cell"
                    >
                      <div className="detail">
                        <div className="data-wrapper">
                          <SpanPanel
                            title="Vacunas"
                            data={getValuePlain(data?.patient_dto?.vaccines)}
                          ></SpanPanel>
                          <SpanPanel
                            title="Datos ginecológicos"
                            data={getValuePlain(data?.patient_dto?.gynecological_data)}
                          ></SpanPanel>                         
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      variant="head"
                      className="table-cell"
                    >
                      <div className="detail">
                        <div className="data-wrapper">
                        <SpanPanel
                            title="Estudios Médicos"
                            data={
                              filesStudy.length !== 0 && (
                                filesStudy.map((file, index) => (
                                  <div style={{padding: '2px'}}>
                                    <Chip
                                      key={index}
                                      avatar={iconsFile(file.type)}
                                      label={file.filename}
                                      onDelete={() => handleDownload(file.base64, file.filename, file.mimetype)}
                                      deleteIcon={<CloudUpload/>}
                                    />
                                  </div>
                                ))
                              )
                            }
                          ></SpanPanel>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default PersonalData;
