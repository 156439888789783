import React, { useEffect, useState } from "react";
import Colors from "../../theme/Colors";
import { getPatientsQueue } from "../../services/queueService";
import { useStateAuthValue } from "../../context/Auth/AuthState";
const TimeWatch = ({ setTime, isVideocallFinished }) => {
  const [initial, setInitial] = useState(+Date.now());
  const [diff, setDiff] = useState(null);

  useEffect(() => {
    setInitial(+Date.now());
  }, []);

  const tick = () => {
    setDiff(new Date(+new Date() - initial));
  };

  useEffect(() => {
    if (initial) {
      requestAnimationFrame(tick);
    }
  }, [initial]);

  useEffect(() => {
    if (diff) {
      requestAnimationFrame(tick);
    }
  }, [diff]);

  useEffect(() => {
    if (isVideocallFinished) {
      const time = timeFormat(diff);
      setTime(time);
    }
  }, [isVideocallFinished]);

  const timeFormat = (date) => {
    if (!date) return "00:00:00";
    const hours =
      date.getUTCHours() > 9 ? date.getUTCHours() : `0${date.getUTCHours()}`;
    const minutes =
      date.getUTCMinutes() > 9
        ? date.getUTCMinutes()
        : `0${date.getUTCMinutes()}`;
    const seconds =
      date.getUTCSeconds() < 10
        ? `0${date.getUTCSeconds()}`
        : date.getUTCSeconds();

    return `${hours}:${minutes}:${seconds}`;
  };

  const [{ userData, userToken }] = useStateAuthValue();

  const handleGetQueue = async () => {
    await getPatientsQueue(
      userToken,
      userData?.professional_dto?.specialties[0]?.id ||
        sessionProfileGet()?.userData.professional_dto?.specialties[0]?.id
    )
      .then((res) => {
        if (res) {
          setPatientsInQueue(res.length);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    handleGetQueue();
    const intervalId = setInterval(async () => {
      handleGetQueue();
    }, 1500);
    return () => clearInterval(intervalId);
  }, []);
  const [patientsInQueue, setPatientsInQueue] = useState(0);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingLeft:"1rem",
          fontSize: 17,
          fontWeight: "bold",
          color: Colors.AzulMediline,
        }}
      >
        Tiempo actual consulta: {timeFormat(diff)}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingLeft:"1rem",
          fontSize: 17,
          fontWeight: "bold",
          color: Colors.AzulMediline,
        }}
      >
        Pacientes en espera: {patientsInQueue}
      </div>
    </>
  );
};

export default TimeWatch;
