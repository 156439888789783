import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
   Radio,
   RadioGroup,
   FormControl,
   FormControlLabel,
   FormHelperText,
   FormLabel,
} from '@material-ui/core';

const RadioGroupField = (props) => {
   const { label, data, extraLabel, className, requiredField, ...rest } = props;
   const [field, meta] = useField(props);
   const isError = !!(meta.touched && meta.error);
   const labelField = requiredField ? `${label}*` : label;

   const renderHelperText = () => {
      if (isError) {
         return (
            <FormHelperText
               className={`field-helper-text ${isError && 'error'}`}
            >
               {meta.error}
            </FormHelperText>
         );
      } else if (extraLabel) {
         return <FormHelperText>{extraLabel}</FormHelperText>;
      }
   };

   return (
      <FormControl {...rest} className={`radio-group-field ${className}`}>
         <FormLabel
            component="legend"
            className={`field-label ${isError && 'error'}`}
         >
            {labelField}
         </FormLabel>
         <RadioGroup {...field} className="radio-group">
            {data.map((item, index) => (
               <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio color="secondary"/>}
                  label={item.name}
                  checked={item.value === field.value}
               />
            ))}
         </RadioGroup>
         {renderHelperText()}
      </FormControl>
   );
};

export default RadioGroupField;

RadioGroupField.defaultProps = {
   data: [],
};

RadioGroupField.propTypes = {
   data: PropTypes.array.isRequired,
};
