import React from 'react';
import { Button, Grid, Tooltip } from '@material-ui/core';
import AutocompleteFreeText from './AutocompleteFreeText';
import { removeIndexFromList } from '../../utils/FormUtil';
import plus from '../../assets/img/plus.svg';
import { Formik } from 'formik';
import * as Yup from 'yup';
// Components
import ListItem from '../ListItem';
import InputField from './InputField';

const AutocompleteListAndField = ({
  allowFreeText = false,
  options,
  stateList,
  setStateList,
  ListFieldLabel,
  setFieldListValue,
  handleDisabled,
  errorList,
  searchTextRequired,
  uniqueMsg,
  searchTextLabel,
  addTooltip,
  field,
}) => {
  const schema = Yup.object().shape({
    searchText: Yup.string()
      .min(1, searchTextRequired)
      .required(searchTextRequired)
      .test("unique", uniqueMsg, function (value) {
        return (
          !stateList?.length ||
          (value && !stateList.find((item) => item.name === value))
        );
      }),
  });

  const initialValues = { searchText: '', field: '' }

  const addItem = (value, validateForm, setField) => {
    value.searchText = value.searchText.trim();
    if (value.searchText?.length) {
      if (stateList.find((item) => item.name === value.searchText)) {
        return;
      }
      const newList = [
        {
          id: Math.random().toString(), // NOSONAR
          name: value.searchText,
          primary: value.searchText,
          field: value.field,
        },
        ...stateList,
      ];
      setStateList(newList);
      setFieldListValue(newList);
      handleDisabled(false);
      Object.keys(value).forEach((key) => setField(key, initialValues[key]));
      validateForm(initialValues);
    }
  };
  const removeItem = (index) => {
    if (index || index === 0) {
      const newList = removeIndexFromList(index, stateList, setStateList);
      setFieldListValue(newList);
      if (!newList.length) {
        handleDisabled(true);
      }
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        validateOnMount
        enableReinitialize
      >
        {({ errors, values, setFieldValue: setField, validateForm }) => (
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
            <Grid item xs={10}>
              <AutocompleteFreeText
                options={options}
                allowFreeText={allowFreeText}
                inputKey="searchText"
                inputValue={values.searchText}
                setField={(value) => {
                  setField("searchText", value);
                  values.searchText = value;
                  validateForm(values);
                }}
                searchTextLabel={searchTextLabel}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                width={40}
                type="button"
                fullWidth={false}
                disabled={
                  !!errors?.searchText ||
                  !values.searchText ||
                  errors?.searchText
                }
                className="plus-button"
                onClick={() => {
                  addItem(values, validateForm, setField);
                }}
              >
                <Tooltip title={addTooltip}>
                  <img src={plus} alt="plus" />
                </Tooltip>
              </Button>
            </Grid>
            <InputField
              maxLength={120}
              name={'field'}
              label={field.label}
              placeholder={field.placeholder}
              rows={10}
              className="field-small"
            />
            {stateList.length !== 0 && (
              <ListItem
                data={stateList}
                removeItem={removeItem}
                noDataMessage=''
                required={true}
                errors={errorList}
                label={ListFieldLabel}
                className="field"
                displayKeys={['field']}
              />
            )}
          </Grid>
        )}
      </Formik>
    </>
  );
}
export default AutocompleteListAndField;
