import React from 'react';
import { useFormikContext } from 'formik';

// Patient Data
import AddressDetail from './patient/AddressDetail';
import MedicalRecordDetail from './patient/MedicalRecordDetail';

// Professional Data
import ProfilePhotoDetail from './professional/ProfilePhotoDetail';
import ProfessionalDataDetail from './professional/ProfessionalDataDetail';
import ProfileSignPhotoDetail from './professional/ProfileSignPhotoDetail';
import AttentionDataDetail from './professional/AttentionDataDetail';

// Shared Data
import PersonalInformationPatientDetail from './patient/PersonalInformationPatientDetail';
import PersonalInformationProfessionalDetail from './professional/PersonalInformationProfessionalDetail';

import { Role } from '../../../models/enums';

const ReviewProfile = ({ currentRole, isFam }) => {
   const isPatient = currentRole === Role.PATIENT;
   const isProfessional = currentRole === Role.PROFESSIONAL;
   const { values } = useFormikContext();

   return (
      <>
         <div className="header">
            <h2>Datos Ingresados</h2>
         </div>
         <div
            className={`detail-wrapper ${
               isProfessional ? 'detail-wrapper--professional' : ''
            }`}
         >
            {isPatient && (
               <>
                  <PersonalInformationPatientDetail formValues={values} isFam={isFam}/>
                  <AddressDetail formValues={values} />
                  <MedicalRecordDetail formValues={values} />
               </>
            )}
            {isProfessional && (
               <>
                  <PersonalInformationProfessionalDetail formValues={values} />
                  <ProfilePhotoDetail formValues={values} />
                  <ProfileSignPhotoDetail formValues={values} />
                  <ProfessionalDataDetail formValues={values} />
                  <AttentionDataDetail formValues={values} />
               </>
            )}
         </div>
      </>
   );
};

export default ReviewProfile;
