import { getDecodedToken, Logout } from './authService';
import { momentDate } from '../utils/DateUtil';

const defaultHeaders = {
  Connection: "close",
  Accept: "application/json",
  "Content-Type": "application/json",
};

const apiFetch = (url, method, headers, body = null) => 
  fetch(url, {
    method: method,
    headers: headers,
    body: body ? JSON.stringify(body) : undefined,
  }).then((response) => response.json());

const get = (url = "", headers = {}) =>
  apiFetch(url, "GET", { ...defaultHeaders, ...headers });

const post = (url = "", body = {}, headers = {}) =>
  apiFetch(url, "POST", { ...defaultHeaders, ...headers }, body);

const put = (url = "", body = {}, headers = {}) =>
  apiFetch(url, "PUT", { ...defaultHeaders, ...headers }, body);

const del = (url = "", body = {}, headers = {}) =>
  apiFetch(url, "DELETE", { ...defaultHeaders, ...headers }, body);

const patch = (url = "", body = {}, headers = {}) =>
  apiFetch(url, "PATCH", { ...defaultHeaders, ...headers }, body);

const Authorization  = (token, displayError = null) => {
  // In case of use "expires in" attribute we must use the method GetSessionToken() and session.expirationDate without * 1000
  const session = getDecodedToken(token);
  if (token && session) {
    const expirationDate = momentDate(session.exp * 1000);
    if (expirationDate.isSameOrBefore(momentDate(new Date()))) {
      const message = 'Tu sesión ha expirado por favor inicia sesión nuevamente';
      if (displayError) {
        displayError(message);
      } else {
        alert(message);
      }
      Logout();
      throw new Error(message);
    }
  }
  return token ? {
    Authorization: `Bearer ${token}`,
  } : undefined;
};

const concatNotNullParam = (url, params) => {
  for (const key in params) {
    const param = params[key];
    if (param === 0 || (param && param !== undefined && param !== '' && 
      (!Array.isArray(param) || param.length) )) {
      if (url.indexOf('?') > -1) {
        url += `&${key}=${param}`;
      } else {
        url += `?${key}=${param}`;
      }
    }
  }
  return url;
};

export default {
  get,
  post,
  put,
  patch,
  Authorization,
  concatNotNullParam,
  delete: del,
};
