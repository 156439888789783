import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { InputField, SelectField } from "../../../../../components/FormFields";
import Typography from "@material-ui/core/Typography";
import { useField, useFormikContext } from 'formik';

function Certificate({ formField, hasErrors, handleDisabled, consultation }) {
  const baseForm = "certificates.";
  const {
    diagnosis,
    certificates: { indications, unit, duration, institution },
  } = formField;
  const [title, setTitle] = useState(
    'Dejo constancia que el Sr/a: Paciente, DNI: Nro presenta el día de la fecha,'
  );
  const [field] = useField(baseForm + unit.name);
  const { values } = useFormikContext();


  useEffect(() => {
    handleDisabled(hasErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasErrors]); 
  

  useEffect(() => {
    if(values?.certificates && !values.certificates.diagnosis){
      values.certificates.diagnosis = values.Diagnosis;
    }
  }, [values?.certificates]); 

  useEffect(() => {
    if (consultation) {
      const patient = consultation?.patient_dto?.user;
      const fullName = patient?.first_name
        ? `${patient?.first_name} ${patient?.last_name || ''}`
        : patient?.alias || patient?.username || "Paciente";
      setTitle(
        `Dejo constancia que el Sr/a: ${fullName}, DNI: ${
          patient?.id_number || 'Nro'
        }, presenta el día de la fecha,`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultation]);

  return (
    <div>
      <div>
      <Typography component={"span"}>
        {title}
      </Typography></div><br></br>
      <div>
        <InputField
          onKeyPress={e => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          name={baseForm + diagnosis.name}
          value={values?.certificates?.diagnosis ? values.certificates.diagnosis : values?.Diagnosis}
          label={diagnosis.label}
          className="field"
        />
      </div><br></br>
      <InputField
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        name={baseForm + indications.name}
        label={indications.label}
        className="field"
        maxLength={80}
      />      
      <div className="pt-10">
        <Typography component={"span"}>Durante:</Typography>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <SelectField
              name={baseForm + unit.name}
              data={days}
              className="field"
            />
          </Grid>
          <Grid item xs={3}>
            <SelectField
              name={baseForm + duration.name}
              data={field.value === 'Horas'? numbersHours : numbersDays}
              className="field"
            />
          </Grid>
        </Grid>
      </div><br></br>
      <InputField
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        name={baseForm + institution.name}
        label={institution.label}
        className="field"
        maxLength={80}
      />
    </div>
  );
}
export default Certificate;
const days = [
  { value: 'Horas', label: 'Horas' },
  { value: 'Dias', label: 'Dias' }
];
const numbersDays = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
  { value: 17, label: '17' },
  { value: 18, label: '18' },
  { value: 19, label: '19' },
  { value: 20, label: '20' },
  { value: 21, label: '21' },
  { value: 22, label: '22' },
  { value: 23, label: '23' },
  { value: 24, label: '24' },
  { value: 25, label: '25' },
  { value: 26, label: '26' },
  { value: 27, label: '27' },
  { value: 28, label: '28' },
  { value: 29, label: '29' },
  { value: 30, label: '30' }
];

const numbersHours = [
  { value: 24, label: '24' },
  { value: 32, label: '32' },
  { value: 48, label: '48' },
  { value: 72, label: '72' } 
];