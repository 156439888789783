import React, { useEffect } from 'react';
import InputField from '../../../../../components/FormFields/InputField';
import { useFormikContext } from 'formik';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { GetPreexistingDisease } from '../../../../../services/userService';
import { useStateAuthValue } from '../../../../../context/Auth/AuthState';
import { useToast } from '../../../../../context/Toast/ToastProvider';
import { ToastType, showToast } from '../../../../../utils/ToastUtil';
import { useState } from 'react';
import { Grid } from '@material-ui/core';

const ConsultRegistry = ({ formField, hasErrors, handleDisabled, consultation }) => {
   const { diagnosis, detail, reason } = formField;
   const { values } = useFormikContext();
   const [{ userToken }] = useStateAuthValue();
   const toastDispatch = useToast();
   const [preexistingDiseaseList, setPreexistingDiseaseList] = useState([]);
   const [preexistingDisease, setPreexistingDisease] = useState(null);
   
   useEffect(() => {
      const getData = async () => {
         await GetPreexistingDisease(userToken, (sessionError) => showToast(toastDispatch, sessionError, ToastType.ERROR, ToastType.ERROR))
         .then((response) => {
            if (!response.data.error) {
               if (response.data.length !== 0) {
                  response.data.forEach(function(obj) {
                     obj.label = obj.name;
                  });
                  setPreexistingDiseaseList(response.data);
               }
            } else {
               showToast(
                  toastDispatch,
                  'Ocurrió un error en la búsqueda de enfermedades preexistentes',
                  ToastType.ERROR,
               );
            }
         })
         .catch((err) => {
            showToast(toastDispatch, err.message, ToastType.ERROR);
         });
      }
      if (userToken) {
         getData();
      }
   }, [userToken]);

   useEffect(() => {
      if(!values?.reason && consultation?.reason){
         values.reason = consultation?.reason;
      }
   }, []);

   useEffect(() => {
      handleDisabled(hasErrors);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [hasErrors]);

   const filterPreexistingDisease = (inputValue) => {
      return preexistingDiseaseList.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    };

   const promiseOptions = inputValue =>
      new Promise(resolve => {
         resolve( inputValue.length > 2 ? filterPreexistingDisease(inputValue) : null);
   });

   const noOptionsMessage = inputValue => {
      if (inputValue && inputValue.inputValue.length < 3) {
        return 'Debe ingresar al menos 3 caractes';
      }else{
        return 'No se encontraron medicamentos';
      }
   };

   const selectCustomStyles = {
      control: (provided, state) => ({
          ...provided,
          width: "100%",
          border: "none",
          borderBottom: "1px solid #6c6c6c",
          boxShadow: state.isFocused,
          background: "transparent",
          borderRadius: "none",
           fontSize: "12px",
          margin: "-6px 0 0 0"
      }), indicatorSeparator: () => ({
          display: "none"
      }), dropdownIndicator: provided => ({
          ...provided, color: "#0c0c0c"
      }), valueContainer: provided => ({
          ...provided, padding: "0"
      }), menuPortal: base => ({ ...base, zIndex: 1301 }),
      menu: (provided) => ({
        ...provided,
        fontSize: "12px",
      }),
   };

   return (
      <>
         <InputField
            name={reason.name}
            label={reason.label}
            value={values?.reason ? values.reason : consultation?.reason}
            rows={3}
            className="field"
         />
         <Grid item xs={10}>
            <label  className="label-medication">{diagnosis.label}</label>
            {
               <AsyncCreatableSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={promiseOptions}
                  onChange={ e => {
                     values.diagnosis =  e ? e.label : null;
                     values.Diagnosis = e ? e.label : null;
                     setPreexistingDisease(e);
                     handleDisabled( e ? false : true);
                  }}
                  value={preexistingDisease}
                  placeholder={"Buscar Enfermedades"}
                  styles={selectCustomStyles}
                  noOptionsMessage={noOptionsMessage}
                  isClearable
               />
            }
         </Grid>
         <InputField
            name={detail.name}
            label={detail.label}
            placeholder={detail.placeholder}
            rows={10}
            className="field-small"
         />
      </>
   );
};
export default ConsultRegistry;
