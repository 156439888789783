import React from "react";

export default function SpanPanel({ title, data }) {
  return (
    <span className="data">
      {title}:<span className="normal">{data}</span>
    </span>
  );
}

