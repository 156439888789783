import React from 'react';
import { useField } from 'formik';
import {
   Checkbox,
   FormControl,
   FormControlLabel,
   FormHelperText,
} from '@material-ui/core';

const CheckboxField = (props) => {
   const { label, ...rest } = props;
   const [field, meta, helpers] = useField({ ...props, type: 'checkbox' });
   const { setValue } = helpers;
   const { touched, error } = meta;
   const isError = !!(touched && error);

   const renderHelperText = () => {
      if (isError) {
         return <FormHelperText>{error}</FormHelperText>;
      }
   };

   const onChange = (e) => {
      setValue(e.target.checked);
   };

   return (
      <FormControl {...rest} error={isError}>
         {renderHelperText()}
         <FormControlLabel
            control={<Checkbox {...field} onChange={onChange} />}
            label={label}
         />
      </FormControl>
   );
};

export default CheckboxField;
