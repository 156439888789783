import React from "react";
import Modal from "../Modal";
import { Grid, Button } from "@material-ui/core";
import { ApplicationStyle } from "../../theme";
import { makeStyles } from "@material-ui/core/styles";

const MedilineStyle = makeStyles(ApplicationStyle);
const ConfirmationModal = ({
  open,
  title,
  subtitle,
  confirmButton,
  cancelButton,
  outlinedCancel = false,
  outlinedConfirm = false,
  handleClose,
  maxWidth
}) => {
  const MedilineClasses = MedilineStyle();

  return (
    <Modal isActive={open} handleClose={handleClose} maxWidth={maxWidth}>
      <div className={MedilineClasses.btnBoxCenterM0}>
        <div className={MedilineClasses.titleBlue}>{title}</div>
        { subtitle && (
          <div className={MedilineClasses.subtitleBlue}>{subtitle}</div>
        )}
        <div className={MedilineClasses.btnSpacing}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Button className={outlinedCancel? MedilineClasses.btnRedNextOutline : MedilineClasses.btnSubmitRed}
                onClick={() => handleClose(false)}>
                 {cancelButton}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={outlinedConfirm? MedilineClasses.btnBlueOutline : MedilineClasses.btnBlueNext}
                onClick={() => handleClose(true)}
              >
                {confirmButton}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
