import React from 'react';



const LiquidationList = () => {
   
   return (<div className="liquidation-list">
   <div className="header">
      {/*<img src={HeaderImage} alt="Liquidaciones" />
      <h2>Liquidaciones</h2>*/}
   </div>

   <div className="container">
    
      </div>
   </div>
);
}
   

export default LiquidationList;
