import React from 'react';

import ScheduleList from './ScheduleList';
import CalendarIcon from '../../../assets/img/agenda.svg';

const AvailabilitySchedule = () => {
   return (
      <div className="schedule">
         <div className="header">
            <img src={CalendarIcon} alt="Agenda de Turnos" />
            <h2>Agenda de Turnos</h2>
         </div>
         <div className="container">
            <ScheduleList />
         </div>
      </div>
   );
};

export default AvailabilitySchedule;
