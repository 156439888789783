import React, { useEffect, useState } from 'react';
import { Field, useField } from 'formik';
import { TextField } from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AutocompleteFieldFreeSolo = (props) => {
   const { label, name, options, className, placeholder, requiredField } = props;
   const [field, meta, helpers] = useField(props.name);
   const { value } = field;
   const { touched, error } = meta;
   const { setValue } = helpers;
   const isError = !!(touched && error);
   const [selectedOptions, setSelectedOptions] = useState();
   const areOptions = options?.length;
   const areValues = value?.length;
   const labelField = requiredField ? `${label}*` : label;

   useEffect(() => {
      if (areOptions && areValues) {
         let defaultValues = [];

         if (typeof value[0] === 'number') {
            defaultValues = options.filter(
               (opt) => opt.id && value.includes(opt.id),
               );
            } else if (value[0]?.id) {
               // when entities are returned
               defaultValues = value;
         }

         setValue(defaultValues);
         setSelectedOptions(defaultValues);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [areOptions, areValues, options, value]);
   const renderHelperText = () => {
      if (isError) {
         return meta.error;
      }
   };

   const onChange = (e, values) => {
      if (values) {
         setValue(values);
      }
   };
   return (
      <div className={className}>
         <Autocomplete
            freeSolo
            {...field}
            onChange={onChange}
            defaultValue={selectedOptions}
            options={areOptions ? options : []}
            getOptionLabel={(opt) => opt?.name || ''}
            getOptionSelected={(opt, val) =>
               opt?.val === '' || opt?.name === val.name
            }
            noOptionsText={'No hay opciones'}
            renderInput={(params) => (
               <Field
                  {...params}
                  label={labelField}
                  name={name}
                  component={TextField}
                  inputProps={params.inputProps}
                  placeholder={placeholder}
                  error={isError}
                  helperText={renderHelperText}
                  />
                  )}
               />
      </div>
   );
};

export default AutocompleteFieldFreeSolo;
