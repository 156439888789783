import React,{ useEffect, useState } from "react";
import { useStateProfConsultFilterValue } from "../../context/profConsultFilterState";
import axios from "axios";
import { showToast, ToastType } from "../../utils/ToastUtil";
import { Call } from "./Call";
import { useToast } from "../../context/Toast/ToastProvider";

const VideoCallPatient = ({ videoCallData, setVideoCall, setMessage, consultSuccess, setConsultSuccess, setQueue, localTracks, setLocalTracks }) => {
  const [{ data, stomp }, dispatch] = useStateProfConsultFilterValue();
  const toastDispatch = useToast();
  const [token, setToken] = useState("");

  useEffect(() => {
    if(data) setQueue(data);
  }, [data]);

  useEffect(() => {
    if(videoCallData && videoCallData.length !== 0){
      const getToken = async () => {
      const url = `${process.env.REACT_APP_AGORA_TOKEN_SERVICE}/rtc/${videoCallData.channel}/${videoCallData.role}/uid/${videoCallData.uid}`;
      try {
        const response = await axios.get(url);
        const token = response.data.rtcToken;
        setToken(token);
      } catch (err) {
        if(process.env.REACT_APP_DEBUG === "true"){
          const errorMessage = "Error:  ~ file: videoCallPatient.js:22 ~ getToken ~ err" + err;
          showToast(toastDispatch, errorMessage, ToastType.ERROR, ToastType.ERROR);
         }
      }
      };
      getToken();
    }
    
  }, [videoCallData]);

  return (
    token && (
      <div>
          <Call
          rtcProps={{
            appId: process.env.REACT_APP_AGORA_APP_ID,
            channel: videoCallData.channel,
            token: token,
            uid: videoCallData.uid,
          }}
          localTracks={localTracks}
          setLocalTracks={setLocalTracks}
          consultSuccess={consultSuccess}
          setConsultSuccess={setConsultSuccess}
          setVideoCall={setVideoCall}
          setMessage={setMessage}
          stomp={stomp}
          stompData={data}
          stompDispatch={dispatch}
        />
      </div>
      
    )
  );
};

export default VideoCallPatient;
