const InitialValues = {
   detail: '',
   diagnosis: '',
   reason: '',
   Diagnosis: '',
   /*
   specialties: {
      diagnosis: '',
      specialtyNames: []
   },
   studies: {
      diagnosis: '',
      studyNames: []
   },
   certificates: {
      diagnosis: '',
      institution: [],
      indications: '',
      duration: '',
      unit: ''
   },
   medications: [
      {
          "name": "medicament 1",
          "posology": "posology 1",
          "diagnosis": "diagnosis 1",
          "quantity": 1
      }
   ]*/
 };
 export default InitialValues;