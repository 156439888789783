import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useClearCache } from 'react-clear-cache';

import Logo from '../../assets/img/logo.png';
import BackgroundHome from '../../assets/img/background-home.jpg';

const Home = () => {
  const { emptyCacheStorage } = useClearCache();
  const history = useHistory();

  return (
    <>
      <div
        className="home"
        style={{ backgroundImage: `url(${BackgroundHome})` }}
      ></div>
      <div className="home-container">
        <div className="header">
          <img src={Logo} alt="Logo" />
          <h2>Hola!</h2>
        </div>
        <div className="button-wrapper">
          <Button
            onClick={() => {
              if(process.env.REACT_APP_CACHE_SHOULD_CLEAN === "true"){
                emptyCacheStorage({duration: 200})
              }
                history.push("/login")
            }}
            className="button button--login"
            variant="contained"
          >
            Iniciar Sesión
          </Button>
          {process.env.REACT_APP_ENABLE_SIGNUP === 'true' && (
            <Button
              href="/signup"
              className="button button--sign-up"
              variant="outlined"
            >
              Registrarme
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
