import ParametricsWrapper from "@mediline/mediline-api-middleware/src/wrappers/parametrics.wrapper";

const phw = new ParametricsWrapper();

export const EventLog = async (userToken, source, type, object) => await phw.eventLog(userToken, source, type, object);

export const GetMedilineFinancers = async (userToken) => await phw.getFinancers(userToken);

export const GetMedilinePlanByFinancer = async (userToken, financerId) => phw.getPlansByFinancer(userToken, financerId);

export const GetMedilineFinancerByPatient = async (userToken, patientId) => phw.getPlanByPatient(userToken, patientId);

export const GetMedilinePlanById = async (userToken, planId) => phw.getPlanById(userToken, planId);

export const GetMedilineFinancersById = async (userToken, id) => await phw.getFinancersById(userToken, id);
