import React, { useState } from 'react';
import './chat.css'
import Conversation from './ChatSend';
import Close from '@material-ui/icons/Close';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { processEmail, validatePhone } from '../../utils/ToastUtil';
import LinearProgress from '@material-ui/core/LinearProgress';
const ChatOverlaySend = ({ setOpenChatSend, openChatSend, setPatientSend, patientSend }) => {
  const [template, setTemplate] = useState('');
  const [loading, setLoading] = useState(false);

  const closeChat = () => {
    setOpenChatSend(false);
    setPatientSend(null);
    setTemplate('');
  };

  return (
    <div>
      <div className="chat-container" style={{ display: openChatSend ? '' : 'none' }}>
        <div className="chat-overlay">
          <button className="close-button" onClick={closeChat}>
            <Close></Close>
          </button>
          <Card sx={{ maxWidth: 345 }}>
            <CardHeader
              avatar={
                <Avatar aria-label="recipe">
                </Avatar>
              }
              title={(patientSend?.first_name ? patientSend.first_name : '') + ' ' +(patientSend?.last_name ? patientSend.last_name : '')}
              subheader={validatePhone(patientSend?.phone_number) ? (patientSend?.phone_number ? patientSend?.phone_number : '') : (patientSend?.familymember ? processEmail(patientSend?.familymember) : '')}
            />
            {loading ? <LinearProgress/> : null}
            <CardContent>
              <Conversation setLoading={setLoading} patientSend={patientSend} setTemplate={setTemplate} template={template} overlayVisible={openChatSend} ></Conversation>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ChatOverlaySend;