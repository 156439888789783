import React, { useState } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';

import PrescriptionList from './PrescriptionList';
import HeaderImage from '../../../assets/img/misRecetas.svg';
import { PrescriptionType } from '../../../models/enums';

const Prescription = () => {
   const [prescriptionType, setPrescriptionType] = useState(
      PrescriptionType.NEW,
   );

   return (
      <div className="prescription">
         <div className="header">
            <div className="image-title">
               <img src={HeaderImage} alt="Mis Recetas" />
               <h2>Mis Recetas</h2>
            </div>
            <ButtonGroup className="btn-group">
               <Button
                  onClick={() => setPrescriptionType(PrescriptionType.NEW)}
                  className={`btn ${
                     prescriptionType === PrescriptionType.NEW ? 'active' : ''
                  }`}
               >
                  Nuevas
               </Button>
               <Button
                  onClick={() =>
                     setPrescriptionType(PrescriptionType.DELIVERED)
                  }
                  className={`btn ${
                     prescriptionType === PrescriptionType.DELIVERED
                        ? 'active'
                        : ''
                  }`}
               >
                  Enviadas
               </Button>
               <Button
                  onClick={() => setPrescriptionType(PrescriptionType.EXPIRED)}
                  className={`btn ${
                     prescriptionType === PrescriptionType.EXPIRED
                        ? 'active'
                        : ''
                  }`}
               >
                  Vencidas
               </Button>
            </ButtonGroup>
         </div>
         <div className="container">
            <PrescriptionList filterStatus={prescriptionType} />
         </div>
      </div>
   );
};

export default Prescription;
