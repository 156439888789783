import React, { createContext, useContext, useReducer } from 'react';
import { meetingSet, meetingGet } from '../../services/authService';

export const VirtualGuardInitialState = {
    name: null,
    consultation: null
 };

const VirtualGuardReducer = (state, action) => {
    switch (action.type) {
      case 'SAVE': {
        const context = {...state};
        meetingSet(context);
        return context;
      }
      case 'GET': {
        let oldContext= meetingGet();
        if (!oldContext) {
          oldContext = VirtualGuardInitialState
        }
        return oldContext;
      }
      default:
            return state;
    }
};

const VirtualGuardContext = createContext();

const VirtualGuardProvider = ({children}) => {
  return (
    <VirtualGuardContext.Provider value={useReducer(VirtualGuardReducer, VirtualGuardInitialState)}>
      {children}
    </VirtualGuardContext.Provider>
  );
};

export default VirtualGuardProvider;

export const UseStateVirtualGuard= () => useContext(VirtualGuardContext);