import axios from "axios";
import decode from "jwt-decode";
import { Buffer } from "buffer";
import { deleteFormValues } from "./localStorageService";
import { AUTH_GATEWAY, PROFESSIONAL_LOG } from "../conf/appSetting";

import HttpClient from "./fetch_api";

export const AUTH_CONNECTION_USERNAME_PASSWORD =
  "Username-Password-Authentication";
export const AUTH_CONNECTION_GOOGLE_OAUTH2 = "google-oauth2";
export const AUTH_SUBFIX_AUTH0 = "auth0";
export const AUTH_SUBFIX_GATEWAY = "gateway";
const ENCODING = "base64";
const TOKEN_KEY = Buffer.from("MEDILINE_TOKEN").toString(ENCODING);
const PROFILE = Buffer.from("MEDILINE_PROFILE").toString(ENCODING);
const MEETING = Buffer.from("MEETING").toString(ENCODING);

export function sessionSet(valueParam, realm, expirationInSeconds = 10) {
  const expirationDate = new Date(Date.now() + 1000 * expirationInSeconds);
  const newValue = {
    value: valueParam,
    auth_method: realm,
    expirationDate: expirationDate.toISOString(),
  };
  sessionStorage.setItem(
    TOKEN_KEY,
    Buffer.from(JSON.stringify(newValue)).toString(ENCODING)
  );
}

export function sessionProfileSet(data) {
  if (data?.professional_dto?.photo_encode) {
    data.professional_dto.photo_encode = "";
  }
  sessionStorage.setItem(
    PROFILE,
    Buffer.from(JSON.stringify(data)).toString(ENCODING)
  );
}

export function sessionProfileGet() {
  const stringValue = sessionStorage.getItem(PROFILE);
  if (stringValue !== null && stringValue?.length) {
    try {
      return JSON.parse(Buffer.from(stringValue, ENCODING).toString());
    } catch {
      return null;
    }
  }
  return null;
}

export function meetingSet(data) {
  sessionStorage.setItem(
    MEETING,
    Buffer.from(JSON.stringify(data)).toString(ENCODING)
  );
}

export function meetingGet() {
  const stringValue = sessionStorage.getItem(MEETING);
  if (stringValue !== null) {
    return JSON.parse(Buffer.from(stringValue, ENCODING).toString());
  }
  return null;
}

export function sessionGet() {
  const stringValue = sessionStorage.getItem(TOKEN_KEY);
  if (stringValue) {
    const value = JSON.parse(Buffer.from(stringValue, ENCODING).toString());
    const expirationDate = new Date(value.expirationDate);
    if (expirationDate > new Date()) {
      return value;
    }
    window.sessionStorage.removeItem(TOKEN_KEY);
  }
  return null;
}

export function GetSessionToken() {
  const session = sessionStorage.getItem(TOKEN_KEY);
  if (session !== null) {
    return JSON.parse(Buffer.from(session, ENCODING).toString());
  }
  return null;
}
export function GetCrUserId() {
  return GetUserBySession().sub;
}

export function getDecodedToken(token) {
  return decode(token);
}

export function GetUserBySession() {
  const tokenKey = sessionGet();
  if (!tokenKey) return null;

  return decode(tokenKey?.value?.idToken || tokenKey?.value?.id_token);
}
export function ChekSession() {
  return sessionGet();
}

export async function Logout() {
  if (sessionGet()) {
    sessionStorage.clear();
  }
  deleteFormValues();
}
export async function Renewal() {}

export const AuthGoogle = async () => {
  // eslint-disable-next-line no-undef
  return (window.location = `https://accounts.google.com/o/oauth2/auth?client_id=${process.env.REACT_APP_GMAIL_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email`);
};

export async function LogIn(emailParam, passwordParam, rememberMe = false) {
  return axios.post(AUTH_GATEWAY.base, {
    username: emailParam,
    password: passwordParam,
    rememberMe,
  });
}

export async function ProfessionalLog(emailParam,event, token) {
  return axios.post(`${PROFESSIONAL_LOG.log}/${emailParam}/${event}`, null, {
    headers: {
      Authorization: `Bearer ${token}`, // Incluir el token Bearer en el encabezado Authorization
    },
  });
}

export async function RecoverPass(email) {
  return axios.post(`${AUTH_GATEWAY.recover}`, email, {
    headers: {
      "Content-Type": "text/plain",
    },
  });
}

export async function FinishRecoverPass(params) {
  return axios.post(AUTH_GATEWAY.recoverFinish, params);
}

export async function LogInSocial(emailParam) {
  return axios.post(AUTH_GATEWAY.social, null, {
    params: { email: emailParam },
  });
}

export async function Signup(params) {
  return axios.post(AUTH_GATEWAY.register, params);
}

export async function ActivateAccount(params) {
  return axios.get(
    HttpClient.concatNotNullParam(AUTH_GATEWAY.activate, params)
  );
}

export async function PassUpdate(params, token) {
  return axios.post(AUTH_GATEWAY.passwordUpdate, params, {
    headers: HttpClient.Authorization(token),
  });
}
