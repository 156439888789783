import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputField from './InputField';
import { Formik, useField } from 'formik';
import * as Yup from 'yup';

const AsyncAutocomplete = ({
    className,
    name,
    options,
    selected,
    label,
    searchTextLabel,
    searchTextMinRequired,
    requestMethod = null,
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  
  const schema = Yup.object().shape({
    searchText: Yup.string()
      .min(3, searchTextMinRequired)
  });

return (
    <>
      <Formik
        initialValues={{ searchText: '' }}
        validationSchema={schema}
        validateOnMount
        enableReinitialize
        >
        {({ values, setFieldValue: setField, validateForm }) => (

          <div className={className}>
            <Autocomplete
              {...field}
              multiple={true}
              limitTags={4}
              noOptionsText={values.searchText?.length > 2 ?
                "No se encontraron coincidencias" : searchTextMinRequired}
              options={options || []}
              defaultValue={selected}
              getOptionLabel={(option) =>
                option?.name || option?.description || option?.label || (option.length ? option : "") || "- Opción sin Nombre-"
              }
              getOptionSelected={(opt, val) =>
                opt?.val === '' || opt?.name === val.name
              }
              blurOnSelect
              renderInput={(params) => (
                <InputField
                  name="searchText"
                  label={label}
                  placeholder={searchTextLabel}
                  className="field"
                  {...params}
                  change={(evt) => {
                    const newValue = evt.currentTarget.value || '';
                    setField("searchText", newValue);
                    if (requestMethod) {
                      requestMethod(newValue);
                    }
                    validateForm(values);
                  }}
                />
              )}
              id="asyncAutocomplete"
              onChange={(_, value) => { 
                setValue(value);
              }}
            />
          </div>
        )}
      </Formik>
    </>
  );
}

export default AsyncAutocomplete;
