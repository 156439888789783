import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
   Button,
   CircularProgress,
   Stepper,
   Step,
   StepLabel,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { v4 as uuidv4 } from 'uuid';
// Patient Data
import {
   patientInitialValues,
   patientFormModel,
   patientValidationSchema,
} from './FormModels/patient';
import AddressForm from './Forms/patient/AddressForm';
import MedicalRecordForm from './Forms/patient/MedicalRecordForm';
import { PatientProfile } from '../../services/patientService';

// Professional Data
import {
   professionalInitialValues,
   professionalFormModel,
   professionalValidationSchema,
} from './FormModels/professional';
import AttentionDataForm from './Forms/professional/AttentionDataForm';
import ProfessionalDataForm from './Forms/professional/ProfessionalDataForm';
import ProfilePhotoForm from './Forms/professional/ProfilePhotoForm';
import ProfileSignPhotoForm from './Forms/professional/ProfileSignPhotoForm';
import { ProfessionalProfile } from '../../services/professionalService';

// Shared Data
import PersonalInformationPatientForm from './Forms/patient/PersonalInformationPatientForm';
import PersonalInformationProfessionalForm from './Forms/professional/PersonalInformationProfessionalForm';

import ReviewProfile from './ReviewProfile';
import HeaderImage from '../../assets/img/miPerfil.svg';
import { AttentionTypeEnum, Role } from '../../models/enums';
// import { AttentionTypeEnum, Role } from '../../models/enums';
import { showToast, ToastType } from '../../utils/ToastUtil';
import {
   fileToBase64,
   initialValuesByRole,
   optionsToIds
} from '../../utils/FormUtil';
import { GetUserProfile } from '../../services/apiService';
import { GetUserBySession } from '../../services/authService';
import { useToast } from '../../context/Toast/ToastProvider';
import { useStateAuthValue } from '../../context/Auth/AuthState';
import { GetPrepaidHealthCategory } from '../../services/prepaidHealthService';
import { OK } from '../../utils/ServiceUtil';

// TODO: Create a better way to populate data based on user role, an Interface maybe..
// For steps, form components, form models, etc..

const patientSteps = [
   'Información Personal',
   'Dirección',
   'Antecedentes Médicos',
   'Datos Ingresados',
];

const professionalSteps = [
   'Información Personal',
   'Foto de Perfil',
   'Firma Profesional',
   'Datos Profesionales',
   'Datos de Atención',
   'Datos Ingresados',
];

const FullRegistration = () => {
   const history = useHistory();
   const [{ userData, userToken }, dispatch] = useStateAuthValue();
   const currentRole = userData?.active_role;
   const isPatient = currentRole === Role.PATIENT;
   const isProfessional = currentRole === Role.PROFESSIONAL;
   const toastDispatch = useToast();
   const [particularPrepaidHealth, setParticularPrepaidHealth] = useState({});
   const [particularPrepaidHealthCategory, setParticularPrepaidHealthCategory] = useState({});
   const [formValues, setFormValues] = useState([]);
   const [activeStep, setActiveStep] = useState(0);
   const [validationSchema, setValidationSchema] = useState({});
   const [formModel, setFormModel] = useState({});
   const [steps, setSteps] = useState([]);
   const currentValidationSchema = validationSchema[activeStep];
   const lastStep = steps.length - 1;
   const isLastStep = activeStep === lastStep;

   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, 'Error');
   };

   useEffect(() => {
      if (isPatient) {
         setSteps(patientSteps);
         setFormModel(patientFormModel);
         setValidationSchema(patientValidationSchema);
      } else if (isProfessional) {
         setSteps(professionalSteps);
         setFormModel(professionalFormModel);
         setValidationSchema(professionalValidationSchema);
      }
   }, [isPatient, isProfessional]);

   useEffect(() => {
      if (userData?.id_number) {
         if (isPatient) {
            setFormValues(patientInitialValues);
         } else if (isProfessional) {
            setFormValues(professionalInitialValues);
         }

         setFormValues(initialValuesByRole(currentRole, userData));
   
   }}, [userData, isPatient, isProfessional, currentRole]);

   useEffect(() => {
      if (!userToken) {
         return;
      }
     const errorMessage = "Ocurrió un error buscando el perfil del usuario";
     GetUserProfile(userToken, showError)
       .then((response) => {
         if (response.status === "ok") {
           const userAuth = GetUserBySession();
           response.data.email =
             response?.data?.email || userAuth?.email || null;
           response.data.username =
             response?.data?.username || userAuth?.nickname || null;
           dispatch({
             type: "SAVE_USER_INFO",
             userAuth,
             userData: response?.data
           });
           dispatch({ type: "SAVE_SESSION" });
         } else {
           showError(errorMessage);
         }
       })
       .catch(() => showError(errorMessage));
   }, [userToken]);

   const renderStepContent = (step, touched,errors) => {
      switch (step) {
         case 0:
            if (isPatient) {
               return <PersonalInformationPatientForm formModel={formModel} touched={touched} particularPrepaidHealth={particularPrepaidHealth} setParticularPrepaidHealth={setParticularPrepaidHealth} particularPrepaidHealthCategory={particularPrepaidHealthCategory} setParticularPrepaidHealthCategory={setParticularPrepaidHealthCategory}/>;
            } else if (isProfessional) {
               return (
                  <PersonalInformationProfessionalForm formModel={formModel} />
               );
            } else {
               return <div>No encontrado</div>;
            }
         case 1:
            if (isPatient) {
               return <AddressForm formModel={formModel} touched={touched} errors={errors}  />;
            } else if (isProfessional) {
               return <ProfilePhotoForm formModel={formModel} />;
            } else {
               return <div>No encontrado</div>;
            }
         case 2:
            if (isPatient) {
               return <MedicalRecordForm formModel={formModel} />;
            } else if (isProfessional) {
               return <ProfileSignPhotoForm formModel={formModel} />;
            } else {
               return <div>No encontrado</div>;
            }
         case 3:
            if (isPatient) {
               return <ReviewProfile currentRole={currentRole} />;
            } else if (isProfessional) {
               return <ProfessionalDataForm formModel={formModel} />;
            } else {
               return <div>No encontrado</div>;
            }
         case 4:
            if (isProfessional) {
               return <AttentionDataForm formModel={formModel} />;
            } else {
               return <div>No encontrado</div>;
            }
         case 5:
            if (isProfessional) {
               return <ReviewProfile currentRole={currentRole} />;
            } else {
               return <div>No encontrado</div>;
            }
         default:
            return <div>No encontrado</div>;
      }
   };

   const submitForm = async (values, actions) => {
      const errorMessage =
         'Ocurrió un problema con la actualización del perfil';
      const successMessage = '¡Actualización de perfil realizada con éxito!';

      if (isPatient) {
         let patientParams = { ...values };
         if(!patientParams.patient_dto.prepaid_health_category){
            const errorMessage = "Ocurrió un error en la búsqueda de planes";
            await GetPrepaidHealthCategory(
              userToken,
              particularPrepaidHealth.id
            )
              .then((response) => {
                if (OK === response?.status) {
                  patientParams.patient_dto.prepaid_health_category =
                    response.data[0];
                } else {
                  showError(errorMessage);
                }
              })
              .catch((e) => console.log(e));
         }
         // TODO: Remove this when Backend delete these "ids" fields
         // Change object values of autocomplete data for array of ids
         patientParams.patient_dto.ids_pre_existing_disease = optionsToIds(
            patientParams.patient_dto.pre_existing_disease,
         );
         
         if(patientParams.anotherMedication && patientParams.anotherMedication?.length > 0){
            patientParams.patient_dto.frequent_medication = [
               ...patientParams.patient_dto.frequent_medication,
               {
                  frequentMedicationId: uuidv4(),
                  id: uuidv4(),
                  name: patientParams.anotherMedication
               }
            ];
         }
         if (patientParams.patient_dto?.study && patientParams.patient_dto?.study?.length !== 0){
            let newPatientStudys = [];
            patientParams.patient_dto.study.map(async (element) => {
               if (element instanceof File) {
                  let newPatientStudy = {
                     mimetype: element.type,
                     filename: element.name,
                     size: element.size,
                     base64: await fileToBase64(
                        element,
                     )
                  }
                  newPatientStudys.push(newPatientStudy);
               } else {
                  newPatientStudys.push(element);
               }
            })
            patientParams.patient_dto.study = newPatientStudys;
         } else {
            patientParams.patient_dto.study = [];
         }

         patientParams.patient_dto.ids_surgery = optionsToIds(
            patientParams.patient_dto.surgeries,
         );
         patientParams.patient_dto.size = Number(String(patientParams.patient_dto.size).replace(',', '.'))

         await PatientProfile(patientParams, userToken, showError)
            .then(() => {
               patientParams.patient_dto.full_profile = true
               dispatch({
                  type: 'SAVE_USER_INFO',
                  userData: patientParams,
               });
               dispatch({ type: 'SAVE_SESSION' });
               showToast(toastDispatch, successMessage, ToastType.SUCCESS);
               history.push('/paciente');
            })
            .catch(() => {
               showError(errorMessage);
               actions.setSubmitting(false);
            });
         setActiveStep(lastStep);
      } else if (isProfessional) {
         let professionalParams = { ...values };

         if (
            typeof professionalParams.professional_dto.photo_encode === 'object'
         ) {
            professionalParams.professional_dto.photo_encode =
               await fileToBase64(
                  professionalParams.professional_dto.photo_encode,
               );
         }

         // TODO: Remove this code when Backend delete these "ids" fields
         // Change object values of autocomplete data for array of ids
         professionalParams.professional_dto.ids_specialties = optionsToIds(
            professionalParams.professional_dto.specialties,
         );

         professionalParams.professional_dto.ids_health_care_providers =
            optionsToIds(
               professionalParams.professional_dto.health_care_providers,
            );

         professionalParams.professional_dto.ids_prepaid_healths = optionsToIds(
            professionalParams.professional_dto.prepaid_healths,
         );

         await ProfessionalProfile(professionalParams, userToken, showError)
            .then(prof => {
               professionalParams.professional_dto.full_profile = true;
               if (prof?.data?.professional_dto?.virtual_guard_availability_str) {
                  professionalParams.professional_dto.virtual_guard_availability_str = prof.data.professional_dto.virtual_guard_availability_str;
               }
               dispatch({
                  type: 'SAVE_USER_INFO',
                  userData: professionalParams,
               });
               dispatch({ type: 'SAVE_SESSION' });
               showToast(toastDispatch, successMessage, ToastType.SUCCESS);
               history.push('/profesional');
            })
            .catch(() => {
               showError(errorMessage);
               actions.setSubmitting(false);
            });
         setActiveStep(lastStep);
      } else {
         showError(errorMessage);
      }
   };

   const handleSubmit = (values, actions) => {
      if (isLastStep) {
         submitForm(values, actions);
      } else {
         // manual check on prepaid health category if patient
         if (
           !(
             isPatient &&
             values.patient_dto.attention_type ===
               AttentionTypeEnum.HEALTHINSURANCE && 
             (values?.patientMemberId !== undefined ? !values.patient_dto.prepaid_health_category?.prepaid_health?.id : userData?.patient_dto?.prepaid_health_category?.prepaid_health.id ||
              values?.patientMemberId !== undefined ? !values.patient_dto.prepaid_health_category?.id : userData?.patient_dto?.prepaid_health_category?.id ||
              values?.patientMemberId !== undefined ? values.patient_dto.affiliate_number === '' : userData?.patient_dto?.affiliate_number === '')
           )
         ) {
           setActiveStep(activeStep + 1);
           actions.setTouched({});
           actions.setSubmitting(false);
         } else {
           actions.setTouched({
             prepaid_health_category: 
             values?.patientMemberId !== undefined ? 
             values.patient_dto.prepaid_health_category?.id ? false : true :
             userData?.patient_dto?.prepaid_health_category?.id ? false : true,
             prepaid_health: 
             values?.patientMemberId !== undefined ? 
             values.patient_dto.prepaid_health_category?.prepaid_health.id ? false : true :
             userData?.patient_dto?.prepaid_health_category?.prepaid_health.id ? false : true,
             affiliate_number : values?.patientMemberId !== undefined ? values.patient_dto.affiliate_number !== '' ? false : true : userData?.patient_dto?.affiliate_number !== '' ? false : true
           });
           actions.setSubmitting(false);
         }
      }
   };

   const handleBack = () => {
      setActiveStep(activeStep - 1);
   };

   return (
      <div className="full-registration">
         <div className="header">
            <img src={HeaderImage} alt="Registro Completo" />
            <h2>Registro Completo</h2>
         </div>

         <div className="column">
            <Stepper alternativeLabel activeStep={activeStep}>
               {steps.map((label) => (
                  <Step key={label} className="step">
                     <StepLabel className="step">{label}</StepLabel>
                  </Step>
               ))}
            </Stepper>
            {userData?.id_number && steps.length && (
               <div className="container">
                  <Formik
                     initialValues={{...formValues, "anotherMedication":""}}
                     validationSchema={currentValidationSchema}
                     onSubmit={handleSubmit}
                     enableReinitialize
                     validateOnMount
                  >
                     {({ isSubmitting, errors, touched }) => {
                        return (
                        <Form id="fullRegistrationForm">
                           {renderStepContent(activeStep, touched,errors)}

                           <div className="btn-wrapper">
                              {activeStep !== 0 && (
                                 <Button
                                    disabled={isSubmitting}
                                    onClick={handleBack}
                                    variant="outlined"
                                    className={`btn btn--back ${
                                       isSubmitting && 'btn--disabled'
                                    }`}
                                 >
                                    Volver
                                 </Button>
                              )}
                              <>
                                 <Button
                                    disabled={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    endIcon={
                                       isSubmitting && (
                                          <CircularProgress size={24} />
                                       )
                                    }
                                    className={`btn ${
                                       isLastStep ? 'btn--save' : 'btn--next'
                                    } ${isSubmitting && 'btn--disabled'}`}
                                 >
                                    {isLastStep ? 'Finalizar' : 'Siguiente'}
                                 </Button>
                              </>
                           </div>
                        </Form>
                     )}}
                  </Formik>
               </div>
            )}
         </div>
      </div>
   );
};

export default FullRegistration;
