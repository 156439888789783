import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  IconButton,
  InputAdornment,
  Icon,
  Button,
  makeStyles,
  Grid,
  SvgIcon,
  Paper,
  Link,
  Typography,
} from '@material-ui/core';
import FormikField from '../FormikField';
import { useStateAuthValue } from '../context/Auth/AuthState';
import { AuthGoogle } from '../services/authService';
import { loginUtil } from '../utils/LoginUtil';
import { useToast } from '../context/Toast/ToastProvider';
import { showToast, ToastType } from '../utils/ToastUtil';
import clickSound from "../assets/notifications/click.mp3" 
import { useProfessionalLogState } from '../context/profesionalPage/professionalLog/professionalLogState';

const useStyles = makeStyles((theme) => ({
  submit: {
    backgroundColor: '#DA2037',
    '&:hover': {
      backgroundColor: '#e84353',
    },
    margin: theme.spacing(3, 0, 2),
    borderRadius: '20px',
    textTransform: 'capitalize',
  },
  btnSecondary: {
    backgroundColor: '#002C54',
    '&:hover': {
      backgroundColor: '#003f77',
    },
    margin: theme.spacing(0, 0, 4),
    borderRadius: '20px',
    textTransform: 'capitalize',
  },
  visibility: {
    color: '#DA2037',
  },
  paperForm: {
    padding: '20px',
  },
  txtForgotPass: {
    margin: theme.spacing(-2, 0, 1),
    textTransform: 'capitalize',
    width: '100%',
    alignSelf: 'center',
    textAlign: 'center',
  },
}));

const initialValuesLogin = {
  email: '',
  password: '',
};

const LoginScheme = Yup.object().shape({
  email: Yup.string()
    .email('Debe ingresar un email válido')
    .required('Email Requerido'),
  password: Yup.string()
    .min(6, ' Debe tener al menos 6 caracteres')
    .required('Debe ingresar una contraseña'),
});

export default function AuthFormLogin() {
  const history = useHistory();
  const classes = useStyles();
  const [hidePassword, seHidePassword] = useState(true);
  const [, setSubmitting] = useState(false);
  const [, dispatch] = useStateAuthValue();
  const [, dispatchProfessionalLog = dispatch] = useProfessionalLogState();
  const toastDispatch = useToast();

  const handleClickShowPassword = () => seHidePassword(!hidePassword);

  async function handleSubmitLogin(event) {
    const audio = new Audio(clickSound);
       audio.addEventListener('canplaythrough', () => {
         audio.play();
       });
    const displayError = (msg) =>
      showToast(toastDispatch, msg, ToastType.ERROR, ToastType.ERROR);
    await loginUtil(event, dispatch,dispatchProfessionalLog, history, setSubmitting, displayError);
  }

  function IconVisible(icon) {
    return <Icon className={classes.visibility}> {icon}</Icon>;
  }

  return (
    <Formik
      initialValues={initialValuesLogin}
      onSubmit={handleSubmitLogin}
      validationSchema={LoginScheme}
    >
      {({ dirty, isValid }) => {
        return (
          <Form>
            <Paper elevation={3} className={classes.paperForm}>
              <FormikField name="email" label="Email" />
              <FormikField
                name="password"
                label="Contraseña"
                type={hidePassword ? 'password' : 'text'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {hidePassword
                          ? IconVisible('visibility_off')
                          : IconVisible('visibility')}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
            <Button
              disabled={!(isValid && dirty)}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              endIcon={<Icon>chevron_right</Icon>}
            >
              Ingresar
            </Button>
            {process.env.REACT_APP_ENABLE_SIGNUP === 'true' && (
              <Button
                href="/signup"
                startIcon={<Icon>chevron_left</Icon>}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.btnSecondary}
              >
                No tengo cuenta
              </Button>
            )}
            <Typography align="right">
              {' '}
              <Link
                className="link"
                onClick={() => history.push('/recuperar-clave')}
              >
                {' '}
                Olvidé mi contraseña{' '}
              </Link>{' '}
            </Typography>
            {process.env.REACT_APP_ENABLE_GOOGLE_SIGNUP === 'true' && (
              <Grid container justifyContent="center">
                <Grid item>
                  <IconButton onClick={AuthGoogle}>
                    <SvgIcon
                      width="41"
                      height="40"
                      viewBox="0 0 41 40"
                      fill="none"
                    >
                      <path
                        d="M9.60938 20C9.60938 18.0182 10.169 16.1617 11.1413 14.5721V7.82932H4.22997C1.48689 11.3049 0 15.5408 0 20C0 24.4592 1.48689 28.6951 4.22997 32.1707H11.1413V25.4279C10.169 23.8383 9.60938 21.9818 9.60938 20Z"
                        fill="#FBBD00"
                      />
                      <path
                        d="M20.5 30.625L15.6953 35.3125L20.5 40C25.0708 40 29.4125 38.5494 32.975 35.8732V29.1376H26.071C24.4274 30.0896 22.5164 30.625 20.5 30.625Z"
                        fill="#0F9D58"
                      />
                      <path
                        d="M11.1414 25.4279L4.22998 32.1707C4.77307 32.8588 5.36445 33.5178 6.00435 34.1422C9.87629 37.9196 15.0243 40 20.5 40V30.625C16.5262 30.625 13.0434 28.5375 11.1414 25.4279Z"
                        fill="#31AA52"
                      />
                      <path
                        d="M41 20C41 18.7832 40.8871 17.5641 40.6643 16.3768L40.4841 15.4159H20.5V24.7909H30.2256C29.2812 26.6238 27.8291 28.1192 26.0709 29.1376L32.9749 35.8733C33.6802 35.3434 34.3557 34.7665 34.9957 34.1422C38.8676 30.3646 41 25.3422 41 20Z"
                        fill="#3C79E6"
                      />
                      <path
                        d="M28.2009 12.487L29.0502 13.3155L35.8451 6.68648L34.9957 5.85789C31.1238 2.08039 25.9758 0 20.5 0L15.6953 4.6875L20.5 9.375C23.4089 9.375 26.1438 10.4802 28.2009 12.487Z"
                        fill="#CF2D48"
                      />
                      <path
                        d="M20.5 9.375V0C15.0242 0 9.87625 2.08039 6.00423 5.85781C5.36433 6.48211 4.77295 7.14109 4.22986 7.8293L11.1412 14.5721C13.0433 11.4625 16.5262 9.375 20.5 9.375Z"
                        fill="#EB4132"
                      />
                    </SvgIcon>
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
