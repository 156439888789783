import React, { useState, useEffect } from 'react';
//eslint-disable-next-line
import { Button, List } from '@material-ui/core';
import { remove } from 'lodash';
import PrescriptionItem from './PrescriptionItem';
import PharmacyListModal from '../PharmacyListModal';
import Modal from '../../../components/Modal';
import { PrescriptionType } from '../../../models/enums';
import { textByPrescriptionType } from '../../../utils/PrescriptionUtil';

const PrescriptionListFam = ({ famPrescr, filterStatus }) => {
   const [selectedPrescription, setSelectedPrescription] = useState([]);
   const [modalPharmacy, setModalPharmacy] = useState(false); 
   const [famState, setFamSate] = useState([]);
 
   useEffect(() =>{ 
    setFamSate(famPrescr);
   },[famPrescr]);

   const handleSelectedPrescription = (e) => {
      const id = e.target.id;
      if (selectedPrescription.includes(id)) {
         setSelectedPrescription(
            remove(selectedPrescription, (element) => element !== id),
         );
      } else {
         setSelectedPrescription((prev) => [...prev, id]);
      }
   };

   const renderList = (data) => {
      if (data?.length) {
         if (
            !data.some((data) => data?.status === filterStatus)
         ) {
            return (
               <div className="empty">
                  <p className="message">
                     {textByPrescriptionType(filterStatus)}
                  </p>
               </div>
            );
         } else {
            return (
               <div className="list">
                  <List>
                     {data.map((data) => {
                        return (
                           <PrescriptionItem
                              key={data.id}
                              prescriptionId={data.prescriptionId}
                              id={data.id}
                              state={data.status}
                              dateCreated={data.createdDate}
                              diagnosis={data.diagnosis}
                              professional={data.professional_name}
                              enrollment={data.professional_license}
                              medications={data.medications}
                              onSelectedPrescription={
                                 handleSelectedPrescription
                              }
                              selectedPrescription={selectedPrescription}
                           />
                        );
                     })}
                  </List>
               </div>
            );
         }
      } else {
         return (
            <div className="empty">
               <p className="message">{textByPrescriptionType(filterStatus)}</p>
            </div>
         );
      }
   };

   /* const pharmacyModal = () => {
      setModalPharmacy(true);
   }; */
   const pharmacyModalClose = () => {
      setModalPharmacy(false);
   };

   const renderButton = () => {
      if (filterStatus === PrescriptionType.NEW) {
         return (
            <div className="button-sent">
               {/* <Button
                  variant="contained"
                  disabled={!selectedPrescription.length}
                  onClick={pharmacyModal}
               >
                  Enviar a farmacia
               </Button> */}
               <Modal
                  maxWidth="md"
                  isActive={modalPharmacy}
                  handleClose={pharmacyModalClose}
               >
                  <PharmacyListModal
                     selectedPrescription={selectedPrescription}
                  />
               </Modal>
            </div>
         );
      }
   };
   return (
        <>
            { famState?.map((f) => {
                  return (<div key={f.famId}>
                  <h3>Miembro: {f.famName}</h3>
                  {renderList(f.content)}
                  </div>)
               }) 
            }
         {renderButton()}
        </>
   );
};

export default PrescriptionListFam;
