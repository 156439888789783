import React, { useState } from 'react';
import { List } from '@material-ui/core';
import Input from '@material-ui/core/Input';

import PharmacyItem from './PharmacyItem';
import IconPharmacy from '../../../assets/img/farmacia2.svg';
import { useStateAuthValue } from '../../../context/Auth/AuthState';
import { useToast } from '../../../context/Toast/ToastProvider';
import { getPharmacySearch } from '../../../services/pharmacyService';
import { showToast, ToastType } from '../../../utils/ToastUtil';

const PharmacyListModal = ({ selectedPrescription }) => {
   const [{ userToken }] = useStateAuthValue();
   const toastDispatch = useToast();
   const [pharmacyList, setPharmacyList] = useState([]);

   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, 'Error');
   };

   const getData = async (value) => {
      await getPharmacySearch({ name: value }, userToken)
         .then((response) => {
            setPharmacyList(response.content);
         })
         .catch((err) => {
            showError(err.message);
         });
   };

   const handleSearch = (e) => {
      const value = e.target.value;

      if (value.length >= 4) {
         getData(value);
      }
   };

   const renderPharmacies = () => {
      if (pharmacyList?.length) {
         return (
            <div className="list">
               <List>
                  {pharmacyList.map((pharmacies) => {
                     return (
                        <PharmacyItem
                           id={pharmacies.id}
                           key={pharmacies.id}
                           namePharmacy={pharmacies.pharmacy.name}
                           selectedPrescription={selectedPrescription}
                           department={pharmacies.name}
                           streetName={pharmacies.streetName}
                           streetNumber={pharmacies.streetNumber}
                           phoneNumber={pharmacies.phoneNumber}
                        />
                     );
                  })}
               </List>
            </div>
         );
      } else {
         return (
            <div className="empty">
               <p className="message">No hay farmacias que mostrar</p>
            </div>
         );
      }
   };
   return (
      <>
         <div className="pharmacy-modal">
            <div className="title-search">
               <div className="title-search__title">
                  <img
                     alt="Farmacia"
                     src={IconPharmacy}
                     className="iconPharmacy"
                  />
                  <h2>
                     Seleccioná tu farmacia de confianza para enviar tu pedido.
                  </h2>
               </div>
            </div>
            <div className="title-search__search">
               <Input className="title-search__search_text"
                  placeholder="Ingresá al menos 4 letras para buscar..."
                  onChange={handleSearch}
                  inputProps={{ 'aria-label': 'description' }}
               />
            </div>
            <div className="pharmacies">{renderPharmacies()}</div>
         </div>
      </>
   );
};

export default PharmacyListModal;
