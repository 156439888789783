import React, {useState} from 'react';
import FormikField from "../FormikField";
import { IconButton, InputAdornment, Icon } from "@material-ui/core";

const PasswordFields = ({ passwordUpdate = false }) => {
    const [hideCurrentPassword, setHideCurrentPassword] = useState(true);
    const [hidePassword, setHidePassword] = useState(true);
    const [hideRepeatPassword, setHideRepeatPassword] = useState(true);
    const handleClickShowPassword = () => setHidePassword(!hidePassword);
    const handleClickShowRepeatPassword = () =>
        setHideRepeatPassword(!hideRepeatPassword);

    const IconVisible = (icon) =>
        <Icon style={{ color: "#DA2037" }}>
            {icon}
        </Icon>;

    return <div>
        { passwordUpdate && (
            <FormikField
            name="currentPassword"
            label="Contraseña Actual"
            type={hideCurrentPassword ? "password" : "text"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=> setHideCurrentPassword(!hideCurrentPassword)}
                        >
                            {hideCurrentPassword
                                ? IconVisible("visibility_off")
                                : IconVisible("visibility")}
                        </IconButton>
                    </InputAdornment>
                )
        }}/>)}
        <FormikField
            name="password"
            label={passwordUpdate ? 'Contraseña Nueva' : 'Contraseña'}
            type={hidePassword ? "password" : "text"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                        >
                            {hidePassword
                                ? IconVisible("visibility_off")
                                : IconVisible("visibility")}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
        <FormikField
            name="passwordConfirmation"
            label={passwordUpdate ? 'Repita la Contraseña Nueva' : 'Repita la Contraseña'}
            type={hideRepeatPassword ? "password" : "text"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowRepeatPassword}
                        >
                            {hideRepeatPassword
                                ? IconVisible("visibility_off")
                                : IconVisible("visibility")}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    </div>;
}

export default PasswordFields;