export default class Schedule {
    id = null;
	groupIds = null;
    check_in = null;
	check_out = null;
	professional_dto = null;
	days = [];
	label = null;
    time = '';
	id_prepaid_healths = [];
	attention_types_dto = [];
	blocked = null;
	last_time_avalability;
	lastTimeAvailability;
	start_date = null;
	duration = 30;
	duration_unit = 'DAYS';

    constructor( checkIn, checkOut, professional_dto = null, params ={}) {
        this.check_in = checkIn;
        this.check_out = checkOut;
		this.professional_dto = professional_dto;
		this.blocked = false;
		this.id = params.id || null;
		this.groupIds = params.groupIds || null;
		this.days = params.days || [];
		this.attentionType = params.attentionType || null;
		this.attention_types_dto = params.attention_types_dto || [];
		this.id_prepaid_healths = params.id_prepaid_healths || [];
		this.label = params.label || null;
		this.last_time_avalability = params.last_time_avalability || null;
		this.lastTimeAvailability = params.lastTimeAvailability || null;
		this.time = `${checkIn} a ${checkOut}`;
		this.start_date = params.startDate || null;
		this.duration = params?.duration >= 0 ? params.duration : 30;
		if (this.groupIds?.length) {
			this.groupIds = this.groupIds.map(item => { return {id: item}; });
		}
    }
}