import React from 'react';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';


const SearchResult = ({ results }) => {
    const history = useHistory();
    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Apellido y Nombre del paciente</TableCell>
                            <TableCell align="right">N° Historia Clinica</TableCell>
                            <TableCell align="right">DNI</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results?.map((value) => {
                            return (
                                <TableRow key={value.id}>
                                    <TableCell>{value.name}</TableCell>
                                    <TableCell align="right">{value.id}</TableCell>
                                    <TableCell align="right">{value.id_number}</TableCell>
                                    <TableCell className="cell-btn" align="right" width="12px">
                                        <IconButton
                                            onClick={() => 
                                                history.push({ pathname: '/historia-clinica/'+value.userLogin,
                                                state: {id_number: value.id_number}})}
                                            className='icon-btn'
                                        >
                                            <ArrowForwardIosOutlinedIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
};

export default SearchResult;
