import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormikField from '../FormikField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { RecoverPass } from '../services/authService';
import { useToast } from '../context/Toast/ToastProvider';
import { showToast, ToastType } from '../utils/ToastUtil';


const useStyles = makeStyles((theme) => ({
  submit: {
    backgroundColor: '#DA2037',
    margin: theme.spacing(3, 0, 2),
    borderRadius: '20px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#e84353',
    }
  },
  paper: {
    justifyContent: 'center',
    height: 200,
  },
  paperForm: {
    padding: '20px',
  },
}));

const RecoverPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const toastDispatch = useToast();
  const initialValuesRecover = {
    email: '',
  };

  const handleSubmitRecoverPass = (event) => {
    RecoverPass(event?.email)
      .then(() => {
        showToast(toastDispatch, 'Se envió mail de confirmación', ToastType.SUCCESS);
        history.push("/correo-enviado")
      })
      .catch((error) => {
        let message = 'Ocurrió un error enviando mail de confirmación';
        if (error.data && (error.data.message || error.data.description)) {
          message = error.data.message || error.data.description;
        }
        showToast(toastDispatch, message, ToastType.ERROR, ToastType.ERROR)
      });
  };

  const RecoverScheme = Yup.object().shape({
    email: Yup.string()
      .email('Debes ingresar un email válido')
      .required('El email es requerido'),
  });


  return (
    <>
    <div style={{ color: "#002C54" }}>
      <h3>Por favor ingresá tu correo para enviar el código de recuperación</h3>
    </div>
      <Formik
        initialValues={initialValuesRecover}
        onSubmit={handleSubmitRecoverPass}
        validationSchema={RecoverScheme}
      >
      {({dirty, isValid}) => {
            return (
            <Form>
              <Paper elevation={3} className={classes.paperForm}>
                <FormikField name="email" label="Email" />
              </Paper>
              <Button
                type="submit"
                disabled={!(isValid && dirty)}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                endIcon={<Icon>chevron_right</Icon>}
              >
                Recuperar Contraseña
              </Button>
            </Form>
        )}}
      </Formik>
    </>

  );
}

export default RecoverPassword;
