export const initialState = {
    data: [],
    stomp: [],
 };
 
 const ProfessionalDashboardReducer = (state, action) => {
   switch (action.type) {
    case "VIRTUAL_GUARD":
         return{
            ...state,
            data: action.data,
            stomp : action.stomp
         }
     default:
       return state;
   }
 };
 
 export default ProfessionalDashboardReducer;