import * as Yup from 'yup';
import formModel from './professionalFormModel';

import { getDifferenceInYears } from '../../../../utils/DateUtil';
import { fileIsImage, stringRegex } from '../../../../utils/FormUtil';

const {
   first_name,
   last_name,
   sex_str,
   birth_date,
   id_number,
   phone_number,
   professional_dto: {
      photo_encode,
      sign_photo,
      licence_str,
      type_licence_str,
      specialties,
      academic_training,
      attention_particular,
   },
} = formModel;

const stringErrorMsg = 'Ingresa sólo letras';
const typeNumberErrorMsg = 'Ingresa sólo números';
const typeDateErrorMsg = 'Ingresa una fecha válida';

const professionalValidationSchema = [
   Yup.object().shape({
      first_name: Yup.string()
         .matches(stringRegex, stringErrorMsg)
         .required(first_name.requiredErrorMsg),
      last_name: Yup.string()
         .matches(stringRegex, stringErrorMsg)
         .required(last_name.requiredErrorMsg),
      sex_str: Yup.string().nullable().required(sex_str.requiredErrorMsg),
      birth_date: Yup.date()
         .nullable()
         .typeError(typeDateErrorMsg)
         .required(birth_date.requiredErrorMsg)
         .test(
            birth_date.name,
            birth_date.minAgeErrorMsg,
            (value) => getDifferenceInYears(value) >= 18,
         ),
      id_number: Yup.number()
         .required(id_number.requiredErrorMsg)
         .typeError(typeNumberErrorMsg),
      phone_number: Yup.number()
         .min(1000000000, phone_number.minErrorMsg)
         .max(9999999999, phone_number.maxErrorMsg)
         .required(phone_number.requiredErrorMsg)
         .typeError(typeNumberErrorMsg),
   }),
   Yup.object().shape({
      professional_dto: Yup.object().shape({
         photo_encode: Yup.lazy((value) => {
            switch (typeof value) {
               case 'object':
                  return Yup.mixed()
                     .required(photo_encode.requiredErrorMsg)
                     .test(
                        photo_encode.name,
                        photo_encode.typeErrorMsg,
                        (photo) => fileIsImage(photo),
                     )
                     .test(
                        photo_encode.name,
                        photo_encode.sizeErrorMsg,
                        (photo) => photo?.size <= photo_encode.size,
                     );
               case 'string':
                  return Yup.string().required(photo_encode.requiredErrorMsg);
               default:
                  return Yup.mixed().required(photo_encode.requiredErrorMsg);
            }
         }),
      }),
   }),
   Yup.object().shape({
      professional_dto: Yup.object().shape({
         sign_photo: Yup.lazy((value) => {
            switch (typeof value) {
               case 'object':
                  return Yup.mixed()
                     .required(sign_photo.requiredErrorMsg)
                     .test(
                        sign_photo.name,
                        sign_photo.typeErrorMsg,
                        (photo) => fileIsImage(photo),
                     )
                     .test(
                        sign_photo.name,
                        sign_photo.sizeErrorMsg,
                        (photo) => photo?.size <= sign_photo.size,
                     );
               case 'string':
                  return Yup.string().required(sign_photo.requiredErrorMsg);
               default:
                  return Yup.mixed().required(sign_photo.requiredErrorMsg);
            }
         })
      }),
   }),
   Yup.object().shape({
      professional_dto: Yup.object().shape({
         licence_str: Yup.number()
            .typeError(typeNumberErrorMsg)
            .required(licence_str.requiredErrorMsg),
         type_licence_str: Yup.string()
            .nullable()
            .required(type_licence_str.requiredErrorMsg),
         specialties: Yup.string()
            .nullable()
            .required(specialties.requiredErrorMsg),
         academic_training: Yup.string().required(
            academic_training.requiredErrorMsg,
         ),
      }),
   }),
   Yup.object().shape({
      professional_dto: Yup.object().shape({
         attention_particular: Yup.boolean().test(
            'attentionData',
            attention_particular.requiredErrorMsg,
            function () {
               return (
                  this.parent.attention_particular ||
                  this.parent.prepaid_healths.length
               );
            },
         ),
         prepaid_healths: Yup.array(),
      }),
   }),
];

export default professionalValidationSchema;
