import React,{useState, useEffect} from 'react';
import { AttentionTypeEnum } from '../../../../models/enums';
import genders from '../../../../tests/mocks/genders';
import { GetMedilineFinancerByPatient } from '../../../../services/medilinePrepaidService';
import { useStateAuthValue } from '../../../../context/Auth/AuthState';
import { ParentalRelationshipType } from '../../../../utils/ParentalRelationshipsUtil';
import { formatDate } from '../../../../utils/DateUtil';
import { valueToName } from '../../../../utils/FormUtil';
import { showToast, ToastType } from '../../../../utils/ToastUtil';
import { useToast } from '../../../../context/Toast/ToastProvider';

const PersonalInformationPatientDetail = ({ formValues, isFam }) => {
   const {
      alias,
      first_name,
      last_name,
      sex_str,
      relationship_to_titular,
      birth_date,
      id_number,
      phone_number,
      patient_dto,
   } = formValues;
   const [{ userData, userToken }] = useStateAuthValue();
   const isPrepaidHealth = patient_dto.prepaid_health_category?.prepaid_health !== 'PARTICULAR' && patient_dto.prepaid_health_category?.prepaid_health
   ?.name !== 'PARTICULAR';
   // Get the name instead sex_str value
   const name = ParentalRelationshipType.map((p) => {
      if(p.value === relationship_to_titular) return p.name;
    });
   const sexStrName = valueToName(genders, sex_str);
   const [medPrepaidHealthPlan, setMedPrepaidHealthPlan] = useState("");
   const [medPrepaidHealthAffNumb, setMedPrepaidHealthAffNumb] = useState("");
   const toastDispatch = useToast();

   useEffect(() => {
      const getPlan = async () => {
         await GetMedilineFinancerByPatient(userToken, userData.patient_dto.id)
         .then((res) => {
            setMedPrepaidHealthPlan(res.planName);
            setMedPrepaidHealthAffNumb(res.affiliateNumber)
         })
         .catch((e) => {
           if(process.env.REACT_APP_DEBUG === "true"){
            const errorMessage = "Error:  ~ file: PersonalInformationPatientDetail.js:44 ~ getPlan ~ error:" + e;
            showToast(toastDispatch, errorMessage, ToastType.ERROR, ToastType.ERROR);
           }
         })
       }
       if(userData?.patient_dto?.id){
         getPlan();
       }
   },[]);

   return (
      <div className="detail">
         <div className="header header--detail">
            <h2>Información Personal</h2>
         </div>
         <div className="data-wrapper">
            <span className="data">
               Alias:<span className="bold">{alias || '-'}</span>
            </span>
            <span className="data">
               Nombre:<span className="bold">{first_name}</span>
            </span>
            <span className="data">
               Apellido:<span className="bold">{last_name}</span>
            </span>
            <span className="data">
               Sexo:<span className="bold">{sexStrName}</span>
            </span>
            {isFam && (
            <span className="data">
               Parentesco:<span className="bold">{name}</span>
            </span>
            )}
            <span className="data">
               Fecha de Nacimiento:
               <span className="bold">{formatDate(birth_date)}</span>
            </span>
            <span className="data">
               DNI:<span className="bold">{id_number}</span>
            </span>
            <span className="data">
               Teléfono:<span className="bold">{phone_number}</span>
            </span>
            <span className="data">
               Tipo de Atención:
               {/* <span className="bold">{patient_dto.attention_type || '-'}</span> */}
               <span className="bold">Plan Mediline</span>
            </span>
            <>
               <span className="data">
                  Prestador:
                  <span className="bold">
                     Mediline
                     {/* {patient_dto.prepaid_health_category?.prepaid_health
                        ?.name || '-'} */}
                  </span>
               </span>
               <span className="data">
                  Plan:
                  <span className="bold">
                     {medPrepaidHealthPlan}
                     {/* {patient_dto.prepaid_health_category?.name || '-'} */}
                  </span>
               </span>
               <span className="data">
                  Número de Asociado:
                  <span className="bold">
                     {medPrepaidHealthAffNumb}
                     {/* {patient_dto.affiliate_number || '-'} */}
                  </span>
               </span>
               </>
            {isPrepaidHealth && (
               <>
                  <span className="data">
                     Obra Social:
                     <span className="bold">
                        {patient_dto.prepaid_health_category?.prepaid_health
                           ?.name || '-'}
                     </span>
                  </span>
                  <span className="data">
                     Plan:
                     <span className="bold">
                        {patient_dto.prepaid_health_category?.name || '-'}
                     </span>
                  </span>
                  <span className="data">
                     Número de Asociado:
                     <span className="bold">
                        {patient_dto.affiliate_number || '-'}
                     </span>
                  </span>
               </>
            )}
         </div>
      </div>
   );
};

export default PersonalInformationPatientDetail;
