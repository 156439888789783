import React, { useState, useEffect } from "react";
import { useStateProfessionalDashboardValue } from "../../context/profesionalPage/profesionalDashboardState";
import { Button } from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import firebase from "../../services/firebase";

export const ProfVideoButtons = ({
  actions,
  consultIsSubmitted,
  consultDuration,
  consultFinishedWithError
}) => {
  const [{ data, stomp }] = useStateProfessionalDashboardValue();
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const [fullScreen, setFullScreen] = useState(true)

  useEffect(() => {
    if (consultIsSubmitted || consultFinishedWithError) {
      toggeLeave();
    }
  }, [consultIsSubmitted, consultFinishedWithError]);

  const toggleCamera = () => {
    const { startVideo, stopVideo } = actions;

    if (trackState.video) {
      stopVideo();
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    } else {
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
      startVideo();
    }
  };

  const toggleMicrophone = () => {
    const { startAudio, stopAudio } = actions;

    if (trackState.audio) {
      stopAudio();
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else {
      startAudio();
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    }
  };

  const fullScreenHandler = () => {
    // remote video is on full screen, set to smaller screen
    if(fullScreen){
      const remoteVideoContainer = document.querySelectorAll('#remote div');
      remoteVideoContainer.forEach(element => {
        element.style.cssText = `
        justify-content: center;
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        background-color: black;
        `;
      });
      const remoteVideo = document.querySelectorAll('#remote .agora_video_player');
      remoteVideo.forEach(element => {
        element.style.cssText = `
        object-fit: contain !important;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        `;
      });
    // remote video is on small screen, set to full screen
    } else {
      const remoteVideoContainer = document.querySelectorAll('#remote div');
      remoteVideoContainer.forEach(element => {
        element.style.cssText = `
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        background-color: black;
        `;
      });
      const remoteVideo = document.querySelectorAll('#remote .agora_video_player');
      remoteVideo.forEach(element => {
        element.style.cssText = `
        object-fit: cover !important;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        `;
      });
    }
    setFullScreen(!fullScreen);
    
  }

  const toggeLeave = () => {
    const { leaveVideoCall } = actions;
    if(!consultFinishedWithError){
      var wsMessage = {
        patientId: data?.patientId,
        orderNumber: data?.orderNumber,
        specialtyId: data?.specialtyId,
        command: "ProfessionalFinish",
        dateTimeProfessionalHangup: consultDuration,
        professionalId: data?.professionalId,
        queueId: data?.queueId,
        date: data?.date,
      };
      firebase.put({...wsMessage, status: 'FINISHED'})
      stomp.send("/app/message", {}, JSON.stringify(wsMessage));
    }
    leaveVideoCall();
  };

  return (
    <div
      style={{
        background: "#da2037",
        borderRadius: "0px 0px 5px 5px",
        display: "flex",
        justifyContent: "space-evenly",
        height: "74px",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div>
        <Button
          style={{
            background: "none",
            boxShadow: "none",
          }}
          variant="contained"
          color={trackState.audio ? "primary" : "secondary"}
          onClick={toggleMicrophone}
        >
          {trackState.audio ? (
            <MicIcon sx={{ fontSize: "27px" }} />
          ) : (
            <MicOffIcon sx={{ fontSize: "27px" }} />
          )}{" "}
        </Button>
      </div>
      <div>
        <Button
          style={{
            background: "none",
            boxShadow: "none",
          }}
          variant="contained"
          color={trackState.video ? "primary" : "secondary"}
          onClick={toggleCamera}
        >
          {trackState.video ? (
            <VideocamIcon sx={{ fontSize: "27px" }} />
          ) : (
            <VideocamOffIcon sx={{ fontSize: "27px" }} />
          )}
        </Button>
      </div>
      <div>
      <Button
        style={{
          background: "none",
          boxShadow: "none",
        }}
        variant="contained"
        color={"primary"}
        onClick={fullScreenHandler}>
          {fullScreen ? 
            <UnfoldLessIcon sx={{ fontSize: "27px" }}/>
          :
            <UnfoldMoreIcon sx={{ fontSize: "27px" }}/>
          }

        </Button>

      </div>
    </div>
  );
};
