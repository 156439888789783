import React from 'react';

import genders from '../../../../tests/mocks/genders';
import { formatDate } from '../../../../utils/DateUtil';
import { valueToName } from '../../../../utils/FormUtil';

const PersonalInformationProfessionalDetail = ({ formValues }) => {
   const {
      alias,
      first_name,
      last_name,
      sex_str,
      birth_date,
      id_number,
      phone_number,
   } = formValues;

   // Get the name instead sex_str value
   const sexStrName = valueToName(genders, sex_str);

   return (
      <div className="detail">
         <div className="header header--detail">
            <h2>Información Personal</h2>
         </div>
         <div className="data-wrapper">
            <span className="data">
               Alias:<span className="bold">{alias || '-'}</span>
            </span>
            <span className="data">
               Nombre:<span className="bold">{first_name}</span>
            </span>
            <span className="data">
               Apellido:<span className="bold">{last_name}</span>
            </span>
            <span className="data">
               Sexo:<span className="bold">{sexStrName}</span>
            </span>
            <span className="data">
               Fecha de nacimiento:
               <span className="bold">{formatDate(birth_date)}</span>
            </span>
            <span className="data">
               DNI:<span className="bold">{id_number}</span>
            </span>
            <span className="data">
               Teléfono:<span className="bold">{phone_number}</span>
            </span>
         </div>
      </div>
   );
};

export default PersonalInformationProfessionalDetail;
