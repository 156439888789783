import PatientWrapper from "@mediline/mediline-api-middleware/src/wrappers/patient.wrapper";

const ptntw = new PatientWrapper();

export const PatientProfile = async (params, userToken, displayError = null) =>
	ptntw.patientProfile(userToken, params, displayError);

export const PatientSearch = async (userToken, params, displayError = null) =>
	ptntw.patientSearch(userToken,params,displayError);

export const PatientGet = async (id, userToken, displayError = null) =>
	ptntw.getPatientClinicHistory(userToken,id, displayError);

export const ChangeMedilinePlan = async (userToken, patient) =>
	ptntw.changePlan(userToken, patient);

export const GetPatientProfile = async (userToken, login) => ptntw.getPatientProfile(userToken, login);