import React from "react";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import PrescriptionData from "./prescriptionData";
import {
  List,
} from "@material-ui/core";

export default function LabTabs({ data }) {
  const [value, setValue] = React.useState("3");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <td sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            inkbarstyle={{ background: "#002c54" }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#002c54",
              },
            }}
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab className="tab-hc" label="Recetas" value="1" />
            <Tab className="tab-hc" label="Indicaciones" value="2" />
            <Tab className="tab-hc" label="Certificados" value="3" />
            <Tab className="tab-hc" label="Estudios" value="4" />
            <Tab className="tab-hc" label="Derivaciones" value="5" />
          </TabList>
        </Box>
        <TabPanel className="panel" value="1">
          <div className="list">
            <List className="list">
              {data?.prescriptions?.length ? data.prescriptions.map((prescription) => {
                return (
                  <PrescriptionData
                    key={prescription.id}
                    prescription={prescription}
                  />
                );
              }):"No existen recetas"}
            </List>
          </div>
        </TabPanel>
        <TabPanel className="panel" value="2">
          <List>
            {data?.indications?.length ? data.indications.map((indication, index) => {
              return (
                <div key={index} className="container-detail-hc">
                  <div className="data" >Medicamento: <span className="normal" >{indication.name}</span></div>
                  <div className="data" >Indicación: <span className="normal" >{indication.indications}</span></div>
                </div>
              );
            }): "No existen indicaciones"}
          </List>
        </TabPanel>
        <TabPanel className="panel" value="3">
          <List>
            {data?.certificates?.length ? data.certificates.map((certificate, index) => {
              return (
                <div key={index} className="container-detail-hc">
                  <div className="data" >Diagnóstico: <span className="normal" >{certificate.diagnosis}</span></div>
                  <div className="data" >Indicación: <span className="normal" >{certificate.indications}</span></div>                  
                  <div className="data" >Periodo: <span className="normal" >{certificate.duration + " " + certificate.unit}</span></div>
                  <div className="data" >Institución: <span className="normal" >{certificate.institution}</span></div>
                </div>
              );
            }): "No existen certificados"}
          </List>
        </TabPanel>
        <TabPanel className="panel" value="4">
          <List>
            {data?.studies?.length ? data.studies.map((studie, index) => {
              return (
                <div key={index} className="container-detail-hc">
                  <div className="data" >Diagnóstico: <span className="normal" >{studie.diagnosis}</span></div> 
                  <div className="data" >Práctica: <span className="normal" >{studie.name}</span></div>                                   
                </div>
              );
            }): "No existen estudios"}
          </List>
        </TabPanel>
        <TabPanel className="panel" value="5">
          <List >
            {data?.specialties?.length ? data.specialties.map((specialtie, index) => {
              return (
                <div key={index} className="container-detail-hc">
                  <div className="data" >Diagnóstico: <span className="normal" >{specialtie.diagnosis}</span></div>
                  <div className="data" >Especialidad: <span className="normal" >{specialtie.name}</span></div>
                  <div className="data" >Comentario: <span className="normal" >{specialtie.comment || ''}</span></div>
                  <div className="line-hc"></div>
                </div>
              );
            }): "No existen derivaciones"}
          </List>
        </TabPanel>
      </TabContext>
    </td>
  );
}
