import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import AddBoxIcon from "@material-ui/icons/AddBox";
import HeaderImage from "../../assets/img/preguntasFrecuentes.svg";
import HeaderEmergencia from "../../assets/img/emergencia_1.svg";
import HeaderEmergenciaCircle from "../../assets/img/help-circle.svg";
import { useStateAuthValue } from "../../context/Auth/AuthState";
import { Role } from "../../models/enums";
import withStyles from "@material-ui/core/styles/withStyles";

const IconLeftAccordionSummary = withStyles({
  expandIcon: {
    order: -1,
  },
})(AccordionSummary);

const Help = () => {
  const [{ userData }] = useStateAuthValue();
  const currentRole = userData?.active_role;
  const isPatient = currentRole === Role.PATIENT;
  const isProfessional = currentRole === Role.PROFESSIONAL;
  const allowed = [
    "Infecciones respiratorias altas como estados gripales, sinusitis, refrío",
    "Tos, catarro, dolor de garganta, disfonía",
    "Lesiones en piel, erupciones, urticaria",
    "Náuseas, vómitos, diarrea, constipación, dolor abdominal leves",
    "Cefalea, migraña",
    "Infecciones tracto urinario, molestias para orinar",
    "Ansiedad, insomnio",
    "Síntomas ginecológicas",
    "Dolores articulares, dolores de espalda",
    "Patología pediátrica de baja complejidad",
    "Picadura de insectos",
    "Conjuntivitis",
    "Traumatismo leves",
    "Control de patologías crónicas estables",
    "Quemaduras menores",
  ];
  const notAllowed = [
    "Dolor de pecho",
    "Señal de infarto o ataque cardíaco",
    "Signos de accidente cerebro vascular como aparición repentina de falta de movilidad o adormecimientos de miembros, dificultad para hablar",
    "Perdida abrupta de la conciencia, sincope",
    "Convulsiones",
    "Sangrados, hemorragias",
    "Dificultad para respirar",
    "Intoxicación, sobredosis de medicamentos",
    "Fracturas",
    "Erupciones cutáneas severas",
    "Dolor abdominal intenso y continuo",
    "Quemaduras moderadas a severas",
    "Fracturas o traumatismos moderados a graves",
    "Traumatismo de cerebro con pérdida de conocimiento",
    "Traumatismo ocular u ojo rojo doloroso",
    "Intento de suicidio (autoeliminación)",
    "Brote psicótico",
  ];
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="help">
      <div className="header-professional">
        <img className="image" src={HeaderEmergenciaCircle} alt="Ayuda" />
        <h2>Ayuda</h2>
      </div>
      <div className="container">
        <div className="header">
          <img className="image" src={HeaderImage} alt="Ayuda" />
          <h2>Preguntas Frecuentes</h2>
        </div>
        <div className="accordion-wrapper">
          <h2>Generales de la Plataforma</h2>
          <Accordion
            expanded={expanded === "panel01"}
            onChange={handleChange("panel01")}
          >
            <IconLeftAccordionSummary
              IconButtonProps={{ edge: "start" }}
              expandIcon={
                expanded === "panel01" ? (
                  <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                ) : (
                  <AddBoxIcon style={{ fill: "#da2037" }} />
                )
              }
            >
              <Typography className="title">¿Qué es MediLine?</Typography>
            </IconLeftAccordionSummary>
            <AccordionDetails>
              <Typography className="content">
                MediLine es una plataforma de telemedicina que brinda diferentes
                servicios de salud de modo virtual, tanto en modalidad App como
                Web, integrando las necesidades del paciente con los
                profesionales de la salud y las farmacias.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel02"}
            onChange={handleChange("panel02")}
          >
            <IconLeftAccordionSummary
              IconButtonProps={{ edge: "start" }}
              expandIcon={
                expanded === "panel02" ? (
                  <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                ) : (
                  <AddBoxIcon style={{ fill: "#da2037" }} />
                )
              }
            >
              <Typography className="title">
                ¿Cuáles son las ventajas que tiene el uso de MediLine con
                respecto al sistema de salud tradicional?
              </Typography>
            </IconLeftAccordionSummary>
            <AccordionDetails>
              <Typography component={"div"} className="content">
                <ol>
                  <li>
                    Recibís atención médica desde tu hogar o en el lugar donde
                    te encuentres ahorrando tiempo y dinero, y ganando seguridad
                    y comodidad.
                  </li>
                  <li>
                    Accedés a especialidades médicas no disponibles en tu zona.
                  </li>
                  <li>
                    Obtenés recetas electrónicas, indicaciones médicas,
                    derivaciones a especialistas, pedidos de análisis clínicos
                    y/o estudios complementarios de tu médico y certificados
                    médicos para presentar donde lo necesites.
                  </li>
                  <li>
                    Accedés a la atención médica sin necesidad de estar afiliado
                    a una prepaga.
                  </li>
                  <li>
                    Recibís los medicamentos en tu domicilio o donde lo indiques
                    directo desde la farmacia que elijas.
                  </li>
                  <li>
                    Pagás en forma virtual y segura en toda la plataforma tanto
                    a profesionales médicos que atiendan en forma particular
                    como a la farmacia.
                  </li>
                  <li>
                    Podés contratar planes MediLine para acceder a nuestros
                    mejores especialistas a precios preferenciales.
                  </li>
                  <li>
                    Accedés a profesionales de renombre/destacados lejos de tu
                    zona
                  </li>
                </ol>
                <p>
                  La telemedicina no ha venido a reemplazar al sistema de salud
                  tradicional ni a la consulta presencial, sino que ofrece una
                  alternativa y un complemento no solo ante la realidad
                  sanitaria en la que nos encontramos inmersos (covid-19),
                  respondiendo a las necesidades de los nuevos consumidores.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel03"}
            onChange={handleChange("panel03")}
          >
            <IconLeftAccordionSummary
              IconButtonProps={{ edge: "start" }}
              expandIcon={
                expanded === "panel03" ? (
                  <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                ) : (
                  <AddBoxIcon style={{ fill: "#da2037" }} />
                )
              }
            >
              <Typography className="title">
                ¿Qué necesito para utilizar el servicio?
              </Typography>
            </IconLeftAccordionSummary>
            <AccordionDetails>
              <Typography className="content">
                Necesitás un dispositivo que posea buena conexión a internet,
                cámara y micrófono (Smartphone, Tablet, Notebook, PC), y una
                conexión de 4G/LTE o una conexión Wi-Fi de alta velocidad.
              </Typography>
            </AccordionDetails>
          </Accordion>
          
          <Accordion
            expanded={expanded === "panel04"}
            onChange={handleChange("panel04")}
          >
            <IconLeftAccordionSummary
              IconButtonProps={{ edge: "start" }}
              expandIcon={
                expanded === "panel04" ? (
                  <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                ) : (
                  <AddBoxIcon style={{ fill: "#da2037" }} />
                )
              }
            >
              <Typography className="title">
                ¿Cómo puedo registrarme para usar MediLine?
              </Typography>
            </IconLeftAccordionSummary>
            <AccordionDetails>
              <Typography className="content">
                Para registrarte debes ingresar tu mail y contraseña. Podés
                hacerlo desde la App o ingresando a www.mediline.com.ar
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel05"}
            onChange={handleChange("panel05")}
          >
            <IconLeftAccordionSummary
              IconButtonProps={{ edge: "start" }}
              expandIcon={
                expanded === "panel05" ? (
                  <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                ) : (
                  <AddBoxIcon style={{ fill: "#da2037" }} />
                )
              }
            >
              <Typography className="title">
                ¿Qué dispositivos permite usar la plataforma?
              </Typography>
            </IconLeftAccordionSummary>
            <AccordionDetails>
              <Typography className="content">
                MediLine es una plataforma multidispositivo (PC, Notebook,
                Tablet, Smartphone, etc.) a la cual podés acceder desde
                cualquiera de ellos tanto a la versión móvil como web o de
                escritorio.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel06"}
            onChange={handleChange("panel06")}
          >
            <IconLeftAccordionSummary
              IconButtonProps={{ edge: "start" }}
              expandIcon={
                expanded === "panel06" ? (
                  <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                ) : (
                  <AddBoxIcon style={{ fill: "#da2037" }} />
                )
              }
            >
              <Typography className="title">
                ¿Cuáles son los Términos y Condiciones de Uso de la plataforma?
              </Typography>
            </IconLeftAccordionSummary>
            <AccordionDetails>
              <Typography className="content">
                Accede haciendo click en Términos y Condiciones para acceder a
                la información necesaria. Te sugerimos que los leas antes de
                regístrate en la plataforma para que estés informado.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel07"}
            onChange={handleChange("panel07")}
          >
            <IconLeftAccordionSummary
              IconButtonProps={{ edge: "start" }}
              expandIcon={
                expanded === "panel07" ? (
                  <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                ) : (
                  <AddBoxIcon style={{ fill: "#da2037" }} />
                )
              }
            >
              <Typography className="title">
                ¿Cuál es la normativa vigente sobre la aplicación de la
                Telemedicina?
              </Typography>
            </IconLeftAccordionSummary>
            <AccordionDetails>
              <Typography className="content">
                MediLine ha basado todo el diseño de su plataforma en la
                siguiente normativa: Ley 25326 de Protección de Datos
                Personales, Ley 26529 Derechos del Paciente, Ley 25505 Firma
                electrónica o digital y Ley 27553 Recetas electrónicas o
                digitales. Además, nos hemos basado en el Proyecto de Ley de
                Regulación de Telemedicina que cuenta con la aprobación de la
                Honorable Cámara de Senadores de la Nación Argentina.
                <br />
                <br />
                https://www4.hcdn.gob.ar/dependencias/dsecretaria/Periodo2020/PDF2020/TP2020/3142-D-2020.pdf
              </Typography>
            </AccordionDetails>
          </Accordion>

          {isPatient && (
            <>
              <br />
              <h2>Usuario Paciente</h2>
              <Accordion
                expanded={expanded === "panel101"}
                onChange={handleChange("panel101")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel101" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Qué requisitos tengo que tener para usar el servicio?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Ser mayor de 18 años. En caso de tener menores o adultos
                    mayores a cargo podrás usar los servicios de la plataforma a
                    través del Titular de un Plan MediLine. En caso de tener
                    menores cargo, solo podrá hacer consultas por videollamada
                    para <b>mayores de 1 año de edad.</b>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel102"}
                onChange={handleChange("panel102")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel102" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Cómo accedo a una teleconsulta?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Una vez registrado en la plataforma, MediLine te brinda los
                    siguientes servicios para acceder a una teleconsulta:
                    <ul>
                      <li>
                        Guardia Virtual: te permite tener una atención inmediata
                        de un profesional de guardia, este servicio está
                        disponible para quienes hayan contratado un Plan
                        Mediline o haya sido contratado por tu financiador.
                      </li>
                      <li>
                        <div className="disable-text">
                        Turno Programado: te permite tener una atención diferida
                        con profesionales que hayan contratado el servicio de la
                        plataforma para su Consultorio Virtual, o con
                        profesionales de tu financiador en caso de haber firmado
                        un convenio con MediLine.
                        </div>
                        <h6 className="functionality-bold">Esta funcionalidad se habilitará en breve.</h6>
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel103"}
                onChange={handleChange("panel103")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel103" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Tengo que pagar para acceder al servicio?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Podrás descargar la aplicación de manera gratuita o ingresá
                    a través de nuestra web. Luego para acceder a consultas
                    médicas tenés tres opciones:
                    <ul>
                      <li>
                        Plan MediLine: adquirí alguno de nuestros servicios de
                        asistencia médica, los cuales podés encontrar en nuestra
                        página web.
                      </li>
                      <li>
                      <div className="disable-text">
                        Particular: pagá una consulta particular con un
                        profesional asociado a nuestra plataforma por medio de
                        nuestra pasarela de pago.
                      </div>
                      <h6 className="functionality-bold">Esta funcionalidad se habilitará en breve.</h6>
                      </li>
                      <li>
                      <div className="disable-text">
                        Obra Social: accedé a través de tu prepaga, de tu
                        empresa o aseguradora, si esta tiene contratada la
                        plataforma como un servicio de telemedicina.
                      </div>
                      <h6 className="functionality-bold">Esta funcionalidad se habilitará en breve.</h6>
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel104"}
                onChange={handleChange("panel104")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel104" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Cómo se seleccionan a los médicos de la plataforma?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    El cuerpo médico de MediLine está formado por un equipo de
                    profesionales que han sido evaluados y capacitados en forma
                    previa a su incorporación a la plataforma. Cualquiera de los
                    profesionales puede responder las consultas de la
                    especialidad a la que pertenecen, y derivar a otros
                    especialistas en caso de que el diagnóstico lo amerite.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel105"}
                onChange={handleChange("panel105")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel105" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Puedo llamar a un médico para que atienda a una persona a
                    cargo?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Si. Se puede llamar en nombre de cualquier integrante del
                    grupo familiar declarado en la plataforma. La persona debe
                    estar presente al momento de realizar la consulta. Las
                    personas a cargo podrán ser menores de 18 años o mayores de
                    65 años, así también como personas con capacidades
                    diferenciales de cualquier edad.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel106"}
                onChange={handleChange("panel106")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel106" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Puedo seleccionar a un médico por su nombre en caso de
                    conocerlo?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    SI, para el servicio de Turno Programado. MediLine además de
                    contar con su cuerpo médico propio también trabaja con
                    médicos asociados a la plataforma que tienen sus
                    Consultorios Virtuales habilitados. Luego de elegir la
                    especialidad deseada, podrás escoger al profesional que
                    prefieras.
                    <br />
                    <br />
                    NO, para el servicio de Guardia Virtual. Al ser este
                    servicio de demanda espontánea el mismo es atendido por el
                    primer profesional disponible en su especialidad. MediLine
                    atiende este servicio con profesional propio de su staff.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel107"}
                onChange={handleChange("panel107")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel107" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Qué pasa si ya he consumido todas las consultas de mi plan?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Si ya consumiste todas las consultas del mes que
                    corresponden al plan contratado, podrás acceder a nuevas
                    consultas seleccionando la modalidad de consulta Particular
                    y pagar la consulta con tu tarjeta de crédito o débito en la
                    misma plataforma.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel108"}
                onChange={handleChange("panel108")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel108" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Qué puedo obtener de una consulta médica?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    En cada consulta médica el profesional que te atiende podrá:
                    registrar la consulta en tu historia clínica, realizar una
                    receta electrónica, pedirte estudios, realizar un
                    certificado laboral y derivarte a un especialista, todo en
                    un mismo acto y sin salir de la plataforma. Cada documento
                    del acto médico será enviado en forma inmediata a tu correo
                    electrónico.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel109"}
                onChange={handleChange("panel109")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel109" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Qué tipo de consultas puedo realizar a través de MediLine?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item lg={12}>
                      <Typography className="content">
                        MediLine es una plataforma para atender consultas
                        virtuales de casos de baja complejidad y atención
                        primaria de salud.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={1}></Grid>
                    <Grid item xs={12} lg={10}>
                      <Grid item sm={12}>
                        <Typography className="permitido">SÍ</Typography>
                      </Grid>
                      <List
                        component="ul"
                        style={{ maxHeight: 250, overflow: "auto" }}
                      >
                        {allowed.map((itemAllowed) => (
                          <ListItem key={itemAllowed}>
                            <ListItemIcon>
                              <CheckCircleIcon style={{ fill: "#228b22" }} />
                            </ListItemIcon>
                            <ListItemText primary={itemAllowed} />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid item xs={12} lg={1}></Grid>
                    <Grid item xs={12} lg={1}></Grid>
                    <Grid item sm={10}>
                      <Grid item sm={12}>
                        <Typography className="nopermitido">NO</Typography>
                      </Grid>
                      <List
                        component="ul"
                        style={{ maxHeight: 250, overflow: "auto" }}
                      >
                        {notAllowed.map((itemNotAllowed) => (
                          <ListItem key={itemNotAllowed}>
                            <ListItemIcon>
                              <CancelIcon style={{ fill: "#da2037" }} />
                            </ListItemIcon>
                            <ListItemText primary={itemNotAllowed} />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel110"}
                onChange={handleChange("panel110")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel110" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Puedo utilizar MediLine en caso de una Emergencia Médica?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    NO, MediLine es un servicio basado en telemedicina y
                    atendemos problemas de salud de baja complejidad y de
                    atención primaria. En caso de emergencia, urgencia,
                    intervención quirúrgica o internación, debes asistir al
                    centro de salud más cercano o comunicarte a un servicio de
                    emergencias médicas de tu localidad.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel111"}
                onChange={handleChange("panel111")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel111" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Puedo hacer consultas desde otro lugar además de mi
                    domicilio declarado?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    SI, al ser una plataforma virtual esta es una de las
                    principales ventajas. Podés acceder a una consulta de
                    Guardia Virtual o una consulta de Turno Programado desde
                    cualquier lugar donde te encuentres, mientras dispongas de
                    buena conexión a internet y un dispositivo electrónico con
                    cámara.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel112"}
                onChange={handleChange("panel112")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel112" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿MediLine ofrece receta electrónica?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    SI, durante la consulta el profesional médico realiza la
                    receta la cual le llega al usuario por medio de una
                    notificación interna dentro de la misma plataforma y al
                    correo electrónico declarado. Te sugerimos que revises la
                    bandeja de correo no deseado si no ves el mail de tu
                    Consulta Terminada en la bandeja de entrada. El profesional
                    médico realizará la receta y te la enviará durante la misma
                    consulta médica sin salir de la plataforma. Luego, podrás
                    visualizarla en el menú de tu Perfil dentro de la
                    plataforma. MediLine cumple con la normativa vigente en
                    relación con la receta electrónica: Ley 25505 Firma
                    electrónica o digital y Ley 27553 Recetas electrónicas o
                    digitales.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel113"}
                onChange={handleChange("panel113")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel113" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Qué pasa si los datos de la receta no son correctos?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Los datos de la receta son tomados del sistema por lo que te
                    recomendamos que mantengas actualizado tu perfil con tus
                    datos personales y de tu obra social en caso de
                    corresponder. No obstante, el profesional que te atienda
                    validará los datos en forma previa a la confección de la
                    receta. De tener algún inconveniente podés escribirnos un
                    mail a: <a href="mailto:contacto@mediline.com.ar">contacto@mediline.com.ar</a> para que podamos solucionar
                    el inconveniente.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel114"}
                onChange={handleChange("panel114")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel114" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Puedo obtener un certificado médico para presentar en mi
                    trabajo?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    SI, el profesional que te atiende puede generar un
                    certificado médico para ser presentado a donde se lo
                    indiques. Una vez realizado el mismo te llega en forma
                    electrónica al correo electrónico declarado, desde allí lo
                    podrás enviar al mail que indiques o imprimir para su
                    presentación.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* <Accordion
                expanded={expanded === "panel115"}
                onChange={handleChange("panel115")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel115" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Puedo comprar los medicamentos recetados a través de
                    MediLine?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    SI, MediLine tiene una amplia red de farmacias asociadas a
                    la plataforma y podrás elegir la que más te convenga ya sea
                    por cercanía a tu domicilio como por preferencia. Luego de
                    haber terminado la consulta médica y haber recibido la
                    receta electrónica, podrás acceder a la misma en tu menú y
                    seleccionar del listado de farmacias asociadas la de tu
                    preferencia para comprar tu medicación sin moverte de tu
                    casa o lugar donde te encuentres.
                    <br />
                    <br />
                    NO, cuando no exista ninguna farmacia asociada en tu zona.
                    En este caso podrás ir en forma presencial a cualquier
                    farmacia presentando la receta electrónica desde tu celular
                    o impresa.
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
              <Accordion
                expanded={expanded === "panel116"}
                onChange={handleChange("panel116")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel116" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Cómo hago para obtener una indicación o una receta
                    posterior a mi consulta?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Se presentan varios casos según el tipo de consulta:
                    <ul>
                      <li>
                        Plan MediLine: podrás hacer una nueva consulta a
                        cualquier profesional de guardia de la especialidad que
                        estás necesitando para que atienda tu inquietud. Esto
                        consume una consulta de tu plan.
                      </li>
                      <li>
                        Particular y Obra Social deberías pagar una nueva
                        consulta al profesional seleccionando el profesional que
                        te atendió.
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* <Accordion
                expanded={expanded === "panel117"}
                onChange={handleChange("panel117")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel117" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Puedo cancelar un turno programado?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    SI, podrás cancelar o reprogramar tu consulta. Para eso
                    debés ingresar a Mis Turnos y seleccionar el turno que
                    querés cancelar o reprogramar. Para más información te
                    invitamos a leer nuestros Términos y Condiciones.
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
              <Accordion
                expanded={expanded === "panel118"}
                onChange={handleChange("panel118")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel118" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Quién puede ver mi historia clínica?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Solo en profesional con quién estás haciendo la
                    videollamada. Los datos de tu historia clínica son
                    resguardados en nuestra plataforma y no son compartidos con
                    nadie más que con el profesional que te atiende.
                    <br />
                    MediLine cumple con toda la normativa vigente relacionada
                    con la Salud: Ley 25326 de Protección de Datos Personales,
                    Ley 26529 Derechos del Paciente, Ley 25505 Firma electrónica
                    o digital y Ley 27553 Recetas electrónicas o digitales.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel207"}
                onChange={handleChange("panel207")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel207" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                  ¿Cómo hago para dar de baja el servicio de asistencia médica contratado?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                  Si quisieras dar de baja el servicio de asistencia médica podés hacerlo a través del <a href="https://mediline.com.ar/servicios/" target="_blank" rel="noreferrer">Botón de Arrepentimiento </a> en nuestra página web, o por Whatsapp al número de Atención al Cliente 
                  <strong>
                    {" "}
                    <a href="https://wa.me/542612170680" target="_blank" rel="noreferrer">
                    +542612170680
                    </a>
                  </strong>
                  , o escribirnos a 
                  <strong>
                    {" "}
                    <a href="mailto:contacto@mediline.com.ar">
                    contacto@mediline.com.ar
                    </a>
                  </strong>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {isProfessional && (
            <>
              <br />
              <h2>Usuario Profesional</h2>
              <Accordion
                expanded={expanded === "panel201"}
                onChange={handleChange("panel201")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel201" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Qué tipos de consulta puedo recibir?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item lg={12}>
                      <Typography className="content">
                        La telemedicina apunta a resolver solo consultas de baja
                        complejidad y atención primaria de la salud,
                        relacionadas a su especialidad, quedando así excluidos
                        problemas médicos que pongan en peligro la vida o que
                        pueda causar deterioro en la persona, eventos que se
                        consideren una emergencia, urgencia o un caso grave. Es
                        el profesional quién evaluará la situación.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={1}></Grid>
                    <Grid item xs={12} lg={10}>
                      <Grid item sm={12}>
                        <Typography className="permitido">SÍ</Typography>
                      </Grid>
                      <List
                        component="ul"
                        style={{ maxHeight: 250, overflow: "auto" }}
                      >
                        {allowed.map((itemAllowed) => (
                          <ListItem key={itemAllowed}>
                            <ListItemIcon>
                              <CheckCircleIcon style={{ fill: "#228b22" }} />
                            </ListItemIcon>
                            <ListItemText primary={itemAllowed} />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid item xs={12} lg={1}></Grid>
                    <Grid item xs={12} lg={1}></Grid>
                    <Grid item sm={10}>
                      <Grid item sm={12}>
                        <Typography className="nopermitido">NO</Typography>
                      </Grid>
                      <List
                        component="ul"
                        style={{ maxHeight: 250, overflow: "auto" }}
                      >
                        {notAllowed.map((itemNotAllowed) => (
                          <ListItem key={itemNotAllowed}>
                            <ListItemIcon>
                              <CancelIcon style={{ fill: "#da2037" }} />
                            </ListItemIcon>
                            <ListItemText primary={itemNotAllowed} />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel202"}
                onChange={handleChange("panel202")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel202" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Qué sucede si no se puede resolver la consulta bajo la
                    modalidad virtual?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Los pacientes asociados a la plataforma MediLine están
                    informados del tipo de consulta virtual que pueden hacer. No
                    obstante, en caso de realizar una consulta con un
                    profesional de la plataforma y la misma no pueda ser
                    resuelta en forma virtual, el profesional podrá hacer una
                    derivación a otro profesional o sugerir un turno presencial.
                    La consulta igual se paga porque el profesional invierte su
                    tiempo en analizar el caso.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel203"}
                onChange={handleChange("panel203")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel203" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Qué acciones me permite realizar la plataforma como
                    profesional médico?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    MediLine ya sea su modalidad App o Web permite al
                    profesional médico: prescribir medicamentos, solicitar
                    estudios, extender certificados médicos y hacer una
                    derivación a otro profesional. Cualquiera de estas acciones
                    puede hacerse mientras se tiene la video-consulta con el
                    Paciente sin tener que interrumpir la llamada. Una vez
                    realizadas, son enviadas al correo electrónico declarado en
                    la plataforma por el Paciente y la receta también queda
                    disponible en la opción Mis Recetas del menú de su perfil.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel204"}
                onChange={handleChange("panel204")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel204" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Qué pasa si quiero modificar mi agenda por algún motivo?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    Si tenés contratado el servicio de Consultorio Virtual para
                    atender Turnos Programados, cada profesional médico puede
                    autogestionar sus tiempos en la plataforma indicando su
                    disponibilidad. No hay que avisar a nadie ni pedir ningún
                    tipo de autorización. Cualquier cambio en la agenda
                    impactará en los turnos programados y la plataforma los
                    cancelará en forma automática notificando al Paciente para
                    su reprogramación.
                    <br />
                    <br />
                    Si sos un profesional del Staff Mediline que atiende el
                    servicio de Guardia Virtual, debés contactarte con la
                    Directora Técnica Médica de Mediline.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel205"}
                onChange={handleChange("panel205")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel205" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Se puede atender sin video, solo con audio?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    SI, se puede atender solo con audio. No obstante, no lo
                    recomendamos ya que la videollamada es una forma de
                    garantizar la identidad de las personas. Muchas veces cuando
                    la señal es mala quitar el video es una opción válida para
                    que la consulta termine con éxito.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel206"}
                onChange={handleChange("panel206")}
              >
                <IconLeftAccordionSummary
                  IconButtonProps={{ edge: "start" }}
                  expandIcon={
                    expanded === "panel206" ? (
                      <IndeterminateCheckBoxIcon style={{ fill: "#da2037" }} />
                    ) : (
                      <AddBoxIcon style={{ fill: "#da2037" }} />
                    )
                  }
                >
                  <Typography className="title">
                    ¿Están mis datos personales resguardados?
                  </Typography>
                </IconLeftAccordionSummary>
                <AccordionDetails>
                  <Typography className="content">
                    El Paciente puede ver los datos relacionados a la
                    especialidad y cualquier otra información que el profesional
                    quiera compartir en sus antecedentes médicos. Los datos
                    personales son resguardados por la plataforma.
                    <br />
                    MediLine cumple con toda la normativa vigente relacionada
                    con la Salud: Ley 25326 de Protección de Datos Personales,
                    Ley 26529 Derechos del Paciente, Ley 25505 Firma electrónica
                    o digital y Ley 27553 Recetas electrónicas o digitales.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </div>
        <div className="contact-wrapper">
          <div className="contact">
            <div className="center-attention">
              <img className="image" src={HeaderEmergencia} alt="Ayuda" />
              <h2>Soporte</h2>
            </div>
            <p>
              Ante cualquier duda podés comunicarte con nosotros por email
              <strong>
                {" "}
                <a href="mailto:contacto@mediline.com.ar">
                  contacto@mediline.com.ar
                </a>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
