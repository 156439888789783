import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useStateAuthValue } from "../../../../context/Auth/AuthState";
import { useStateProfConsultFilterValue } from "../../../../context/profConsultFilterState";
import { FilterType } from "../../../../utils/FilterUtil";
import {
  GetSpeedTestTime,
  PostSpeedTest,
} from "../../../../services/apiService";
import { showToast, ToastType } from "../../../../utils/ToastUtil";
import { useToast } from "../../../../context/Toast/ToastProvider";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  submit: {
    backgroundColor: "#DA2037",
    "&:hover": {
      backgroundColor: "#e84353",
    },
    margin: theme.spacing(3, 0, 2),
    borderRadius: "20px",
    textTransform: "capitalize",
  },
}));

const TestConnection = () => {
  const toastDispatch = useToast();
  const [{ userToken }] = useStateAuthValue();
  const [{}, dispatch] = useStateProfConsultFilterValue();
  const classes = useStyles();
  const [timeoutFinished, setTimeoutFinished] = useState(false);
  let stopLoading = false;

  const goBack = () => {
    stopLoading = true;
    dispatch({
      type: "LOADING",
      clickButton: FilterType.FINISH_CONSULTATION,
      loading: false,
    });
    localStorage.setItem("consult", null);
  };

  useEffect(() => {
    let timeLimit = { time: 16000 };
    const testConNection = async () => {
      if (userToken) {
        timeLimit = await GetSpeedTestTime(userToken);
        PostSpeedTest(userToken)
          .then((res) => {
            if (((res.time_download + res.time_upload) < timeLimit?.time / 1000) && !stopLoading) {
              dispatch({
                type: "LOADING",
                clickButton: FilterType.VIRTUAL_GUARD_CALLED,
                loading: false,
              });
              localStorage.setItem("consult", true);
            }
          })
          .catch((e) => {
            showToast(
              toastDispatch,
              e.message,
              ToastType.ERROR,
              ToastType.ERROR
            );
          });
      }
    };
    testConNection();

    let timer = setTimeout(() => {
      setTimeoutMessage();
    }, timeLimit?.time);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const setTimeoutMessage = () => {
    setTimeoutFinished(true);
    setTimeout(() => {
      goBack();
    }, [5000]);
  };

  return (
    <div className="test-connection-container">
      <div className="test-connection">
        {timeoutFinished ? (
          <>
            <p style={{ textAlign: "center" }} color="grey">
              La calidad de conexión de tu internet es insuficiente para
              establecer una videoconsulta.
            </p>
          </>
        ) : (
          <>
            <ClipLoader
              color={"grey"}
              loading={true}
              size={90}
              speedMultiplier={0.6}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <p color="grey">Testeando calidad de conexión...</p>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={goBack}
            >
              Cancelar
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default TestConnection;
