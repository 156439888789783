import React, { useState } from 'react';
import {
   IconButton,
   ListItem,
   ListItemSecondaryAction,
   ListItemText,
   Tooltip,
   Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import Modal from '../../components/Modal';
import { NotificationStatus } from '../../models/enums';
import { formatDate } from '../../utils/DateUtil';

const NotificationItem = ({ notification, handleRead, handleDelete }) => {
   const { status, subject, description, creation_date } = notification;
   const [openNotificationDetail, setOpenNotificationDetail] = useState(false);
   const isReaded = NotificationStatus.ACCEPTED === status;
   const [readed, setReaded] = useState(isReaded);

   const handleClick = () => {
      setOpenNotificationDetail(true);
      if (!readed) {
         handleRead();
         setReaded(true);
      }
   };

   const handleClose = () => {
      setOpenNotificationDetail(false);
   };

   return (
      <>
         <ListItem
            className={`list-item ${readed ? 'readed' : ''}`}
            onClick={handleClick}
            button
         >
            <ListItemText
               className="item-text"
               primary={
                  <Typography component="span">
                     {formatDate(creation_date) || ''}
                  </Typography>
               }
            />
            <ListItemText
               className="item-text"
               primary={subject || ''}
            />
            <ListItemSecondaryAction>
               <Tooltip title="Eliminar notificación">
                  <IconButton onClick={() => handleDelete()}>
                     <DeleteIcon />
                  </IconButton>
               </Tooltip>
            </ListItemSecondaryAction>
         </ListItem>
         <Modal
            isActive={openNotificationDetail}
            handleClose={handleClose}
            title={subject || ''}
         >
            {description && (
               <div dangerouslySetInnerHTML={{ __html: description }} />
            )}
         </Modal>
      </>
   );
};

export default NotificationItem;
