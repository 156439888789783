import React, { createContext, useState } from 'react';
import AgoraRTC from "agora-rtc-sdk-ng";

const AgoraContext = createContext();

const AgoraProvider = ({ children }) => {
  const [client] = useState(() => AgoraRTC.createClient({ mode: "rtc", codec: "vp8" }));

  return (
    <AgoraContext.Provider value={{ client: client,  }}>
      {children}
    </AgoraContext.Provider>
  );
};

export { AgoraContext, AgoraProvider };
