import React from 'react';

import {
   DatePickerField,
   InputField,
   RadioGroupField,
} from '../../../../components/FormFields';
import genders from '../../../../tests/mocks/genders';

const PersonalInformationProfessionalForm = ({ formModel }) => {

   const {
      alias,
      first_name,
      last_name,
      sex_str,
      birth_date,
      id_number,
      phone_number
   } = formModel;


   return (
      <>
         <div className="header">
            <h2>Información Personal</h2>
         </div>
         <div className="form">

            <div>
               <InputField
                  name={alias.name}
                  label={alias.label}
                  className="field"
                  style={{width : "100%"}}
               />
               <InputField
                  name={first_name.name}
                  label={first_name.label}
                  className="field"
                  requiredField
                  style={{width : "100%"}}
               />
               <InputField
                  name={last_name.name}
                  label={last_name.label}
                  className="field"
                  requiredField
                  style={{width : "100%"}}
               />
               <InputField
                  name={id_number.name}
                  label={id_number.label}
                  className="field"
                  disabled={true}
                  style={{width : "100%"}}
               />
            </div>
            <div>
               <RadioGroupField
                  name={sex_str.name}
                  label={sex_str.label}
                  data={genders}
                  extraLabel={sex_str.extraLabel}
                  className="field"
                  requiredField
               />
               <DatePickerField
                  name={birth_date.name}
                  label={birth_date.label}
                  format="dd/MM/yyyy"
                  minDate={new Date(birth_date.minDate)}
                  maxDate={new Date()}
                  disableFuture
                  views={['year', 'month', 'date']}
                  openTo="year"
                  className="field"
                  requiredField
                  style={{width : "100%"}}
               />
               <InputField
                  name={phone_number.name}
                  label={phone_number.label}
                  extraLabel={phone_number.extraLabel}
                  className="field"
                  requiredField
                  style={{width : "100%"}}
               />
            </div>
         </div>
      </>
   );
};

export default PersonalInformationProfessionalForm;
