import React from 'react';
import {valueToName } from '../../../../utils/FormUtil';
import licenceTypes from '../../../../tests/mocks/licenceTypes';

const ProfessionalDataDetail = ({ formValues }) => {
   const {
      professional_dto: {
         licence_str,
         type_licence_str,
         specialties,
         academic_training,
      },
   } = formValues;

   // Get the name instead type_licence_str value
   const typeLicenceStrName = valueToName(licenceTypes, type_licence_str);
   return (
      <div className="detail">
         <div className="header header--detail">
            <h2>Datos Profesionales</h2>
         </div>
         <div className="data-wrapper">
            <span className="data">
               Matrícula:<div className="bold">{licence_str}</div>
            </span>
            <span className="data">
               Tipo de Matrícula:
               <div className="bold">{typeLicenceStrName}</div>
            </span>
            <span className="data">
               Especialidad:
               <div className="options">{specialties[0].name}</div>
            </span>
            <span className="data">
               Formación Académica y Reconocimientos:
               <div className="bold">{academic_training}</div>
            </span>
         </div>
      </div>
   );
};

export default ProfessionalDataDetail;
