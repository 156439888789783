import React, { useState } from 'react';
import { Button, ListItem } from '@material-ui/core';

import ConfirmationModal from '../PharmacyItem/ConfirmModal';
import { useStateAuthValue } from '../../../../context/Auth/AuthState';
import { useToast } from '../../../../context/Toast/ToastProvider';
import Modal from '../../../../components/Modal';
import { showToast, ToastType } from '../../../../utils/ToastUtil';
import { sendOrder } from '../../../../services/pharmacyService';

const PharmacyItem = (props) => {
   const {
      selectedPrescription,
      namePharmacy,
      id,
      department,
      streetName,
      phoneNumber,
      streetNumber,
   } = props;

   const toastDispatch = useToast();
   const [{ userToken }] = useStateAuthValue();
   const [confirmationModal, setConfirmationModal] = useState(false);

   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
   };

   const confirmationModalClose = () => {
      setConfirmationModal(false);
      window.location.reload()
   };

   const sentPrescription = async () => {
      const errorMessage = 'Ocurrió un error en el envío de la receta';

      let arrayId = [];

      selectedPrescription.map((selectedPrescriptionId) => {
         return arrayId.push({ id: selectedPrescriptionId });
      });

      await sendOrder(
         {
            branchOffice: {
               id: id,
            },
            prescriptions: arrayId,
         },
         userToken,
      )
         .then(() => {
            
               setConfirmationModal(true);
       
         })
         .catch(() => {
            showError(errorMessage);
         });
   };

   return (
      <ListItem className="list-item-pharmacy">
         <div className="name-pharmacy">
            <span className="name-pharmacy__name">{namePharmacy}</span>
            {'-'}
            <span className="name-pharmacy__departament">{department}</span>
         </div>
         <div></div>
         <div className="direction-telephone">
            <div className="direction">
               <span>{streetName}</span>
               <span className="direction__number">{streetNumber}</span>
            </div>
            <div className="telephone">
                  <span className="phone__number">Teléfono:{phoneNumber}</span>
            </div>
         </div>
         <div></div>
         <div></div>
         <Button variant="contained" color="primary" onClick={sentPrescription}>
            Enviar a Farmacia
         </Button>
         <Modal
            maxWidth="md"
            isActive={confirmationModal}
            handleClose={confirmationModalClose}
         >
            <ConfirmationModal/>
         </Modal>
      </ListItem>
   );
};

export default PharmacyItem;
