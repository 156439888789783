import React from 'react';
 
import AccordionList from './AccordionList';
 
const ActionPanel = ({data, setMessage, setConsultIsSubmitted, setConsultFinishedWithError, stomp, setPatientLeftContainerStyle, setPatientLeftProfessionalContainerStyle, patientFinish, setPatientFinish}) => (
   <div className="action-panel" style={{ height: "100%"}}>
      <AccordionList setPatientFinish={setPatientFinish} patientFinish={patientFinish} setContainerStyle={setPatientLeftContainerStyle} setPatientLeftProfessionalContainerStyle={setPatientLeftProfessionalContainerStyle} consultationData={data} setMessage={setMessage} setConsultFinishedWithError={setConsultFinishedWithError} stomp={stomp} setConsultIsSubmitted={setConsultIsSubmitted}></AccordionList>
   </div>
);
 
export default ActionPanel;