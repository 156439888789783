import React, { useEffect, useState } from 'react';
import { List } from '@material-ui/core';

import AppointmentItem from './AppointmentItem';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useStateAuthValue } from '../../../context/Auth/AuthState';
import { useToast } from '../../../context/Toast/ToastProvider';
import Appointment from '../../../models/Appointment';
import AppointmentStatus from '../../../models/enums/AppointmentStatus';
import {
   getAppointments,
   putAppointment,
} from '../../../services/appointmentService';
import {
   appointmentStatusTranslatePlural,
   sortDescAppointmentList,
} from '../../../utils/AppointmentUtil';
import {
   formatDate,
   substractMonths,
   addMonths,
} from '../../../utils/DateUtil';
import { removeIndexFromList } from '../../../utils/FormUtil';
import { OK } from '../../../utils/ServiceUtil';
import { showToast, ToastType } from '../../../utils/ToastUtil';

const AppointmentList = ({ filterStatus }) => {
   const [{ userToken }] = useStateAuthValue();
   const [appointmentList, setAppointmentList] = useState([]);
   const [selectedAppointment, setSelectedAppointment] = useState();
   const [openModal, setOpenModal] = useState(false);
   const [loading, setLoading] = useState(false);
   const toastDispatch = useToast();

   const showError = (msgError) => {
      showToast(toastDispatch, msgError, ToastType.ERROR, ToastType.ERROR);
   };

   useEffect(() => {
      let isMounted = true;
      if (userToken) {
         setLoading(true);
         fetchAppointments(isMounted);
      }
      return () => (isMounted = false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userToken, filterStatus]);

   const fetchAppointments = async (isMounted = true) => {
      //Appointments api call disabled for launching
   /*    const errorMessage =
         'Ocurrió un error en la búsqueda de turnos programados';
      const threeMonthsAgo = formatDate(substractMonths(new Date(), 3));
      const nextMonth = formatDate(addMonths(new Date(), 1));
      const today = formatDate(new Date());
      let dateFrom = null;
      let dateTo = null;
      if (filterStatus === AppointmentStatus.PROGRAMMED) {
         dateFrom = today;
         dateTo = nextMonth;
      } else {
         dateFrom = threeMonthsAgo;
         dateTo = nextMonth;
      }

      await getAppointments(
         {
            statuses: [filterStatus],
            dateFrom: [dateFrom],
            dateTo: [dateTo],
         },
         userToken,
         showError,
      )
         .then((response) => {
            if (isMounted && response?.status === OK) {
               const appointmentDtos = response?.data?.map((appointment) => {
                  let appointmentDto = Appointment.AppointmentDto(
                     appointment?.id,
                     appointment?.appointment_date,
                     appointment?.status,
                     appointment?.patient_dto,
                     appointment?.professional_dto,
                  );
                  appointmentDto.consultation_id = appointment?.consultation_id;
                  appointmentDto.specialty = appointment?.specialty?.name;
                  return appointmentDto;
               });
               setAppointmentList(sortDescAppointmentList(appointmentDtos));
            } else {
               showError(errorMessage);
            }
         })
         .catch(() => {
            showError(errorMessage);
         })
         .finally(() => setLoading(false)); */
   };

   const handleClose = (confirmResponse) => {
      setOpenModal(false);

      if (confirmResponse) {
         const errorMessage = 'Ocurrió un error al cancelar el turno';
         setLoading(true);
         putAppointment(
            selectedAppointment?.id,
            { status: AppointmentStatus.CANCELLED },
            userToken,
            showError,
         )
            .then((response) => {
               if (OK === response?.status.toUpperCase()) {
                  removeIndexFromList(
                     selectedAppointment.index,
                     appointmentList,
                     setAppointmentList,
                  );
               } else {
                  showError(errorMessage);
               }
               setSelectedAppointment(null);
            })
            .catch(() => showError(errorMessage))
            .finally(() => setLoading(false));
      }
   };

   const renderList = () => {
      if (appointmentList.length) {
         return (
            <div className="list">
               <List>
                  {appointmentList.map((appointment, index) => {
                     return (
                        <AppointmentItem
                           appointment={appointment}
                           key={appointment.id}
                           remove={() => {
                              setSelectedAppointment(appointment);
                              setOpenModal(true);
                           }}
                           handleDelete={() => {
                              appointment.index = index;
                              setSelectedAppointment(appointment);
                              setOpenModal(true);
                           }}
                        />
                     );
                  })}
               </List>
            </div>
         );
      } else {
         return (
            <div className="empty">
               <p className="message">
                  No hay turnos {appointmentStatusTranslatePlural(filterStatus)}{' '}
                  que mostrar
               </p>
            </div>
         );
      }
   };

   return (
      <div className="my-appointments-list">
         {loading ? <LoadingSpinner /> : renderList()}
         <ConfirmationModal
            open={openModal}
            handleClose={handleClose}
            title="¿Deseas cancelar el turno?"
            confirmButton="Confirmar"
            cancelButton="Volver"
            maxWidth="sm"
         />
      </div>
   );
};

export default AppointmentList;
